import { Link } from "react-router-dom";
// import { Link } from "@mui/material";
import "assets/styles/layout/HeaderSignUp.scss";
import logo from "assets/images/common/lg-logo.jpg";

function HeaderSignUp() {
  return (
    <div>
      <div className="header-login">
        <div className="logo-login">
          <Link to="/"><img src={logo} alt="LG Logo" /></Link>
        </div>
      </div>
    </div>
  );
}
export default HeaderSignUp;