import React, { useState, useEffect, useRef } from 'react';
import { ApiManager } from 'common/lib/ApiUtil';
import { ComNoticfication } from 'common/components/ComNoticfication';
import close from "assets/icons/close.svg";
import search from "assets/icons/search.svg";
const ComTextBoxFind = React.forwardRef(({
    callRelationFuntion         // 부모에서 호출 할 함수
    , callOpenPopupFunction
    , callClearFunction
    , callOpenValid             //단건 조회전 valid체크
    , defaultValue              // 기본 값
    , elementId                 // 객체 아이디

    // 스타일 관련
    , defaultDisabled       // 활성화여부
    , defaultParentStyle
    , defaultStyle
    , defaultClassName
    , textField
    , defaultReadOnly
    , defaultRaiseClick
    //단건 세팅
    , defaultSearch
}

    , ref) => {

    const textRef = useRef(null);
    const elementRef = useRef(null);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userValue, setUserValue] = useState(false);
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [userClickRise, setUserClickRise] = useState(false);

    const [userIsReadOnly, setUserIsReadOnly] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);
    const [userParentStyle, setUserParentStyle] =  useState({});
    const [hasParentStyle, setHasParentStyle] = useState(false)
    const [isClearButtonShow, setIsClearButtonShow] = useState(false);


    React.useImperativeHandle(ref, () => (
        {
            getValue: () => textRef.current

            , setStyle: (v) => { setHasStyle(true); setUserStyle(v); }
            , setClassName: (v) => { setHasClassName(true); setUserClassName(v); }
            , setDisabled: (v) => { setUserDisable(v); }
            , setValue: (v) => {
                textRef.current = v;

                if (v !== undefined && v != null) {
                    if (textField && v.hasOwnProperty(textField)) {
                        setUserValue(v[textField]);

                        if (v[textField] !== undefined && v[textField] !== null && v[textField].toString().replace(/ /gi, "").length > 0) {
                            //readonly일경우 노출X
                            if (defaultReadOnly) {
                                setIsClearButtonShow(false);
                            } else {
                                setIsClearButtonShow(true);
                            }
                        }
                        else {
                            setIsClearButtonShow(false);
                        }

                    }
                    else {
                        setUserValue(v);

                        if (v !== undefined && v !== null && v.toString().replace(/ /gi, "").length > 0) {
                            setIsClearButtonShow(true);
                        }
                        else {
                            setIsClearButtonShow(false);
                        }
                    }
                }
                else {
                    setUserValue('');
                    setIsClearButtonShow(false);
                }
            }
            , setFocus: () => {
                elementRef.current.focus();
            },
        }
    ));


    // 전달 파라미터가 변경될때
    useEffect(() => {
        if (defaultValue !== undefined && defaultValue !== null) {
            textRef.current = defaultValue;
            setUserValue(defaultValue);
        }
        if (defaultStyle) {
            setUserStyle(defaultStyle);
            setHasStyle(true);
        }

        if (defaultParentStyle) {
            setUserParentStyle(defaultParentStyle)
            setHasParentStyle(true);
        }

        if (defaultClassName) {
            setUserClassName(defaultClassName)
            setHasClassName(true)
        }
        if (defaultDisabled === undefined || defaultDisabled === null) {
            setUserDisable(false);
        }
        else {
            setUserDisable(defaultDisabled);
        }

        if (defaultReadOnly === undefined || defaultReadOnly === null) {
            setUserIsReadOnly(false);
        }
        else {
            setUserIsReadOnly(defaultReadOnly);
        }

        if (defaultRaiseClick === undefined || defaultRaiseClick === null) {
            setUserClickRise(true);
        }
        else {
            setUserClickRise(defaultReadOnly);
        }

    }, [defaultValue, defaultStyle, defaultParentStyle, defaultClassName, defaultDisabled, defaultReadOnly, defaultRaiseClick]);


    const onComTextBoxFindOpenPopup = () => {
        if (!userIsReadOnly) {
            let chkValid = true;
            if (callOpenValid) {
                chkValid = callOpenValid();
            }
            if (chkValid) {
                if (userValue && defaultSearch?.current?.url) {
                    onSearchExecute(defaultSearch.current);
                } else {
                    callOpenPopupFunction(elementId, userValue, null);
                }
            }
        }
    }

    const onSearchExecute = async (sendParam) => {

        try {
            let resultData = await ApiManager.post(sendParam.url, sendParam);
            if (resultData?.statusCode) {
                ComNoticfication.ShowErrorUI("Error", "An error occurred during processing");
            } else {
                if (resultData) {
                    callOpenPopupFunction(elementId, userValue, resultData);
                } else {
                    callOpenPopupFunction(elementId, userValue, null);
                }
            }
        }
        catch (ex) {
            console.log(ex.message)
        }
    }

    const onComTextBoxFindKeyPress = (e) => {
        if (e.key === 'Enter') {
            onComTextBoxFindOpenPopup();
        }
    }

    const clearValue = () => {

        textRef.current = null;

        setIsClearButtonShow(false);
        setUserValue("");
        if (callClearFunction) {
            callClearFunction(elementId, "")
        }
    }

    const textChanged = e => {
        let v = e.target.value
        setUserValue(v);

        if (v !== undefined && v !== null && v.toString().replace(/ /gi, "").length > 0) {
            setIsClearButtonShow(true);
        }
        else {
            setIsClearButtonShow(false);
        }
        if (textRef.current) {
            textRef.current = null;
        }

        if (callRelationFuntion) {
            callRelationFuntion(elementId, v);
        }
    }

    return (
        <div className='find-input-area'>
            <div className="find-ip-parent" style={{width: '100%'}}>
                <input name="comTextBoxFind"
                    ref={elementRef}
                    type="text"
                    value={userValue || ""}
                    disabled={userDisable}
                    className={hasClassName ? userClassName : ""}
                    style={hasStyle ? userStyle : { position: "relative" }}
                    onKeyPress={onComTextBoxFindKeyPress}
                    onChange={textChanged}
                    onClick={userClickRise ? onComTextBoxFindOpenPopup : null}
                    readOnly={userIsReadOnly}
                />
                <img
                    onClick={clearValue}
                    className="btn-close"
                    src={close}
                    style={{ display: userDisable ? "none" : (isClearButtonShow ? "block" : "none") }}
                />
                <img
                    className="btn-search"
                    src={search}
                    style={{ display: userDisable ? "none" : "block" }}
                    onClick={onComTextBoxFindOpenPopup}
                />
            </div>
        </div>

    )
});
export default React.memo(ComTextBoxFind);