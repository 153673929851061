import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/PagesCommon.scss';
import search from "assets/icons/search-normal.svg";

import * as wijmo from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { createElement, format } from "@grapecity/wijmo";
import { MessageContext } from "common/lib/MessageProvider";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';
import ComTextArea from "common/components/ComTextArea";
import ComTextBox from "common/components/ComTextBox";

function FollowUpRemark({props}) {
  //url
  const saveFollowUpRemarkUrl = "/order/sampleOrder/saveFollowUpRemark";

  const [headerId, setHeaderId] = useState('');
  const entityFollowUpRemark = useRef(null);
  const entityFollowUpComment = useRef(null);
  const entityRemarkDate = useRef(null);

  const _isMounted = useRef(true);

  const onAddRemarkExecute = async(r) => {
    if (r) {
      let followUpRemark = entityRemarkDate.current.getValue() + " : " + entityFollowUpComment.current.getValue() + "\n" + entityFollowUpRemark.current.getValue();

      // send remark (headerId, followUpRemark)
      let sendParam = {
        headerId: headerId,
        followUpRemark: followUpRemark
      }

      let resultData = await ApiManager.post(saveFollowUpRemarkUrl, sendParam);
      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "Follow Up/ Activity Remark has not been saved successfully");
      } else {
        ComNoticfication.ShowAlertUI("Success", "Follow Up/ Activity Remark has been saved successfully.");
        entityFollowUpComment.current.setValue('');
        entityFollowUpRemark.current.setValue(followUpRemark);
      }
    }
  };

  const onClickAddRemark = () => {
    if (entityFollowUpComment.current.getValue().length > 0) {
      ComNoticfication.ShowConfirmUI("Info", "Are you sure you want to add this Remark?", onAddRemarkExecute);
    } else {
      ComNoticfication.ShowErrorUI("Error", "Please input Follow Up/ Activity Remark!");
    }
  };

  useEffect(() => {
    let abortController = new AbortController();

    setHeaderId(props?.headerId ?? '');
    entityRemarkDate.current.setValue(wijmo.Globalize.format(new Date(), "MM/dd/yyyy"));      
    entityFollowUpRemark.current.setValue(props?.remark ?? '');

    return () => { _isMounted.current = false; abortController.abort(); }
  }, [props]);


  return (
    <div className="bx-card-group mt-10">        
      <div className="search-wrapper">
        <div className="bx-search2">
          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">Follow Up / <br />Activity Remark</label>
            </div>
            <div className="bx-inp combo inp-mix inp-column">    
              <div className="inp-items">
                <ComTextBox ref={entityRemarkDate} defaultDisabled={true}/>
                <ComTextBox ref={entityFollowUpComment}/>
                <Button variant="contained" onClick={onClickAddRemark}>Add</Button>
              </div>
              {/* <div className="inp-items"> */}
                <ComTextArea 
                  ref={entityFollowUpRemark}
                  defaultStyle={{
                    resize: "vertical",
                    minHeight: "40px",
                  }}
                />
              {/* </div>  */}
            </div>
          </div>
        </div>   
      </div>                  
    </div>  
  );
}

export default FollowUpRemark;