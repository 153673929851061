import { useEffect, useRef, useState } from "react";

import { FlexGrid } from "@grapecity/wijmo.react.grid";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";

import { CollectionView } from "@grapecity/wijmo";
import Divider from "@mui/material/Divider";
import { ComUtils } from "common/lib/ComUtils";

import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";

function ShowModelSearch({ callbackFuntion, isOpen, randomKey }) {
  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);
  const filterObject = useRef();
  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGidData] = useState(null);

  const initFilter = (f) => {
    filterObject.current = f;
  };
  const initGridFormat = () => {
    let gridFormat = [
      {
        binding: "lineNo",
        header: " ",
        width: 50,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "modelCode",
        header: "Model Code",
        width: 300,
        align: "center",
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "description",
        header: "Description",
        width: "*",
        minWidth: 300,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
      },
    ];
    setGridColGroup([...gridFormat]);
  };

  const initGridBindData = () => {
    let resultData = [
      {
        lineNo: 1,
        modelCode: "10004953",
        description: "PCB 8 LAYER",
      },
      {
        lineNo: 2,
        modelCode: "10120316D",
        description: "PCB",
      },
      {
        lineNo: 3,
        modelCode: "10120317B",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
      {
        lineNo: 4,
        modelCode: "10120340E",
        description: "PCB",
      },
    ];
    setGidData(
      new CollectionView(resultData, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
  };

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });
  };
  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();
    if (isOpen) {
      popComponent.current.show(true);

      positionTopValue.current = popComponent.current.hostElement.style.top;
    }
    setPopuView(isOpen);

    initGridFormat();
    initGridBindData();

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [randomKey]);

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    callbackFuntion(false, -1, null);
  };

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Model Search"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal ">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">Model Code</label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox />
              <div className="btn-group-end">
                <Button
                  variant="contained"
                  className="btn-default btn-search-in-title"
                >
                  {"Search"}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", maxHeight: "350px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ShowModelSearch;
