import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';
import { FlexGrid } from '@grapecity/wijmo.react.grid';

import Divider from "@mui/material/Divider";

import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import * as wjInput from '@grapecity/wijmo.react.input';
import ComTextBox from 'common/components/ComTextBox';
import { ComUtils } from 'common/lib/ComUtils';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { MessageContext } from "common/lib/MessageProvider";


function AnalystSchPop({ open, closeFn, randomKey, reqBillToCode, reqClaimAnalyst, reqSalesChannelName, reqClaimType, reqDivisionCode, reqType, analystResData }) {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const msgComErr003 = ComUtils.getMessage(messageObject, "COM_ERR_00003"); // The data is not available
  const msgClmErr011 = ComUtils.getMessage(messageObject, "CLM_ERR_00011"); // An error occurred during processing

  //session정보
  const userType = sessionStorage.getItem("userType").toString();

  //url  
  const retrieveAnalyList = "/com/comPop/retrieveAnalyList";


  // 검색
  const entitySalesChannelName = useRef(null);
  const entityClaimAnalyst = useRef(null);


  const filterSearch = useRef(null);
  const gridSearch = useRef(null);


  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  /***** AnalyListSearch Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {

    setGridObject(sender);

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, { hasShowNumbering: true, selectionMode: 6, canAreaInfo: false, hasCheckBox: false, hasDisableCheck: true, hasReadOnlyCombo: true, readOnlyComboList: ["langCode", "effectiveStartDate"] })


    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
        popComponent.current.hide();
        closeFn(sender.selectedItems[0]);
      }
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

  }

  const onSourceChanged = () => {
    if (gridObject) {
      gridObject.select(-1, -1);
    }
  }
  /*******AnalyList Search Grid Format *********************************************************************************/
  //Memory 
  const _isMounted = useRef(true);

  // AnalyList Search Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: 'billTo', header: "Bill To Code", width: 150, allowDragging: true, isReadOnly: true, visible: false, align: "left" }
      , { binding: 'billToName', header: "Bill To Name", width: 200, allowDragging: true, isReadOnly: true, visible: false, align: "left" }
      , { binding: 'salesChannelName', header: "Sales Channel Name", width: 200, allowDragging: true, isReadOnly: true, visible: false, align: "center" }
      , { binding: 'claimAnalyst', header: "Claim Analyst", width: 567, allowDragging: false, isReadOnly: true, align: "left" }
      , { binding: 'empNo', header: "Emp no", width: 567, allowDragging: false, isReadOnly: true, visible: false, align: "left" }
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();

    const getPageInfo = async () => {

      let sendParam = {
        defalultYn: "Y",
        billToCode: reqBillToCode
      }
      const [resultData] = await Promise.all([
        ApiManager.post(retrieveAnalyList, sendParam),
      ]);

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
      } else {
        setGridData(
          new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );
        setGridDataCount(resultData.list.length);
      }

      //조회된 내역존재 (다건)
      // if (analystResData) {
      //   setGridData(new CollectionView(analystResData.list, { trackChanges: true, refreshOnEdit: false }));

      //   entityPageNo.current = 1;
      //   let pageNoVal = entityPageNo.current;
      //   let sendParam = {
      //     pageSize: '1'
      //     , rowSize: '100'
      //     , pageNo: pageNoVal.toString()
      //     , billToCode: reqBillToCode
      //     , salesChannelName: reqSalesChannelName
      //     , claimAnalyst: reqClaimAnalyst
      //     , empNo: ''
      //     , claimType: reqClaimType
      //     , divisionCode: reqDivisionCode
      //     , userType: userType
      //     , reqType: reqType
      //   }
      //   searchDataRef.current = sendParam;

      //   totCntRef.current = Number(analystResData.totCnt);
      //   pageCntRef.current = Number(analystResData.list.length);
      //   setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());

      // }
      SearchGridFormat();
    }
    getPageInfo();
    if (open) {
      filterSearch?.current?.clear();
    }

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  // 조회
  const onSearch = (param, e) => {

    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {
      pageSize: '1'
      , rowSize: '100'
      , pageNo: pageNoVal.toString()
      , billToCode: reqBillToCode
      , salesChannelName: entitySalesChannelName.current.getValue()
      , claimAnalyst: entityClaimAnalyst.current.getValue()
      , empNo: ''
      , claimType: reqClaimType
      , divisionCode: reqDivisionCode
      , userType: userType
      , reqType: reqType
    }

    searchDataRef.current = sendParam;
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {

    filterSearch.current.clear();
    try {

      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveAnalyList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveAnalyList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
        // setGridDataCount(0);
      } else {
        if (sendParam.billToCode == null) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgComErr003);
        } else {
          let resultCnt = 0;
          //조회버튼 클릭
          if (param.searchFlag) {

            setGridData(
              new CollectionView(resultData.list, {
                trackChanges: true,
                refreshOnEdit: false,
              })
            );
            resultCnt = resultData.list.length;
            //단건시 팝업 close
            if (resultCnt == 1) {
              popComponent.current.hide();
              closeFn(resultData.list[0]);
            }
          } else {
            let collectionView = new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })

            let totResultData = [...param.view._pgView, ...collectionView._pgView];
            setGridData(totResultData);
            resultCnt = totResultData.length;
          }
          totCntRef.current = Number(resultData.totCnt);
          pageCntRef.current = Number(resultCnt);

          if (totCntRef.current == pageCntRef.current) {
            setGridDataCount(totCntRef.current.toLocaleString());
          } else {
            setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
          }
        }
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Analyst Search"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Sales Channel Name</label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox ref={entitySalesChannelName} elementId={"entitySalesChannelName"} />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Claim Analyst</label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox ref={entityClaimAnalyst} elementId={"entityClaimAnalyst"} />
            </div>
          </div>
        </div>
        <div className="w-100 btn-group-end mt-10">
          <Button className="upload-btn" variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>
            {"Search"}
          </Button>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ width: "602px", minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  );

}

export default AnalystSchPop;