import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';


import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";

import Divider from "@mui/material/Divider";

import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";
import { ComUtils } from "common/lib/ComUtils";
import { CollectionView, createElement, format } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { MessageContext } from "common/lib/MessageProvider";
import ComDateNotRequired from 'common/components/ComDateNotRequired';


function PriceInvoicePop({ open, closeFn, reqBillToCode, reqInvoiceNo, rsltData, randomKey, pageInfo }) {
  //session정보
  const userLevel = pageInfo.userLevel; 

  //url  
  const retrievePriceInvoiceListUrl = "/com/gsrComPop/retrievePriceInvoiceList";

  // 검색
  const entityinvoiceNo = useRef(null);
  const entityInvoiceDateFrom = useRef();
  const entityInvoiceDateTo = useRef();
  const [invCode, setInvCode] = useState(null);
  const loadingFlag = useRef(false);

  const filterSearch = useRef(null);
  const [popuView, setPopuView] = useState(true);



  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();
  
  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const availableMessage = ComUtils.getMessage(messageObject, "COM_ERR_00003");
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028");    //Submit Error. Please Contact System Admin.
  const msgComErr003 = ComUtils.getMessage(messageObject, "COM_ERR_00003");    //The data is not available


  const initFilter = (f) => {
    filterSearch.current = f;
  };

  const initGrid = (sender) => {
    setGridObject(sender);

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, { hasShowNumbering: true, selectionMode: 6, canAreaInfo: false, hasCheckBox: false, hasDisableCheck: true, hasReadOnlyCombo: true, readOnlyComboList: ["langCode", "effectiveStartDate"] })


    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
        popComponent.current.hide();
        closeFn(sender.selectedItems[0]);
      } else {
        //메세지 부분 추가 2
        ComNoticfication.ShowErrorUI(
          ComUtils._altTitleError,
          availableMessage
        );
      }
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current !== totCntRef.current) {
          let view = s.collectionView;
          let index = view._pgView.length; // keep position in case the view is sorted
          entityPageNo.current += 1;
          let pageNoVal = entityPageNo.current;
          searchDataRef.current.pageNo = pageNoVal.toString();
          onSearchExecute(searchDataRef, { searchFlag: false, view: view })
          view.refresh();
          view.currentPosition = index;
        }
      }
    });

  };

  const onSourceChanged = () => {
    if (gridObject) {
      gridObject.select(-1, -1);
    }
  }  

  //Memory
  const _isMounted = useRef(true);

  // Grid Format
  const initGridFormat = useCallback(() => {
    
    let gridFormat = [
      { binding: 'invoiceNo', header: "Invoice#", width: 110, allowDragging: true, isReadOnly: true, align: "center" },
      { binding: 'invoiceDate', header: "Invoice Date", width: 110, allowDragging: true, isReadOnly: true, align: "center" },
      { binding: 'modelName', header: "Model", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
      { binding: 'netAmount', header: "Net Amount", width: 100, allowDragging: true, isReadOnly: true, align: "right" },
      { binding: 'taxAmount', header: "Tax", width: 100, allowDragging: true, isReadOnly: true, align: "right" },
      { binding: 'invoiceAmount', header: "Invoice Amount", width: "*", allowDragging: true, isReadOnly: true, align: "right" }
    ];
    setGridColGroup([...gridFormat]);
  }, []);


  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {
      initGridFormat();

      const [fromDate, toDate] = await Promise.all([

        ApiManager.getServerDate("MM/DD/YYYY", 0, -6),
        ApiManager.getServerDate("MM/DD/YYYY")

      ]);

      entityInvoiceDateTo?.current?.setValue(toDate);

      if (rsltData) {
        setGridData(new CollectionView(rsltData.list, { trackChanges: true, refreshOnEdit: false }));

        entityPageNo.current = 1;
        let pageNoVal = entityPageNo.current;
        let sendParam = {
          pageSize: '1'
          , rowSize: pageInfo.pageRowSize
          , pageNo: pageNoVal.toString()
          , billToCode: reqBillToCode
          , invoiceNo: reqInvoiceNo
          , endDate : toDate
          , userLevel: userLevel
        }
        searchDataRef.current = sendParam;

        totCntRef.current = Number(rsltData.totCnt);
        pageCntRef.current = Number(rsltData.list.length);
        setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());

        entityInvoiceDateFrom?.current?.setValue(null);
      }else{

        entityInvoiceDateFrom?.current?.setValue(fromDate);

      }
      initGridFormat();


    }
    getPageInfo();

    if (open) {
      filterSearch?.current?.clear();
    }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [open]);


  // 조회
  const onSearch = (param, e) => {
    pageCntRef.current = 0;
    totCntRef.current = 0;
    
    let invoiceDateFrom = entityInvoiceDateFrom?.current?.getDateValueInteger() ?? "";
    let invoiceDateTo = entityInvoiceDateTo?.current?.getDateValueInteger() ?? "";

    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;
    let sendParam = {
      pageSize: "1",
      rowSize: pageInfo.pageRowSize || "100",
      pageNo: pageNoVal.toString(),
      billToCode: reqBillToCode,
      invoiceNo: entityinvoiceNo?.current?.getValue() === null ? "" : entityinvoiceNo?.current?.getValue(),
      startDate: invoiceDateFrom.toString(),
      endDate: invoiceDateTo.toString()
    }

    searchDataRef.current = sendParam;
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    filterSearch.current.clear();
    try {

      let resultData = await ApiManager.post(retrievePriceInvoiceListUrl, sendParam);

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
      } else {
        let resultCnt = 0;
        if (param.searchFlag) {
          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);
        setGridDataCount(totCntRef.current.toLocaleString());
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top
  }


  return (

    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{ width: "840px" }}
    >
      <div className="wj-dialog-header">
        <h3 className="pop-title">{"Price Difference Search"}</h3>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap" style={{display: "flex"}}>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Invoice#
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox 
                ref={entityinvoiceNo} 
                elementId={"entityinvoiceNo"}
                defaultValue={reqInvoiceNo}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Invoice Date
              </label>
            </div>
            <div className="bx-inp combo inp-mix">
              <ComDateNotRequired
                ref={entityInvoiceDateFrom}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
              />
              {` ~ `}
              <ComDateNotRequired
                ref={entityInvoiceDateTo}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
              />
            </div>
            <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
          </div>
        </div>
        <div style={{paddingTop: "10px"}}>Total: {gridDataCount}</div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight:"200px", height: "200px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup >
  );
}

export default PriceInvoicePop;
