import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';


import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";

import Divider from "@mui/material/Divider";

import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";
import { ComUtils } from "common/lib/ComUtils";
import { CollectionView, createElement, format } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { MessageContext } from "common/lib/MessageProvider";
import search from "assets/icons/search.svg";
import VendorRegPop from './Clm/VendorRegPop';
import VendorModPop from './Clm/VendorModPop';


function SupplierPop({ open, closeFn, billToData, reqRandomKey, supplierCode, pageInfo }) {
  //session정보
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userLevel = pageInfo.userLevel; 

  const randomKey = useRef(null);

  //url  
  const retrieveSupplierListUrl = "/com/comOther/retrieveSupplierList";

  // 검색
  const entityCallVendorCode = useRef(null);
  const entityVendorCode = useRef(null);
  const entityVendorName = useRef(null);


  const filterSearch = useRef(null);
  const gridSearch = useRef(null);


  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);


  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const availableMessage = ComUtils.getMessage(messageObject, "COM_ERR_00003");
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028");    //Submit Error. Please Contact System Admin.


  const initFilter = (f) => {
    filterSearch.current = f;
  };

  const initGrid = (sender) => {
    setGridObject(sender);

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, { hasShowNumbering: true, selectionMode: 6, canAreaInfo: false, hasCheckBox: false, hasDisableCheck: true, hasReadOnlyCombo: true, readOnlyComboList: ["langCode", "effectiveStartDate"] })


    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
        popComponent.current.hide();
        closeFn(sender.selectedItems[0]);
      } else {
        //메세지 부분 추가 2
        ComNoticfication.ShowErrorUI(
          ComUtils._altTitleError,
          availableMessage
        );
      }
    });
  };

  //Memory
  const _isMounted = useRef(true);

  // Grid Format
  const initGridFormat = useCallback(() => {
    
    let gridFormat = [
      { binding: 'isNew', header: "", width: 360, allowDragging: true, isReadOnly: true, align: "left", visible: false },
      { binding: 'vendorCode', header: "Vendor Code", width: 175, allowDragging: true, isReadOnly: true, align: "left"
        , cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = ctx.value;
          //let rowIndex = ctx.row.index;
          if(value !== '' && value !== null){
            let element = createElement(
              format(
                `
                  <div style="display: flex; align-items: center;">
                    <span>${value}</span>
                    <img src=${search} width = '20px' height='15px' style="margin-left: 2px;"/>
                  </div>
                `,
                {}
              ),
              cell
            );
            element.onclick = function (e) {
              e.preventDefault();
              fncOpenVendorPop(dataItem?.isNew);
            };
          }
        }
      },
      { binding: 'vendorName', header: "Vendor Name", width: 220, allowDragging: true, isReadOnly: true, align: "left" },
      { binding: 'payToAddress', header: "Address", width: 360, allowDragging: true, isReadOnly: true, align: "left" }
    ];
    setGridColGroup([...gridFormat]);
  }, []);


  const fncOpenVendorPop = (isNew) => {
    if (isNew === 'Y') {
      openVendorRegPopup()
    } else {
      openVendorModPopup()
    }
  }


  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {
      initGridFormat();
      entityVendorName?.current?.setValue(supplierCode);

      let sendParam = {
        billToCode: billToData.billToCode,
        supplierCode: entityVendorCode?.current?.getValue() === null ? "" : entityVendorCode?.current?.getValue(),
        supplierName: entityVendorName?.current?.getValue() === null ? "" : entityVendorName?.current?.getValue()
      }

      let resultData = await ApiManager.post(retrieveSupplierListUrl, sendParam);

      if (resultData?.resultCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgGsrErr028);
      } else {
        console.log('resultData: ', resultData);
        setGridData(
          new CollectionView(resultData, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );
      }
        
      
    }
    getPageInfo();
    if (open) {
      filterSearch?.current?.clear();
    }

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);


  // 조회
  const onSearch = (param, e) => {

    let sendParam = {
      billToCode: billToData.billToCode,
      supplierCode: entityVendorCode?.current?.getValue() === null ? "" : entityVendorCode?.current?.getValue(),
      supplierName: entityVendorName?.current?.getValue() === null ? "" : entityVendorName?.current?.getValue()
    }

    searchDataRef.current = sendParam;
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    filterSearch.current.clear();
    try {

      let resultData = await ApiManager.post(retrieveSupplierListUrl, sendParam);

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
      } else {
        setGridData(
          new CollectionView(resultData, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  //VendorRegPop[S]
  const [openVendorRegPop, setOpenVendorRegPop] = useState(false);

  const openVendorRegPopup = () => {

    randomKey.current = ComUtils.setRefRandomKey();
    setOpenVendorRegPop(true);

  }

  const closeVendorRegPopup = (regData) => {

    setOpenVendorRegPop(false);

  }
  //VendorRegPop[E]

  //VendorRegPop[S]
  const [openVendorModPop, setOpenVendorModPop] = useState(false);

  const openVendorModPopup = () => {

    randomKey.current = ComUtils.setRefRandomKey();
    setOpenVendorModPop(true);

  }

  const closeVendorModPopup = (regData) => {

    setOpenVendorModPop(false);

  }
  //VendorModPop[E]

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top
  }


  return (

    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h3 className="pop-title">{"Supplier"}</h3>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap" style={{display: "flex"}}>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Vendor Code
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox 
                ref={entityVendorCode} 
                elementId={"entityVendorCode"}

              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Vendor Name
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox ref={entityVendorName} elementId={"entityVendorName"} defaultValue={supplierCode} />
              <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
        {openVendorRegPop ? <VendorRegPop open={openVendorRegPop} closeFn={closeVendorRegPopup} billToData={billToData} reqRandomKey={randomKey.current} pageInfo={pageInfo} /> : null}
        {openVendorModPop ? <VendorModPop open={openVendorModPop} closeFn={closeVendorModPopup} billToData={billToData} callVendorCode={entityCallVendorCode?.current} reqRandomKey={randomKey.current} pageInfo={pageInfo} /> : null}
      </div>
    </wjInput.Popup >
  );
}

export default SupplierPop;
