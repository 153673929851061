import React, { useState, useEffect, useRef } from "react";
import search from "assets/icons/search.svg";
import close from "assets/icons/close.svg";

const ComTextBox = React.forwardRef(
  (
    {
      callOpenPopupFunction,
      callRelationFuntion, // 부모에서 호출 할 함수
      callKeyUpFuction,
      callKeyDownFuction,
      callRelationBlur,

      defaultValue, // 기본 값
      elementId, // 객체 아이디
      placeholder,
      // 스타일 관련
      defaultDisabled, // 활성화여부
      defaultReadOnly, // readonly여부
      defaultStyle,
      frameStyle,
      defaultClassName,
      defaultMaxLength,
      keyPressFuction,
      isSearch = false,
      isClose = false,
    },
    ref
  ) => {
    const textRef = useRef(null);
    const elementRef = useRef();
    const isModified = useRef(false);
    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userFrameStyle, setUserFrameStyle] = useState({});
    const [userValue, setUserValue] = useState(false);
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);
    const [userReadOnly, setUserReadOnly] = useState(false);
    const [userMaxLength, setUserMaxLength] = useState(0);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);
    const [hasFrameStyle, setHasFrameStyle] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [randomKey, setRandomKey] = useState(null);

    React.useImperativeHandle(ref, () => ({
      getValue: () => textRef.current,

      setStyle: (v) => {
        setHasStyle(true);
        setUserStyle(v);
      },
      setClassName: (v) => {
        setHasClassName(true);
        setUserClassName(v);
      },
      setDisabled: (v) => {
        setUserDisable(v);
      },
      setReadOnly: (v) => {
        setUserReadOnly(v);
      },
      setValue: (v) => {
        textRef.current = v;
        setUserValue(v);
      },
      setFocus: () => {
        elementRef.current.focus();
      },
    }));

    // 전달 파라미터가 변경될때
    useEffect(() => {
      if (defaultValue !== undefined && defaultValue !== null) {
        textRef.current = defaultValue;
        setUserValue(defaultValue);
      }
      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }
      if (defaultReadOnly === undefined || defaultReadOnly === null) {
        setUserReadOnly(false);
      } else {
        setUserReadOnly(defaultReadOnly);
      }

      if (defaultMaxLength) {
        setUserMaxLength(defaultMaxLength);
      }
      if (frameStyle) {
        setUserFrameStyle(frameStyle);
        setHasFrameStyle(true);
      }
    }, [
      defaultValue,
      defaultStyle,
      defaultClassName,
      defaultDisabled,
      defaultMaxLength,
      frameStyle,
    ]);

    const textChanged = (e) => {
      isModified.current = true;
      setUserValue(e.target.value);

      textRef.current = e.target.value;
      if (callRelationFuntion) {
        callRelationFuntion(elementId, e.target.value);
      }
    };

    const onComTextBoxKeyPress = (e) => {
      if (keyPressFuction) {
        keyPressFuction(e);
      }
    };

    const handleKeyUp = (e) => {
      if (callKeyUpFuction) {
        callKeyUpFuction(elementId, e);
      }
    };

    const handleKeyDown = (e) => {
      if (callKeyDownFuction) {
        callKeyDownFuction(elementId, e);
      }
    };



    const handleFocus = (e) => {
      elementRef.current.select();
    };

    const handleOnClickSearch = () => {
      callOpenPopupFunction(elementId, userValue);
    };

    const handleBlur = (e) => {
      if (callRelationBlur) {
        let value = textRef.current ?? "";
        if (value !== "" && value !== null) {
          if (isModified.current) {
            callRelationBlur(elementId, e.target.value);  
  
            isModified.current = false;
          }
        }
      }
    };


    return (
      <div className={isSearch ? "input-search" : "input-normal"} style={hasFrameStyle ? userFrameStyle : {}}>
        <input
          name="comTextBox"
          type="text"
          ref={elementRef}
          value={userValue || ""}
          onChange={textChanged}
          disabled={userDisable}
          className={hasClassName ? userClassName : ""}
          style={hasStyle ? userStyle : {}}
          placeholder={placeholder}
          onKeyPress={onComTextBoxKeyPress}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          maxLength={userMaxLength ? userMaxLength : ""}
          onBlur={handleBlur}
          onFocus={handleFocus}
          readOnly={userReadOnly}
        />

        {isClose && <img className="ic-close" src={close} width="20px" />}
        {isSearch && (
          <>
            <img
              className="ic-search"
              src={search}
              onClick={handleOnClickSearch}
            />
          </>
        )}
      </div>
    );
  }
);
export default React.memo(ComTextBox);
