import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUser, registerUserProceeding, registerUserSuccessful, registerUserFailed } from 'store/auth/register/reducer';

import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import "assets/styles/pages/SignUpPage.scss";

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import ComTextBox from "common/components/ComTextBoxWithForm";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";


const PersonalInforExternalPage = React.forwardRef(
  ({ }
    , ref
  ) => {

    const nameRegex = /^[a-zA-Z0-9\s]*$/;
    const digitsRegex = /^[0-9]*$/;
    const alphaNumericRegex = /^[a-zA-Z0-9 ]*$/;
    const emailRegex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    // const alphaRegex = /^[a-zA-Z ]*$/;

    // 메세지 추가
    // const { messageObject } = useContext(MessageContext);
    // const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");
    // const errorMessage = ComUtils.getMessage(messageObject, "JMSG_COM_ERR_001");

    const entityUserName = useRef(null);
    const entityEmail = useRef(null);
    const entityOfficePhone = useRef(null);

    const entityJobTitle = useRef(null);
    const entityMobilePhone = useRef(null);
    const entityDepartment = useRef(null);

    const dispatch = useDispatch();
    const AccountProperties = createSelector(
      (state) => state.account,
      (account) => ({
        user: account.user
      })
    );
    const { user } = useSelector(AccountProperties, shallowEqual);

    React.useImperativeHandle(ref, () => ({
      isEmptyRequired: () => {
        if (!user.userName) {
          ComNoticfication.ShowAlertUICallback(
            ComUtils._altTitleWarn, "User Name is required."
            , () => { entityUserName.current.setFocus(); });
        }
        else if (!user.email) {
          ComNoticfication.ShowAlertUICallback(
            ComUtils._altTitleWarn, "E-mail is required."
            , () => { entityEmail.current.setFocus(); });
        }
        else if (!user.officePhone) {
          ComNoticfication.ShowAlertUICallback(
            ComUtils._altTitleWarn, "Office Phone is required."
            , () => { entityOfficePhone.current.setFocus(); });
        }
      }
    }));

    const onBlurUserName = (id, value) => {
      // if (value.match(nameRegex)) {
      if (nameRegex.test(value)) {
        user.userName = value;
        dispatch(registerUserProceeding(user));
      } else {
        if (value) ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "Special Character is not allowed in the User Name.");
      }
    };

    const onBlurEmail = (id, value) => {
      // if (value.match(emailRegex)) {
      if (emailRegex.test(value)) {
        user.email = value;
        dispatch(registerUserProceeding(user));
      } else {
        if (value) ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "Email value is invalid.");
      }
    };

    const onBlurOfficePhone = (id, value) => {
      if (digitsRegex.test(value)) {
        user.officePhone = value;
        dispatch(registerUserProceeding(user));
      } else {
        if (value) ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "office value is invalid.");
      }
    };

    const onBlurMobilePhone = (id, value) => {
      if (digitsRegex.test(value)) {
        user.mobilePhone = value;
        dispatch(registerUserProceeding(user));
      } else {
        if (value) ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "mobile value is invalid.");
      }
    };

    const onBlurJobTitle = (id, value) => {
      if (value) {
        user.jobtitle = value;
        dispatch(registerUserProceeding(user));
      }
    };

    const onBlurDepartment = (id, value) => {
      if (value) {
        user.dept = value;
        dispatch(registerUserProceeding(user));
      }
    };

    const _isMounted = useRef(true);
    useEffect(() => {
      let abortController = new AbortController();

      const getPageInfo = async () => {

        entityUserName.current.setValue(user.userName);
        entityEmail.current.setValue(user.email);
        entityOfficePhone.current.setValue(user.officePhone);
        entityMobilePhone.current.setValue(user.mobilePhone);
        entityJobTitle.current.setValue(user.jobtitle);
        entityDepartment.current.setValue(user.dept);

      }

      getPageInfo();

      // ComponentWillUnmount in Class Component
      return () => {
        _isMounted.current = false;
        abortController.abort();
      };
    }, [user]);


    return (
      <div className="content-signup">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid>
            <p>
              User Name <span className="required">*</span>
            </p>
            <ComTextBox ref={entityUserName} elementId={"entityUserName"} callRelationBlur={onBlurUserName} defaultMaxLength={100} />
          </Grid>
          <Grid>
            <p>
              E-mail <span className="required">*</span>
            </p>
            <ComTextBox ref={entityEmail} elementId={"entityEmail"} callRelationBlur={onBlurEmail} defaultMaxLength={100} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid>
            <p>
              Office Phone <span className="required">*</span>
            </p>
            <ComTextBox ref={entityOfficePhone} elementId={"entityOfficePhone"} callRelationBlur={onBlurOfficePhone} defaultMaxLength={10} />
          </Grid>
          <Grid>
            <p>Job Title</p>
            <ComTextBox ref={entityJobTitle} elementId={"entityJobTitle"} callRelationBlur={onBlurJobTitle} defaultMaxLength={100} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid>
            <p>Mobile Phone</p>
            <ComTextBox ref={entityMobilePhone} elementId={"entityMobilePhone"} callRelationBlur={onBlurMobilePhone} defaultMaxLength={10} />
          </Grid>
          <Grid>
            <p>Department</p>
            <ComTextBox ref={entityDepartment} elementId={"entityDepartment"} callRelationBlur={onBlurDepartment} defaultMaxLength={100} />
          </Grid>
        </Grid>

      </div>
    );
  }
);
export default PersonalInforExternalPage;
