import { Divider } from "@mui/material";
import "assets/styles/pages/SignUpPage.scss";
import "assets/styles_mobile/components/layout/pages/ResponsiveSignUp.scss";
import IrSpiffConfDtl from "Pages/Clm/Isf/IrSpiffConfDtl";
import OtherClaimRequestDtl from "Pages/Clm/Oth/Other/OtherClaimRequestDtl"
import SampleRequestApprover from "Pages/Spl/SampleRequestApprover";
import SampleTrackingDateExtension from "Pages/Spl/SampleTrackingDateExtension";
import SampleTrackingInvoice from "Pages/Spl/SampleTrackingInvoice";
import SampleTrackingGift from "Pages/Spl/SampleTrackingGift";
import SampleTrackingAddressChange from "Pages/Spl/SampleTrackingAddressChange";
import SampleFollowUpReturn from "Pages/Spl/SampleFollowUpReturn";
import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderSignUp from "./HeaderSignUp";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import ClaimAgreementApprovalListMod from "Pages/Agr/ClaimAgreementApprovalListMod";

function EpLoginPage() {
  const dispatch = useDispatch();

  const [isType, setIsType] = useState(null);
  const entityType = useRef(null);
  const entityReqId = useRef(null);
  const entityActionTarget = useRef(null);
  const entityCacEntryNo = useRef(null);

  const [otherClaimProps, setOtherClaimProps] = useState({});
  const [sampleProps, setSampleProps] = useState({});

  const [pageInfo, setPageInfo] = useState(null);
  const randomKey = useRef(null);

  const _isMounted = useRef(true);
  let content;

  if (isType === "other") {
    content = (
      <OtherClaimRequestDtl
        pageInfo={pageInfo}
        initData={otherClaimProps}
        key={randomKey.current}
      />);
  } else if (isType === "spl") {
    if (sampleProps?.page === "SampleRequestApprover") {
      content = <SampleRequestApprover
        pageInfo={pageInfo}
        props={sampleProps}
        key={randomKey.current}
      />;
    } else if (sampleProps?.page === "SampleTrackingDateExtension") {
      content = <SampleTrackingDateExtension
        pageInfo={pageInfo}
        props={sampleProps}
        key={randomKey.current}
      />;
    } else if (sampleProps?.page === "SampleTrackingInvoice") {
      content = <SampleTrackingInvoice
        pageInfo={pageInfo}
        props={sampleProps}
        key={randomKey.current}
      />;
    } else if (sampleProps?.page === "SampleTrackingGift") {
      content = <SampleTrackingGift
        pageInfo={pageInfo}
        props={sampleProps}
        key={randomKey.current}
      />;
    } else if (sampleProps?.page === "SampleTrackingAddressChange") {
      content = <SampleTrackingAddressChange
        pageInfo={pageInfo}
        props={sampleProps}
        key={randomKey.current}
      />;
    } else if (sampleProps?.page === "SampleFollowUpReturn") {
      content = <SampleFollowUpReturn
        pageInfo={pageInfo}
        props={sampleProps}
        key={randomKey.current}
      />;
    }
  } else if (isType === "cac") {
    content = <ClaimAgreementApprovalListMod
      pageInfo={pageInfo}
      closeFn={null}
      modRandomKey={randomKey.current}
      reqEntryNo={entityCacEntryNo.current}
      reqBillTo={null}
      setViewType={null}
      setDtlViewType={null}
      epLoginFlag={true}
    />
  } else if (isType === "ir" || isType === "spiff") {
    content = (
      <IrSpiffConfDtl
        pageInfo={pageInfo}
        closeFn={null}
        key={randomKey.current}
        reqClaimType={entityType?.current?.toUpperCase()}
        reqClaimId={entityReqId.current}
        reqActionTarget={entityActionTarget.current}
        reqUserLevel={"1"}
        dtlRandomKey={randomKey.current}
        popupYn={"Y"}
      />
    );
  }
  useEffect(() => {
    let abortController = new AbortController();

    //ep login IR/SPIFF Confirm open
    if (sessionStorage.getItem("epLogin") === "Y") {


      setTimeout(() => {
        let now = new Date();
        let timeKey = now.toISOString().replace('T', ' ').substring(0, 19).replace(/[^0-9]/g, '') + now.getMilliseconds().toString();

        randomKey.current = timeKey;
        entityType.current = sessionStorage.getItem("type");
        entityReqId.current = sessionStorage.getItem("reqId");

        let t = {};
        t["origSysDocumentRef"] = "";
        t["copyFlag"] = false;
        t["editFlag"] = false;
        t["timeKey"] = timeKey;
        t["Active"] = true;
        t["pageRowSize"] = sessionStorage.getItem("pageRowSize");

        if (sessionStorage.getItem("type") === "other") {
          let menuItem = {
            menuId: "M9105",
            menuName: "Other Claim Confirm",
            menuUrl: "Clm/Oth/Other/OtherClaimConfirmList",
            tabUniqueKey: "M9105",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Customer Claims > Other Claims > Other Claim Confirm";

          t["claimType"] = sessionStorage.getItem("type");
          t["systemEntryNo"] = sessionStorage.getItem("reqId");
          t["viewType"] = sessionStorage.getItem("viewType");

          setPageInfo(t);
          setIsType(entityType.current);

          setOtherClaimProps({
            epLogin: true,
            systemEntryNo: entityReqId.current,
            viewType: sessionStorage.getItem("viewType"),
          });
        } else if (sessionStorage.getItem("type") === "spl") {
          let menuItem = {
            menuId: "M0138",
            menuName: "Sample Order Tracking",
            menuUrl: "Spl/SampleOrderTracking",
            tabUniqueKey: "M0138",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Sample > Sample Order Tracking";

          t["actionType"] = sessionStorage.getItem("actionType");
          t["headerId"] = sessionStorage.getItem("reqId");
          t["dealType"] = sessionStorage.getItem("dealType");
          t["readType"] = sessionStorage.getItem("readType");
          t["targetEmpNo"] = sessionStorage.getItem("targetEmpNo");

          setPageInfo(t);
          setIsType(entityType.current);

          setSampleProps({
            epLogin: true,
            headerId: sessionStorage.getItem("reqId"),
            actionType: sessionStorage.getItem("actionType") ?? "",
            dealType: sessionStorage.getItem("dealType") ?? "",
            readType: sessionStorage.getItem("readType") ?? "",
            targetEmpNo: sessionStorage.getItem("targetEmpNo") ?? "",
            orderType: "",
            page: SampleRequestUtils.convertDealTypeToTrackingPage(sessionStorage.getItem("dealType")),
          });
        } else if (sessionStorage.getItem("type") === "cac") {
          let menuItem = {
            menuId: "M9010",
            menuName: "Claim Agreement Approval List",
            menuUrl: "Agr/ClaimAgreementApprovalList",
            tabUniqueKey: "M9010",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Agreement > Claim Agreement Approval List";

          t["type"] = sessionStorage.getItem("type");
          t["ssosalesid"] = sessionStorage.getItem("ssosalesid");
          t["viewType"] = sessionStorage.getItem("viewType");
          t["entryNo"] = sessionStorage.getItem("entryNo");
          entityCacEntryNo.current = sessionStorage.getItem("entryNo");
          setPageInfo(t);
          setIsType(entityType.current);
        } else {
          let menuItem = {
            menuId: "M0116",
            menuName: "IR/SPIFF Confirm",
            menuUrl: "Clm/Isf/IrSpiffConfList",
            tabUniqueKey: "M0116",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Customer Claims > IR/Dealer SPIFF > IR/SPIFF Confirm"
          t["claimType"] = sessionStorage.getItem("type");
          t["claimId"] = sessionStorage.getItem("reqId");
          t["viewType"] = false;
          entityActionTarget.current = sessionStorage.getItem("actionTarget");
          setPageInfo(t);
          setIsType(entityType.current);
        }

        // dispatch(openMenu(t));

      }, 200);
    }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };

  }, []);

  return (
    <div className="sign-up-page" style={{ overflowX: 'auto', whiteSpace: "nowrap" }}>
      <HeaderSignUp />
      <Divider />
      {content}

    </div>
  );
}
export default EpLoginPage;
