import React,{useState, useEffect, useRef} from 'react';


const ComPassword = React.forwardRef(({
    callRelationFuntion         // 부모에서 호출 할 함수
    , callRelationBlur // 부모에서 호출 할 함수
    , defaultValue              // 기본 값
    , elementId                 // 객체 아이디
    , placeholder
    // 스타일 관련
    , defaultDisabled       // 활성화여부
    , defaultStyle
    , defaultClassName       
    , defaultMaxLength
    , keyPressFuction
  }
  , ref)=>{

    const textRef = useRef(null);
    const elementRef = useRef();
    // 스타일 관련
    const [userStyle,       setUserStyle]       = useState({});
    const [userValue,       setUserValue]       = useState(false);
    const [userClassName,   setUserClassName]   = useState(false);
    const [userDisable,     setUserDisable]     = useState(false);
    const [userMaxLength,   setUserMaxLength]   = useState(0);

    const [hasClassName,    setHasClassName]    = useState(false);
    const [hasStyle,        setHasStyle]        = useState(false);
    
    React.useImperativeHandle(ref, () => (
        {
             getValue           : () => textRef.current     
            
            ,setStyle           : (v)=>{setHasStyle(true); setUserStyle(v);}
            ,setClassName       : (v)=>{setHasClassName(true);setUserClassName(v);}
            ,setDisabled        : (v)=>{setUserDisable(v);}
            ,setValue           : (v)=>{textRef.current  = v; setUserValue(v);}
            ,setFocus           : ()=>{elementRef.current.focus();}
        }
    ));


    // 전달 파라미터가 변경될때
    useEffect(()=>{
        if (defaultValue !== undefined && defaultValue!==null){
            textRef.current  = defaultValue;
            setUserValue(defaultValue);
        }
        if (defaultStyle){
            setUserStyle(defaultStyle);
            setHasStyle(true);
        }

        if (defaultClassName){
            setUserClassName(defaultClassName)
            setHasClassName(true)
        }
        if (defaultDisabled === undefined || defaultDisabled=== null){
            setUserDisable(false);
        }
        else{
            setUserDisable(defaultDisabled);
        }

        if (defaultMaxLength){
            setUserMaxLength(defaultMaxLength);
        }

    },[defaultValue, defaultStyle, defaultClassName, defaultDisabled,defaultMaxLength]);

    const textChanged=e=>{

        setUserValue(e.target.value);
        
        textRef.current = e.target.value;
        if (callRelationFuntion){
            callRelationFuntion(elementId, e.target.value);
        }
      }
  
    const onComTextBoxKeyPress=(e)=>{
        if (keyPressFuction){
            keyPressFuction(e);
        }
    }

    const handleBlur = (e) => {
        if (callRelationBlur) {
            callRelationBlur(elementId, e.target.value);
        }
    }

    return (
        <input name="comTextBox" 
            type="password" 
            ref = {elementRef}
            value={userValue||""}
            onChange={textChanged} 
            disabled={userDisable}
            className={hasClassName?userClassName:""}
            style={hasStyle?userStyle:{}}
            placeholder={placeholder}
            onKeyPress={onComTextBoxKeyPress}
            maxLength={userMaxLength?userMaxLength:''}
            onBlur={handleBlur}
         />
    )
 });
export default React.memo(ComPassword);