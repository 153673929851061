import { ComNoticfication } from 'common/components/ComNoticfication';
import axios from 'axios';
import cookie from "react-cookies";
const axiosUtils = axios.create({ withCredentials: true, });
axiosUtils.defaults.baseURL = '/lgsp'


let cancelTokenArr = [];

export const setCancelToken = (cancelObj) => {
    const idx = cancelTokenArr.findIndex((item) => item.id === cancelObj.id);
    if (idx === -1) {
        cancelTokenArr.push(cancelObj);
    }
    return cancelTokenArr;
};

export const cancelAxios = (token, noCancel = false) => {
    const nArr = Array.isArray(token) ? token : [token];

    for (let i = nArr.length - 1; i >= 0; i--) {
        const id = nArr[i];
        const idx = cancelTokenArr.findIndex((item) => item.id === id);
        if (idx > -1) {
            if (!noCancel) {
                const msg = cancelTokenArr[idx].id;
                cancelTokenArr[idx]['token'].cancel(msg);
            }
            cancelTokenArr.splice(idx, 1);
        }
    }

    return cancelTokenArr;
};

export const getCancelToken = () => {
    return cancelTokenArr;
};


export const cancelAllAxios = () => {

    while (cancelTokenArr.length > 0) {
        const item = cancelTokenArr.pop();
        const msg = item.id;
        item['token'].cancel(msg);
    }
    //Loading.hideLoading();
    //Loading.hideLoading();

    document.getElementById("loadingArea").style.display = "none";
    return cancelTokenArr;
};



axiosUtils.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.withCredentials = true;

        config.headers['maxContentLength'] = Infinity;
        config.headers['maxBodyLength'] = Infinity;

        const csrfToken = sessionStorage.getItem("csrfToken");
        const menuId = sessionStorage.getItem("currentOpenMenu") ? (JSON.parse(sessionStorage.getItem("currentOpenMenu")).menuId || 'non') : 'non';
        if (csrfToken) {
            config.headers['X-CSRF-TOKEN'] = csrfToken;
            config.headers['X-MENU-ID'] = menuId;
        }
        const CancelToken = axios.CancelToken;
        const axiosCancelSource = CancelToken.source();
        setCancelToken({ id: config.url, token: axiosCancelSource });

        //document.getElementById("loadingArea").style.display = "flex";
        if ((config?.loadbarOpen ?? true)) {
            setTimeout(() => { if (cancelTokenArr.length > 0) { document.getElementById("loadingArea").style.display = "flex"; } }, 900);
        }
        //setTimeout(() => {if (cancelTokenArr.length > 0){}}, 1);

        return { ...config, cancelToken: axiosCancelSource.token };

    }
    , error => {
        //Loading.hideLoading(true);
        document.getElementById("loadingArea").style.display = "none";
        return Promise.reject(error);
    }
);


axiosUtils.interceptors.response.use(
    res => {
        //Loading.hideLoading();
        if (res.config && res.config.url) {
            let arr = cancelAxios(res.config.url, true);
            if (arr.length === 0) {

                if ((res?.config?.loadbarClose ?? null) === null) {
                    document.getElementById("loadingArea").style.display = "none";
                }
                else {
                    if (res.config.loadbarClose) {
                        document.getElementById("loadingArea").style.display = "none";
                    }
                }

                //Loading.hideLoading();
            } else {
                if ((res?.config?.loadbarClose ?? null) === null) {
                    document.getElementById("loadingArea").style.display = "none";
                }
                else {
                    if (res.config.loadbarClose) {
                        document.getElementById("loadingArea").style.display = "none";
                    }
                }

                //Loading.hideLoading(true);
            }
        }

        if (res.status === 200) {
            //document.getElementById("loadingArea").style.display = "none";  

            let arr = cancelAxios(res.config.url, true);
            if (arr.length === 0) {

                if ((res?.config?.loadbarClose ?? null) === null) {
                    document.getElementById("loadingArea").style.display = "none";
                }
                else {
                    if (res.config.loadbarClose) {
                        document.getElementById("loadingArea").style.display = "none";
                    }
                }

                //Loading.hideLoading();
            } else {
                if ((res?.config?.loadbarClose ?? null) === null) {
                    document.getElementById("loadingArea").style.display = "none";
                }
                else {
                    if (res.config.loadbarClose) {
                        document.getElementById("loadingArea").style.display = "none";
                    }
                }

                //Loading.hideLoading(true);
            }
            if (res?.headers["content-type"]) {
                if (res.headers["content-type"].indexOf("application/json") >= 0) {

                    if (res.data.code === "-9999" || res.data.code === "-9997") {
                        // cancelAllAxios();
                        cookie.remove("SSO_LOGIN", { path: "/" });
                        if (res.data.code === "-9999") { //세션이 끊겼을때만 메세지 (로그아웃 경우 메세지 X -9997)
                            if ((sessionStorage?.getItem("epLogin") ?? '') !== "Y") {
                                ComNoticfication.ShowAlertUICallback("Error", "Session is Expired! please Log-In again");
                            }
                        }
                        setTimeout(() => {
                            let ssoLogInType = sessionStorage.getItem('ssoLogIn');
                            sessionStorage.clear();
                            sessionStorage.setItem('ssoLogIn', ssoLogInType);
                            document.location.href = '/';
                        }, 2000);
                    }

                    if (res.data.code === "-9996" || res.data.code === "-9995") {
                        // cancelAllAxios();
                        cookie.remove("SSO_LOGIN", { path: "/" });
                        let ssoUrl = process.env.REACT_APP_SSO_URL;
                        if (res.data.code === "-9995") { //세션이 끊겼을때만 메세지 (로그아웃 경우 메세지 X -9995)
                            ComNoticfication.ShowAlertUICallback("Error", "Session is Expired! please Log-In again");
                        }
                        setTimeout(() => {
                            let ssoLogInType = sessionStorage.getItem('ssoLogIn');
                            sessionStorage.clear();
                            sessionStorage.setItem('ssoLogIn', ssoLogInType);
                            document.location.href = ssoUrl;
                        }, 2000);
                    }
                    if (res.data.code !== "0") {
                        // 메세지 코드에 따라서 메세지 노출 처리
                        return null;
                    }
                    return res.data;
                }
                else {
                    return res;
                }
            }
        }
    }
    , error => {

        let arrCancel = null;
        try {

            arrCancel = cancelAxios(error.response.config.url, true);
        } catch (e) {
            console.log(e.toString());
        } finally {

            if (!arrCancel || arrCancel.length === 0) {
                document.getElementById("loadingArea").style.display = "none";
                //Loading.hideLoading();
            } else {
                document.getElementById("loadingArea").style.display = "none";
                //Loading.hideLoading(true);
            }

        }

        if (error !== undefined && error !== null
            && error.response !== undefined && error.response !== null
            && error.response.status !== undefined && error.response.status !== null) {
            let errorCode = error.response.status || "";
            if (errorCode === 401) {
                // 로그인 에러 : 모든 요청을 취소한다.
                // cancelAllAxios();
            }
            else {
                return -1;//Promise.reject(error);
            }
        }
        else {
            return -1;//Promise.reject(error);
        }
    }
);

export default axiosUtils;
