import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Button, Divider } from "@mui/material";
import "assets/styles/pages/PagesCommon.scss";
import close from "assets/icons/close.svg";
import { openMenu, closeMenu } from "reducer/OpenMenus";
import { useDispatch, useSelector } from "react-redux";

import { ComUtils } from "common/lib/ComUtils";
import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { SampleUtils } from "Pages/Spl/Common/SampleUtils";

import * as wijmo from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";

import search from "assets/icons/search.svg";

import _ from "lodash";
import { CellFactory, DataMap, GroupRow, CellType } from "@grapecity/wijmo.grid";
import { InputDate, InputNumber, ComboBox } from "@grapecity/wijmo.input";
import { FlexGrid} from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import GridUserMergeFooter from "common/lib/GridUserMergeFooter";
import { createElement, format } from "@grapecity/wijmo";

import { MessageContext } from "common/lib/MessageProvider";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextBox from "common/components/ComTextBox";
import ComTextBoxFindWithcode from "common/components/ComTextBoxFindWithcode";
import ComTextArea from "common/components/ComTextArea";

import Breadcrumb from "common/components/ComBreadcrumb";

import { ApiManager } from 'common/lib/ApiUtil';
import SampleOrderTopNav from "./Common/SampleOrderTopNav";

import AddressInfoPop from "Pages/Pop/Spl/AddressInfoPop";
import WarehousePop from "Pages/Pop/Spl/WarehousePop";
import PersonInfoSchPop from "Pages/Pop/Spl/PersonInfoSchPop";

function SampleFollowUpReturn({ pageInfo, props, closeFn }) {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const msgSplInf001 = ComUtils.getMessage(messageObject, "SPL_INF_00001");           //Save Completed!

  //error 메시지
  const msgSplErr001 = ComUtils.getMessage(messageObject, "SPL_ERR_00001");           //An error occurred during save
  const msgSplErr004 = ComUtils.getMessage(messageObject, "SPL_ERR_00004");           //Requestor is inactive!\nPlease contact A/R dept.
  const msgSplErr017 = ComUtils.getMessage(messageObject, "SPL_ERR_00017");           //Delivery Customer max length is 30
  const msgSplErr019 = ComUtils.getMessage(messageObject, "SPL_ERR_00019");           //Pickup Date Should Not Be Before!
  const msgSplErr020 = ComUtils.getMessage(messageObject, "SPL_ERR_00020");           //Pickup Date Should Be Same

  //confirm 메시지
  const msgSplCnf003 = ComUtils.getMessage(messageObject, "SPL_CNF_00003");           //Confirm Save?

  const dispatch = useDispatch();

  const userId = sessionStorage.getItem("userId").toString();
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const retrieveOrderDetail = "/order/sampleFollowUp/retrieveOrderDetail";
  const saveOthersInInfo = "/order/sampleFollowUpReturn/saveOthersInInfo";

  // popup
  const [randomKey, setRandomKey] = useState(null);
  const [openModalAddressInfo, setOpenModalAddressInfo] = useState(false);
  const addressInfoPopupLineId = useRef(null);
  const [openModalWarehouse, setOpenModalWarehouse] = useState(false);
  const defaultPersonInfoSearchParam = useRef({
    url: "/com/splComPop/retrievePersonInfoList"
    , comCode: comCode
    , searchText: ""
    , searchType: 'userName'
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const personInfoResData = useRef(null);
  const [openModalPersonInfoList, setOpenModalPersonInfoList] = useState(false);

  const initState = useRef(false);
  const initData = useRef({});
  const requesterActive = useRef('');

  const [orderInfo, setOrderInfo] = useState({});

  const [headerId, setHeaderId] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [salesOrderNo, setSalesOrderNo] = useState('');
  const [isPageReadOnly, setPageReadOnly] = useState(props?.readType === 'read' || props?.readType === 'list' || props?.readType === 'desk');
  const [isEditRequestor, setEditRequestor] = useState(false);
  const [requestorEmail, setRequestorEmail] = useState('');
  const [fileList, setFileList] = useState([]);

  // entities
  const entityDivisionSample = useRef(null);
  const entityDivisionShowroom = useRef(null);
  const entityDivisionAcademy = useRef(null);
  const entityRequestorName = useRef(null);
  const entityContactName = useRef(null);
  const entityReceiverName = useRef(null);
  const entityReceiverPhoneNo = useRef(null);
  const entitySpecialInstruction = useRef(null);
  const fileElement = useRef(null);

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState(0);


  const onChangedNav = (dealType) => {
    // param을 만들어 SampleFollwUp으로 올리고 거기서 전환시키게함
    let pageCtx = {
      step: "1",
      dealType: dealType,
      readType: props?.readType,
      headerId: initData.current?.othersOutHeaderId,
      orderType: props?.orderType,
      currentHeaderId: initData.current?.headerId,
    };

    // 아직 initData를 불러오기전에 빠르게 이동한 경우 예외처리
    if (!pageCtx.headerId || !pageCtx.currentHeaderId) {
      return;
    }

    closeFn?.(pageCtx);
  }

  const onClickToList = () => {
    closeFn?.();
  }  

  const onClickDeleteRow = async () => {
  }

  const onClickSubmit = async () => {
    let view = gridObject.current?.collectionView;
    let modelArray = "";
    let lineIdArray = "";
    let reqQtyArray = "";

    // 1. check validation
    if (requesterActive.current != "Y") {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr004);
      return;
    }    

    if (view.items.length == 0) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00011", ["at last one row"]));
        return;
    }
    if (!entityContactName?.current?.getValue()?.trim()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00014", ["Contact Name"]), ()=>entityContactName?.current?.setFocus());
      return;
    }
    if (!entityReceiverName?.current?.getValue()?.trim()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00014", ["Delivery Customer"]), ()=>entityReceiverName?.current?.setFocus());
      return;
    }
    if (entityReceiverName?.current?.getValue()?.length > 30) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, msgSplErr017, ()=>entityReceiverName?.current?.setFocus());
      return;
    }
    if (!entityReceiverPhoneNo?.current?.getValue()?.trim()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00014", ["Contact Phone"]), ()=>entityReceiverPhoneNo?.current?.setFocus());
      return;
    }
  
    if(!entitySpecialInstruction?.current?.getValue()?.trim()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00005", ["The Special Instruction"]), ()=>entitySpecialInstruction?.current?.setFocus());
      return;
    }

    // totalQty, totalAmt 계산
    const total = view.items.reduce((acc, item) => {
      acc.totalQty += item.reqQty;
      acc.totalAmt += item.reqQty * item.unitListPrice;
      return acc;
    }, { totalQty: 0, totalAmt: 0 });

    if (total.totalQty <= 0) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00018", ['The Qty']));
      return;
    }

    let tmpPickupDate;
    let modelList = gridObject?.current?.collectionView.items
                      .filter(item => item.reqQty > 0)
                      .map(item => ({
                        ...item,
                        pickupDate: wijmo.Globalize.format(new Date(item.pickupDate), 'MM/dd/yyyy'), // Date 형식을 변환
                        pickupTime: item?.pickupTime || '08:00~17:00'
                      }));
    for (let i = 0; i < modelList.length; i++) {
      let item = modelList[i];

      if (!item.modelSuffix) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00018", ['The modelSuffix']), ()=>ComUtils.setGridFocus(gridObject, i, "modelSuffix"));
        return;
      }

      if (!item.warehouseCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00012", ["the wareHouse"]), ()=>ComUtils.setGridFocus(gridObject, i, "warehouseCode"));
        return;
      }

      if (!item.warehouseCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00012", ["the wareHouse"]), ()=>ComUtils.setGridFocus(gridObject, i, "warehouseCode"));
        return;
      }      

      if (item.remainingQty < item.reqQty) {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00013", ["Apply", (i+1)]), ()=>ComUtils.setGridFocus(gridObject, i, "reqQty"));
        return;
      }

      let iDays = ComUtils.DateDiff(new Date(), new Date(Date.parse(item.pickupDate)));
      if (iDays <= 0 && Number(item.reqQty) > 0) {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, msgSplErr019, ()=>ComUtils.setGridFocus(gridObject, i, "pickupDate"));
        return;
      }

      tmpPickupDate = item.pickupDate;

      modelArray = modelArray + item.modelSuffix + ",";
			lineIdArray = lineIdArray + item.lineId + ",";
			reqQtyArray = reqQtyArray + item.reqQty + ",";
    }      

    // pickupDate의 값이 모두 같은지 확인
    let isSame = modelList.every(item => wijmo.Globalize.format(item.pickupDate, 'MM/dd/yyyy') === tmpPickupDate);
    if (!isSame) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgSplErr020);
      return;
    }

    // check sales person evaluation
    let resultData = await SampleUtils.checkSalesPersonEvaluation(props?.headerId, modelArray, lineIdArray, reqQtyArray);
    if (!resultData) {
      return;
    }   

    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, msgSplCnf003, async (r) => {
      if (r) {
        let sendParam = {...initData.current};
        sendParam = SampleUtils.replaceNullsWithEmptyString(sendParam);        

        if (isEditRequestor) {
          sendParam.originalNm = entityRequestorName?.current?.getCode() ?? '';
          sendParam.originalId = entityRequestorName?.current?.getText() ?? '';
        }
        sendParam.requesterEmail = requestorEmail;
        sendParam.contactName = entityContactName?.current?.getValue() ?? '';
        sendParam.receiverName = entityReceiverName?.current?.getValue() ?? '';
        sendParam.receiverPhoneNo = entityReceiverPhoneNo?.current?.getValue() ?? '';
        sendParam.currPackaging = entitySpecialInstruction?.current?.getValue() ?? '';  
        sendParam.fileList = fileList.filter(f => !f.isReadOnly);  // fileList에서 isReadOnly인 것은 제외하고 전송
        sendParam.modelList = modelList;
        sendParam.pickupTime = sendParam.modelList[0]?.pickupTime;
        sendParam.totalQty = total.totalQty;
        sendParam.totalAmt = total.totalAmt;        
        

        // send
        let resultData = await ApiManager.post(saveOthersInInfo, sendParam);
        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr001);
        } else {
          if (resultData?.returnMessage === '1') {
            // followup list로 이동
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msgSplInf001, () => {
              closeFn?.();

              let closeMenuInfo = _.cloneDeep({});
              closeMenuInfo["tabUniqueKey"] = "M0139";
              dispatch(closeMenu(closeMenuInfo));
  
              setTimeout(() => {
                resultData.page = "SampleFollowUpReturn";
                resultData.actionType = "set";
                resultData.readType = "list";
    
                let newMenuInfo = _.cloneDeep(pageInfo);
                let now = new Date();
                let timeKey = now.toISOString().replace('T', ' ').substring(0, 19).replace(/[^0-9]/g, '') + now.getMilliseconds().toString()
                newMenuInfo["menuName"] = "Sample Order Tracking";
                newMenuInfo["tabUniqueKey"] = "M0139";
                newMenuInfo["timeKey"] = timeKey;
                newMenuInfo["Active"] = true;
                newMenuInfo["copyFlag"] = true;
                newMenuInfo["editFlag"] = true;
                newMenuInfo["fromMenuCode"] = pageInfo.menuId;
                newMenuInfo["menuUrl"] = "Spl/SampleOrderTracking";
                newMenuInfo["param"] = resultData;
                newMenuInfo["moveDetail"] = true;
                dispatch(openMenu(newMenuInfo));
              }, 200);
            }); 
          } else {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgSplErr001);
          }
        }        
       }
    })
  }

  // requestor popup
  const openPersonInfoListPopup = (elementId, userCode, resultData) => {
    ComUtils.setComRandomKey(setRandomKey);

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        entityRequestorName.current.setValue({code: resultData.list[0].userName, text: resultData.list[0].empNo});
        setRequestorEmail(resultData.list[0].emailId);
      } else {
        //여러건이면 팝업으로 전달
        personInfoResData.current = resultData;
        setOpenModalPersonInfoList(true);
      }
    } else {
      setOpenModalPersonInfoList(true);
    }
  };

  const callPersonInfoListPopup = (elementId, value) => {
    defaultPersonInfoSearchParam.current.searchText = value;
    entityRequestorName.current.setValue({code: value});
  };

  const closePersonInfoListPopup = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      entityRequestorName.current.setValue({code: selectedItems.userName, text: selectedItems.empNo});
      setRequestorEmail(selectedItems.emailId);

      defaultPersonInfoSearchParam.current.searchText = selectedItems.userName;
      requesterActive.current = "Y";
      setEditRequestor(false);
    }
    personInfoResData.current = null;
    setOpenModalPersonInfoList(false);
  };

  const openAddressInfoPopup = async (row, e) => {
    ComUtils.setComRandomKey(setRandomKey);
    addressInfoPopupLineId.current = row.value; // line id
    setOpenModalAddressInfo(true);
  };

  const closeAddressInfoPopup = async (selectedItems) => {
    setOpenModalAddressInfo(false);
    addressInfoPopupLineId.current = null;
  };

  const openModelWarehousePopup = async (row, e) => {
    ComUtils.setComRandomKey(setRandomKey);
    setOpenModalWarehouse(true);
  };

  const closeWarehousePopup = async (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      let view = gridObject?.current?.collectionView;
      if (view) {
        let row = gridObject?.current?.selection.row;

        view.items[row].warehouseCode = selectedItems.warehouseCode;
        view.items[row].subInventoryCode = selectedItems.subCode;
        view.items[row].avqty = selectedItems.avqty;

        view.refresh();
      }
    }
    setOpenModalWarehouse(false);
  };

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
      canAreaInfo: false,
      useMerge: true
    });
    sender.allowSorting = false;

    // add total footer
    sender.columnFooters.rows.push(new GroupRow());
    sender.mergeManager = new GridUserMergeFooter(gridObject.current);
  };

  const gridFormatItem = (s, e) => {
    if (s.columns[e.col].binding === "modelSuffix") {
      s.columnFooters.setCellData(0, e.col, "Total", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  };


  const initGridFormat = useCallback(
    (
      pickupTimeMap,
      findAddress,
      findWarehouse,
    ) => {
      let gridFormat = [
        {
          binding: "lineNo",
          header: "Line No",
          width: 70,
          isReadOnly: true,
          allowDragging: true,
          align: "center",
        },
        {
          binding: "modelSuffix",
          header: "Model",
          width: "*",
          minWidth: 220,
          allowDragging: true,
          isReadOnly: true,
        },
        {
          binding: "orginalShippedQty",
          header: "Original Shipped QTY",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
          aggregate: 'Sum',
        },
        {
          binding: "clearedQty",
          header: "Cleared QTY",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
          aggregate: 'Sum',
        },
        {
          binding: "remainingQty",
          header: "Remaining QTY	",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          align: "right",
          format: "n0",
          aggregate: 'Sum',
        },
        {
          binding: "reqQty",
          header: "To Be Returned QTY",
          width: 160,
          allowDragging: true,
          isRequired: true,
          isReadOnly: isPageReadOnly,
          align: "right",
          format: "n0",
          editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
          maxLength: 30,
        },
        {
          binding: "warehouseCode",
          header: "Return Warehouse",
          width: 160,
          allowDragging: true,
          isReadOnly: true,
          isRequired: true,
          align: "center",
          maxLength: 30,
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let value = ctx.value;
            let bizType = initData.current?.bizType;
            let rowIndex = ctx.row.index;
            let dataItem = ctx.row.dataItem;
            
            if (isPageReadOnly) {
              return value;
            } else {
              // 조회 버튼 생성
              let element = createElement(
                format(`<div class="wj-cell-button"><span>${value}</span> <img class="grid_btn_find" src='${search}' /></div>`, {}),
                cell
              );
              let buttonElement = element.querySelector("img.grid_btn_find");
              buttonElement.onclick = function (e) {
                e.preventDefault();
                findWarehouse.apply(e, [{ rowIndex, dataItem, ctx }, e]);
              };
            }
          }
        },
        {
          binding: "pickup",
          header: "Request Pick Up",
          width: 120,
          allowDragging: true,
          isReadOnly: isPageReadOnly,
          align: "center",
          columns: [
            {
              binding: "pickupDate",
              header: "Date",
              width: 120,
              allowDragging: true,
              isRequired: true,
              isReadOnly: isPageReadOnly,
              align: "center",
              editor: new InputDate(document.createElement("div"),{
                format: 'MM/dd/yyyy', mask: '##/##/####', isRequired: false,
                invalidInput: (s, e) => { if (s.text === '__/__/____') { s.text = ""; s.value = null; } }
              }),
              cellTemplate: (ctx, cell) => {
                cell?.firstChild?.remove();
                let value = ctx.value;
                if (value) {
                  return wijmo.Globalize.format(new Date(value), 'MM/dd/yyyy');
                } else {
                  ctx.value = new Date();
                  return wijmo.Globalize.format(new Date(), 'MM/dd/yyyy');
                }
              }
            },
            {
              binding: "pickupTime",
              header: "Time",
              width: 120,
              allowDragging: true,
              isRequired: true,
              isReadOnly: isPageReadOnly,
              align: "center",
              dataMap: pickupTimeMap,
              cellTemplate: (ctx, cell) => ctx.value ?? '08:00~17:00',
            },
          ]
        },
        {
          binding: "lineId",
          header: "Address",
          width: 80,
          allowDragging: true,
          isReadOnly: true,
          align: "center",
          cellTemplate: (ctx, cell) => {
            cell?.firstChild?.remove();
            let value = ctx.value;
            let dataItem = ctx.row.dataItem;
            let element = createElement(
              format(`<div class="wj-cell-button" style="justify-content: center;"><img class="grid_btn_find" src='${search}' /></div>`, {}), cell
            );
            let buttonElement = element.querySelector("img.grid_btn_find");
            buttonElement.onclick = function (e) {
              e.preventDefault();  
              findAddress.apply(e, [{ value }, e]);
            }; //.bind({ctx});
          },
        },
        {
          binding: "pickedBy",
          header: "Picked By",
          width: 160,
          allowDragging: true,
          isReadOnly: isPageReadOnly,
          maxLength: 50,
        },
        {
          binding: "contactInfo",
          header: "Contact Info",
          width: 160,
          allowDragging: true,
          isReadOnly: isPageReadOnly,
          maxLength: 50,
        },
      ];
      setGridColGroup([...gridFormat]);
    }, []);


  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    initGridFormat(SampleRequestUtils.getPickupTimeMap(), openAddressInfoPopup, openModelWarehousePopup);

    const getPageInfo = async () => {
      initState.current = false;

      if (props?.epLogin) {
        ComUtils.removeEpLoginSessionItem();
      }

      let resultData = await ApiManager.post(retrieveOrderDetail, props);
      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "SPL_ERR_00002", ["sample request"]));
        return;
      }
      initData.current = resultData?.query;      
      requesterActive.current = resultData?.query?.requesterActive;
      let newFileList = resultData?.fileList?.map(f => {
        let file = SampleRequestUtils.convertFile(f);
        file.isReadOnly = true; // 서버에서 불러온것인지 확인하기 위함
        return file;
      });

      SampleRequestUtils.initBusinessDivisionList(entityDivisionSample,
        entityDivisionShowroom,
        entityDivisionAcademy,
        resultData?.bizType,
        resultData?.query?.bizType,
        true);

      setEditRequestor(resultData?.query?.requesterActive !== 'Y' && resultData?.query?.leaderId === resultData?.query?.userId);
      entityRequestorName?.current?.setValue({code: resultData?.query?.originalNm, text: resultData?.query?.originalId});

      // readOnly info
      setOrderInfo(resultData?.query);
      setFileList(newFileList);
      setRequestDate(resultData?.query?.createDate);
      setHeaderId(props.headerId);
      setRequestorEmail(resultData?.query?.requesterEmail);

      entityContactName?.current?.setValue(resultData?.query?.contactNm);
      entityReceiverName?.current?.setValue(resultData?.query?.receiverName);
      entityReceiverPhoneNo?.current?.setValue(resultData?.query?.receiverPhoneNo);
      entitySpecialInstruction?.current?.setValue(resultData?.query?.currPackaging);

      if (isPageReadOnly) {
        setSalesOrderNo("GERP IN#" + (resultData?.query?.salesOrderNo ?? ''));
      } else {
        setSalesOrderNo("GERP SO#" + (resultData?.query?.salesOrderNo ?? ''));
      }        

      // Product List
      let collectionView = new CollectionView(resultData?.resultList, {trackChanges: true,refreshOnEdit: false});
      collectionView.items.forEach(item => {
        item.reqQty = item.reqQty || 0;
        item.pickupDate = item.pickupDate || new Date();

        if (!isPageReadOnly) {
          if (initData.current?.bizType === '1') {
            item.warehouseCode = 'NJ2';
          } else if (initData.current?.bizType === '2') {
            item.warehouseCode = 'N1D';
          } else if (initData.current?.bizType === '12') {
            item.warehouseCode = 'NMN';
          } else {
            item.warehouseCode = 'NJ2';
          }
        }
      });

      setGridData(collectionView);
      setGridDataCount(resultData?.resultList?.length);

      initState.current = true;
    }

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);


  const [isHiddenSearch, setHiddenSearch] = useState(true);
  return (
    <div className="page-wrap">
      <div className="breadcrumb-header"  style={{ display: props?.epLogin ? 'none' : '' }}>
        <Breadcrumb items={pageInfo} />
      </div>
      <Divider component="li" />
      <SampleOrderTopNav dealType={props?.dealType} readType={props?.readType} orderType={props?.orderType} onChangedNav={onChangedNav}/>
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div>
              <div className="txt-title">
                <h2>{salesOrderNo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h2>
                <div className="bx-contact-group">Request No: {headerId} | Request Date : {requestDate}</div>
              </div>              
            </div>

            <div className="link-action d-flex">  
              { closeFn &&
                <Button className="btn-default" variant="outlined" onClick={() => onClickToList()}>
                  {"List"}
                </Button>
              }
              {!isPageReadOnly &&
                <Button variant="contained" className="btn-save" onClick={(e) => onClickSubmit()}>
                  {"Submit"}
                </Button>
              }                       
            </div>
          </div>

          <div
            className="search-wrapper"
            style={isHiddenSearch ? {} : { display: "none" }}
          >
            <div className="bx-search2 fixed-column3">
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">
                    Business Division
                  </label>
                </div>
                <div className="bx-inp comRadiBox-inp">
                  <div className="inp-radio-sample">
                    <p className="fz-11">Sample: </p>
                    <div className="flex-wrap">
                      <ComRadioBox
                        ref={entityDivisionSample}
                        elementId={"entityDivisionSample"}
                        radioGroupName={"entityDivision"}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="inp-radio-sample">
                    <p className="fz-11">Showroom: </p>
                    <div className="flex-wrap">
                      <ComRadioBox
                        ref={entityDivisionShowroom}
                        elementId={"entityDivisionShowroom"}
                        radioGroupName={"entityDivision"}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="inp-radio-sample">
                    <p className="fz-11">Academy: </p>
                    <div className="flex-wrap">
                      <ComRadioBox
                        ref={entityDivisionAcademy}
                        elementId={"entityDivisionAcademy"}
                        radioGroupName={"entityDivision"}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Order Type</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComRadioBox
                    radioGroupName={"entityOrderType"}
                    radioList={[
                      { Value: "OTHERS_OUT_NO_RETURN", Description: "No Return"}, 
                      { Value: "OTHERS_OUT_FOR_RETURN", Description: "Return"}
                    ]}
                    direction={1}
                    defaultValue={orderInfo?.othersOutOrderType}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Reason
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    elementId={"entityReason"}
                    defaultValue={orderInfo?.reasonNm}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    To be Returned Date
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    elementId={"entityTobeReturnDate"}
                    defaultValue={orderInfo?.collectionDate}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Bill To Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.billTo}
                    elementId={"entityBillToCd"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Reason Detail</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.reasonDetailNm}
                    elementId={"entityReasonDetail"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Requestor</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  { isEditRequestor ?
                    <ComTextBoxFindWithcode
                      ref={entityRequestorName}
                      elementId={"entityRequestorName"}
                      callOpenPopupFunction={openPersonInfoListPopup}
                      callRelationFuntion={callPersonInfoListPopup}
                      defaultRaiseClick={false}
                      defaultReadOnly={false}
                      defaultSearch={defaultPersonInfoSearchParam}
                    />
                    :
                    <ComTextBox
                      defaultValue={orderInfo?.originalNm}
                      defaultDisabled={true}
                    />
                  }
                  
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Bill To Name
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.billToNm}
                    elementId={"entityBillToNm"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Expense Dept Code
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.expenseDepartment}
                    elementId={"entityExpenseDepartment"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Requestor Email</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={requestorEmail}
                    elementId={"entityRequestorEmail"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Ship To Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.shipTo}
                    elementId={"entityShipToCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Expense Account
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.expenseAccount}
                    elementId={"entityExpenseAccount"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Liftgate(VIP Only)
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComRadioBox
                    radioList={[{ "Value": "Y", "Description": "Yes" }, { "Value": "N", "Description": "No" }]}
                    defaultValue={orderInfo?.liftGateFlag}
                    radioGroupName={"entityLiftgate"}
                    direction={1}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Delivery Address
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.address1}
                    elementId={"entityAddress1"}
                    defaultDisabled={true}
                    defaultMaxLength={1000}
                    defaultStyle={{textTransform:"uppercase"}}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Expense Project Code
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.expenseProjectCode}
                    elementId={"entityExpenseProjectCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> B2B-Project Code</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.b2bProjectCode}
                    elementId={"entityB2bProjectCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> 
                    <span className="requiresimbol"> *</span> Contact Name
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityContactName}
                    elementId={"entityContactName"}
                    defaultDisabled={isPageReadOnly}
                    defaultMaxLength={200}
                    defaultStyle={{textTransform:"uppercase"}}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense AU</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.expenseAu}
                    elementId={"entityExpenseAu"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> White Glove</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComRadioBox
                    radioList={[{ "Value": "Y", "Description": "Yes" }, { "Value": "N", "Description": "No" }]}
                    defaultValue={orderInfo?.whiteGloveFlag}
                    radioGroupName={"entityWhiteGlove"}
                    direction={1}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label-sample">
                  <div className="content-item-sample">
                    <label className="txt-label">
                      City
                    </label>
                    <ComTextBox
                      defaultValue={orderInfo?.city}
                      elementId={"entityCity"}
                      defaultDisabled={true}
                    />                  
                  </div>
                  <div className="content-item-sample">
                    <label className="txt-label">
                      State
                    </label>                
                    <ComTextBox
                      defaultValue={orderInfo?.state}
                      elementId={"entityState"}
                      defaultDisabled={true}
                    />                 
                  </div>
                  <div className="content-item-sample">
                    ZIP
                    <ComTextBox
                      defaultValue={orderInfo?.zip}
                      elementId={"entityZip"}
                      defaultDisabled={true}
                    />                      
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Expense Activity
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.expenseActivity}
                    elementId={"entityExpenseActivity"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Delivery Arrival Date
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    defaultValue={orderInfo?.reqArrivalDate}
                    elementId={"entityReqArrivalDate"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="column-2column">
                <div className="ct-fl-sample-request">
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">
                        <span className="requiresimbol"> *</span> Delivery Customer
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        ref={entityReceiverName}
                        elementId={"entityReceiverName"}
                        defaultDisabled={isPageReadOnly}
                        defaultMaxLength={30}
                        defaultStyle={{textTransform:"uppercase"}}
                      />
                    </div>
                  </div> 
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label"> Reference(PO.etc.)</label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        defaultValue={orderInfo?.custPoNo}
                        elementId={"entityCustPoNo"}
                        defaultDisabled={true}
                        defaultMaxLength={50}
                      />
                    </div>
                  </div>           
                </div>
                <div className="ct-fl-sample-request">
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">
                        <span className="requiresimbol"> *</span> Contact Phone
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        ref={entityReceiverPhoneNo}
                        elementId={"entityReceiverPhoneNo"}
                        defaultDisabled={isPageReadOnly}
                        defaultMaxLength={30}
                      />
                    </div>
                  </div>
                  <div className="bx-item">
                    <div className="div-label">
                      <label className="txt-label">
                        Ship Method Code
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        defaultValue={orderInfo?.shipMethodCode}
                        elementId={"entityShipMethodCode"}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>          
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Pick Remark</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    defaultValue={orderInfo?.pickingRemark}
                    elementId={"entityPickingRemark"}
                    defaultDisabled={true}
                  />
                </div>
              </div>          
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">Sample Purpose</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    defaultValue={orderInfo?.shippingRemark}
                    elementId={"entityShippingRemark"}
                    defaultDisabled={true}
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                    }}
                  />
                </div>
              </div>
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span> Special Instruction
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextArea
                    ref={entitySpecialInstruction}
                    elementId={"entitySpecialInstruction"}
                    defaultDisabled={isPageReadOnly}
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                    }}
                  />
                </div>
              </div>
              <div className="bx-item column-3column">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span> Attach File
                  </label>
                </div>
                <div className="bx-inp">
                  <div className="layout-file">
                    { !isPageReadOnly &&
                      <div className="file-search">
                        <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={()=>fileElement?.current?.click()}>
                          {"Find File"}
                        </Button>
                        <input 
                          type="file" 
                          onChange={(e) => SampleUtils.checkFile(e, fileList, SampleRequestUtils.uploadFileExcute, setFileList, fileElement, userId)} 
                          ref={fileElement} 
                          style={{ display: 'none' }} 
                          multiple 
                        />
                      </div>
                    }
                    <div className="file-group">
                      {
                        fileList.map((file, index) => (
                          <div className="file-list" key={file.serverFileName}>
                            <div className="file-stt">{index + 1}</div>
                            <div className="file-name">
                              { file?.isReadOnly ? // 서버에서 불러온 파일은 삭제를 못하게 처리
                                <span onClick={e=>SampleRequestUtils.downloadFile(e, file)}>{file.displayFileName}</span>
                                :
                                <>
                                <span>{file.displayFileName}</span>
                                <img src={close} width="12px" onClick={(e) => SampleRequestUtils.removeFile(e, file, fileList, setFileList)} />
                                </>
                              }
                              
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Product List"}</h3>
                  <p className="txt-total">
                    {"Total"}
                    <strong className="txt-num">{gridDataCount}</strong>
                  </p>
                </div>
              </div>
              <div className="link-action d-flex">
                {!isPageReadOnly &&
                  <Button variant="outlined" disabled={true} onClick={() => onClickDeleteRow()}>{"Delete Current Row"}</Button>
                }
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                formatItem={gridFormatItem}
                style={{ minheight: "200px" }}
              >
              </FlexGrid>
            </div>
          </div>
        </div>
      </div>

      {openModalAddressInfo && <AddressInfoPop open={openModalAddressInfo} closeFn={closeAddressInfoPopup} randomKey={randomKey} headerId={headerId} lineId={addressInfoPopupLineId?.current} pageInfo={pageInfo}/> }
      {openModalWarehouse && <WarehousePop open={openModalWarehouse} closeFn={closeWarehousePopup} randomKey={randomKey} pageInfo={pageInfo} />}
      {openModalPersonInfoList && <PersonInfoSchPop open={openModalPersonInfoList} closeFn={closePersonInfoListPopup} randomKey={randomKey} reqSearchText={entityRequestorName?.current?.getCode()} resultData={personInfoResData.current} pageInfo={pageInfo} />}
    </div>
  );
}

export default SampleFollowUpReturn;
