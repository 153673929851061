import _ from 'lodash';
import moment from 'moment/moment';
import * as wijmo from "@grapecity/wijmo";
import { DataMap, GroupRow, CellType } from "@grapecity/wijmo.grid";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComNoticfication } from "common/components/ComNoticfication";
import { FileUtils } from 'common/lib/FileUtils';

export const SampleRequestUtils = () => { };

SampleRequestUtils.Pages = {
  SampleRequest: 'SampleRequest',
  SampleRequestExcel: 'SampleRequestExcel',
  SampleRequestApprover: 'SampleRequestApprover',
};

SampleRequestUtils.getBusinessDivisionElementID = (bizType) => {
  if (Number(bizType) <= 30) {
    return "entityDivisionSample"
  } else if (Number(bizType) == "65" || (Number(bizType) > 30 && Number(bizType) <= 60)) {
    return "entityDivisionShowroom";
  }
  else if (Number(bizType) != "65" && (Number(bizType) > 60 && Number(bizType) <= 90)) {
    return "entityDivisionAcademy";
  }
  return "";
}

SampleRequestUtils.initBusinessDivisionList = (entityDivisionSample, entityDivisionShowroom, entityDivisionAcademy, bizType, defaultValue = null, disable = false) => {
  if (!bizType || !entityDivisionSample || !entityDivisionShowroom || !entityDivisionAcademy) {
    return;
  }

  const divisionSampleList = bizType
    .filter(item => Number(item.code) <= 30 && item.code != 12) // MC DIVISION 제거
    .map(item => ({
      Value: item.code,
      Description: item.value,
      Disable: !disable ? (item.code == 6 || item.code == 7 || item.code == 12) : true
    }));
  entityDivisionSample?.current?.setList({
    data: divisionSampleList,
    defaultValue: defaultValue,
  });

  const divisionShowroomList = bizType
    .filter(item => item.code == "65" || (Number(item.code) > 30 && Number(item.code) <= 60))
    .map(item => ({
      Value: item.code,
      Description: item.value
    }));
  entityDivisionShowroom?.current?.setList({
    data: divisionShowroomList,
    defaultValue: defaultValue,
  });

  const divisionAcademyList = bizType
    .filter(item => item.code != "65" && (Number(item.code) > 60 && Number(item.code) <= 90))
    .map(item => ({
      Value: item.code,
      Description: item.value
    }));
  entityDivisionAcademy?.current?.setList({
    data: divisionAcademyList,
    defaultValue: defaultValue,
  });
};

// SampleRequestUtils.initOrderType = (dealType, query) => {
//     if (dealType === "return") {
//       entityOrderType?.current?.setValue(query.othersOutOrderType || 'OTHERS_OUT_FOR_RETURN');
//     } else {
//       entityOrderType?.current?.setValue('OTHERS_OUT_NO_RETURN');
//     }
//   };

SampleRequestUtils.initReason = (entityReason, reasonList, defaultValue) => {
  entityReason?.current?.setList({
    data: reasonList,
    code: "reasonCd",
    name: 'reasonNm',
    initValue: defaultValue
  });
};

  SampleRequestUtils.initReasonDetail = (entityReasonDetail, reasonDetailList, defaultValue) => {
    entityReasonDetail?.current?.setList({
      data: reasonDetailList,
      code: "code",
      name: 'value',
      initValue: defaultValue
    });
  };

SampleRequestUtils.initDepartment = (entityExpenseDepartment, deptList, defaultValue) => {
  entityExpenseDepartment?.current?.setList({
    data: deptList,
    code: "code",
    name: 'value',
    initValue: defaultValue
  });
};

SampleRequestUtils.initExpenseAccount = (entityExpenseAccount, acctList, defaultValue) => {
  entityExpenseAccount?.current?.setList({
    data: acctList,
    code: "code",
    name: 'value',
    initValue: defaultValue
  });
};

SampleRequestUtils.initExpenseProjectCode = (entityExpenseProjectCode, projectCodeList, defaultValue) => {
  entityExpenseProjectCode?.current?.setList({
    data: projectCodeList,
    code: "code",
    name: 'code',
    initValue: defaultValue
  });
};

SampleRequestUtils.initExpenseAu = (entityExpenseAu, auList, defaultValue) => {
  entityExpenseAu?.current?.setList({
    data: auList,
    code: "code",
    name: 'code',
    initValue: defaultValue
  });
};

SampleRequestUtils.initExpenseActivity = (entityExpenseActivity, activityCodeList, defaultValue) => {
  entityExpenseActivity?.current?.setList({
    data: activityCodeList,
    code: "code",
    name: 'value',
    initValue: defaultValue
  });
};

//   SampleRequestUtils.initShipMethodCode = (shipMethodCodeList, defaultValue) => {  
//     entityShipMethodCode?.current?.setList({ data: shipMethodCodeList, code: "code", name: 'value'});

//     if (defaultValue) {
//       entityShipMethodCode?.current?.setValue(defaultValue)
//     }
//   };

SampleRequestUtils.getYearList = () => {
  let out = [];
  for (let i = 2015; i <2035; i++) {
    out.push({ cd: `${i}`, nm: `${i}` });
  }
  return out;
};

SampleRequestUtils.getMonthList = () => {
  return Array.from({ length: 12 }, (_, index) => {
    const month = (index + 1).toString().padStart(2, '0');
    return { cd: `${month}`, nm: `${month}` };
  });
};

SampleRequestUtils.getTimeList = () => {
  return Array.from({ length: 24 }, (_, index) => {
    const hour = (index + 1).toString().padStart(2, '0');
    return { cd: `${hour}:00`, nm: `${hour}:00` };
  });
};

SampleRequestUtils.checkPurposeLength = (v) => {
  let len = 0;
  for (let i = 0; i < v.length; i++) {
    if (v.charCodeAt(i) > 256) {
      len += 4;
    } else {
      len++;
    }
  }

  return len > 2000;
};

SampleRequestUtils.getModelSubInventory = () => {
  return new DataMap([{ cd: "GOODSET", nm: "GOODSET" },
  { cd: "GS-SMP-IN", nm: "GS-SMP-IN" },
  { cd: "BOXDMG", nm: "BOXDMG" },
  { cd: "GRADE-A", nm: "GRADE-A" },
  { cd: "GRADE-B", nm: "GRADE-B" },
  { cd: "GRADE-C", nm: "GRADE-C" },
  { cd: "GRADE-D", nm: "GRADE-D" },
  { cd: "GS-NA-IN", nm: "GS-NA-IN" },
  { cd: "REFUR-00", nm: "REFUR-00" },
  { cd: "REFUR-01", nm: "REFUR-01" },
  { cd: "SHOWROOM", nm: "SHOWROOM" },
  { cd: "UNITDMG", nm: "UNITDMG" },
  { cd: "*", nm: "*" },], "cd", "nm");
};

SampleRequestUtils.getPickupTimeMap = () => {
  return new DataMap([
    { cd: "08:00~17:00", nm: "08:00~17:00" },
    { cd: "08:00~12:00", nm: "08:00~12:00" },
    { cd: "12:00~17:00", nm: "12:00~17:00" },
  ], "cd", "nm");
};

SampleRequestUtils.getDefaultModel = () => {
  return {
    lineNo: "",                                       
    modelSuffix: "",  
    model: "",  
    suffix: "",
    qty: 0,
    warehouseCd: "",
    avqty: 0,
    price: 0,
    totalAmt: 0,
    apmsNo: "",
    subInventoryCd: "GS-SMP-IN",              
    actType: "C",
    pmEmpNo: "",                                                     
    itemCategory: "",                               
  };
};


SampleRequestUtils.getCcs = (ccsList) => {
  let CcsString = '';
  ccsList.forEach(ccs => {
    CcsString = CcsString + ccs.emailId + ';';
  })
  return CcsString;
}

SampleRequestUtils.getDealType = (orderType) => {
  switch (orderType) {
    case "OTHERS_IN_FOR_RETURN":
    case "OTHERS_IN_NO_RETURN":
      return "return";
    case "OTHERS_OUT_FOR_RETURN":
    case "OTHERS_OUT_NO_RETURN":
      return "request";
    case "SAMPLE_SALES":
      return "invoice";
    case "GIFT":
      return "gift";
    case "ADDRESS_CHANGE":
      return "addressChange";
    case "DATE_CHANGE":
      return "dateChange";
    case "MISSING":
      return "missing";
  }
}

SampleRequestUtils.getDefaultReasonCode = (bizType) => {
  var defaultReason = 'FR01';

  if (bizType == 2) {
    defaultReason = 'NR15';// For B2B Division- reason code of Gift Testing should appear first on the list
  } else if (bizType == 12) {
    defaultReason = 'NR16';
  } else {
    if (bizType > 30 && bizType <= 90) {
      defaultReason = 'NR09';
    } else {
      defaultReason = 'NR01';
    }
  }
  return defaultReason;
}

SampleRequestUtils.checkProjectCode = async (faProjectCode, plannedDate, location, originalId) => {
  var isValid = false;
  var faDeptCode = "";

  if (!faProjectCode) {
    ComNoticfication.ShowErrorUI("error", 'FA Project Code is required for Fixed Asset.');
    return { isValid, faDeptCode };
  }
  if (!plannedDate) {
    ComNoticfication.ShowErrorUI("error", 'Planned installation Date is required for Fixed Asset.');
    return { isValid, faDeptCode };
  }

  if (!location) {
    ComNoticfication.ShowErrorUI("error", 'Location is required for Fixed Asset.');
    return { isValid, faDeptCode };
  }

  let resultData = await ApiManager.post("/spl/sampleRequest/checkProjectCode", {
    faProjectCode: faProjectCode,
    originalId: originalId
  });

  if (resultData?.statusCode) {
    ComNoticfication.ShowErrorUI("Error", "System error. Please contact system admin.");
  } else {
    if (resultData.result == "ok") {
      isValid = true;
      faDeptCode = resultData.faDeptCode;
    } else {
      ComNoticfication.ShowErrorUI("Error", resultData.result);
    }
  }

  return { isValid, faDeptCode };
};

// fileList, setFileList, fileElement
SampleRequestUtils.uploadFileExcute = async (uploadFileInfo, fileList, setFileList, fileElement, userId) => {

  let formData = new FormData();
  formData.append('UploadFile', uploadFileInfo);
  formData.append("customPath", "sampleportal");

  try {
    let retrieveFileData = await ApiManager.post("/Common/File/FileUploadCustomPath", formData);
    let uploadedFileInfo = { "serverFileName": retrieveFileData[0].savedFileName, "filePath": retrieveFileData[0].savedFilePath, "fileSize": uploadFileInfo.size, "displayFileName": retrieveFileData[0].orgFileName, "userId": userId }
    setFileList(fileList => [...fileList, uploadedFileInfo]);
  }
  catch (ex) {
    ComNoticfication.ShowErrorUI("Error", ex);
  }
  fileElement.current.value = "";
}

SampleRequestUtils.removeFile = async (e, fileInfo, fileList, setFileList) => {

  let response = await ApiManager.post("/Common/File/FileDelete", {
    "filePath": fileInfo.filePath,
    "serverFileName": fileInfo.serverFileName,
    "customPath": "sampleportal"
  });

  let newFileList = fileList.filter(f => f.serverFileName !== fileInfo.serverFileName);
  setFileList(newFileList);
}

SampleRequestUtils.downloadFile = (e, file) => {
  FileUtils.callFileDownload(file, "/spl/common/retrieveFile", "sampleportal", e);
}

SampleRequestUtils.convertFile = (file) => {
  return {
    serverFileName: file?.fileName,
    displayFileName: file?.fileName,
    fileId: file?.fileId,
    filePath: file?.filePath,
  };
}

SampleRequestUtils.setOrderInfo = async (refs, resultData, resultShipMethodCode) => {
  
  refs?.entityShipMethodCode?.current?.setList({ data: resultShipMethodCode, code: "code", name: 'value' });
  refs?.setShowFas(resultData.query.expenseAccountCd == "18300323");

  refs?.setHeaderId(resultData.query.headerId);
  refs?.setRequestDate(resultData?.query?.createDate ?? '');
  refs?.setEntryBy(resultData?.query?.createUserName ?? '');

  SampleRequestUtils.initBusinessDivisionList(refs?.entityDivisionSample, 
    refs?.entityDivisionShowroom, 
    refs?.entityDivisionAcademy, 
    resultData.bizType, 
    resultData.query.bizType,
    true);
  refs?.entityDivisionSample?.current?.setValue(resultData.query.bizType);
  refs?.entityDivisionShowroom?.current?.setValue(resultData.query.bizType);
  refs?.entityDivisionAcademy?.current?.setValue(resultData.query.bizType);    
  refs?.entityOrderType?.current?.setValue(resultData.query.orderType);
  refs?.entityReason?.current?.setList({data: [{cd: resultData.query.reasonCode, nm: resultData.query.reasonNm}]});
  refs?.entityTobeReturnDate?.current?.setValue(resultData.query.collectionDate);
  refs?.entityBillToCd?.current?.setValue(resultData.query.billTo);
  refs?.entityReasonDetail?.current?.setList({data: [{cd: resultData.query.reasonDetailCode, nm: resultData.query.reasonDetailNm}]});  
  refs?.entityRequestorName?.current?.setValue(resultData.query.originalNm);
  refs?.entityBillToNm?.current?.setList({data: [{cd: resultData.query.billTo, nm: resultData.query.billToNm}]});
  refs?.entityExpenseDepartment?.current?.setList({data: [{cd: resultData.query.expenseDepartmentCd, nm: resultData.query.expenseDepartment}]});      
  refs?.entityRequestorEmail?.current?.setValue(resultData.query.requesterEmail);
  refs?.entityShipToCode?.current?.setList({data: [{cd: resultData.query.shipTo, nm: resultData.query.shipToNm}]});
  refs?.entityExpenseAccount?.current?.setList({data: [{cd: resultData.query.expenseAccountCd, nm: resultData.query.expenseAccount}]});
  refs?.entityLiftgate?.current?.setValue(resultData.query.liftGateFlag);
  refs?.entityAddress1?.current?.setValue(resultData.query.address1);    
  refs?.entityExpenseProjectCode?.current?.setList({data: [{cd: resultData.query.expenseProjectCd, nm: resultData.query.expenseProjectCode}]});
  refs?.entityB2bProjectCode?.current?.setValue(resultData.query.b2bProjectCode);
  refs?.entityContactName?.current?.setValue(resultData.query.contactNm);
  refs?.entityExpenseAu?.current?.setList({data: [{cd: resultData.query.expenseAuCd, nm: resultData.query.expenseAu}]});  // ?expenseAuCd
  refs?.entityWhiteGlove?.current?.setValue(resultData.query.whiteGloveFlag);
  refs?.entityReceiverPhoneNo?.current?.setValue(resultData.query.receiverPhoneNo);
  refs?.entityExpenseActivity?.current?.setList({data: [{cd: resultData.query.expenseActivityCd, nm: resultData.query.expenseActivity}]});
  refs?.entityReceiverEmail?.current?.setValue(resultData.query.receiverEmail);
  refs?.entityInstallation?.current?.setValue(resultData.query.installationFlag);
  refs?.entityCustPoNo?.current?.setValue(resultData.query.custPoNo);
  refs?.entityFaProjectCode?.current?.setValue(resultData.query.faProjectCode);
  refs?.entityPlannedDate?.current?.setValue(resultData.query.faInstallDate);
  refs?.entityLocation?.current?.setValue(resultData.query.faLocation);
  refs?.entityCity?.current?.setValue(resultData.query.city);
  refs?.entityState?.current?.setValue(resultData.query.state);
  refs?.entityZip?.current?.setValue(resultData.query.zip);
  if (resultData.mData.length > 0) {
    refs?.entityCountry?.current?.setList({data: [{cd: resultData.mData[0].countryCode, nm: resultData.mData[0].countryCode}]});
  }
    
  refs?.entityPickingRemark?.current?.setValue(resultData.query.pickingRemark);
  refs?.entityReceiverName?.current?.setValue(resultData.query.receiverName);
  refs?.entityReqArrivalDate?.current?.setValue(resultData.query.reqArrivalDate);
  refs?.entityDeliveryTime1?.current?.setList({data: [{cd: resultData.query.deliveryTime1, nm: resultData.query.deliveryTime1}]});
  refs?.entityDeliveryTime2?.current?.setList({data: [{cd: resultData.query.deliveryTime2, nm: resultData.query.deliveryTime2}]});
  refs?.entityDeliveryRemark?.current?.setValue(resultData.query.deliveryRemark);
  refs?.entityPurposeType?.current?.setList({data: [{cd: resultData.query.shippingTypeCode, nm: resultData.query.shippingType}]});
  refs?.entityShipMethodCode?.current?.setValue(resultData.query.shipMethodCode);
  refs?.entityAirShipmentReason?.current?.setValue(resultData.query.airShipmentReason);
  refs?.entityShippingRemark?.current?.setValue(resultData.query.shippingRemark);

  // set files
  const newFileList = resultData.fileList?.map(f => SampleRequestUtils.convertFile(f));
  const newAirFileList = resultData.fileListAir.map(f => SampleRequestUtils.convertFile(f));

  refs?.setFileList(newFileList);
  refs?.setAirFileList(newAirFileList);
}

SampleRequestUtils.getOrderDetailForApprPage = (orderType) => {
  switch (orderType) {
    case "OTHERS_IN_FOR_RETURN":
    case "OTHERS_IN_NO_RETURN":
      return "return";
    case "ADDRESS_CHANGE":
      return "SampleTrackingAddressChange";
    case "SAMPLE_SALES":
      return "SampleTrackingInvoice";
    case "GIFT":
      return "SampleTrackingGift";    
    case "DATE_CHANGE":
      return "SampleTrackingDateExtension";
    case "MISSING":
      return "SampleTrackingMissing";
    default:
      return "SampleRequestApprover";
  }
};

SampleRequestUtils.convertDealTypeToTrackingPage = (dealType) => {
  switch (dealType) {
    case "return":
      return "SampleFollowUpReturn";
    case "invoice":
      return "SampleTrackingInvoice";
    case "gift":
      return "SampleTrackingGift";
    case "addressChange":
      return "SampleTrackingAddressChange";
    case "dateChange":
      return "SampleTrackingDateExtension";
    default:
      return "SampleRequestApprover";
  }
}

SampleRequestUtils.getOrderDetailForFollowUpPage = (orderType) => {
  switch (orderType) {
    case "OTHERS_IN_FOR_RETURN":
    case "OTHERS_IN_NO_RETURN":
      return "SampleFollowUpReturn";
    case "OTHERS_OUT_FOR_RETURN":
    case "OTHERS_OUT_NO_RETURN":
      return "SampleFollowUpReturn";
    case "SAMPLE_SALES":
      return "SampleFollowUpInvoice";
    case "GIFT":
      return "SampleFollowUpDonation";
    case "ADDRESS_CHANGE":
      return "SampleFollowUpAddressChange";
    case "DATE_CHANGE":
      return "SampleFollowUpDateExtension";
    case "MISSING":
      return "missing";
  }
}

SampleRequestUtils.convertDealTypeToFollowUpPage = (dealType) => {
  switch (dealType) {
    case "return":
      return "SampleFollowUpReturn";
    case "invoice":
      return "SampleFollowUpInvoice";
    case "gift":
      return "SampleFollowUpDonation";
    case "addressChange":
      return "SampleFollowUpAddressChange";
    case "dateChange":
      return "SampleFollowUpDateExtension";
    default:
      return "SampleFollowMissing";
  }
}

SampleRequestUtils.getDetailPage = (param) => {
  let dealType = SampleRequestUtils.getDealType(param.orderType);
  let actionType = "";
  let readType = "";
  let page = "";

  if (dealType == "request") {
    actionType = param.orderStatus == "REQUESTING" ? "set" : "view";
    page = param.orderStatus == "SAVED" ? "SampleRequest" : "SampleRequestApprover";
  } else if (dealType != "return") {
    actionType = param.orderStatus == "REQUESTING" ? "set" : "view";
    readType = "list";
    
    // page = "retrieveOrderDetailForAppr";
    page = SampleRequestUtils.getOrderDetailForApprPage(param.orderType);
  } else {
    readType = param.orderStatus == "REQUESTING" ? "edit" : "list";
    // page = "retrieveOrderDetailForF";
    page = SampleRequestUtils.getOrderDetailForFollowUpPage(param.orderType);
  }

  return {
    headerId: param.headerId,
    orderType: param.orderType,
    page,
    dealType,
    actionType,
    readType    
  };
}
