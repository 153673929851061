import React, {
  useEffect,
  useRef,
  useState
} from "react";

//redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  registerUserProceeding
} from "store/auth/register/reducer";

import * as wijmo from "@grapecity/wijmo";
import "@grapecity/wijmo.cultures/wijmo.culture.en";

import Grid from "@mui/material/Grid";
import "assets/styles/pages/PagesCommon.scss";
import "assets/styles/pages/SignUpPage.scss";

import { ComNoticfication } from "common/components/ComNoticfication";

import ComDate from "common/components/ComDate";
import ComPassword from "common/components/ComPassword";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextBoxFind from "common/components/ComTextBoxFindWithForm";
import ComTextBox from "common/components/ComTextBoxWithForm";

import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";

import CityZipSchPopWithRegister from "Pages/Pop/CityZipSchPopWithRegister";

const PersonalInforSpiffPage = React.forwardRef(({ }, ref) => {
  const retrieveTodayInfoUrl = "/init/b2buser/retrieveToday";
  const retrieveCityZipListUrl = "/init/b2buser/retrieveCityZipList";


  const nameRegex = /^[a-zA-Z\s-]*$/;
  const digitsRegex = /^[0-9]*$/;
  const alphaNumericRegex = /^[a-zA-Z0-9 #.\-]*$/;
  const emailRegex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  // const alphaRegex = /^[a-zA-Z ]*$/;

  // 메세지 추가
  // const { messageObject } = useContext(MessageContext);
  // const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");
  // const errorMessage = ComUtils.getMessage(messageObject, "JMSG_COM_ERR_001");

  const condBirthDateRef = useRef(null);
  const condCitizen = useRef(null);

  const entityFirstName = useRef(null);
  const entityMiddleName = useRef(null);
  const entityLastName = useRef(null);

  const entitySSN1 = useRef(null);
  const entitySSN2 = useRef(null);
  const entitySSN3 = useRef(null);
  const entityHMSAddress = useRef(null);

  const entitASNumber = useRef(null);
  const entityCity = useRef(null);
  const entityState = useRef(null);
  const entityZip = useRef(null);

  const entityEmail = useRef(null);
  const entityReEmail = useRef(null);

  const entityMobilePhone = useRef(null);
  const entityHomePhone = useRef(null);

  const currentTodayInfo = useRef(null);

  const randomKey = useRef(null);
  const zipResData = useRef(null);
  const [openModalZip, setOpenModalZip] = useState(false);

  const dispatch = useDispatch();
  const AccountProperties = createSelector(
    (state) => state.account,
    (account) => ({
      user: account.user,
    })
  );
  const { user } = useSelector(AccountProperties, shallowEqual);

  React.useImperativeHandle(ref, () => ({
    isEmptyRequired: () => {
      if (!user.firstName) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "First Name is required."
          , () => { entityFirstName.current.setFocus(); });
      }
      else if (!user.lastName) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Last Name is required."
          , () => { entityLastName.current.setFocus(); });
      }
      else if (!user.year) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Date Of Birth is required."
          , () => { condBirthDateRef.current.setFocus(); });
      }
      else if (!user.ssn1) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Social Security Number is required."
          , () => { entitySSN1.current.setFocus(); });
      }
      else if (!user.ssn2) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Social Security Number is required."
          , () => { entitySSN2.current.setFocus(); });
      }
      else if (!user.ssn3) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Social Security Number is required."
          , () => { entitySSN3.current.setFocus(); });
      }
      else if (!user.zipCode) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Zip Code is required."
          , () => { entityZip.current.setFocus(); });
      }
      else if (!user.streetAddress) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "Home Mailing Street Address is required."
          , () => {
            entityHMSAddress.current.setFocus();
          });
      }
      else if (!user.email) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "E-mail is required."
          , () => { entityEmail.current.setFocus(); });
      }
      else if (!user.confirmEmail) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Re-enter E-mail is required."
          , () => { entityReEmail.current.setFocus(); });
      }
      else if (!user.officePhone) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Home Phone is required."
          , () => { entityHomePhone.current.setFocus(); });
      }
      else if (!user.ssn) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "Social Security Number is required."
          , () => { entitySSN1.current.setFocus(); });
      } else { }
    },
  }));

  const defaultZipSearchParam = useRef({
    url: retrieveCityZipListUrl,
    comCode: user.comCode,
    searchBizType: "",
    searchText: "",
    searchType: "zip",
    pageSize: "1",
    rowSize: "100",
    pageNo: "1",
  });

  const openZipPopup = (elementId, userCode, resultData) => {
    randomKey.current = ComUtils.setRefRandomKey();

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        entityZip.current.setValue(resultData.list[0].postalCode);
        entityCity.current.setValue(resultData.list[0].cityName);
        entityState.current.setValue(resultData.list[0].stateCode);
        user.city = resultData.list[0].cityName;
        user.state = resultData.list[0].stateCode;
        user.zipCode = resultData.list[0].postalCode;
        dispatch(registerUserProceeding(user));
      } else {
        //여러건이면 팝업으로 전달
        zipResData.current = resultData;
        setOpenModalZip(true);
      }
    } else {
      setOpenModalZip(true);
    }
  };

  const callZipPopup = (elementId, value) => {
    defaultZipSearchParam.current.searchText = value.toUpperCase();
    entityZip.current.setValue(value.toUpperCase());
  };

  const clearZipPopup = (elementId, value) => {
    entityZip.current.setValue(value);
    entityCity.current.setValue(value);
    entityState.current.setValue(value);
    user.city = value;
    user.state = value;
    user.zipCode = value;
    dispatch(registerUserProceeding(user));
  };

  const closeZipPopup = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      entityZip.current.setValue(selectedItems.postalCode);
      entityCity.current.setValue(selectedItems.cityName);
      entityState.current.setValue(selectedItems.stateCode);

      user.city = selectedItems.cityName;
      user.state = selectedItems.stateCode;
      user.zipCode = selectedItems.postalCode;
      dispatch(registerUserProceeding(user));

      defaultZipSearchParam.current.searchText = selectedItems.userName;
    }
    zipResData.current = null;
    setOpenModalZip(false);
  };

  const onBlurFirstName = (id, value) => {

    // if (value.match(nameRegex)) {
    if (nameRegex.test(value)) {
      user.firstName = value.toUpperCase();
      dispatch(registerUserProceeding(user));
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "Special characters and numbers are not allowed in the Name.",
          () => {
            entityFirstName.current?.setValue(null);
            entityFirstName.current?.setFocus();
          }
        );
    }
  };
  const onBlurMiddleName = (id, value) => {
    if (nameRegex.test(value)) {
      user.middleName = value.toUpperCase();
      dispatch(registerUserProceeding(user));
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "Special characters and numbers are not allowed in the Name.",
          () => {
            entityMiddleName.current?.setValue(null);
            entityMiddleName.current?.setFocus();
          }
        );
    }
  };
  const onBlurLastName = (id, value) => {
    if (nameRegex.test(value)) {
      user.lastName = value.toUpperCase();
      dispatch(registerUserProceeding(user));
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "Special characters and numbers are not allowed in the Name.",
          () => {
            entityLastName.current?.setValue(null);
            entityLastName.current?.setFocus();
          }
        );
    }
  };

  const onChangeSSN1 = (id, value) => {
    entitySSN1.current.setValue(value.replace(/[^0-9]/g, ""));
  };
  const onBlurSSN1 = (id, value) => {
    user.ssn1 = value;
    dispatch(registerUserProceeding(user));
  };

  const onChangeSSN2 = (id, value) => {
    entitySSN2.current.setValue(value.replace(/[^0-9]/g, ""));
  };
  const onBlurSSN2 = (id, value) => {
    user.ssn2 = value;
    dispatch(registerUserProceeding(user));
  };

  const onChangeSSN3 = (id, value) => {
    entitySSN3.current.setValue(value.replace(/[^0-9]/g, ""));
  };
  const onBlurSSN3 = (id, value) => {
    user.ssn3 = value;
    dispatch(registerUserProceeding(user));
  };

  const onChangeBirthDate = (id, text, value) => {
    if (currentTodayInfo.current) {
      let tmpCurrentDate = `${currentTodayInfo.current.year}${currentTodayInfo.current.month}${currentTodayInfo.current.day}`;
      let arrDate = value.split("-");

      if (
        parseInt(tmpCurrentDate.substring(0, 4)) - parseInt(arrDate[0]) > 18 ||
        (parseInt(tmpCurrentDate.substring(0, 4)) - parseInt(arrDate[0]) ==
          18 &&
          parseInt(arrDate[1] + arrDate[2]) <=
          parseInt(tmpCurrentDate.substring(4)))
      ) {
        user.year = arrDate[0];
        user.month = arrDate[1];
        user.day = arrDate[2];
        dispatch(registerUserProceeding(user));
      } else {
        ComNoticfication.ShowAlertUI(
          ComUtils._altTitleWarn,
          "RSA should be over 18 years old."
        );
      }
    }
  };

  const onChangeCitizenType = (elementId, selectedValue) => {
    user.isUSCitizen = selectedValue;
    dispatch(registerUserProceeding(user));
  };

  const onBlurHMSAddress = (id, value) => {
    if (alphaNumericRegex.test(value)) {
      let tmpSAddress = value.toUpperCase();
      if (
        tmpSAddress.indexOf("BOX ") != -1 &&
        (tmpSAddress.indexOf("PO ") != -1 ||
          tmpSAddress.indexOf("P O") != -1 ||
          tmpSAddress.indexOf("P.O") != -1 ||
          tmpSAddress.indexOf("POST ") != -1)
      ) {
        ComNoticfication.ShowAlertUI(
          ComUtils._altTitleWarn,
          "PO BOX is not allowed."
        );
      } else {
        user.streetAddress = value;
        dispatch(registerUserProceeding(user));
      }
    } else {
      if (value) {
        user.streetAddress = null;
        entityHMSAddress.current.setValue(null);
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn, "The Home Mailing Street value is invalid."
          , () => {
            entityHMSAddress.current.setFocus();
          }
        );
      }
    }
  };

  const onBlurASNumber = (id, value) => {
    if (alphaNumericRegex.test(value)) {
      user.aptNumber = value;
      dispatch(registerUserProceeding(user));
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "The Apt/Suite Number value is invalid.",
          () => {
            entitASNumber.current?.setValue(null);
            entitASNumber.current?.setFocus();
          }
        );
    }
  };

  const onBlurEmail = (id, value) => {
    // if (value.match(emailRegex)) {
    if (emailRegex.test(value)) {
      user.email = value;
      dispatch(registerUserProceeding(user));
    } else {
      if (value) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          `Email address ${value} is invalid.`,
          () => {
            entityEmail.current?.setValue(null);
            entityEmail.current?.setFocus();
          }
        );
      }
    }
  };
  const onBlurReEmail = (id, value) => {
    if (emailRegex.test(value)) {
      if (user.email === value) {
        user.confirmEmail = value;
        dispatch(registerUserProceeding(user));
      } else {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "Please check that your e-mail addresses match and try again.",
          () => {
            entityReEmail.current?.setValue(null);
            entityReEmail.current?.setFocus();
          }
        );
      }
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "The re-enter Email value is invalid.",
          () => {
            entityReEmail.current?.setValue(null);
            entityReEmail.current?.setFocus();
          }
        );
    }
  };

  const onBlurMobilePhone = (id, value) => {
    if (alphaNumericRegex.test(value)) {
      user.mobilePhone = value;
      dispatch(registerUserProceeding(user));
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "The Mobile Phone number value is invalid.",
          () => {
            entityMobilePhone.current?.setValue(null);
            entityMobilePhone.current?.setFocus();
          }
        );
    }
  };

  const onBlurHomePhone = (id, value) => {
    if (digitsRegex.test(value)) {
      user.officePhone = value;
      dispatch(registerUserProceeding(user));
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "The Home Phone number value is invalid.",
          () => {
            entityHomePhone.current?.setValue(null);
            entityHomePhone.current?.setFocus();
          }
        );
    }
  };

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {
      const [todayInfo] = await Promise.all([
        ApiManager.post(retrieveTodayInfoUrl, {}),
      ]);

      if (todayInfo && todayInfo.length > 0) {
        currentTodayInfo.current = todayInfo[0];

        let limitDate = new Date(
          todayInfo[0]["year"],
          parseInt(todayInfo[0]["month"]) - 1,
          todayInfo[0]["day"]
        );

        const currentYear = limitDate.getFullYear();
        const year18YearsAgo = currentYear - 18; // Calculate the year 18 years ago
        const date18YearsLater = new Date(
          year18YearsAgo,
          limitDate.getMonth(),
          limitDate.getDate()
        ); /// Add 18 years to the current date

        if (user.year && user.month && user.day) {
          let preDate = new Date(user.year, parseInt(user.month) - 1, user.day);
          condBirthDateRef.current.setValue(
            wijmo.Globalize.format(preDate, "MM/dd/yyyy")
          );
        } else {
          condBirthDateRef.current.setValue(
            // wijmo.Globalize.format(date18YearsLater, "MM/dd/yyyy")
            wijmo.Globalize.format(null, "MM/dd/yyyy") //기본 빈값으로 샛팅. 
          );
        }
        condBirthDateRef.current.setMaxDate(
          wijmo.Globalize.format(date18YearsLater, "MM/dd/yyyy")
        );
      }

      let radioDataCitizen = [
        { Value: "1", Description: "Yes" },
        { Value: "0", Description: "No" },
      ];

      condCitizen.current.setList({
        data: radioDataCitizen,
        defaultValue: user.isUSCitizen,
        //   defaultValue: radioDataCitizen[0].Value,
      });

      entityFirstName.current.setValue(user.firstName);
      entityMiddleName.current.setValue(user.middleName);
      entityLastName.current.setValue(user.lastName);

      entitySSN1.current.setValue(user.ssn1);
      entitySSN2.current.setValue(user.ssn2);
      entitySSN3.current.setValue(user.ssn3);

      entityHMSAddress.current.setValue(user.streetAddress);
      entitASNumber.current.setValue(user.aptNumber);

      entityCity.current.setValue(user.city);
      entityState.current.setValue(user.state);
      entityZip.current.setValue(user.zipCode);

      entityEmail.current.setValue(user.email);
      entityReEmail.current.setValue(user.confirmEmail);

      entityMobilePhone.current.setValue(user.mobilePhone);
      entityHomePhone.current.setValue(user.officePhone);
    };

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [user]);

  return (
    <div className="content-signup">
      <div className="content-text">
        <span className="title-info-page">
          Your personal information will be provided to the LG Debit Card
          issuing bank. It is important that you provide correct information to
          avoid debit card processing rejection. Your debit card will be mailed
          to your below home mailing address.
        </span>
      </div>
      <div className="input-content-signup">
        <div className="item-input3">
          <p>
            First Name <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityFirstName}
            elementId={"entityFirstName"}
            callRelationBlur={onBlurFirstName}
            defaultMaxLength={40}
            defaultStyle={{ "textTransform": "uppercase" }}
          />
        </div>
        <div className="item-input3">
          <p>Middle Name</p>
          <ComTextBox
            ref={entityMiddleName}
            elementId={"entityMiddleName"}
            callRelationBlur={onBlurMiddleName}
            defaultMaxLength={40}
            defaultStyle={{ "textTransform": "uppercase" }}
          />
        </div>
        <div className="item-input3">
          <p>
            Last Name <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityLastName}
            elementId={"entityLastName"}
            callRelationBlur={onBlurLastName}
            defaultMaxLength={40}
            defaultStyle={{ "textTransform": "uppercase" }}
          />
        </div>
      </div>
      <div className="input-content-signup">
        <div className="item-input3 custom-inputdate">
          <p>
            Date Of Birth <span className="required">*</span>
          </p>
          <ComDate
            ref={condBirthDateRef}
            elementId={"condBirthDateRef"}
            callRelationFuntion={onChangeBirthDate}
            isNullable={true}
            controlDateFormat={"MM/dd/yyyy"}
            controlDateMask={"##/##/####"}
            controlDateValidFormat={"__/__/____"}
          />
        </div>
        <div className="item-input3 flex-center-item">
          <p>
            Are you US Citizen? <span className="required">*</span>
          </p>

          <div className="radio-btn d-flex" style={{ border: "1px solid #d7d3d0", borderRadius: "4px", minHeight: "38px" }}>
            <ComRadioBox
              ref={condCitizen}
              elementId={"condCitizen"}
              radioGroupName={"citizenType"}
              direction={1}
              callRelationFuntion={onChangeCitizenType}
            />
          </div>
        </div>
        <div className="item-input3">
          <p>
            Social Security Number <span className="required">*</span>
          </p>
          <div className="ip-number-card">
            <div style={{ width: "110px" }}>
              <ComPassword
                ref={entitySSN1}
                elementId={"entitySSN1"}
                callRelationFuntion={onChangeSSN1}
                callRelationBlur={onBlurSSN1}
                defaultMaxLength={3}
              />
            </div>
            -
            <div style={{ width: "63px" }}>
              <ComPassword
                ref={entitySSN2}
                elementId={"entitySSN2"}
                callRelationFuntion={onChangeSSN2}
                callRelationBlur={onBlurSSN2}
                defaultMaxLength={2}
              />
            </div>
            -
            <div style={{ width: "145px" }}>
              <ComTextBox
                ref={entitySSN3}
                elementId={"entitySSN3"}
                callRelationFuntion={onChangeSSN3}
                callRelationBlur={onBlurSSN3}
                defaultMaxLength={4}
              />
            </div>
          </div>
          <span className="hint-text">
            Required for IRS Tax Identification Number and issue a LG Debit
            Card.
          </span>
        </div>
      </div>

      <div className="input-content-signup">
        <div className="item-input3">
          <p>
            Zip Code <span className="required">*</span>
          </p>
          <ComTextBoxFind
            ref={entityZip}
            elementId={"entityZip"}
            callOpenPopupFunction={openZipPopup}
            callRelationFuntion={callZipPopup}
            callClearFunction={clearZipPopup}
            defaultRaiseClick={false}
            defaultReadOnly={false}
            defaultSearch={defaultZipSearchParam}
          />
        </div>
        <div className="item-input3">
          <p>
            City <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityCity}
            elementId={"entityCity"}
            defaultDisabled={true}
          />
        </div>
        <div className="item-input3">
          <p>
            State <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityState}
            elementId={"entityState"}
            defaultDisabled={true}
          />
        </div>
      </div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid className="ip-number">
          <p>
            Home Mailing Street Address <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityHMSAddress}
            elementId={"entityHMSAddress"}
            callRelationBlur={onBlurHMSAddress}
            defaultMaxLength={200}
          />
          <span className="hint-text">
            Your LG Debit Card cannot be sent to a PO BOX address. Please enter
            physical street address only.
          </span>
        </Grid>
        <Grid>
          <p>Apt/Suite Number</p>
          <ComTextBox
            ref={entitASNumber}
            elementId={"entitASNumber"}
            callRelationBlur={onBlurASNumber}
            defaultMaxLength={20}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid>
          <p>
            E-mail <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityEmail}
            elementId={"entityEmail"}
            callRelationBlur={onBlurEmail}
            defaultMaxLength={100}
          />
        </Grid>
        <Grid>
          <p>
            Re-enter E-mail <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityReEmail}
            elementId={"entityReEmail"}
            callRelationBlur={onBlurReEmail}
            defaultMaxLength={100}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid>
          <p>Mobile Phone</p>
          <ComTextBox
            ref={entityMobilePhone}
            elementId={"entityMobilePhone"}
            callRelationBlur={onBlurMobilePhone}
            defaultMaxLength={10}
          />
        </Grid>
        <Grid className="ip-number">
          <p>
            Home Phone <span className="required">*</span>
          </p>
          <ComTextBox
            ref={entityHomePhone}
            elementId={"entityHomePhone"}
            callRelationBlur={onBlurHomePhone}
            defaultMaxLength={10}
            placeholder="Ex: 1002003000"
          />
          <span className="hint-text">Please input 10-digit number only.</span>
        </Grid>
      </Grid>
      {openModalZip && (
        <CityZipSchPopWithRegister
          closeFn={closeZipPopup}
          randomKey={randomKey.current}
          reqSearchText={entityZip?.current?.getValue()}
          resultData={zipResData?.current}
          reqComCode={user.comCode}
        />
      )}
    </div>
  );
});
export default PersonalInforSpiffPage;
