import * as wijmo from "@grapecity/wijmo";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { InputNumber, InputDate } from "@grapecity/wijmo.input";
import { createElement, format } from "@grapecity/wijmo";
import { BaseClaim } from "./BaseClaim";
import search from "assets/icons/search.svg";
import IcPdf from "assets/images/files/file_icon_pdf.png";

class ClaimPriceDifference extends BaseClaim {
  constructor(props) {
    super(props);    
  }

  getGirdFormat = () => {
    let out = [
      { 
        binding: 'isChecked2', 
        header: " ", 
        width: 30, 
        allowDragging: false, 
        isReadOnly: false, 
        allowResizing: false, 
        allowSorting: false ,
        visible: this.pageType !== 'Dtl',
      },    
      {
        binding: "invoiceNo",
        header: "LG Invoice#",
        width: 160,
        allowDragging: true,
        isRequired: true,
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openPriceInvoiceNoPopup;
          let callPdfFunc = this.downloadPdf;        

          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <div style="float: right;">
                  <img class="grid_btn_find" src='${search}' style="display: ${this.pageType === 'Dtl' ? 'none' : ''}"/>
                  <img class="grid_btn_find_pdf ml-5" src='${IcPdf}' />
                </div>
              </div>`
            ,{}),cell);

          // cell.addEventListener('keydown', function (e) {
          //   if (e.keyCode === Key.Enter) {
          //     onCellEnter?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          //   }
          // });          
    

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };

          let buttonElementPdf = element.querySelector("img.grid_btn_find_pdf");
          if (buttonElementPdf) {
            buttonElementPdf.onclick = function (e) {
              e.preventDefault();
              callPdfFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
            };
          }
        },
      },
      {
        binding: "modelCode",
        header: "LG Model",
        width: 200,
        allowDragging: true,
        isRequired: true,
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openModelCodePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "RequestOrg",
        header: "Org. Request",
        width: 150,
        allowDragging: true,
        visible: !this.orgRequestHidden,
        columns: [
          {
            binding: "orgRequestQty",
            header: "Qty",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
            aggregate: "Sum",
            align: "right",
            visible: !this.orgRequestHidden,
          },
          { 
            binding: "orgRequestPrice", 
            header: "Price", 
            width: 100, 
            allowDragging: true, 
            isReadOnly: true,
            format: "n2", 
            align: "right", 
            visible: !this.orgRequestHidden,
          },
          { 
            binding: "orgRequestAmt",
            header: "Amt", 
            width: 100, 
            allowDragging: true, 
            isReadOnly: true,
            align: "right", 
            format: "n2", 
            aggregate: "Sum", 
            visible: !this.orgRequestHidden,
          },
        ],
      },
      {
        binding: "request",
        header: "Request",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "requestQty",
            header: "Qty",
            width: 100,
            allowDragging: true,
            isRequired: true,
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
            aggregate: 'Sum',
          },
          {
            binding: "requestPrice",
            header: "Price",
            width: 100,
            allowDragging: true,
            isRequired: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
          {
            binding: "requestAmt",
            header: "Amt",
            width: 100,
            allowDragging: true,
            isRequired: true,
            isReadOnly: true,
            align: "right",
            format: "n2",
            aggregate: 'Sum',
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
        ],
      },
      {
        binding: "rejectCode",
        header: "Rejection Reason",
        width: 180,
        allowDragging: true,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
        dataMap: this.rejectYn === 'Y' ? this.rejectionReasonMap : undefined,
      },
      {
        binding: "rejectDesc",
        header: "Rejection Comment",
        width: 160,
        allowDragging: true,
        isRequired: false,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
      },
      {
        binding: "lgInvoiceDa",
        header: "LG Invoice",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "invoiceDate",
            header: "Date",
            width: 130,
            allowDragging: true,
            isReadOnly: true,
            align: "center",
            format: 'MM/dd/yyyy',
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              let value = ctx.value;
              if (value) {
                value = wijmo.Globalize.format(new Date(value), 'MM/dd/yyyy');
                return value;
              } else {
                return '';
              }
            },
            editor: new InputDate(document.createElement("div"), {
              format: "MM/dd/yyyy",
              mask: "##/##/####",
              isRequired: false,
              invalidInput: (s, e) => {
                if (s.text === "__/__/____") {
                  s.text = "";
                  s.value = null;
                }
              },
            })
          },
          {
            binding: "invoiceAmt",
            header: "Amt",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
        ],
      },
      {
        binding: "lgInvoicePrice",
        header: "Invoice Price",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "oldInvoicePrice",
            header: "Old",
            width: 100,
            allowDragging: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
          {
            binding: "newInvoicePrice",
            header: "New",
            width: 100,
            allowDragging: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
        ],
      },
      {
        binding: "activitySalesClaimDate",
        header: "Activity Date",
        width: 130,
        allowDragging: true,
        isRequired: true,
        align: "center",
        format: 'MM/dd/yyyy',
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          if (value) {
            value = wijmo.Globalize.format(new Date(value), 'MM/dd/yyyy');
            return value;
          } else {
            return '';
          }
        },
        editor: new InputDate(document.createElement("div"), {
          format: "MM/dd/yyyy",
          mask: "##/##/####",
          isRequired: false,
          invalidInput: (s, e) => {
            if (s.text === "__/__/____") {
              s.text = "";
              s.value = null;
            }
          },
        })
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openChargePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        align: "right",
        format: "n2",
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
      },

      {
        binding: "customerDesc",
        header: "Customer Description",
        width: "*",
        minWidth: 250,
        allowDragging: true,
        isRequired: false,
      },
      {
        binding: "approved",
        header: "Approved",
        width: 150,
        allowDragging: true,
        visible: this.pageType === 'Dtl',
        columns: [
          {
            binding: "approvedQty",
            header: "Qty",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
            aggregate: 'Sum',
            visible: this.pageType === 'Dtl',
          },
          {
            binding: "approvedPrice",
            header: "Price",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
            visible: this.pageType === 'Dtl',
          },
          {
            binding: "approvedAmt",
            header: "Amt",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            isReadOnly: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
            aggregate: 'Sum',
            visible: this.pageType === 'Dtl',
          },
        ],
      },
    ];

    return out;
  }

  setFormatItem = (s, e) => {
    if (s.columns[e.col]?.binding === "invoiceNo") {
      s.columnFooters.setCellData(0, e.col, "Total", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  }

  getData = () => {
    return [{
      isChecked2: false,
      invoiceNo: "",
      modelCode: "",
      requestQty: null,
      requestPrice: null,
      requestAmt: null,
      invoiceDate: null,
      invoiceAmt: null,
      oldInvoicePrice: null,
      newInvoicePrice: null,
      activitySalesClaimDate: null,
      lgChargebackNo: "",
      lgChargebackAmt: null,
      customerDesc: "",
    }]
  }


  getTemplateFileName = () => {
    return "PriceDifference";
  }

  getTemplateGirdFormat = () => {    
    return [
      {
        binding: "invoiceNo",
        header: "*LG Invoice#",
        width: 150,
      },
      {
        binding: "modelCode",
        header: "*LG Model",
        width: 150,
      },
      {
        binding: "request",
        header: "Request",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "requestQty",
            header: "*Qty",
            width: 150,
            align: "right",
          },
          {
            binding: "requestPrice",
            header: "*Price",
            width: 150,
            align: "right",
          },
          {
            binding: "requestAmt",
            header: "*Amt",
            width: 150,
            align: "right",
          },
        ],
      },
      {
        binding: "lgInvoiceDa",
        header: "LG Invoice",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "invoiceDate",
            header: "Date",
            width: 150,
            align: "center",
          },
          {
            binding: "invoiceAmt",
            header: "Amt",
            width: 150,
            align: "right",
          },
        ],
      },
      {
        binding: "lgInvoicePrice",
        header: "Invoice Price",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "oldInvoicePrice",
            header: "Old",
            width: 150,
            align: "right",
          },
          {
            binding: "newInvoicePrice",
            header: "New",
            width: 150,
            align: "right",
          },
        ],
      },
      {
        binding: "activitySalesClaimDate",
        header: "Activity Date",
        width: 150,
        align: "center",
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 200,
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 200,
        align: "right",
      },

      {
        binding: "customerDesc",
        header: "Customer Description",
        width: 250,
      },
    ];
  }

  getTemplateData = () => {
    return [{
      invoiceNo: "input Invoice No",
      modelCode: "input Model",
      requestQty: "1",
      requestPrice: "1",
      requestAmt: "1",
      invoiceDate: "12/31/2015",
      invoiceAmt: "1",
      oldInvoicePrice: "1",
      newInvoicePrice: "1",
      activitySalesClaimDate: "12/31/2015",
      lgChargebackNo: "input Chargeback No",
      lgChargebackAmt: "1",
      customerDesc: "input Customer Description",
    }]
  }

  convertTemplateToGridData = (el, idx) => {
    let gridFormat = this.getTemplateGirdFormat();

    return {
      rowNum: idx,
      invoiceNo: el[this.getHeaderByBinding(gridFormat, "invoiceNo")],
      modelCode: el[this.getHeaderByBinding(gridFormat, "modelCode")],
      requestQty: el[this.getHeaderByBinding(gridFormat, "requestQty")],
      requestPrice: el[this.getHeaderByBinding(gridFormat, "requestPrice")],
      requestAmt: el[this.getHeaderByBinding(gridFormat, "requestAmt")],
      invoiceDate: el[this.getHeaderByBinding(gridFormat, "invoiceDate")],
      invoiceAmt: el[this.getHeaderByBinding(gridFormat, "invoiceAmt")],
      oldInvoicePrice: el[this.getHeaderByBinding(gridFormat, "oldInvoicePrice")],
      newInvoicePrice: el[this.getHeaderByBinding(gridFormat, "newInvoicePrice")],
      activitySalesClaimDate: el[this.getHeaderByBinding(gridFormat, "activitySalesClaimDate")],
      lgChargebackNo: el[this.getHeaderByBinding(gridFormat, "lgChargebackNo")],
      lgChargebackAmt: el[this.getHeaderByBinding(gridFormat, "lgChargebackAmt")],
      customerDesc: el[this.getHeaderByBinding(gridFormat, "customerDesc")],
    }
  }

  isMergeNumbering() {
    return true;
  }
}
export default ClaimPriceDifference;
