import React, { useState, useEffect, useRef } from 'react';
import "@grapecity/wijmo.cultures/wijmo.culture.en";
import * as wjInput from '@grapecity/wijmo.react.input';
import * as wijmo from "@grapecity/wijmo";
import { ComUtils } from 'common/lib/ComUtils';

const ComDateNotRequired = React.forwardRef(({
  callRelationFuntion   // 부모에서 호출 할 함수
  , controlDateFormat     // 날짜 형식
  , defaultValue          // 기본 값
  , elementId             // 객체 아이디
  , isNullable

  // 스타일 관련
  , defaultDisabled       // 활성화여부
  , defaultStyle
  , defaultClassName

  , controlDateMask
  , controlDateValidFormat

  , defaultSelectionMode // 0 : None, 1 : day, 2 : month, 3: Range
}
  , ref) => {

  const dateObjectRef = useRef(null);
  const dateRef = useRef(null);

  const dateValueRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState();

  const [minSelectDate, setMinSelectDate] = useState(null);

  // 스타일 관련
  const [userStyle, setUserStyle] = useState({});
  const [userClassName, setUserClassName] = useState(false);
  const [userDisable, setUserDisable] = useState(false);
  const [userFormat, setUserFormat] = useState('yyyy-MM-dd')
  const [userMask, setUserMask] = useState("####-##-##")
  const [userValidText, setUserValidText] = useState("__/__/____")


  const [hasClassName, setHasClassName] = useState(false);
  const [hasStyle, setHasStyle] = useState(false);

  const [userSelectionMode, setUserSelectionMode] = useState(1)



  React.useImperativeHandle(ref, () => (
    {

      getOriginalValue: () => {
        if (dateValueRef.current) { return dateValueRef.current }
        return null;
      }
      , getValue: () => {
        if (dateRef.current) {
          if (typeof dateRef.current === 'object' && dateRef.current !== null) {
            return ComUtils.DateToInteger(dateRef.current);
          } else {
            return dateRef.current.replace(/-/gi, "")
          }

        }
        return null;
      }
      , getCheckedValues: () => {
        if (dateRef.current) {
          if (typeof dateRef.current === 'object' && dateRef.current !== null) {
            return ComUtils.DateToInteger(dateRef.current);
          } else {
            return dateRef.current.replace(/-/gi, "")
          }
        }
        return null;
      }
      , getDateValues: () => {
        if (dateValueRef?.current) { return wijmo.Globalize.format(dateValueRef.current, 'yyyy-MM-dd'); }
        return null;
      }
      , getDateValueString: () => {
        if (dateValueRef?.current) { return wijmo.Globalize.format(dateValueRef.current, 'yyyyMMdd'); }
        return null;
      }
      , getDateValueInteger: () => {
        if (dateValueRef?.current) { return parseInt(wijmo.Globalize.format(dateValueRef.current, 'yyyyMMdd'), 10); }
        return null;
      }
      , setValue: (v) => {
        if (v) {

          let finnalData = v
          if (!wijmo.isDate(v)) {
            finnalData = new Date(v)
          }
          setSelectedDate(finnalData);

          dateRef.current = finnalData;
          dateValueRef.current = finnalData;
        }
        else {
          setSelectedDate(null); dateRef.current = null; dateValueRef.current = null
        }
      }
      , setValueEmpty: () => {
        setSelectedDate(new Date(""));
        setTimeout(() => {
          setSelectedDate(null);
          dateRef.current = null;
          dateValueRef.current = null;
        }, 200);
      }

      //,setValue           : (v)=>{console.log(elementId+"_"+new Date()); setSelectedDate(new Date());}
      , setFormat: (v) => { setUserFormat(v); }
      , setMask: (v) => { setUserMask(v) }
      , setValidText: (v) => { setUserValidText(v) }

      , setinit: (v) => {
        if (v?.defaultValue) { setSelectedDate(v.defaultValue); dateRef.current = v.defaultValue; }
        if (v?.format) { setUserFormat(v.format); }
        if (v?.mask) { setUserMask(v.mask) }
        if (v?.validFormat) { setUserValidText(v.validFormat) }
        if (v?.style) { setUserStyle(v.style); setHasStyle(true); }


      }
      , setDisabled: (v) => { setUserDisable(v); }
      , setMinDate: (v) => {
        let minDate = v;
        if (!wijmo.isDate(v)) {
          minDate = new Date(v)
        }
        setMinSelectDate(minDate);
      }
      , setFocus: () => {
        dateObjectRef.current.control.hostElement.focus();
      }
    }));


  // 초기화 설정
  useEffect(() => {
    if (defaultValue) {
      dateValueRef.current = new Date(defaultValue);
      setSelectedDate(new Date(defaultValue));
    }
    else {
      if (isNullable) {
        setSelectedDate(null)
        dateRef.current = null;
      }
      else {
        dateValueRef.current = new Date(defaultValue);
        setSelectedDate(new Date());
      }
    }
    if (defaultStyle) {
      setUserStyle(defaultStyle);
      setHasStyle(true);
    }

    if (defaultClassName) {
      setUserClassName(defaultClassName)
      setHasClassName(true)
    }
    if (defaultDisabled === undefined || defaultDisabled === null) {
      setUserDisable(false);
    }
    else {
      setUserDisable(defaultDisabled);
    }

    if (controlDateFormat) {
      setUserFormat(controlDateFormat);
    }

    if (controlDateMask) {
      setUserMask(controlDateMask)
    }

    if (controlDateValidFormat) {
      setUserValidText(controlDateValidFormat)
    }

    if (defaultSelectionMode) {
      setUserSelectionMode(defaultSelectionMode)
    }

  }, [defaultValue, defaultDisabled, defaultStyle, defaultClassName, isNullable, controlDateFormat, controlDateMask, controlDateValidFormat, defaultSelectionMode])


  const dateChanged = (e) => {

    dateRef.current = e.text;
    dateValueRef.current = e.value;
    if (callRelationFuntion) {
      callRelationFuntion(elementId, e.text, wijmo.Globalize.format(new Date(e.value), 'yyyy-MM-dd'), e.value);
    }
  }
  return (
    <wjInput.InputDate
      ref={dateObjectRef}
      name="comDate"
      value={selectedDate ? selectedDate : null}
      valueChanged={dateChanged}
      isReadOnly={userDisable}
      isDisabled={userDisable}
      className={hasClassName ? userClassName : ""}
      style={hasStyle ? userStyle : {}}

      format={userFormat}
      mask={userMask}
      invalidInput={
        (s, e) => {
          if (s.text === userValidText) {
            s.text = ""; s.value = null;
          }
        }}
      isRequired={isNullable ? false : true}
      min={minSelectDate}

      selectionMode={userSelectionMode}
    />

  )
});
export default React.memo(ComDateNotRequired);