import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
  } from "react";
  import "assets/styles/pages/PagesCommon.scss";

import { MessageContext } from "common/lib/MessageProvider";
import styled from "@emotion/styled";
  
//import PageNavigation from 'Layout/PageNavigation';

function SampleOrderTopNav({dealType, readType, orderType, onChangedNav}) {
  const styleSelected = {
      backgroundColor: "#A50034", 
      color: "white",
      cursor: "pointer",
      fontSize: "13px",
  };

  //Memory
  const _isMounted = useRef(true);

  const isEnableNav = (navDealType) => {
    if (readType != 'list' && readType != 'desk') {
      if (orderType == 'OTHERS_OUT_NO_RETURN') {
        return navDealType == 'return' || navDealType == 'addressChange';
      } else {
        return true;
      }
    }

    return false;
  }

  const getStyle = (id) => {
    if (dealType === id) {
      // 현재 선택된 dealType
      return styleSelected;
    } else {
      if (isEnableNav(id)) {
        return {
          backgroundColor: "#FFFFFF", 
          color: "black",
          cursor: "pointer",
          fontSize: "13px",
        };
      } else {
        return {
          backgroundColor: "#FFFFFF", 
          color: "gray",
          fontSize: "13px",
        };
      }     
    } 
  }

  const onClickNav = (event) => {
    if (isEnableNav(event.target.id)) {
      onChangedNav?.(event.target.id);
    }
  }

  useEffect(() => {
    let abortController = new AbortController(); 

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);


  return (
      <table className="bx-table-approval">
      <thead>
        <tr className="title-table-approval">
          <th className="w-150">No Approval</th>
          <th className="w-600">Approval Up to PM</th>
          <th className="w-400">Same as Non-Return Sample Approval</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td 
            className="w-150" 
            id="return"
            style={getStyle("return")}
            onClick={onClickNav}
          >
            {"Return"}
          </td>
          <td className="w-600 content-table">
            <div className="approval-layout"> 
              <div 
                className="item-table-approval br-right h-100" 
                id="addressChange"
                style={getStyle("addressChange")}
                onClick={onClickNav}
              >
                {"Location Transfer"}
              </div>
              <div 
                className="item-table-approval br-right" 
                id="dateChange"
                style={getStyle("dateChange")}
                onClick={onClickNav}
              >
                {"Return Date Extension"}
              </div>
              <div 
                className="item-table-approval" 
                id="invoice"
                style={getStyle("invoice")}
                onClick={onClickNav}
              >
                {"Invoice(To Be Billed)"}
              </div>
            </div>
          </td>
          <td 
            className="w-400" 
            id="gift"
            style={getStyle("gift")}
            onClick={onClickNav}
            >
              {"Donation(Gift)"}
            </td>
        </tr>
      </tbody>
    </table>
  );
}
  
  export default SampleOrderTopNav;
  