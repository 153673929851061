import { legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; //리덕스 개발자 도구

// import { createStore, applyMiddleware, compose } from "redux";
// import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
// import rootSaga from "./sagas";

// const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer
  , composeWithDevTools()
//   composeEnhancers(applyMiddleware(sagaMiddleware))
);
// sagaMiddleware.run(rootSaga);

export default store;