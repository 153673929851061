import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';


import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";

import Divider from "@mui/material/Divider";

import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";
import { ComUtils } from "common/lib/ComUtils";
import { CollectionView } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { MessageContext } from "common/lib/MessageProvider";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import ComCheckBox from 'common/components/ComCheckBox';
import ComTextArea from 'common/components/ComTextArea';
import BillToSchPop from '../BillToSchPop';
import ComRadioBoxNoneCheck from 'common/components/ComRadioBoxNoneCheck';
import VendorPrtPop from 'Pages/Clm/Oth/Vendor/VendorPrtPop';


function VendorModPop({ open, closeFn, reqRandomKey, billToData, callVendorCode, pageInfo }) {
  //session정보
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userId = sessionStorage.getItem("userId").toString();
  const userLevel = pageInfo.userLevel;

  const randomKey = useRef(null);

  //url  
  const retrieveVendorInfo = "/clm/oth/vendor/retrieveVendorInfo";
  const updateVendorInfo = "/clm/oth/vendor/updateVendorInfo";

  const entityBillToCode = useRef(null);
  const entityBillToName = useRef(null);
  const [billToCd, setBillToCd] = useState("");
  const [billToNm, setBillToNm] = useState("");
  // Requestor Information
  const entityReqDate = useRef(null);
  const entityReqDeptName = useRef(null);
  const entityReqDeptCode = useRef(null);
  const entityReqName = useRef(null);
  const entityReqExt = useRef(null);
  const entityReqEmail = useRef(null);

  const entityPurchaseAmtLess = useRef(null);
  const entityPurchaseAmtAbove = useRef(null);
  const entityPurchasePerLess = useRef(null);
  const entityPurchasePerAbove = useRef(null);
  const entitySubsidiary = useRef(null);
  const entityExpenseType = useRef(null);
  const entityActionType = useRef(null);
  const entityPayFrequency = useRef(null);

  const entityDescribe = useRef(null);

  // Vendor Information
  const entityVendorName = useRef(null);
  const entityVendorDbaName = useRef(null);
  const entityVendorAddress = useRef(null);
  const entityVendorCity = useRef(null);
  const entityVendorStateProvince = useRef(null);
  const entityVendorPostalCode = useRef(null);
  const entityVendorTaxId = useRef(null);
  const entityVendorDunBradstreet = useRef(null);
  const entityDumyY = useRef(null);
  const entityDumyN = useRef(null);
  const entityRemAddress = useRef(null);
  const entityRemCity = useRef(null);
  const entityRemStateProvince = useRef(null);
  const entityRemPostalCode = useRef(null);
  const entityPayTerm = useRef(null);
  const entityPayMethod = useRef(null);
  const entityReqContactName = useRef(null);
  const entityReqContactDept = useRef(null);
  const entityReqContactPhone = useRef(null);
  const entityReqContactFax = useRef(null);

  // Bank Information
  const entityBankName = useRef(null);
  const entityBankAddress = useRef(null);
  const entityBankCity = useRef(null);
  const entityBankStateProvince = useRef(null);
  const entityBankPostalCode = useRef(null);
  const entityBankContract = useRef(null);
  const entityBankContractPhone = useRef(null);
  const entityBankNameAcct = useRef(null);
  const entityAccountNo = useRef(null);
  const entityAbaRoutingNo = useRef(null);

  // Trade Reference Information
  const entityCerPrintName = useRef(null);
  const entityCerTitle = useRef(null);
  const entityCerPhone = useRef(null);
  const entityCerSignature = useRef(null);
  const entityCerDate = useRef(null);
  const entityComment = useRef(null);

  const entityVendorCode = useRef(null);

  const filterSearch = useRef();
  const popComponent = useRef();

  // radio disabled
  const [handlePurchaseAmtLess, setHandlePurchaseAmtLess] = useState(false);
  const [handlePurchaseAmtAbove, setHandlePurchaseAmtAbove] = useState(false);

  //popup(print)
  const printElementIs = useRef(null);
  const [viewPrintIs, setViewPrintIs] = useState(false);
  const [statusPrintDataIs, setStatusPrintDataIs] = useState(null);

  const [isHiddenVDInfo, setHiddenVDInfo] = useState(true);
  const [isHiddenBankInfo, setHiddenBankInfo] = useState(true);
  const [isHiddenTrade, setHiddenTrade] = useState(true);
  const [isHiddenRequestorInfo, setHiddenRequestorInfo] = useState(true);

  
  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const successMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_001");
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028");    //Submit Error. Please Contact System Admin.


  const onSave = () => {
    let vendorName = entityVendorName?.current?.getValue();
    let vendorAddress = entityVendorAddress?.current?.getValue();
    let vendorCity = entityVendorCity?.current?.getValue();
    let vendorStateProvince = entityVendorStateProvince?.current?.getValue();
    let vendorPostalCode = entityVendorPostalCode?.current?.getValue();
    let payMethod = entityPayMethod?.current?.getValue();
    let bankName = entityBankName?.current?.getValue();
    let bankAddress = entityBankAddress?.current?.getValue();
    let bankCity = entityBankCity?.current?.getValue();
    let bankStateProvince = entityBankStateProvince?.current?.getValue();
    let bankPostalCode = entityBankPostalCode?.current?.getValue();
    let bankContract = entityBankContract?.current?.getValue();
    let bankContractPhone = entityBankContractPhone?.current?.getValue();
    let bankNameAcct = entityBankNameAcct?.current?.getValue();
    let accountNo = entityAccountNo?.current?.getValue();
    let abaRoutingNo = entityAbaRoutingNo?.current?.getValue();
    let ceritifyPrintName = entityCerPrintName?.current?.getValue();
    let ceritifyTitle = entityCerTitle?.current?.getValue();
    let ceritifyPhone = entityCerPhone?.current?.getValue();

    if (vendorName == null || vendorName == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Name] of the [Vendor Information]", () => { entityVendorName.current.setFocus(); });
    } else if (vendorAddress == null || vendorAddress == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Address] of the [Vendor Information]", () => { entityVendorAddress.current.setFocus(); });
    } else if (vendorCity == null || vendorCity == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [City] of the [Vendor Information]", () => { entityVendorCity.current.setFocus(); });
    } else if (vendorStateProvince == null || vendorStateProvince == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [State/Province] of the [Vendor Information]", () => { entityVendorStateProvince.current.setFocus(); });
    } else if (vendorPostalCode == null || vendorPostalCode == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Postal Code] of the [Vendor Information]", () => { entityVendorPostalCode.current.setFocus(); });
    } else if (payMethod == null || payMethod == "") {
      ComNoticfication.ShowAlertUI("", "Enter the [Pay Method] of the [Vendor Information]");
    } else if (bankName == null || bankName == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Name of Bank] of the [Bank Information]", () => { entityBankName.current.setFocus(); });
    } else if (bankAddress == null || bankAddress == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Bank Address] of the [Bank Information]", () => { entityBankAddress.current.setFocus(); });
    } else if (bankCity == null || bankCity == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [City] of the [Bank Information]", () => { entityBankCity.current.setFocus(); });
    } else if (bankStateProvince == null || bankStateProvince == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [State/Province] of the [Bank Information]", () => { entityBankStateProvince.current.setFocus(); });
    } else if (bankPostalCode == null || bankPostalCode == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Postal Code] of the [Bank Information]", () => { entityBankPostalCode.current.setFocus(); });
    } else if (bankContract == null || bankContract == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Bank Contact] of the [Bank Information]", () => { entityBankContract.current.setFocus(); });
    } else if (bankContractPhone == null || bankContractPhone == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Contact Phone #] of the [Bank Information]", () => { entityBankContractPhone.current.setFocus(); });
    } else if (bankNameAcct == null || bankNameAcct == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Name on the Acct.] of the [Bank Information]", () => { entityBankNameAcct.current.setFocus(); });
    } else if (accountNo == null || accountNo == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Account No.] of the [Bank Information]", () => { entityAccountNo.current.setFocus(); });
    } else if (abaRoutingNo == null || abaRoutingNo == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [ABA Routing No.] of the [Bank Information]", () => { entityAbaRoutingNo.current.setFocus(); });
    } else if (ceritifyPrintName == null || ceritifyPrintName == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Print Name]", () => { entityCerPrintName.current.setFocus(); });
    } else if (ceritifyTitle == null || ceritifyTitle == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Title.]", () => { entityCerTitle.current.setFocus(); });
    } else if (ceritifyPhone == null || ceritifyPhone == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Phone #]", () => { entityCerPhone.current.setFocus(); });
    } else {
      onSaveExecute();
    }
  }

  const onSaveExecute = async () => {
    let sendParam = {};
    sendParam.conCode = comCode;
    sendParam.txtBillTo = entityBillToCode?.current?.getValue();
    sendParam.requestorDate = entityReqDate?.current?.getValue();
    sendParam.requestorDeptName = entityReqDeptName?.current?.getValue();
    sendParam.requestorDeptCode = entityReqDeptCode?.current?.getValue();
    sendParam.requestorName = entityReqName?.current?.getValue();
    sendParam.requestorExt = entityReqExt?.current?.getValue();
    sendParam.requestorEmail = entityReqEmail?.current?.getValue();

    sendParam.purchaseAmt = entityPurchaseAmtLess?.current?.getValue() || entityPurchaseAmtAbove?.current?.getValue();
    sendParam.purchasePer = entityPurchasePerLess?.current?.getValue() || entityPurchasePerAbove?.current?.getValue();

    sendParam.subsidiary = entitySubsidiary?.current?.getValue();
    sendParam.expenseType = entityExpenseType?.current?.getValue();
    sendParam.actionType = entityActionType?.current?.getValue();
    sendParam.paymentFrequency = entityPayFrequency?.current?.getValue();

    sendParam.vendorName = entityVendorName?.current?.getValue();
    sendParam.vendorDbaName = entityVendorDbaName?.current?.getValue();
    sendParam.vendorAddress = entityVendorAddress?.current?.getValue();
    sendParam.vendorCity = entityVendorCity?.current?.getValue();
    sendParam.vendorStateProvince = entityVendorStateProvince?.current?.getValue();
    sendParam.vendorPostalCode = entityVendorPostalCode?.current?.getValue();
    sendParam.vendorTaxId = entityVendorTaxId?.current?.getValue();
    sendParam.vendorDunBradstreet = entityVendorDunBradstreet?.current?.getValue();

    sendParam.remittanceAddress = entityRemAddress?.current?.getValue();
    sendParam.remittanceCity = entityRemCity?.current?.getValue();
    sendParam.remittanceStateProvince = entityRemStateProvince?.current?.getValue();
    sendParam.remittancePostalCode = entityRemPostalCode?.current?.getValue();

    sendParam.payTerm = entityPayTerm?.current?.getValue();
    sendParam.payMethod = entityPayMethod?.current?.getValue();

    sendParam.requestorContactName = entityReqContactName?.current?.getValue();
    sendParam.requestorContactDept = entityReqContactDept?.current?.getValue();
    sendParam.requestorContactPhone = entityReqContactPhone?.current?.getValue();
    sendParam.requestorContactFax = entityReqContactFax?.current?.getValue();

    sendParam.bankName = entityBankName?.current?.getValue();
    sendParam.bankAddress = entityBankAddress?.current?.getValue();
    sendParam.bankCity = entityBankCity?.current?.getValue();
    sendParam.bankStateProvince = entityBankStateProvince?.current?.getValue();
    sendParam.bankPostalCode = entityBankPostalCode?.current?.getValue();
    sendParam.bankContract = entityBankContract?.current?.getValue();
    sendParam.bankContractPhone = entityBankContractPhone?.current?.getValue();
    sendParam.bankNameAcct = entityBankNameAcct?.current?.getValue();
    sendParam.accountNo = entityAccountNo?.current?.getValue();
    sendParam.abaRoutingNo = entityAbaRoutingNo?.current?.getValue();
    sendParam.ceritifyPrintName = entityCerPrintName?.current?.getValue();
    sendParam.ceritifyTitle = entityCerTitle?.current?.getValue();
    sendParam.ceritifyPhone = entityCerPhone?.current?.getValue();
    sendParam.ceritifySignature = entityCerSignature?.current?.getValue();
    sendParam.ceritifyDate = entityCerDate?.current?.getValue();
    sendParam.vendorCode = entityVendorCode.current;
    sendParam.userId = userId;

    let resultData = await ApiManager.post(updateVendorInfo, sendParam);

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
    } else {
      ComNoticfication.ShowAlertUI("", successMessage);
      popComponent.current.hide();
      closeFn(null);
    }
  }

  // radio Disabled
  const radioChangeDisabled = (elementId, val) => {
    if (elementId === "entityPurchaseAmtLess") {
      if (entityPurchaseAmtLess?.current?.getCheckedValues()) { // LESS
        setHandlePurchaseAmtLess(true);
      } else {
        setHandlePurchaseAmtLess(false);
      }
      entityPurchasePerLess.current.setValue("");

    } else if (elementId === "entityPurchaseAmtAbove") {
      if (entityPurchaseAmtAbove?.current?.getCheckedValues()) { // ABOVE
        setHandlePurchaseAmtAbove(true);
      } else {
        setHandlePurchaseAmtAbove(false);
      }
      entityPurchasePerAbove.current.setValue("");
    }
  }

  // printP 버튼
  const popupIsOpenPrint = () => {
    let now = new Date();
    let timeKey = now.toISOString().replace('T', ' ').substring(0, 19).replace(/[^0-9]/g, '') + now.getMilliseconds().toString();

    const svgRegex = /<svg[^>]*>[\s\S]*?<\/svg>/gi;

    let printData = document.querySelector("#printArea");
    printData.innerHTML = printData.innerHTML.replace(svgRegex, '');
    let windowObj = window.open(
      '',
      'Print' + timeKey,
      'width=800, height=800, toolbars=no, scrollbars=no, status=no, resizable=no'
    );
    let printHtml = printData.innerHTML + "<link rel='stylesheet' type='text/css' href='/styles/PagesCommon.css'></link><div><button onClick='javascript:window.print()'>Print</button></div><script>setTimeout(() => window.print(), 1000);</script>"
    windowObj.document.writeln(printHtml);
    windowObj.document.close();
    windowObj.focus();
  }

  const printIsStatus = () => {
    ""
    popupIsOpenPrint();
  }

  //Memory
  const _isMounted = useRef(true);

  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {

      let paramBillToCode = billToData.billToCode;
      if (paramBillToCode) {
        const [resultData] = await Promise.all([
          ApiManager.post(retrieveVendorInfo, { billToCode: paramBillToCode, vendorCode: callVendorCode }),
        ]);

        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
        } else {
          entityBillToCode?.current?.setValue(null);
          entityBillToName?.current?.setValue(null);
          entityReqDate?.current?.setValue(null);
          entityReqDeptName?.current?.setValue(null);
          entityReqDeptCode?.current?.setValue(null);
          entityReqName?.current?.setValue(null);
          entityReqExt?.current?.setValue(null);
          entityReqEmail?.current?.setValue(null);

          entityPurchaseAmtLess?.current?.setValue(null);
          entityPurchasePerLess?.current?.setValue(null);
          entityPurchaseAmtAbove?.current?.setValue(null);
          entityPurchasePerAbove?.current?.setValue(null);
          entitySubsidiary?.current?.setValue(null);
          entityExpenseType?.current?.setValue(null);
          entityActionType?.current?.setValue(null);
          entityPayFrequency?.current?.setValue(null);

          entityVendorName?.current?.setValue(null);
          entityVendorDbaName?.current?.setValue(null);
          entityVendorAddress?.current?.setValue(null);
          entityVendorCity?.current?.setValue(null);
          entityVendorStateProvince?.current?.setValue(null);
          entityVendorPostalCode?.current?.setValue(null);
          entityVendorTaxId?.current?.setValue(null);
          entityVendorDunBradstreet?.current?.setValue(null);
          entityRemAddress?.current?.setValue(null);
          entityRemCity?.current?.setValue(null);
          entityRemStateProvince?.current?.setValue(null);
          entityRemPostalCode?.current?.setValue(null);
          entityPayTerm?.current?.setValue(null);
          entityPayMethod?.current?.setValue(null);
          entityReqContactName?.current?.setValue(null);
          entityReqContactDept?.current?.setValue(null);
          entityReqContactPhone?.current?.setValue(null);
          entityReqContactFax?.current?.setValue(null);
          entityBankName?.current?.setValue(null);
          entityBankAddress?.current?.setValue(null);
          entityBankCity?.current?.setValue(null);
          entityBankStateProvince?.current?.setValue(null);
          entityBankPostalCode?.current?.setValue(null);
          entityBankContract?.current?.setValue(null);
          entityBankContractPhone?.current?.setValue(null);
          entityBankNameAcct?.current?.setValue(null);
          entityAccountNo?.current?.setValue(null);
          entityAbaRoutingNo?.current?.setValue(null);
          entityCerPrintName?.current?.setValue(null);
          entityCerTitle?.current?.setValue(null);
          entityCerPhone?.current?.setValue(null);
          entityCerSignature?.current?.setValue(null);
          entityCerDate?.current?.setValue(null);
          entityVendorCode.current = null;

          entityBillToCode?.current?.setValue(resultData?.billToCode);
          entityBillToName?.current?.setValue(resultData?.billToName);
          entityReqDate?.current?.setValue(resultData?.requestorDate);
          entityReqDeptName?.current?.setValue(resultData?.requestorDeptName);
          entityReqDeptCode?.current?.setValue(resultData?.requestorDeptCode);
          entityReqName?.current?.setValue(resultData?.requestorName);
          entityReqExt?.current?.setValue(resultData?.requestorExt);

          if (resultData?.purchaseAmt != null && resultData?.purchaseAmt != '') {
            if (resultData?.purchaseAmt === 'LESS') {
              entityPurchaseAmtLess?.current?.setValue(resultData?.purchaseAmt);
              entityPurchasePerLess?.current?.setValue(resultData?.purchasePer);
              setHandlePurchaseAmtLess(true);
              setHandlePurchaseAmtAbove(false);
            } else if (resultData?.purchaseAmt === 'ABOVE') {
              entityPurchaseAmtAbove?.current?.setValue(resultData?.purchaseAmt);
              entityPurchasePerAbove?.current?.setValue(resultData?.purchasePer);
              setHandlePurchaseAmtAbove(true);
              setHandlePurchaseAmtLess(false);
            }
          } else {
            setHandlePurchaseAmtLess(false);
            setHandlePurchaseAmtAbove(false);
          }

          entitySubsidiary?.current?.setValue(resultData?.subsidiary);
          entityExpenseType?.current?.setValue(resultData?.expenseType);
          entityActionType?.current?.setValue(resultData?.actionType);
          entityPayFrequency?.current?.setValue(resultData?.paymentFrequency);

          entityVendorName?.current?.setValue(resultData?.vendorName);
          entityVendorDbaName?.current?.setValue(resultData?.vendorDbaName);
          entityVendorAddress?.current?.setValue(resultData?.vendorAddress);
          entityVendorCity?.current?.setValue(resultData?.vendorCity);
          entityVendorStateProvince?.current?.setValue(resultData?.vendorStateProvince);
          entityVendorPostalCode?.current?.setValue(resultData?.vendorPostalCode);
          entityVendorTaxId?.current?.setValue(resultData?.vendorTaxId);
          entityVendorDunBradstreet?.current?.setValue(resultData?.vendorDunBradstreet);
          entityRemAddress?.current?.setValue(resultData?.remittanceAddress);
          entityRemCity?.current?.setValue(resultData?.remittanceCity);
          entityRemStateProvince?.current?.setValue(resultData?.remittanceStateProvince);
          entityRemPostalCode?.current?.setValue(resultData?.remittancePostalCode);

          entityPayTerm?.current?.setValue(resultData?.payTerm);
          entityPayMethod?.current?.setValue(resultData?.payMethod);

          entityReqContactName?.current?.setValue(resultData?.requestorContactName);
          entityReqContactDept?.current?.setValue(resultData?.requestorContactDept);
          entityReqContactPhone?.current?.setValue(resultData?.requestorContactPhone);
          entityReqContactFax?.current?.setValue(resultData?.requestorContactFax);
          entityBankName?.current?.setValue(resultData?.bankName);
          entityBankAddress?.current?.setValue(resultData?.bankAddress);
          entityBankCity?.current?.setValue(resultData?.bankCity);
          entityBankStateProvince?.current?.setValue(resultData?.bankStateProvince);
          entityBankPostalCode?.current?.setValue(resultData?.bankPostalCode);
          entityBankContract?.current?.setValue(resultData?.bankContract);
          entityBankContractPhone?.current?.setValue(resultData?.bankContractPhone);
          entityBankNameAcct?.current?.setValue(resultData?.bankNameAcct);
          entityAccountNo?.current?.setValue(resultData?.accountNo);
          entityAbaRoutingNo?.current?.setValue(resultData?.abaRoutingNo);
          entityCerPrintName?.current?.setValue(resultData?.ceritifyPrintName);
          entityCerTitle?.current?.setValue(resultData?.ceritifyTitle);
          entityCerPhone?.current?.setValue(resultData?.ceritifyPhone);
          entityCerSignature?.current?.setValue(resultData?.ceritifySignature);
          entityCerDate?.current?.setValue(resultData?.ceritifyDate);
          entityVendorCode.current = resultData?.vendorCode;

          //Print Popup 전달 정보(billToCode)
          setStatusPrintDataIs(resultData);
        }
      }
    }
    getPageInfo();
    if (open) {
      filterSearch?.current?.clear();
    }

    return () => { 
      _isMounted.current = false; 
      abortController.abort(); 
    }

  }, [reqRandomKey]);

  
  const onCancleClick = () => {
    popComponent.current.hide();
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }


  return (
<wjInput.Popup
      className="pop-resizable"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{
        height: "700px",
        overflowY: "auto",
        padding: "30px"
      }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"VENDOR MODIFY"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="page-wrap">
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <div className="bx-btn-group">
            <Button variant="outlined" style={{fontSize: "1.1rem"}} onClick={printIsStatus}>
              {"Print"}
            </Button>
            <Button variant="outlined" style={{marginLeft: "5px", fontSize: "1.1rem"}} onClick={onCancleClick}>
              {"List"}
            </Button>
            <Button variant="contained" style={{marginLeft: "5px", fontSize: "1.1rem"}} onClick={onSave}>
              {"Save"}
            </Button>
          </div>
        </div>
        <div className="page-in-wrap">
          <span className="required-field">(*) is required madatory field</span>
          <div className="search-wrapper">
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Bill To Code
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityBillToCode}
                    elementId={"entityBillToCode"}
                    defaultDisabled={true}
                    defaultValue={billToCd}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Bill To Name
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityBillToName}
                    elementId={"entityBillToName"}
                    defaultDisabled={true}
                    defaultValue={billToNm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="printArea">

          <div className="title-rg-form">
            LG Electronics Vendor Registration Form
          </div>

          <span className="required-form">A.To be completed by LG Employee</span>
          <div
            className="guide-table mt-10"
            style={{ minWidth: "800px", margin: "auto" }}
          >
            <table>
              <thead>
                <tr>
                  <td></td>
                  <th scope="col">Requestor</th>
                  <th scope="col">Team Leader </th>
                  <th scope="col">Dept. Leader</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="txt-center">Dept.</th>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                </tr>
                <tr>
                  <td className="txt-center required-field">
                    * Hand Written Sign{" >> "}
                  </td>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                </tr>
                <tr>
                  <th className="txt-center">GP</th>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                </tr>
                <tr>
                  <th className="txt-center">ACCOUNTING</th>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                  <td className="txt-center"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenRequestorInfo ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenRequestorInfo(!isHiddenRequestorInfo)}
              >
                {isHiddenRequestorInfo ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Requestor Information</span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenRequestorInfo ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Date</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqDate}
                      elementId={"entityReqDate"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Dept. Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqDeptName}
                      elementId={"entityReqDeptName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Dept. Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqDeptCode}
                      elementId={"entityReqDeptCode"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Requestor's Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqName}
                      elementId={"entityReqName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Ext #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqExt}
                      elementId={"entityReqExt"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Email</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqEmail}
                      elementId={"entityReqEmail"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Purchase Amount</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="d-flex-column">
                      <div className="group-checkbox-entry">
                        <div className="item-checkbox">
                          <ComRadioBoxNoneCheck
                            radioList={[
                              { Value: "LESS", Description: "Less than $10K (per" },
                            ]}
                            ref={entityPurchaseAmtLess}
                            elementId={"entityPurchaseAmtLess"}
                            radioGroupName={"entityPurchaseAmtLess"}
                            callRelationFuntion={radioChangeDisabled}
                            defaultDisabled={handlePurchaseAmtAbove ? true : false}
                          />
                          <ComRadioBoxNoneCheck
                            radioList={[
                              { Value: "ORDER", Description: "Order" },
                              { Value: "YEAR", Description: "Year" },
                            ]}
                            ref={entityPurchasePerLess}
                            elementId={"entityPurchasePerLess"}
                            radioGroupName={"entityPurchasePerLess"}
                            callRelationFuntion={radioChangeDisabled}
                            defaultDisabled={handlePurchaseAmtLess ? false : true}
                          />
                          <label>&#41; *send this form to lge.usaccounting@lge.com</label>
                        </div>
                      </div>
                      <div className="group-checkbox-entry">
                        <div className="item-checkbox">
                          <ComRadioBoxNoneCheck
                            radioList={[
                              { Value: "ABOVE", Description: "$10K and above (per" },
                            ]}
                            ref={entityPurchaseAmtAbove}
                            elementId={"entityPurchaseAmtAbove"}
                            radioGroupName={"entityPurchaseAmtAbove"}
                            callRelationFuntion={radioChangeDisabled}
                            defaultDisabled={handlePurchaseAmtLess ? true : false}
                          />
                          <ComRadioBoxNoneCheck
                            radioList={[
                              { Value: "ORDER", Description: "Order" },
                              { Value: "YEAR", Description: "Year" },
                            ]}
                            ref={entityPurchasePerAbove}
                            elementId={"entityPurchasePerAbove"}
                            radioGroupName={"entityPurchasePerAbove"}
                            callRelationFuntion={radioChangeDisabled}
                            defaultDisabled={handlePurchaseAmtAbove ? false : true}
                          />
                          <label>&#41; *send this form to lge.gp@lge.com</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Subsidiary</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="group-checkbox-entry">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "US", Description: "US" },
                          { Value: "MU", Description: "MU" },
                          { Value: "MR", Description: "MR" },
                          { Value: "ZE", Description: "ZE" },
                          { Value: "AI", Description: "AI" },
                        ]}
                        direction={1}
                        ref={entitySubsidiary}
                        elementId={"entitySubsidiary"}
                        radioGroupName={"entitySubsidiary"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Expense Type</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="group-checkbox-entry">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "RE", Description: "Regular Expense" },
                          { Value: "FA", Description: "Fixed Asset (EPS)" },
                          { Value: "PO", Description: "Purchase Order" },
                        ]}
                        direction={1}
                        // callRelationFuntion={handleDivision}
                        ref={entityExpenseType}
                        elementId={"entityExpenseType"}
                        radioGroupName={"entityExpenseType"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Action Type</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="group-checkbox-entry">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "NEW", Description: "New" },
                          { Value: "CHANGE", Description: "Change" },
                          { Value: "DEACTIVATION", Description: "Deactivation" },
                          { Value: "REACTIVATION", Description: "Reactivation" },
                        ]}
                        direction={1}
                        // callRelationFuntion={handleDivision}
                        ref={entityActionType}
                        elementId={"entityActionType"}
                        radioGroupName={"entityActionType"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Payment Frequency</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="group-checkbox-entry">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "FREQUENTLY", Description: "Frequently" },
                          { Value: "ANNUALLY", Description: "Annually" },
                          { Value: "ONETIME", Description: "One Time" },
                        ]}
                        direction={1}
                        // callRelationFuntion={handleDivision}
                        ref={entityPayFrequency}
                        elementId={"entityPayFrequency"}
                        radioGroupName={"entityPayFrequency"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Describe in detail the bussiness perpose for which this vendor
                  being hired:
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  <ComTextArea
                    ref={entityDescribe}
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                      background: "#fff",
                    }}
                    elementId={"entityDescribe"}
                    defaultDisabled={true}
                  ></ComTextArea>
                </div>
              </div>
            </div>
          </div>
          <span className="required-form">B.To be completed by supplier</span>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenVDInfo ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenVDInfo(!isHiddenVDInfo)}
              >
                {isHiddenVDInfo ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Vendor Information</span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenVDInfo ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorName}
                      elementId={"entityVendorName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">DBA Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorDbaName}
                      elementId={"entityVendorDbaName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Address
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorAddress}
                      elementId={"entityVendorAddress"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>City
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorCity}
                      elementId={"entityVendorCity"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>State/Province
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorStateProvince}
                      elementId={"entityVendorStateProvince"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Postal Code
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorPostalCode}
                      elementId={"entityVendorPostalCode"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Tax ID #
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorTaxId}
                      elementId={"entityVendorTaxId"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Dun & Bradstreet #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorDunBradstreet}
                      elementId={"entityVendorDunBradstreet"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Company Website(URL)</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      Certified Minority or Women Owned Company (M/WBE)?
                    </label>
                  </div>
                  <div
                    className="bx-inp combo inp-mix"
                    style={{ height: "60px" }}
                  >
                    <div className="group-checkbox-entry">
                      <div className="item-checkbox">
                        <ComCheckBox
                          ref={entityDumyY}
                          elementId={"entityDumyY"}
                          idCheckbox="entityDumyY"
                          defaultStyle={{ pointerEvents: 'none' }}
                        />
                        <label className="text-checkbox">
                          Yes
                        </label>
                      </div>
                      <div className="item-checkbox">
                        <ComCheckBox
                          ref={entityDumyN}
                          elementId={"entityDumyN"}
                          idCheckbox="entityDumyN"
                          defaultStyle={{ pointerEvents: 'none' }}
                        />
                        <label className="text-checkbox">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Remittance Address (If different from above adress)
                </div>

                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemAddress}
                      elementId={"entityRemAddress"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">City</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemCity}
                      elementId={"entityRemCity"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">State/Province</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemStateProvince}
                      elementId={"entityRemStateProvince"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemPostalCode}
                      elementId={"entityRemPostalCode"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Payment Information
                </div>
                <div
                  className="bx-item column-4column"
                  style={{ height: "60px" }}
                >
                  <div className="div-label">
                    <label className="txt-label">
                      E-mail address for notification of payment / PO
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">df</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Pay Term</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="group-checkbox-entry">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "0", Description: "0" },
                          { Value: "10", Description: "10" },
                          { Value: "15", Description: "15" },
                          { Value: "20", Description: "20" },
                          { Value: "25", Description: "25" },
                          { Value: "30", Description: "30" },
                          { Value: "45", Description: "45" },
                          { Value: "60", Description: "60" },
                          { Value: "75", Description: "75" },
                          { Value: "90", Description: "90" },
                        ]}
                        direction={1}
                        ref={entityPayTerm}
                        elementId={"entityPayTerm"}
                        radioGroupName={"entityPayTerm"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Pay Method
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="pay-method">
                      <div className="please-select">* Please select one</div>
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "ACH", Description: "ACH(Direct Deposit)" },
                          { Value: "WIRE", Description: "Wire(Overseas only)" },
                          { Value: "CHECK", Description: "Check" },
                        ]}
                        direction={1}
                        ref={entityPayMethod}
                        elementId={"entityPayMethod"}
                        radioGroupName={"entityPayMethod"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Requestor Contact Information
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactName}
                      elementId={"entityReqContactName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Dept</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactDept}
                      elementId={"entityReqContactDept"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactPhone}
                      elementId={"entityReqContactPhone"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label"> Fax #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactFax}
                      elementId={"entityReqContactFax"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Accounts Receivable Primary Contact Information
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Position</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label"> E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="required-form">C.To be completed by supplier</span>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenBankInfo ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenBankInfo(!isHiddenBankInfo)}
              >
                {isHiddenBankInfo ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">
                  Bank Information (Bank Account notification letter or voided
                  check is required)
                </span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenBankInfo ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name of Bank
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankName}
                      elementId={"entityBankName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Bank Address
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankAddress}
                      elementId={"entityBankAddress"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>City
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankCity}
                      elementId={"entityBankCity"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>State/Province
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankStateProvince}
                      elementId={"entityBankStateProvince"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Postal Code
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankPostalCode}
                      elementId={"entityBankPostalCode"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Bank Contact
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankContract}
                      elementId={"entityBankContract"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Contact Phone #
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankContractPhone}
                      elementId={"entityBankContractPhone"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name on the Acct.
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankNameAcct}
                      elementId={"entityBankNameAcct"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Account No.
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityAccountNo}
                      elementId={"entityAccountNo"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>ABA Routing No.
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityAbaRoutingNo}
                      elementId={"entityAbaRoutingNo"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      IBAN No. (Only for Europe)
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      SWIFT code (Only for Non-U.S.)
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenTrade ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenTrade(!isHiddenTrade)}
              >
                {isHiddenTrade ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Trade Reference Information</span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenTrade ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Trade Reference #1</label>
                  </div>
                  <div className="bx-inp combo inp-mix">Company name:</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">City/State</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Trade Reference #2</label>
                  </div>
                  <div className="bx-inp combo inp-mix">Company name:</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">City/State</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Trade Reference #3</label>
                  </div>
                  <div className="bx-inp combo inp-mix">Company name:</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">City/State</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  I certify that the information provided above is correct.
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Print Name
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerPrintName}
                      elementId={"entityCerPrintName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Title
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerTitle}
                      elementId={"entityCerTitle"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Phone #
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerPhone}
                      elementId={"entityCerPhone"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Signature</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerSignature}
                      elementId={"entityCerSignature"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Date</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerDate}
                      elementId={"entityCerDate"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div className="bx-inp-control open">
                <span className="txt-title">D. LG Accounting ONLY</span>
              </div>
            </div>

            <div className="search-wrapper">
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <ComTextArea
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                      background: "#fff",
                    }}
                    elementId={"entityComment"}
                    defaultDisabled={true}
                    defaultValue={"Comments:"}
                  ></ComTextArea>
                </div>
              </div>
              <div className="lg-accounting-vendor-code mt-10">Vendor Code:</div>
            </div>
          </div>

        </div>

      </div>

      {viewPrintIs && <VendorPrtPop ref={printElementIs} orderData={statusPrintDataIs} windowPrintFunction={popupIsOpenPrint} />}
    </div>
    </wjInput.Popup>
  );
}

export default VendorModPop;
