import React, { useState, useEffect, useRef } from "react";

function RdaioHorizontalist({
  radioValuObject,
  groupName,
  onClickFunction,
  onRadioClickFunction,
  isdisabled,
  defaultCheckValue,
  randomKey,
}) {
  return (
    <span className="inp-radio">
      <input
        type="radio"
        name={groupName}
        id={radioValuObject.Value + randomKey}
        value={radioValuObject.Value}
        onChange={onClickFunction}
        onClick={onRadioClickFunction}
        disabled={isdisabled}
        checked={defaultCheckValue === radioValuObject.Value}
      />{" "}
      <label
        className="label-radio-common"
        htmlFor={radioValuObject.Value + randomKey}
      >
        {radioValuObject.Description}
      </label>
    </span>
  );
}

function RdaioVerticalist({
  radioValuObject,
  groupName,
  onClickFunction,
  isdisabled,
  defaultCheckValue,
  randomKey,
}) {
  return (
    <li>
      {radioValuObject.Description}
      <input
        type="radio"
        name={groupName}
        value={radioValuObject.Value}
        id={radioValuObject.Value + randomKey}
        onChange={onClickFunction}
        disabled={isdisabled}
        checked={defaultCheckValue === radioValuObject.Value}
      />
    </li>
  );
}

const ComRadioBoxNoneCheck = React.forwardRef(
  (
    {
      radioList, // 라디오 표시 목록
      radioGroupName, // 라디오 그룹 코드
      direction, // 표시 방향 1 : 가로 2: 세로
      callRelationFuntion, // 부모에서 호출 할 함수
      defaultValue, // 기본 값
      isAvtive, // 활성화여부
      elementId, // 객체 아이디

      // 스타일 관련
      defaultDisabled, // 활성화여부
      defaultStyle,
      defaultClassName,
    },
    ref
  ) => {
    const radioRef = useRef(null);

    const beforeChange = useRef(null);
    const [radioDirection, setRadioDirection] = useState(1);
    const [selectedRadioValue, setSelectedRadioValue] = useState();
    const [userRadioList, setUserRadioList] = useState([]);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    let now = new Date();
    const groupUniqueKey =
      now
        .toISOString()
        .replace("T", " ")
        .substring(0, 19)
        .replace(/[^0-9]/g, "") + now.getMilliseconds().toString();
    React.useImperativeHandle(ref, () => ({
      getValue: () => radioRef.current,
      getCheckedValues: () => radioRef.current,
      setValue: (v) => {
        setSelectedRadioValue(v);
        beforeChange.current = v;
        radioRef.current = v;
      },
      cancleChange: () => {
        setSelectedRadioValue(beforeChange.current);
        radioRef.current = beforeChange.current;
      },
      setList: (v) => {
        setSelectedRadioValue(v.defaultValue);
        setUserRadioList(v.data);

        radioRef.current = v?.defaultValue ?? "";
      },
    }));

    // 전달 파라미터가 변경될때
    useEffect(() => {
      if (direction !== undefined) {
        setRadioDirection(direction);
      }
      if (!selectedRadioValue && defaultValue) {
        setSelectedRadioValue(defaultValue);
        radioRef.current = defaultValue;
        beforeChange.current = defaultValue;
      }

      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }

      if (radioList) {
        setUserRadioList(radioList);
      }
    }, [
      direction,
      selectedRadioValue,
      userClassName,
      defaultValue,
      defaultDisabled,
      defaultClassName,
      defaultStyle,
      radioList,
    ]);

    const radioChanged = (e) => {
      beforeChange.current = radioRef.current;
      let selectedValue = e.target.value;
      setSelectedRadioValue(selectedValue);
      radioRef.current = selectedValue;
      if (callRelationFuntion) {
        callRelationFuntion(elementId, selectedValue);
      }
    };

    const radioClick = (e) => {
      beforeChange.current = radioRef.current;
      let selectedValue = e.target.value;
      let chkFlag = true;
      if (beforeChange.current !== null && beforeChange.current === selectedValue) {
        chkFlag = false;
        setSelectedRadioValue(null);
        radioRef.current = null;
        if (callRelationFuntion) {
          callRelationFuntion(elementId, selectedValue);
        }
        
      }
    };

    return (
      <>
        {radioDirection === 1 ? (
          userRadioList.map((el) => (
            <RdaioHorizontalist
              radioValuObject={el}
              key={el.Value + groupUniqueKey}
              randomKey={groupUniqueKey}
              groupName={
                radioGroupName
                  ? radioGroupName + groupUniqueKey
                  : groupUniqueKey
              }
              onClickFunction={radioChanged}
              onRadioClickFunction={radioClick}
              isdisabled={el.Disable !== undefined ? el.Disable : userDisable}
              // isdisabled={userDisable}
              defaultCheckValue={selectedRadioValue}
            />
          ))
        ) : (
          <ul>
            {userRadioList.map((el) => (
              <RdaioVerticalist
                radioValuObject={el}
                key={el.Value + groupUniqueKey}
                randomKey={groupUniqueKey}
                groupName={
                  radioGroupName
                    ? radioGroupName + groupUniqueKey
                    : groupUniqueKey
                }
                onClickFunction={radioChanged}
                isdisabled={el.Disable !== undefined ? el.Disable : userDisable}
                // isdisabled={userDisable}
                defaultCheckValue={selectedRadioValue}
              />
            ))}
          </ul>
        )}
      </>
    );
  }
);
export default React.memo(ComRadioBoxNoneCheck);
