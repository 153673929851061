import _ from 'lodash';
import moment from 'moment/moment';
import * as wijmo from "@grapecity/wijmo";
import { DataMap, GroupRow, CellType } from "@grapecity/wijmo.grid";
import FileSaver from 'file-saver';
import { ApiManager } from 'common/lib/ApiUtil';
import { ComNoticfication } from "common/components/ComNoticfication";

export const FileUtils = () => { };

FileUtils.allowFileExt = [
  "docx",
  "doc",
  "pdf",
  "msg",
  "eml",
  "xls",
  "jpeg",
  "txt",
  "xlsx",
  "zip",
  "jpg",
  "png",
  "gif",
  "mht",
  "rar",
  "ppt",
  "pptx",
  "tif",
  "jfif",
  "html",
  "htm",
  "ics",
  "tiff",
  "mov",
  "mp4",
  "csv"
];

FileUtils.fileExtMatch = (fileName) => {

  if (fileName) {
    let fname = fileName.split(".");
    if (fname.length > 0) {
      let extName = fname[fname.length - 1];
      switch (extName.toUpperCase()) {
        case "TXT":
          return "file-name txt";
          break;
        case "DOC":
        case "DOCX":
          return "file-name doc";
          break;
        case "XLS":
        case "XLSX":
        case "XLSB":
          return "file-name excel"
          break;
          break;
        case "PPT":
        case "PPTX":
          return "file-name ppt"
          break;
        case "PDF":
          return "file-name pdf"
          break;
        case "GIF":
          return "file-name gif"
          break;
        case "JPEG":
        case "BMP":
        case "JPG":
        case "PNG":
          return "file-name img"
          break;
        case "ZIP":
          return "file-name zip"
          break;
        default:
          return "file-name"
      }
    }
  }

  return "file-name"
}

FileUtils.callFileDownload = async (file, url, customPath, event) => {


  if (file?.fileId) {
    let fileinfo = await ApiManager.downloadFileById(url, { "fileId": file.fileId, "fileSeq": file.fileSeq, "customPath": customPath })
    if (fileinfo != -1) {
      let filename = null;
      let disposition = fileinfo.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      if (filename) {
        let extPass = true;
        filename = filename.replace(/\.\./g, "");
        let extensionRegex = /\.([^.]*)$/;
        let match = filename.match(extensionRegex);

        if (FileUtils.allowFileExt?.indexOf(match[1].toLowerCase()) > -1) {
          extPass = true;
        } else {
          ComNoticfication.ShowErrorUI("Error", "Wrong file format. Please attach PDF or image file formats.");
          return;
        }
        if (extPass) {
          try {
            FileSaver.saveAs(new Blob([fileinfo.data], { type: fileinfo.headers['content-type'] }), decodeURI(filename));
          } catch (ex) {
            console.log(ex);
            ComNoticfication.ShowAlertUI("Info", "There are no files to download");

          }
        }
      }
    } else {
      ComNoticfication.ShowAlertUI("Error", "File Not Found");

    }

  }
  else {
    ComNoticfication.ShowAlertUI("Info", "You can download it after you save it");

  }
}

FileUtils.callServerFileDownload = async (url, sendParam, strFileName) => {

  let resultData = await ApiManager.callExcelDowonload(url, sendParam);
  if (resultData != undefined && resultData != -1) {

    let disposition = resultData.headers["content-disposition"];

    let filename = strFileName;
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    if (filename) {
      let extPass = false;
      filename = filename.replace(/\.\./g, "");
      let extensionRegex = /\.([^.]*)$/;
      let match = filename.match(extensionRegex);

      if (FileUtils.allowFileExt?.indexOf(match[1].toLowerCase()) > -1) {
        extPass = true;
      } else {
        ComNoticfication.ShowErrorUI("Error", "Wrong file format. Please attach PDF or image file formats.");
        return;
      }
      if (extPass) {
        try {
          FileSaver.saveAs(
            new Blob([resultData.data], {
              type: resultData.headers["content-type"],
            }),
            decodeURI(filename)
          );
        } catch (ex) {
          console.log(ex);
        }
      }


    }
  } else {
    ComNoticfication.ShowAlertUI("Info", "There are no files to download");
  }
}

