import React,{useState, useEffect, useRef} from 'react';
import * as wjInput from '@grapecity/wijmo.react.input';

const ComMaskInput = React.forwardRef(({
    callRelationFuntion     // 부모에서 호출 할 함수
    , callLostFocus
    , defaultValue            // 기본 값
    , elementId               // 객체 아이디

    // 스타일 관련
    , defaultDisabled           // 활성화여부
    , defaultStyle              // 스타일 
    , defaultClassName          // CSS 클래스명    
  
    , mask
    , overwriteMode
  }
  , ref)=>{

    const maskInputRef = useRef(null);
    const [textValue, setTextValue] = useState(0);
    const elementRef = useRef();

    // 스타일 관련
    const [userStyle,       setUserStyle]               = useState({});
    const [userClassName,   setUserClassName]           = useState(false);
    const [userDisable,     setUserDisable]             = useState(false);

    const [hasClassName,    setHasClassName]            = useState(false);
    const [hasStyle,        setHasStyle]                = useState(false);
    
    const [userMask, setUserMask] = useState(null);
    const [userOverwriteMode, setUserOverwriteMode] = useState(false);

    React.useImperativeHandle(ref, () => (
        {
             getValue           : () => maskInputRef.current     
            ,setValue           : (v)=> {maskInputRef.current = v;    setTextValue(v);}
            ,setFocus           : () => {elementRef.current?.control.hostElement.focus();}
        }
    ));


    // 전달 파라미터가 변경될때
    useEffect(()=>{    
        if (defaultValue !== undefined && defaultValue !== null){
            if (!isNaN(defaultValue)){
                maskInputRef.current = defaultValue;
                setTextValue(defaultValue);
            }
        }
        else{            
            setTextValue(0);
        }

        if (mask !== undefined &&  mask !== null){
            setUserMask(mask);
        }

        if (overwriteMode !== undefined &&  overwriteMode !== null){
            setUserOverwriteMode(overwriteMode);
        }        
        
        if (defaultStyle){
            setUserStyle(defaultStyle);
            setHasStyle(true);
        }

        if (defaultClassName){
            setUserClassName(defaultClassName)
            setHasClassName(true)
        }
        if (defaultDisabled === undefined || defaultDisabled=== null){
            setUserDisable(false);
        }
        else{
            setUserDisable(defaultDisabled);
        }

    },[defaultValue, mask, overwriteMode, userClassName, defaultDisabled, defaultStyle, defaultClassName  ]);

    const onlostFocus=e=>{
        maskInputRef.current = e.rawValue;
        if (callLostFocus){
            callLostFocus(elementId, e.rawValue);
        }
    }
    const onValueChanged=e=>{
        maskInputRef.current = e.rawValue;
        if (callRelationFuntion){
            callRelationFuntion(elementId, e.rawValue);
        }
    }      
  
    return (
        <wjInput.InputMask          
            value={textValue} 
            mask={userMask} 
            overwriteMode={userOverwriteMode}
            lostFocus   = {onlostFocus} 
            ref={elementRef}
            valueChanged={onValueChanged}
            isDisabled    = {userDisable}
            className   = {hasClassName?userClassName:"wj_with10"}
            style={hasStyle ? userStyle : {}}
        />
    )
 });
export default React.memo(ComMaskInput);
