import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeMenu,
  viewMenu,
  closeAll,
  moveLeft,
  moveRight,
} from "reducer/OpenMenus";

import "assets/styles/layout/TagPage.scss";
import close from "assets/icons/close.svg";
import closeActive from "assets/icons/close-active.svg";
import { Button } from "@mui/material";
// import arrowRightPage from "assets/icons/arrow-right-page.svg";
// import arrowLeftPage from "assets/icons/arrow-left.svg";

import { ComUtils } from "common/lib/ComUtils";
import { ComNoticfication } from "common/components/ComNoticfication";

const MenuTabList = React.memo(
  function OpenMenuList({ menu, onRemove, onViewChange, oRef }) {
    //console.log("tab : " + menu.Active);
    return (
      <>
        {menu.menuId == "0" ? (
          <div className="fixed-home">
            <button
              type="button"
              className="tab-home"
              onClick={() => onViewChange(menu)}
            >
              <span>Home</span>
            </button>
          </div>
        ) : (
          <div
            className={menu.Active ? "btn-page-active" : "btn-page-inactive"}
            key={menu.menuId}
            ref={menu.Active ? oRef : undefined}
          >
            <div className="btn-content" onClick={() => onViewChange(menu)}>
              <span>{menu.menuName}</span>
              <img
                className="ml-10"
                src={menu.Active ? closeActive : close}
                style={{ width: "16px", height: "16px" }}
                onClick={() => onRemove(menu)}
              />
            </div>
          </div>
        )}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.menu === nextProps.menu
);

function TagPage() {

  const _confirmMessage = "Do you want to close all open menus?";

  const dispatch = useDispatch();

  const openMenu = useSelector((state) => state.openMenus);
  let currentMenu = openMenu.find((item) => item.Active) || null;

  const menuRef = useRef();
  const isRemoveCheck = useRef(false);

  /*   useEffect(() => {
      let currentMues = openMenu;
      let viewMenus = currentMues.filter(el => el.Active === true);
      if (viewMenus.length ===0){
          dispatch(viewMenu(currentMues[currentMues.length -1]))
      }
    }, [openMenu, dispatch]) 
  
    if (!openMenu) return (<div>home</div>);*/

  const onViewChange = (menu) => {
    if (!isRemoveCheck.current) {
      dispatch(viewMenu(menu));
      document.getElementById("areaInfoObject").style.display = "none";
    } else {
      isRemoveCheck.current = false;
    }
  };

  const onRemove = (menu) => {
    isRemoveCheck.current = true;
    dispatch(closeMenu(menu));
    document.getElementById("areaInfoObject").style.display = "none";
  };

  const onRemoveAll = () => {
    if (openMenu.length > 1) {
      ComNoticfication.ShowConfirmUI(
        ComUtils._altTitleInfo,
        _confirmMessage,
        onCloseAll
      );
    } else {
      onCloseAll(true);
    }
  };

  const onCloseAll = async (r) => {
    if (r) {
      document.getElementById("areaInfoObject").style.display = "none";
      dispatch(closeAll());
    }
  };

  const onMoveLeft = () => {
    document.getElementById("areaInfoObject").style.display = "none";
    dispatch(moveLeft());
  };

  const onMoveRight = () => {
    document.getElementById("areaInfoObject").style.display = "none";
    dispatch(moveRight());
  };

  useEffect(() => {
    if (menuRef?.current /* && currentMenu?.menuId !== "0" */) {
      menuRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "end",
      });
    }
  }, [currentMenu]);

  // 임시 홈버튼
  function HomeClick(e) {
    window.location.replace("/");
  }
  return (
    <div
      className="layout-tagpage"
      // neocave 0409변경 inline-style before - margin-top:50px;
      style={{ marginTop: currentMenu?.menuId !== "0" ? "40px" : "40px" }}
    >
      <div className="menu-tabs">
        <div className="layout-button">
          {/* <div class="fixed-home">
            <button type="button" className="tab-home" onClick = {HomeClick}>
              <span>Home</span>
            </button>
          </div> */}
          {openMenu !== undefined && openMenu !== null && openMenu.length > 0
            ? openMenu.map((menu) => (
              <MenuTabList
                menu={menu}
                key={menu.tabUniqueKey}
                onRemove={onRemove}
                onViewChange={onViewChange}
                oRef={menuRef}
              />
            ))
            : ""}
        </div>
        <div className="tabControl">
          <Button variant="outlined" onClick={onMoveLeft}>
            {"<"}
          </Button>
          <Button variant="outlined" onClick={onMoveRight}>
            {">"}
          </Button>
          <Button variant="outlined" onClick={onRemoveAll}>
            Close All
          </Button>
        </div>
        {/* <div className="ic-redirect">
          <img src={arrowLeftPage} style={{ width: "16px", height: "16px" }} />
          <img src={arrowRightPage} style={{ width: "16px", height: "16px" }} />
        </div>
        <div className="close-all">
          <span>Close All</span>
          <img src={close} style={{ width: "12px", height: "12px" }} />
        </div> */}
      </div>
    </div>
  );
}

export default TagPage;
