import * as wijmo from "@grapecity/wijmo";
import { InputNumber, InputDate } from "@grapecity/wijmo.input";
import { createElement, format } from "@grapecity/wijmo";
import { BaseClaim } from "./BaseClaim";
import search from "assets/icons/search.svg";
import IcPdf from "assets/images/files/file_icon_pdf.png";

class ClaimTax extends BaseClaim {
  constructor(props) {
    super(props);    
  }

  getGirdFormat = () => {
    let out = [
      { 
        binding: 'isChecked2', 
        header: " ", 
        width: 30, 
        allowDragging: false, 
        isReadOnly: false, 
        allowResizing: false, 
        allowSorting: false ,
        visible: this.pageType !== 'Dtl',
      },
      {
        binding: "invoiceNo",
        header: "LG Invoice#",
        width: 160,
        allowDragging: true,
        isRequired: true,
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openTaxInvoiceNoPopup;
          let callPdfFunc = this.downloadPdf;        

          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <div style="float: right;">
                  <img class="grid_btn_find" src='${search}' style="display: ${this.pageType === 'Dtl' ? 'none' : ''}"/>
                  <img class="grid_btn_find_pdf ml-5" src='${IcPdf}' />
                </div>
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };

          let buttonElementPdf = element.querySelector("img.grid_btn_find_pdf");
          if (buttonElementPdf) {
            buttonElementPdf.onclick = function (e) {
              e.preventDefault();
              callPdfFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
            };
          }
        },
      },    
      {
        binding: "orgRequestAmt",
        header: "Org. Request Amt",
        width: 140,
        allowDragging: true,
        isReadOnly: true, 
        align: "right",
        format: "n2",
        aggregate: 'Sum',
        visible: !this.orgRequestHidden,
      },  
      {
        binding: "requestAmt",
        header: "Request Amt",
        width: 150,
        allowDragging: true,
        isRequired: true,
        align: "right",
        format: "n2",
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
      },
      {
        binding: "rejectCode",
        header: "Rejection Reason",
        width: 180,
        allowDragging: true,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
        dataMap: this.rejectYn === 'Y' ? this.rejectionReasonMap : undefined,
      },
      {
        binding: "rejectDesc",
        header: "Rejection Comment",
        width: 160,
        allowDragging: true,
        isRequired: false,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
      },
      {
        binding: "lgInvoiceDa",
        header: "LG Invoice",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "invoiceDate",
            header: "Date",
            width: 150,
            allowDragging: true,
            isReadOnly: true,
            align: "center",
            cellTemplate: (ctx, cell) => {
              cell?.firstChild?.remove();
              let value = ctx.value;
              if (value) {
                value = wijmo.Globalize.format(new Date(value), 'MM/dd/yyyy');
                return value;
              } else {
                return '';
              }
            },
            editor: new InputDate(document.createElement("div"), {
              format: "MM/dd/yyyy",
              mask: "##/##/####",
              isRequired: false,
              invalidInput: (s, e) => {
                if (s.text === "__/__/____") {
                  s.text = "";
                  s.value = null;
                }
              },
            })
          },
          {
            binding: "invoiceTax",
            header: "Tax",
            width: 150,
            allowDragging: true,
            isReadOnly: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
            aggregate: 'Sum',
          },
        ],
      },
    
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openChargePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        align: "right",
        format: "n2",
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
      },

      {
        binding: "customerDesc",
        header: "Customer Description",
        width: "*",
        minWidth: 250,
        allowDragging: true,
        isRequired: false,
      },
      {
        binding: "approvedAmt",
        header: "Approved.Amt",
        width: 150,
        align: "right",
        format: "n2",
        aggregate: 'Sum',
        allowDragging: true,
        visible: this.pageType === 'Dtl',
      },
    ];

    return out;
  }

  setFormatItem = (s, e) => {
    if (s.columns[e.col]?.binding === "invoiceNo") {
      s.columnFooters.setCellData(0, e.col, "Total", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  }

  getData = () => {
    return [{
      isChecked2: false,
      invoiceNo: "",
      requestAmt: null,
      invoiceDate: null,
      invoiceTax: null,
      lgChargebackNo: "",
      lgChargebackAmt: null,
      customerDesc: ""
    }]
  }

  getTemplateFileName = () => {
    return "Tax";
  }

  getTemplateGirdFormat = () => {    
    return [
      {
        binding: "invoiceNo",
        header: "*LG Invoice#",
        width: 200,
      },
      {
        binding: "requestAmt",
        header: "*Request Amt",
        width: 150,
        align: "right",
      },
      {
        binding: "lgInvoiceDa",
        header: "LG Invoice",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "invoiceDate",
            header: "Date",
            width: 150,
            align: "center",
          },
          {
            binding: "invoiceTax",
            header: "Tax",
            width: 150,
            align: "right",
          },
        ],
      },    
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 200,
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 200,
        align: "right",
      },
      {
        binding: "customerDesc",
        header: "Customer Description",
        width: 250,
        allowDragging: true,
      },
    ];
  }

  getTemplateData = () => {
    return [{
      invoiceNo: "input Invoice No",
      requestAmt: "1",
      invoiceDate: "12/31/2015",
      invoiceTax: "1",
      lgChargebackNo: "input Chargeback No",
      lgChargebackAmt: "1",
      customerDesc: "input Customer Description"
    }]
  }

  convertTemplateToGridData = (el, idx) => {
    let gridFormat = this.getTemplateGirdFormat();

    return {
      rowNum: idx,
      invoiceNo: el[this.getHeaderByBinding(gridFormat, "invoiceNo")],
      requestAmt: el[this.getHeaderByBinding(gridFormat, "requestAmt")],
      invoiceDate: el[this.getHeaderByBinding(gridFormat, "invoiceDate")],
      invoiceTax: el[this.getHeaderByBinding(gridFormat, "invoiceTax")],
      lgChargebackNo: el[this.getHeaderByBinding(gridFormat, "lgChargebackNo")],
      lgChargebackAmt: el[this.getHeaderByBinding(gridFormat, "lgChargebackAmt")],
      customerDesc: el[this.getHeaderByBinding(gridFormat, "customerDesc")],
    }
  }

  isMergeNumbering() {
    return true;
  }
}
export default ClaimTax;