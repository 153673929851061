import ComCheckBox from "common/components/ComCheckBox";
import ComTextBox from "common/components/ComTextBox";
import React, { useState, useEffect, useRef } from "react";

const ComTypeOfChecks = React.forwardRef(
  (
    {
      callRelationFuntion, // 부모에서 호출 할 함수
      elementId, // 객체 아이디
      divValue,
      typeValue
    },
    ref
  ) => {

  const [types, setTypes] = useState("typeCoop");
  const [checkedList, setCheckedList] = useState([]);
  
  const [tsaOther, setTsaOther] = useState(null);
  const [csfOther, setCsfOther] = useState(null);
  const [b2bOther, setB2bOther] = useState(null);
  

  const entityAdvertising = useRef(null);
  const entitySignage = useRef(null);
  const entityEvent = useRef(null);
  const entitySponsorship = useRef(null);
  const entityPromotion = useRef(null);
  const entityIncentiveTrip = useRef(null);
  const entityChampions = useRef(null);
  const entityTravelExpense = useRef(null);
  const entityTestingEquipment = useRef(null);
  const entityTrainingMaterial = useRef(null);
  const entityService = useRef(null);
  const entityRequiredTools = useRef(null);
  const entityOtherDesc = useRef(null);
  const entityOtherDescTxt = useRef(null);
  const entityGoodwill = useRef(null);
  const entityLegal = useRef(null);
  const entityLogistics = useRef(null);
  const entitySalesConcession = useRef(null);
  const entitySalesContest = useRef(null);
  const entityCSFOtherDesc = useRef(null);
  const entityCSFOtherDescTxt = useRef(null);
  const entityB2BAdvertising = useRef(null);
  const entityBrandApparel = useRef(null);
  const entityBrandPremiums = useRef(null);
  const entityCollateral = useRef(null);
  const entityPurchase = useRef(null);
  const entityEventSponsorship = useRef(null);
  const entityPages = useRef(null);
  const entityCommunications = useRef(null);
  const entityItems = useRef(null);
  const entityLearn = useRef(null);
  const entityTelemarketing = useRef(null);
  const entityTradeshow = useRef(null);
  const entityWebinar = useRef(null);
  const entityMeetingSponsorship = useRef(null);
  const entityB2BOtherDesc = useRef(null);
  const entityB2BOtherDescTxt = useRef(null);
  const entityMarketing = useRef(null);
  const entityVideo = useRef(null);

  React.useImperativeHandle(ref, () => ({
    getTypes: () => {
      return types;
    },
    getActivityCheckBox: () => {
      let array = new Array();
      let obj = null;
      let list = [];
      let activities = null;
      
      if (types === "typeCoop") {
        list = ["chkAdvertisingCoop", "chkSignage", "chkEvent", "chkSponsorship", "chkPromotion", "chkIncentiveTrip"];
        for (let i=0; i<list.length; i++) {
          obj = new Object();
          activities = document.getElementById(list[i]);
          obj.id = list[i];
          obj.value = activities.nextElementSibling.textContent;
          obj.checked = activities.checked;

          array.push(obj);
        }
      } else if (types === "typeMDF") {
        list = ["chkChampions"];
        for (let i=0; i<list.length; i++) {
          obj = new Object();
          activities = document.getElementById(list[i]);
          obj.id = list[i];
          obj.value = activities.nextElementSibling.textContent;
          obj.checked = activities.checked;

          array.push(obj);
        }
      } else if (types === "typeTSA") {
        list = ["chkTSATravelExpense", "chkTSATestingEquipment", "chkHVACTrainingMaterial", "chkTSAService", "chkTSARequiredTools", "chkTsaOther"];
        for (let i=0; i<list.length; i++) {
          obj = new Object();
          activities = document.getElementById(list[i]);
          obj.id = list[i];
          obj.value = activities.nextElementSibling.textContent;
          obj.checked = activities.checked;

          array.push(obj);
        }
      } else if (types === "typeCSF") {
        list = ["chkLabor", "chkLegal", "chkLogistics", "chkLSalesConcession", "chkCsfOther"];
        for (let i=0; i<list.length; i++) {
          obj = new Object();
          activities = document.getElementById(list[i]);
          obj.id = list[i];
          obj.value = activities.nextElementSibling.textContent;
          obj.checked = activities.checked;

          array.push(obj);
        }
      } else if (types === "typeB2BMDF") {
        list = ["chkAdvertising", "chkCoBrandApparel", "chkCoBrandedPremiums", "chkCollateral", "chkDemoProductPurchase", "chkDirectMarketing",
          "chkEndUserEvent", "chkMicrositeWebLanding", "chkPRCommunications", "chkSearchEngine", "chkSeminar", "chkTelemarketing",
          "chkTradeshow", "chkVideo", "chkWebinar", "chkMeetingSponsor", "chkSalesContest", "chkB2BMdfOther"
        ];
        for (let i=0; i<list.length; i++) {
          obj = new Object();
          activities = document.getElementById(list[i]);
          obj.id = list[i];
          obj.value = activities.nextElementSibling.textContent;
          obj.checked = activities.checked;

          array.push(obj);
        }
      }
      
      return array;
    },
    getCheckedList: () => {
      return checkedList;
    },
    getOtherDescValue: () => {
      let returnVal = null;
      if (entityOtherDesc.current?.getCheckedValues()) {
        returnVal = entityOtherDescTxt.current?.getValue();
      } else if (entityB2BOtherDesc.current?.getCheckedValues()) {
        returnVal = entityB2BOtherDescTxt.current?.getValue();
      } else if (entityCSFOtherDesc.current?.getCheckedValues()) {
        returnVal = entityCSFOtherDescTxt.current?.getValue();
      }

      return returnVal;
    },
    setList: (arr, id, text) => {
      setCheckedList(arr);
      if (id === "txtTsaOther") {
        setTsaOther(text);
      } else if (id === "txtCsfOther") {
        setCsfOther(text);
      } else if (id === "txtB2BMdfOther") {
        setB2bOther(text);
      }
    },
    setNull: () => {
      setCheckedList([]);
      setTsaOther(null);
      setCsfOther(null);
      setB2bOther(null);
    },
  }));

  useEffect(() => {
  
    if (divValue === "AE(CAC)") {
      if (typeValue === "DFS and PTAC Co-op") {
        setTypes("typeCoop");
      } else if (typeValue === "DFS and PTAC MDF") {
        setTypes("typeMDF");
      } else if (typeValue === "TSA") {
        setTypes("typeTSA");
      } else {
        setTypes("typeCSF");
      }
    } else if (divValue === "B2B") {
      setTypes("typeB2BMDF");
    } else {
      setTypes("typeOther");
    }

  }, [divValue, typeValue]);

  const onCheckActivity = (elementId, isChecked) => {
    let value = null;
    if(elementId === 'entityAdvertising'){
      value = 'Advertising';
    }
    if(elementId === 'entitySignage'){
      value = 'Signage';
    }
    if(elementId === 'entityEvent'){
      value = 'Event';
    }
    if(elementId === 'entitySponsorship'){
      value = 'Sponsorship';
    }
    if(elementId === 'entityPromotion'){
      value = 'Promotion';
    }
    if(elementId === 'entityIncentiveTrip'){
      value = 'Incentive Trip';
    }
    if(elementId === 'entityChampions'){
      value = 'Champions';
    }
    if(elementId === 'entityTravelExpense'){
      value = 'TSA Travel Expense';
    }
    if(elementId === 'entityTestingEquipment'){
      value = 'TSA Testing Equipment';
    }
    if(elementId === 'entityTrainingMaterial'){
      value = 'HVAC Training Material';
    }
    if(elementId === 'entityService'){
      value = 'TSA Service';
    }
    if(elementId === 'entityRequiredTools'){
      value = 'TSA Required Tools';
    }
    if(elementId === 'entityOtherDesc'){
      value = 'Other Desc';
    }
    if(elementId === 'entityGoodwill'){
      value = 'Goodwill';
    }
    if(elementId === 'entityLegal'){
      value = 'Legal';
    }
    if(elementId === 'entityLogistics'){
      value = 'Logistics';
    }
    if(elementId === 'entitySalesConcession'){
      value = 'Sales Concession';
    }
    if(elementId === 'entityCSFOtherDesc'){
      value = 'Other Desc';
    }
    if(elementId === 'entityB2BAdvertising'){
      value = 'Advertising(Print/electronic)';
    }
    if(elementId === 'entityBrandApparel'){
      value = 'Co-Brand Apparel';
    }
    if(elementId === 'entityBrandPremiums'){
      value = 'Co-branded premiums';
    }
    if(elementId === 'entityCollateral'){
      value = 'Collateral';
    }
    if(elementId === 'entityPurchase'){
      value = 'Demo product purchase';
    }
    if(elementId === 'entityEventSponsorship'){
      value = 'End-User Event Sponsorship';
    }
    if(elementId === 'entityPages'){
      value = 'Microsite/Web page/Landing page';
    }
    if(elementId === 'entityCommunications'){
      value = 'PR/Communications';
    }
    if(elementId === 'entityItems'){
      value = 'Giveaway/Promotional Items';
    }
    if(elementId === 'entityLearn'){
      value = "Seminar/Lunch'n Learn";
    }
    if(elementId === 'entityTelemarketing'){
      value = "Telemarketing";
    }
    if(elementId === 'entityTradeshow'){
      value = 'Tradeshow';
    }
    if(elementId === 'entityVideo'){
      value = 'Video';
    }
    if(elementId === 'entityWebinar'){
      value = 'Webinar';
    }
    if(elementId === 'entityMeetingSponsorship'){
      value = 'Sales Meeting Sponsorship';
    }
    if(elementId === 'entitySalesContest'){
      value = 'Sales Contest';
    }
    if(elementId === 'entityB2BOtherDesc'){
      value = 'Other Desc';
    }

    if (isChecked) {
      setCheckedList([...checkedList, value]);
    } else {
      setCheckedList(checkedList.filter(el => el !== value));
    }
  };


  return (
    <>
      {
        types === "typeCoop" ?
          <>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityAdvertising}
                    elementId={"entityAdvertising"}
                    idCheckbox="chkAdvertisingCoop"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Advertising")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkAdvertisingCoop"
                  >
                    Advertising
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entitySignage}
                    elementId={"entitySignage"}
                    idCheckbox="chkSignage"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Signage")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkSignage"
                  >
                    Signage
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityEvent}
                    elementId={"entityEvent"}
                    idCheckbox="chkEvent"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Event")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkEvent"
                  >
                    Event
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entitySponsorship}
                    elementId={"entitySponsorship"}
                    idCheckbox="chkSponsorship"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Sponsorship")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkSponsorship"
                  >
                    Sponsorship
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityPromotion}
                    elementId={"entityPromotion"}
                    idCheckbox="chkPromotion"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Promotion")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkPromotion"
                  >
                    Promotion
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityIncentiveTrip}
                    elementId={"entityIncentiveTrip"}
                    idCheckbox="chkIncentiveTrip"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Incentive Trip")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkIncentiveTrip"
                  >
                    Incentive Trip
                  </label>
                </div>
              </div>
            </div>
          </> :
        types === "typeMDF" ?
          <>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityChampions}
                    elementId={"entityChampions"}
                    idCheckbox="chkChampions"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Champions")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkChampions"
                  >
                    Champions
                  </label>
                </div>
              </div>
            </div>
          </> :
        types === "typeTSA" ?
          <>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityTravelExpense}
                    elementId={"entityTravelExpense"}
                    idCheckbox="chkTSATravelExpense"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("TSA Travel Expense")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTSATravelExpense"
                  >
                    TSA Travel Expense
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityTestingEquipment}
                    elementId={"entityTestingEquipment"}
                    idCheckbox="chkTSATestingEquipment"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("TSA Testing Equipment")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTSATestingEquipment"
                  >
                    TSA Testing Equipment
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityTrainingMaterial}
                    elementId={"entityTrainingMaterial"}
                    idCheckbox="chkHVACTrainingMaterial"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("HVAC Training Material")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkHVACTrainingMaterial"
                  >
                    HVAC Training Material
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityService}
                    elementId={"entityService"}
                    idCheckbox="chkTSAService"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("TSA Service")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTSAService"
                  >
                    TSA Service
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityRequiredTools}
                    elementId={"entityRequiredTools"}
                    idCheckbox="chkTSARequiredTools"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("TSA Required Tools")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTSARequiredTools"
                  >
                      TSA Required Tools
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityOtherDesc}
                    elementId={"entityOtherDesc"}
                    idCheckbox="chkTsaOther"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Other Desc")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTsaOther"
                  >
                    Other Desc
                  </label>
                  <div className="ml-5">
                    <ComTextBox
                      ref={entityOtherDescTxt}
                      elementId={"entityOtherDescTxt"}
                      defaultDisabled={!checkedList.includes("Other Desc")}
                      defaultValue={tsaOther}
                    />
                  </div>
                </div>
              </div>
            </div>
          </> :
        types === "typeCSF" ?
          <>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityGoodwill}
                    elementId={"entityGoodwill"}
                    idCheckbox="chkLabor"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Goodwill")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkLabor"
                  >
                    Goodwill
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityLegal}
                    elementId={"entityLegal"}
                    idCheckbox="chkLegal"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Legal")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkLegal"
                  >
                      Legal
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityLogistics}
                    elementId={"entityLogistics"}
                    idCheckbox="chkLogistics"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Logistics")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkLogistics"
                  >
                    Logistics
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entitySalesConcession}
                    elementId={"entitySalesConcession"}
                    idCheckbox="chkLSalesConcession"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Sales Concession")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkLSalesConcession"
                  >
                      Sales Concession
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityCSFOtherDesc}
                    elementId={"entityCSFOtherDesc"}
                    idCheckbox="chkCsfOther"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Other Desc")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkCsfOther"
                  >
                    Other Desc
                  </label>
                  <div className="ml-5">
                    <ComTextBox
                      ref={entityCSFOtherDescTxt}
                      elementId={"entityCSFOtherDescTxt"}
                      defaultDisabled={!checkedList.includes("Other Desc")}
                      defaultValue={csfOther}
                    />
                  </div>
                </div>
              </div>
            </div>
          </> :
        types === "typeB2BMDF" ?
          <>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityB2BAdvertising}
                    elementId={"entityB2BAdvertising"}
                    idCheckbox="chkAdvertising"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Advertising(Print/electronic)")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkAdvertising"
                  >
                    Advertising(Print/electronic)
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityBrandApparel}
                    elementId={"entityBrandApparel"}
                    idCheckbox="chkCoBrandApparel"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Co-Brand Apparel")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkCoBrandApparel"
                  >
                    Co-Brand Apparel
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityBrandPremiums}
                    elementId={"entityBrandPremiums"}
                    idCheckbox="chkCoBrandedPremiums"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Co-branded premiums")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkCoBrandedPremiums"
                  >
                    Co-branded premiums
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityCollateral}
                    elementId={"entityCollateral"}
                    idCheckbox="chkCollateral"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Collateral")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkCollateral"
                  >
                    Collateral
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityPurchase}
                    elementId={"entityPurchase"}
                    idCheckbox="chkDemoProductPurchase"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Demo product purchase")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkDemoProductPurchase"
                  >
                      Demo product purchase
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityMarketing}
                    elementId={"entityMarketing"}
                    idCheckbox="chkDirectMarketing"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Direct Marketing")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkDirectMarketing"
                  >
                      Direct Marketing
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityEventSponsorship}
                    elementId={"entityEventSponsorship"}
                    idCheckbox="chkEndUserEvent"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("End-User Event Sponsorship")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkEndUserEvent"
                  >
                      End-User Event Sponsorship
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityPages}
                    elementId={"entityPages"}
                    idCheckbox="chkMicrositeWebLanding"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Microsite/Web page/Landing page")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkMicrositeWebLanding"
                  >
                        Microsite/Web page/Landing page
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityCommunications}
                    elementId={"entityCommunications"}
                    idCheckbox="chkPRCommunications"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("PR/Communications")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkPRCommunications"
                  >
                        PR/Communications
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityItems}
                    elementId={"entityItems"}
                    idCheckbox="chkSearchEngine"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Giveaway/Promotional Items")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkSearchEngine"
                  >
                        Giveaway/Promotional Items
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityLearn}
                    elementId={"entityLearn"}
                    idCheckbox="chkSeminar"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Seminar/Lunch'n Learn")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkSeminar"
                  >
                        Seminar/Lunch'n Learn
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityTelemarketing}
                    elementId={"entityTelemarketing"}
                    idCheckbox="chkTelemarketing"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Telemarketing")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTelemarketing"
                  >
                        Telemarketing
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityTradeshow}
                    elementId={"entityTradeshow"}
                    idCheckbox="chkTradeshow"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Tradeshow")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkTradeshow"
                  >
                        Tradeshow
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityVideo}
                    elementId={"entityVideo"}
                    idCheckbox="chkVideo"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Video")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkVideo"
                  >
                        Video
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityWebinar}
                    elementId={"entityWebinar"}
                    idCheckbox="chkWebinar"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Webinar")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkWebinar"
                  >
                        Webinar
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityMeetingSponsorship}
                    elementId={"entityMeetingSponsorship"}
                    idCheckbox="chkMeetingSponsor"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Sales Meeting Sponsorship")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkMeetingSponsor"
                  >
                        Sales Meeting Sponsorship
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entitySalesContest}
                    elementId={"entitySalesContest"}
                    idCheckbox="chkSalesContest"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Sales Contest")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkSalesContest"
                  >
                        Sales Contest
                  </label>
                </div>
              </div>
            </div>
            <div className="bx-item combo">
              <div className="group-checkbox-entry ml-5">
                <div className="item-checkbox">
                  <ComCheckBox
                    ref={entityB2BOtherDesc}
                    elementId={"entityB2BOtherDesc"}
                    idCheckbox="chkB2BMdfOther"
                    callRelationFuntion={onCheckActivity}
                    defaultValue={checkedList.includes("Other Desc")}
                  />
                  <label
                    className="text-checkbox"
                    htmlFor="chkB2BMdfOther"
                  >
                    Other Desc
                  </label>
                  <div className="ml-5">
                    <ComTextBox
                      ref={entityB2BOtherDescTxt}
                      elementId={"entityB2BOtherDescTxt"}
                      defaultDisabled={!checkedList.includes("Other Desc")}
                      defaultValue={b2bOther}
                    />
                  </div>
                </div>
              </div>
            </div>
          </> :
          null
        }
      </>
    );
  }
);
export default React.memo(ComTypeOfChecks);
