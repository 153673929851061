import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openMenu, viewHome } from "reducer/OpenMenus";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Collapse, Popover, Tooltip } from "@mui/material";

// import "assets/styles/layout/DashboardHeader.scss";
import "assets/styles/layout/LandingHeader.scss";
import arow_down from "assets/icons/arrow-down.svg";
import star from "assets/icons/star.svg";
import notification from "assets/icons/notification.svg";
import profile_circle from "assets/icons/profile-circle.svg";
import logout from "assets/icons/logout.svg";
import starOutline from "assets/icons/star-outline.svg";
import starOutlineActive from "assets/icons/star-outline-active.svg";
import global from "assets/icons/global.svg";
import ListItem from "@mui/material/ListItem";
import arow_down_dark from "assets/icons/arow_down_dark.svg";
import MenuIcon from "assets/icons/menu-hamburger.svg";
import check from "assets/icons/check.svg";

import { ApiManager } from "common/lib/ApiUtil";

function LandingHeader(props) {
  const [profileEl, setProfileEl] = useState(null);
  const [favoriteEl, setFavoriteEl] = useState(null);
  const [contactUsEl, setContactUsEl] = useState(null);
  const [isShowLang, setIsShowLang] = useState(false);

  const openProfile = Boolean(profileEl);
  const openFavorite = Boolean(favoriteEl);
  const openContactUs = Boolean(contactUsEl);

  const onOpenProfile = (event) => {
    setProfileEl(!profileEl ? event.currentTarget : null);
    setFavoriteEl(null);
    setContactUsEl(null);
  };
  const onOpenFavorite = (event) => {
    setFavoriteEl(!favoriteEl ? event.currentTarget : null);
    setProfileEl(null);
    setContactUsEl(null);
  };

  const onOpenContactUs = (event) => {
    setContactUsEl(!contactUsEl ? event.currentTarget : null);
    setFavoriteEl(null);
    setProfileEl(null);
  };

  const onOpenLang = (event) => {
    setIsShowLang(!isShowLang);
  };
  const handleCloseProfile = () => {
    setProfileEl(null);
  };
  const handleCloseFavorite = () => {
    setFavoriteEl(null);
  };
  const handleCloseContactUs = () => {
    setContactUsEl(null);
  };

  const idFavorite = openFavorite ? "favorite-popover" : undefined;
  const idProfile = openProfile ? "profile-popover" : undefined;
  const idContactUs = openContactUs ? "contactus-popover" : undefined;

  const dispatch = useDispatch();

  const [favorites, setFavorite] = useState([
    {
      text: "RA Status Tracking",
      isFavorite: true,
    },
    {
      text: "Invoice",
      isFavorite: true,
    },
    {
      text: "Program Management",
      isFavorite: true,
    },
    {
      text: "Salesperson SPIFF Claim",
      isFavorite: true,
    },
    {
      text: "IR Confirm",
      isFavorite: true,
    },
  ]);

  const logOut = async () => {
    await ApiManager.post("/init/LogOut", {});
    //let ssoLogInType = sessionStorage.getItem('ssoLogIn')
    sessionStorage.clear();
    //sessionStorage.setItem('ssoLogIn', ssoLogInType)
    document.location.href = "/";
  };

  const changeFavorite = (e) => {
    setFavorite(
      favorites.map((x) => {
        if (x.text === e.text) {
          return {
            ...x,
            isFavorite: !x.isFavorite,
          };
        }
        return x;
      })
    );
  };

  const redirectMyPage = () => {
    let t = {};
    let menuItem = {
      menuId: "my-page",
      menuName: "My Info",
      menuUrl: "Adm/MyPage",
    };
    Object.assign(t, menuItem);
    t["origSysDocumentRef"] = "";
    t["copyFlag"] = false;
    t["editFlag"] = false;
    t["timeKey"] = menuItem.menuId;
    t["tabUniqueKey"] = menuItem.menuId;
    t["Active"] = true;
    dispatch(openMenu(t));
    handleCloseProfile();
  };

  const handleOpenNoticePage = () => {
    let t = {};
    let menuItem = {
      menuId: "noice-page-mobile",
      menuName: "Notice Page Mobile",
      menuUrl: "Landing/components/NoticeMobile",
    };
    Object.assign(t, menuItem);
    t["origSysDocumentRef"] = "";
    t["copyFlag"] = false;
    t["editFlag"] = false;
    t["timeKey"] = menuItem.menuId;
    t["tabUniqueKey"] = menuItem.menuId;
    t["Active"] = true;
    dispatch(openMenu(t));
  };

  
  return (
    <div className="header-landing-wrapper">
      <img
        src={MenuIcon}
        onClick={props.handleToggle}
        alt="menuicon"
        className="toggle-menu-icon"
      />
      <div className="header-landing-content">
        <div className="header-language">
          <div style={{ color: "red", fontSize: "25px", fontWeight: "bold" }}>
            {sessionStorage?.getItem("profile") != "prd"
              ? "(" + sessionStorage?.getItem("profile")?.toUpperCase() + ")"
              : ""}
          </div>
          {/* <div
            onClick={() => {
              document.location.href = "/";
            }}
          >
            <span>Welcome to LG Sales Portal,</span>
          </div> */}
        </div>
        <div className="profile">
          <div className="profile-info" onClick={onOpenProfile}>
            <span className="profile-name">
              Welcome {sessionStorage?.getItem("userName")}
            </span>
            <img src={arow_down} style={{ height: "20px", width: "20px" }} />
          </div>

          {/* Menu favorite */}
          <Popover
            id={idFavorite}
            open={openFavorite}
            anchorEl={favoriteEl}
            onClose={handleCloseFavorite}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "10px" }}
          >
            <div className="layout-favorite">
              <span className="title-favorite">Favorite</span>
              <List>
                <Divider component="li" />
                {favorites.map((x, index) => (
                  <div key={index}>
                    <ListItem style={{ width: "260px" }}>
                      <div
                        className="layout-item-favorite"
                        onClick={() => changeFavorite(x)}
                      >
                        <span>{x.text}</span>
                        <img
                          src={x.isFavorite ? starOutlineActive : starOutline}
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      </div>
                    </ListItem>
                    <Divider component="li" />
                  </div>
                ))}
              </List>
            </div>
          </Popover>

          {/* End Menu favorite */}

          {/* Menu profile */}
          <Popover
            id={idProfile}
            open={openProfile}
            anchorEl={profileEl}
            onClose={handleCloseProfile}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{ marginTop: "15px" }}
          >
            <div className="layout-profile">
              <div className="drw-info">
                {/* <div className="drw-info-layout">
                <span className="drw-info-name">{sessionStorage?.getItem("userName")}</span>
                <span className="drw-info-team">LG SPIFF Team</span>
              </div> */}
              </div>
              <div className="profile-my-page">
                <img
                  src={profile_circle}
                  style={{ height: "20px", width: "20px" }}
                />
                <div style={{ marginLeft: "10px" }} onClick={redirectMyPage}>
                  My Info
                </div>
              </div>
              <div className="profile-item item-language" onClick={onOpenLang}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={global} style={{ height: "20px", width: "20px" }} />
                  <span style={{ marginLeft: "10px" }}>Language</span>
                </div>
                <div style={{ marginRight: "5px" }}>
                  <img
                    src={arow_down_dark}
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
              </div>
              <Collapse
                in={isShowLang}
                timeout="auto"
                unmountOnExit
                style={{ paddingTop: "unset" }}
              >
                <List component="div">
                  <div className="global-item">
                    <div>
                      <div className="layout-language">
                        <span className="txt-language">English</span>
                      </div>
                    </div>
                    <div>
                      <img src={check} />
                    </div>
                  </div>
                </List>
              </Collapse>
              <div className="profile-item" onClick={logOut}>
                <img src={logout} style={{ height: "20px", width: "20px" }} />
                <div style={{ marginLeft: "10px" }}>Logout</div>
              </div>
            </div>
          </Popover>

          {/* End menu profile */}

          {/* Menu Contact US */}
          <Popover
            id={idContactUs}
            open={openContactUs}
            anchorEl={contactUsEl}
            onClose={handleCloseContactUs}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ marginTop: "15px" }}
          >
            <div className="layout-favorite">
              <span className="title-favorite">Contact Us</span>
              <List>
                <Divider component="li" />
                <div>
                  <ListItem style={{ width: "250px" }}>
                    <div className="layout-item-favorite">
                      <span className="fwb">SPIFF Support Options :</span>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="layout-item-favorite">
                      <span className="ml-10">spiff.claim@lge.com</span>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="layout-item-favorite">
                      <span className="ml-10">1-855-4LG-SPIF (454-7743)</span>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="layout-item-favorite">
                      <span className="ml-10">
                        Mon~Fri, 8-12pm & 1pm-5pm EST
                      </span>
                    </div>
                  </ListItem>
                  <Divider component="li" />
                  <ListItem>
                    <div className="layout-item-favorite">
                      <span className="fwb">
                        General Information & Questions :
                      </span>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="layout-item-favorite">
                      <span className="ml-10">LG Fax number: 201-408-1339</span>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div className="layout-item-favorite">
                      <span className="ml-10">
                        LG Debit Card Inquiries: 866-326-8689
                      </span>
                    </div>
                  </ListItem>
                  <ListItem>
                    <div>
                      <span className="ml-10">
                        Card website: https://login.mypaymentvault.com/
                      </span>
                      {/* <span className="ml-10">aichelpdesk@lge.com</span> */}
                    </div>
                  </ListItem>
                  <Divider component="li" />
                </div>
              </List>
            </div>
          </Popover>

          {/* End Menu Contact Us */}
        </div>
        {/* <div className="star" onClick={onOpenFavorite}>
          { neocave 0409 inline-style 변경 before - height:32px; width:32px }
          <Tooltip
            title="My Favorite"
            placement="bottom"
            className="tooltip-favorite"
          >
            <img
              src={star}
              style={{ height: "16px", width: "16px", fill: "red" }}
            />
          </Tooltip>
        </div> */}
        <div className="header-language ml-10" onClick={onOpenContactUs}>
          <span>Contact Us</span>
          <img src={arow_down} style={{ height: "20px", width: "20px" }} />
        </div>
        <div className="ic-notification" onClick={handleOpenNoticePage}>
          <img
            src={notification}
            style={{ marginTop: "2px", height: "25px", width: "25px" }}
          />
          {/* <div className="number-notification">1</div> */}
        </div>
      </div>
    </div>
  );
}

export default LandingHeader;
