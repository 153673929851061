import * as wjGrid from '@grapecity/wijmo.grid';

class GridUserMergeFooter  extends wjGrid.MergeManager  {
    constructor(grid) {
     super(grid);
   }
 
   getMergedRange(panel, row, col, clip) {
     if (panel.cellType !== wjGrid.CellType.ColumnFooter) {
       return super.getMergedRange(panel, row, col, clip);
     }
     var rg = new wjGrid.CellRange(row, col);
     // expand left/right
     for (var i = rg.col; i < panel.columns.length - 1; i++) {
       if (
         panel.getCellData(rg.row, i, true) !=
         panel.getCellData(rg.row, i + 1, true)
       )
         break;
       rg.col2 = i + 1;
     }
     for (var i = rg.col; i > 0; i--) {
       if (
         panel.getCellData(rg.row, i, true) !=
         panel.getCellData(rg.row, i - 1, true)
       )
         break;
       rg.col = i - 1;
     }
     return rg;
   }
 }
 

export default GridUserMergeFooter