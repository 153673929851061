import * as wjGrid from '@grapecity/wijmo.grid';

class GridUserMerge extends wjGrid.MergeManager {
    getMergedRange(panel, currentRow, currentCol, clip = false) {

        // BottomLeft: = 6
        // Cell: = 1
        // ColumnFooter: = 5
        // ColumnHeader: = 2
        // None: = 0
        // RowHeader: = 3
        // TopLeft: = 4

        // Header 
        // 체크 박스 병합
        if (panel.cellType === 4 ){

            
            let  rng = new wjGrid.CellRange(currentRow, currentCol);

            // expand left/right
            for (let i = rng.col; i < panel.columns.length - 1; i++) {
                if (
                panel.getCellData(rng.row, i, true) !==
                panel.getCellData(rng.row, i + 1, true)
                )
                break;
                rng.col2 = i + 1;
            }
            for (let i = rng.col; i > 0; i--) {
                if (
                panel.getCellData(rng.row, i, true) !==
                panel.getCellData(rng.row, i - 1, true)
                )
                break;
                rng.col = i - 1;
            }
            //
            // expand up/down
            for (let i = rng.row; i < panel.rows.length - 1; i++) {
                if (
                panel.getCellData(i, rng.col, true) !==
                panel.getCellData(i + 1, rng.col, true)
                )
                break;
                rng.row2 = i + 1;
            }
            for (let i = rng.row; i > 0; i--) {
                if (
                panel.getCellData(i, rng.col, true) !==
                panel.getCellData(i - 1, rng.col, true)
                )
                break;
                rng.row = i - 1;
            }
            //
            // done
            return rng;
            
        }
        // Heaer 처리
        else if (panel.cellType === 2){
            
            // create basic cell range
            let rng = null;
            
            // start with single cell
            rng = new wjGrid.CellRange(currentRow, currentCol);

            
            // get reference values to use for merging
            let val = panel.getCellData(currentRow, currentCol, false);
            
            // Row Expand Up
            while (rng.row > 0 &&
                panel.getCellData(rng.row - 1, currentCol, false) === val ){
                rng.row--;
            }
            // Row Expand down
            while (rng.row2 < panel.rows.length - 1 &&
                panel.getCellData(rng.row2 + 1, currentCol, false) === val){
                rng.row2++;
            }
            if ((panel.rows.length -1) !== currentRow){
                // Col Expand Left
                while (rng.col > 0 &&
                    panel.getCellData(rng.row, rng.col -1 , false) === val ){
                    rng.col--;
                }
                //
                // Col Expand Right
                while (rng.col2 < panel.columns.length - 1 &&
                    panel.getCellData(rng.row2, rng.col2+1, false) === val){
                    rng.col2++;
                }
            }
                
            // No Merge
            if (rng.isSingleCell) {
                rng = null;
            }
            //
            // done
            return rng;
            
        }
        // Data Cell 
        else if (panel.cellType === 1){

            // 데이터 가져오는 방법
            //panel.grid.collectionView._pgView[currentRow].uiFlag

            if (!panel.columns[currentCol].allowMerging){
                return null;
            }
            //
            // create basic cell range
            let rng = null;
            //
            // start with single cell
            rng = new wjGrid.CellRange(currentRow, currentCol);
            
            let minRow = panel.viewRange.row;
            let maxRow = panel.viewRange.row2;
            
            let currentValueArray = [];

            // get reference values to use for merging
            for (let i=currentCol; i>=0; i--){
                currentValueArray.push(panel.getCellData(currentRow, i, false));
            }

            // current Values Array to String
            let currentValue = currentValueArray.join(",");
            
            // expand up
            let staticHeight = panel.grid.rows.defaultSize;
            let checkCount = (((minRow * staticHeight) +panel.grid.scrollPosition.y )+ staticHeight)
            //if ( 1< checkCount && checkCount < 11){
            //    if (minRow > 0){
            //        minRow =minRow ;
            //    }
            //}
            if ( 1< checkCount && checkCount <= 15){

                if (minRow === 0 && checkCount<=12){
                    minRow =minRow +1;
                }
                else if (minRow > 0){
                    minRow =minRow +1;
                }
            }
           
            while (rng.row > minRow){
                
                let targetValueArray = [];
                for (let i=currentCol; i>=0; i--){
                    targetValueArray.push(panel.getCellData(rng.row - 1, i, false));
                }

                if (targetValueArray.join(",") !== currentValue){
                    break;
                }
                
                if (rng.row  > 0){
                    rng.row--;
                }
                else{
                    break;
                }
                
            }
            
            // expand down
            while (rng.row2 <= maxRow+ 2){ //panel.rows.length - 1){
                let targetValueArray = [];
                for (let i=currentCol; i>=0; i--){
                    targetValueArray.push(panel.getCellData(rng.row2 + 1, i, false));
                }

                if (targetValueArray.join(",") !== currentValue){
                    break;
                }
                rng.row2++;
            }
            
            // No Merge
            if (rng.isSingleCell) {
                rng = null;
            }
            //
            // done
            return rng;
        }
    }
}

export default GridUserMerge