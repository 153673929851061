import { useEffect, useRef, useState } from "react";
import * as wjInput from "@grapecity/wijmo.react.input";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Link } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import "assets/styles_mobile/components/layout/pages/ResponsiveModal.scss";

function ShowModalWaitingForApproval({ callbackFuntion, isOpen, randomKey }) {
  const positionTopValue = useRef();
  const popComponent = useRef();

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();
    if (isOpen) {
      popComponent.current.show(true);
    }
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [isOpen, randomKey]);

  const onCancleClick = () => {
    popComponent.current.hide();
    callbackFuntion();
  };

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    // popComponent.current.show(true);
    positionTopValue.current = popComponent.current.hostElement.style.top;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable pop-small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ maxWidth: "28.646vw", height: "auto" }}
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title show-fg">
          {
            "The account unlock request has already been submitted. Please check your email for the confirmation previously sent to you."
          }
        </h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="wj-dialog-input">
        <p></p>
        <div className="form-ip">
          <p>
            If you have any inquiries, please contact us at
            <span className="required"> spiff.clam@lge.com </span> or call
            <span className="required"> 855-4LG-SPIFF </span>
          </p>
        </div>
        <div className="btn-dialog">
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={onCancleClick}
          >
            {"Cancel"}
          </Button>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ShowModalWaitingForApproval;
