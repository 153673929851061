import React, { useState, useEffect, useRef } from "react";
import close from "assets/icons/close.svg";
import search from "assets/icons/search.svg";

import { ApiManager } from "common/lib/ApiUtil";
import { ComNoticfication } from "common/components/ComNoticfication";

const ComTextBoxFindWithcode = React.forwardRef(
  (
    {
      callRelationFuntion,  // 부모에서 호출 할 함수
      callRelationBlur,     // 부모에서 호출 할 함수
      callOpenPopupFunction,
      callClearFunction,
      callOpenValid,        //단건 조회전 valid체크
      defaultValue,         // 기본 값
      elementId,            // 객체 아이디
      placeholderCode,      // Code placeholder
      placeholderText,      // Text placeholder

      // 스타일 관련
      defaultDisabled, // 활성화여부
      defaultStyle,
      defaultClassName,
      defaultReadOnly,
      defaultRaiseClick,
      //단건 세팅
      defaultSearch,
      isRequired = false,

      isLoading = true, // 로딩여부
    },

    ref
  ) => {
    const codeRef = useRef(null);
    const textRef = useRef(null);
    const focusRef = useRef(null);

    const isModified = useRef(false);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userValue, setUserValue] = useState(false);
    const [userCode, setUserCode] = useState(false);
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [userClickRise, setUserClickRise] = useState(false);

    const [userIsReadOnly, setUserIsReadOnly] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);
    const [isClearButtonShow, setIsClearButtonShow] = useState(false);

    const [shouldFocus, setShouldFocus] = useState(false);

    useEffect(() => {
      if (shouldFocus && focusRef.current) {
        focusRef.current.focus();
        setShouldFocus(false);
      }
    }, [shouldFocus])


    React.useImperativeHandle(ref, () => ({
      getValue: () => {
        return { cd: codeRef?.current ?? "", nm: textRef?.current ?? "" };
      },
      getCode: () => codeRef.current,
      getText: () => textRef.current,

      setStyle: (v) => {
        setHasStyle(true);
        setUserStyle(v);
      },
      setClassName: (v) => {
        setHasClassName(true);
        setUserClassName(v);
      },
      setDisabled: (v) => {
        setUserDisable(v);
      },
      setValue: (v) => {

        if (v == null) {
          clearValue();
        } else {
          if (v?.code ?? "") {
            codeRef.current = v?.code ?? "";
            textRef.current = v?.text ?? "";

            setUserCode(v?.code ?? "");
            setUserValue(v?.text ?? "");

            if ((v?.code ?? "").toString().replace(/ /gi, "").length > 0) {
              setUserValue(v?.text ?? "");
              setIsClearButtonShow(true);
            } else {
              setIsClearButtonShow(false);
            }
          }
        }
      },
      setFocusCode: (v) => {
        setShouldFocus(true);
      },
    }));

    // 전달 파라미터가 변경될때
    useEffect(() => {
      if (defaultValue !== undefined && defaultValue !== null) {
        codeRef.current = defaultValue;
        setUserCode(defaultValue);
      }
      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }

      if (defaultReadOnly === undefined || defaultReadOnly === null) {
        setUserIsReadOnly(false);
      } else {
        setUserIsReadOnly(defaultReadOnly);
      }

      if (defaultRaiseClick === undefined || defaultRaiseClick === null) {
        setUserClickRise(true);
      } else {
        setUserClickRise(defaultReadOnly);
      }
    }, [
      defaultValue,
      defaultStyle,
      defaultClassName,
      defaultDisabled,
      defaultReadOnly,
      defaultRaiseClick,
    ]);

    const onComTextBoxFindOpenPopup = () => {
      //readonly가 아닌경우
      if (!userIsReadOnly) {
        let chkValid = true;
        //호출전 valid체크
        if (callOpenValid) {
          chkValid = callOpenValid();
        }
        if (chkValid) {
          if (userCode && defaultSearch?.current?.url) {
            onSearchExecute(defaultSearch.current);
          } else {
            callOpenPopupFunction(elementId, userCode, null);
          }
        }
      }
    };

    const onSearchExecute = async (sendParam) => {
      try {
        let resultData = null;
        resultData = isLoading ? await ApiManager.post(sendParam.url, sendParam) : await ApiManager.post(sendParam.url, sendParam, { loadbarOpen: false });
        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(
            "Error",
            "An error occurred during processing"
          );
        } else {
          if (resultData) {
            callOpenPopupFunction(elementId, userCode, resultData);
          } else {
            callOpenPopupFunction(elementId, userCode, null);
          }
        }
      } catch (ex) {
        console.log(ex.message);
      }
    };

    const onComTextBoxFindKeyPress = (e) => {
      if (e.key === "Enter") {
        onComTextBoxFindOpenPopup();
      }
    };

    const clearValue = () => {
      codeRef.current = null;

      setIsClearButtonShow(false);
      setUserValue("");
      setUserCode("");
      if (callClearFunction) {
        callClearFunction(elementId, "");
      }
    };

    const textChanged = (e) => {
      isModified.current = true;
      let v = e.target.value;

      setUserCode(v);
      setUserValue("");
      if (
        v !== undefined &&
        v !== null &&
        v.toString().replace(/ /gi, "").length > 0
      ) {
        setIsClearButtonShow(true);
      } else {
        setIsClearButtonShow(false);
      }
      if (codeRef.current) {
        codeRef.current = null;
      }

      if (callRelationFuntion) {
        callRelationFuntion(elementId, v);
      }
    };

    const handleBlur = (e) => {

      if (callRelationBlur) {
        //callRelationBlur(elementId, e.target.value);
        if (isModified.current) {
          if (e.target.value && textRef?.current?.length === 0) {
            onComTextBoxFindOpenPopup();
          }

          isModified.current = false;
        }
      }
    };

    return (
      <div className="find-input-area">
        <div className="find-ip-parent">
          <input
            name="comTextBoxFindCode"
            type="text"
            value={userCode || ""}
            className={hasClassName ? userClassName : ""}
            style={hasStyle ? userStyle : {}}
            onKeyUp={onComTextBoxFindKeyPress}
            onChange={textChanged}
            onClick={userClickRise ? onComTextBoxFindOpenPopup : null}
            onBlur={handleBlur}
            readOnly={userIsReadOnly ? "readonly" : ""}
            ref={focusRef}
            placeholder={placeholderCode}
          />
          {!isClearButtonShow && isRequired ? (
            <span className="btn-close field-required">*</span>
          ) : (
            <img
              onClick={clearValue}
              className="btn-close"
              src={close}
              style={{ display: userDisable ? "none" : (isClearButtonShow ? "block" : "none") }}
            />
          )}

          <img
            className="btn-search"
            src={search}
            style={{ display: userDisable ? "none" : "block" }}
            onClick={onComTextBoxFindOpenPopup}
          />
        </div>

        <input
          name="comTextBoxFindText"
          type="text"
          value={userValue || ""}
          disabled={"disabled"}
          className={hasClassName ? userClassName : ""}
          style={hasStyle ? userStyle : {}}
          readOnly={"readonly"}
          placeholder={placeholderText}
        />
      </div>
    );
  }
);
export default React.memo(ComTextBoxFindWithcode);
