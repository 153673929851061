import { useState, useEffect, useRef } from "react";

import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import "assets/styles/pages/DiscountChart.scss";
import CloseIcon from "@mui/icons-material/Close";

import * as wjInput from "@grapecity/wijmo.react.input";

function DiscountChart({ callbackFuntion, isOpen, randomKey }) {
  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    setPopuView(() => isOpen);

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [isOpen, randomKey]);

  const onCancelClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    callbackFuntion(false, -1, null);
  };

  let top = 0;

  const initForm = (s) => {
    // if (isOpen) {
      popComponent.current = s;
      popComponent.current.show(true);
      positionTopValue.current = popComponent?.current?.hostElement?.style?.top;
    // }
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable notice-popup"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ width: "200px", maxWidth: "80vw" }}
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <div></div>
        <Button color="secondary" className="btn-close" onClick={onCancelClick}>
          <CloseIcon />
        </Button>
      </div>
      <div style={{ height: "700px", overflow: "auto" }}>
        <div className="notice-wrapper">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div>Confidential</div>
          </div>

          <div className="title-notice">
            HA - Discount Chart<br />Concealed Damage ONLY
          </div>

          <div className="on-using">
            <div className="on-using-left">
              <div>Minor Scratch - Front – 15% Max</div>
              <div>Minor Scratch – All Other Locations - 10%</div>
              <div>Minor Scratch – Display Panel – 15%</div>
            </div>
            <div className="on-using-center">
              <div>---------------------------</div>
            </div>
            <div className="on-using-right">
              <div className="key-right fwb">
                Minimal Damage<br />Allowance
              </div>
            </div>
          </div>
          <div className="on-using">
            <div className="on-using-left">
              <div>Severe Scratch – Front – 30% Max.</div>
              <div>Severe Scratch – All Other Locations - 15%</div>
              <div>Severe Scratch – Display Panel – 30%</div>
            </div>
            <div className="on-using-center">
              <div>---------------------------</div>
            </div>
            <div className="on-using-right">
              <div className="key-right fwb">
                Considerable Damage<br />Allowance
              </div>
            </div>
          </div>
          <div className="on-using">
            <div className="on-using-left">
              <div>Minor Dent – Back – 10%</div>
              <div>Minor Dent – Front – 25%</div>
              <div>Minor Dent – Left or Right Side – 15%</div>
              <div>Minor Dent – Top – 15%</div>
            </div>
            <div className="on-using-center">
              <div>---------------------------</div>
            </div>
            <div className="on-using-right">
              <div className="key-right fwb">
                Minimal Damage<br />Allowance
              </div>
            </div>
          </div>
          <div className="on-using">
            <div className="on-using-left">
              <div>Corner Damage (pushed in) – 30%</div>
            </div>
            <div className="on-using-center">
              <div>---------------------------</div>
            </div>
            <div className="on-using-right">
              <div className="key-right fwb">
                Maximal Damage<br />Allowance
              </div>
            </div>
          </div>
          <div className="on-using">
            <div className="on-using-left">
              <div>Severe Dent – Back – 25% Max.</div>
              <div>Severe Dent – Front – 30%</div>
              <div>Severe Dent – Left or Right Side – 30%</div>
              <div>Severe Dent – Top – 30%</div>
            </div>
            <div className="on-using-center">
              <div>---------------------------</div>
            </div>
            <div className="on-using-right">
              <div className="key-right fwb">
                Maximal Damage<br />Allowance
              </div>
            </div>
          </div>

          <div className="desc-notice">
            <span>Higher Discount</span>
            &nbsp;- Requests higher than 30% must have approval by Product Manager.
          </div>
          <div className="desc-notice">
            <span>Display Panel Damage Other Than Scratch</span>
            &nbsp;- should be handled via No Charge Parts Request to Huntsville.
          </div>
          <div className="desc-notice">
            <span>Doors, handles and any other replaceable parts</span>
            &nbsp;- should be handled via a No Charge Parts Request to Huntsville.
            These parts will be billed to Sales.
          </div>
          <div className="desc-notice">
            <span>DOA</span>
            &nbsp;- Merchandise that is found to be initially defective, by a servicing dealer,
            should be repaired and submitted to Huntsville under the usual warranty
            procedure. Non-Servicing dealers should contact Huntsville Service Division
            for a service in their area. LG will offer a 15% discount
            (with service receipt) to compensate for B stock.
          </div>
          <div className="desc-notice no-under">
            Merchandise that is found to be defective by the <span>end user</span>
            &nbsp;should be referred to Huntsville Service – 1-800-243-0000.
          </div>
          <div className="desc-notice">
            <span>Carton Damages</span>
            &nbsp;- should be refused upon receipt and returned to carrier.
            LG will process a claim against the carrier in this case.
          </div>
          <div className="desc-notice">
            <span>Scrap discounts (100%)</span>
            &nbsp;- should only be committed after signatures are received and will only be
            credited after Sales Administration receives the manufacturer’s model plate.
            Photographs should be requested and received when possible.
          </div>

          <div className="desc-notice-footer">
            <div className="required-field">
              Original to A/R - Copy to Sales Administration - Copy to Logistics
            </div>
            <div className="required-field">
              All information contained herein is to be kept CONFIDENTIAL.
            </div>
          </div>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default DiscountChart;
