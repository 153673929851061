import React, { Component } from 'react';
import * as wijmo from "@grapecity/wijmo";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Button } from "@mui/material";
import "assets/styles/pages/PagesCommon.scss";

import { ComUtils } from "common/lib/ComUtils";

import ComCombo from "common/components/ComCombo";

import ComTextBox from "common/components/ComTextBox";

import { InputNumber, InputDate } from "@grapecity/wijmo.input";
import search from "assets/icons/search.svg";
import ComDate from "common/components/ComDate";

import { CollectionView } from "@grapecity/wijmo";

//import * as wjGrid from '@grapecity/wijmo.grid';
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { createElement, format, toggleClass } from "@grapecity/wijmo";
import GridUserMergeFooter from "common/lib/GridUserMergeFooter";

// 컬럼

import { MessageContext } from "common/lib/MessageProvider";

import Breadcrumb from "common/components/ComBreadcrumb";

import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { GroupRow, DataMap } from "@grapecity/wijmo.grid";

class BaseClaim extends React.Component {
  constructor(props) {
    super(props);    

    this.disputeMap = new DataMap([
      { cd: "Y", nm: "Fund Available" },
      { cd: "S", nm: "Short Fund" },
      { cd: "P", nm: "No PGM" },
    ], "cd", "nm");

    this.amtMap = new DataMap([
      { cd: "%", nm: "%" },
      { cd: "Amt.", nm: "Amt." },
    ], "cd", "nm");

    if (props.rejectionReasonList) {
      this.rejectionReasonMap = new DataMap(props.rejectionReasonList, "cd", "nm");
    }    

    this.userType = props.userType;
    this.pageType = props.pageType;
    this.claimAnalystYn = props.claimAnalystYn;
    this.rejectYn = props.rejectYn;
    this.orgRequestHidden = props.orgRequestHidden;
    this.openChargePopup = props.openChargePopup;
    this.openModelCodePopup = props.openModelCodePopup;
    this.openLgProgramModelPopup = props.openLgProgramModelPopup;
    this.openLgProgramPopup = props.openLgProgramPopup;
    this.openOrgProgramPopup = props.openOrgProgramPopup;
    this.openInvoiceNoPopup = props.openInvoiceNoPopup;
    this.openPriceInvoiceNoPopup = props.openPriceInvoiceNoPopup;
    this.openTaxInvoiceNoPopup = props.openTaxInvoiceNoPopup;
    this.downloadPdf = props.downloadPdf;


    // this.state = {
    //   isDisabledorgPgmNo: props.isDisabledorgPgmNo ?? true,
    // }
    this.isDisabledorgPgmNo = props.isDisabledorgPgmNo;
  }

  update = (prevProps) => {
    // prop 값이 변경되었을 때 상태를 업데이트
    // if (prevProps.isDisabledorgPgmNo !== this.state.isDisabledorgPgmNo) {
    //   this.setState({ isDisabledorgPgmNo: prevProps.isDisabledorgPgmNo });
    // }

    this.isDisabledorgPgmNo = prevProps.isDisabledorgPgmNo;
  }

  getGirdFormat = () => {
    throw new Error("Method 'getGirdFormat()' must be implemented.");
  }

  setFormatItem = (s, e) => {
    throw new Error("Method 'setFormatItem()' must be implemented.");
  }

  getData = () => {
    throw new Error("Method 'getData()' must be implemented.");
  }

  getTemplateFileName = () => {
    throw new Error("Method 'getTemplateFileName()' must be implemented.");
  }

  getTemplateGirdFormat = () => {
    throw new Error("Method 'getTemplateGirdFormat()' must be implemented.");
  }

  getTemplateData = () => {
    throw new Error("Method 'getTemplateData()' must be implemented.");
  }

  isMergeNumbering() {
    throw new Error("Method 'isMergeNumbering()' must be implemented.");
  }

  getHeaderByBinding = (dataArray, bindingName) => {
    for (let item of dataArray) {
      if (item.columns) {
        const column = item.columns.find(item2 => item2.binding == bindingName);
        if (column) {
          return column ? column.header.toLowerCase() : "";
        }
      } else {
        if (item.binding == bindingName) {
          return item ? item.header.toLowerCase() : "";
        }
      }
    }

  }
}
export {BaseClaim};