import { Button } from '@mui/material';
import "assets/styles/pages/SignUpPage.scss";

function CompleteSignUpExternalPage() {
    return (
        <div className='body-text-complete'>
            <div className='content-text'>
                <div>
                    <span className='title-context'>Your Sign up has been requested.</span>
                </div>
                <div>
                    <p>Service access will be available after administrator approval.</p>
                    <p>Administrator approval may take up to 3 business days, and the approval status will be sent to the email you provide. </p>
                    <p>Thank you.</p>
                </div>
                <div className='btn-footer complete-page'>
                    <Button href='/' variant="contained" className='btn-home'>{"Home"}</Button>
                </div>
            </div>
        </div>
    )
}
export default CompleteSignUpExternalPage;