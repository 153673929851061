
import React,{useEffect} from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import {closeMenu, viewMenu, closeAll} from 'reducer/OpenMenus';
import { Button } from '@mui/material';

import 'assets/styles/layout/TabContainer.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const MenuTabList = React.memo(function OpenMenuList({menu, onRemove, onViewChange }){
  
    //console.log("tab : " + menu.Active);

    return (
        <>
            <li className={menu.Active?"bx-tab active on":"bx-tab"}>
                <Button 
                    // className={menu.Active?"btn-nav active on":"btn-nav"}
                    className="btn-nav" 
                    startIcon={<DescriptionOutlinedIcon  />} 
                    onClick={()=> onViewChange(menu)}
                >
                    <span className="btn-text">{menu.menuName}</span>
                </Button>
                <span className="btn-tab-close"><CloseIcon onClick={() => onRemove(menu)} /></span>
            </li>
        </>
      )
}, (prevProps, nextProps) => prevProps.menu === nextProps.menu);

function TabLayer(){
    const menu = useSelector(state=>state.openMenus);
    const openMenu = menu;
    const dispatch = useDispatch();


    useEffect(() => {
        let currentMues = openMenu;
        let viewMenus = currentMues.filter(el => el.Active === true);
        if (viewMenus.length ===0){
            dispatch(viewMenu(currentMues[currentMues.length -1]))
        }
    }, [openMenu, dispatch])
  
    if (!openMenu) return (<div>home</div>);
  
    const onViewChange=(menu)=>{
        dispatch(viewMenu(menu))
        document.getElementById("areaInfoObject").style.display="none";
    }

    const onRemove=(menu)=>{
        dispatch(closeMenu(menu));
        document.getElementById("areaInfoObject").style.display="none";
    }

    const onRemoveAll=()=>{
        document.getElementById("areaInfoObject").style.display="none";
        dispatch(closeAll());
        
    }

    return (
        <>
        {/* <p className="bx-all-close"><Button onClick={onRemoveAll} endIcon={<HighlightOffOutlinedIcon />}>Close All</Button></p>
        <ul className="tab-list-wrap">
            {
                (openMenu!==undefined && openMenu !== null && openMenu.length > 0)? openMenu.map(menu => (
                    menu.menuCode !=="0"?<MenuTabList menu={menu} key={menu.tabUniqueKey} onRemove={onRemove} onViewChange={onViewChange}/>:''
                )):''
            }	
        </ul> */}
      </>
    );
}
export  default TabLayer;