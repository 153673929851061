import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUser, registerUserProceeding, registerUserSuccessful, registerUserFailed } from 'store/auth/register/reducer';

import { Button, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import "assets/styles/pages/SignUpPage.scss";

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import ComDate from "common/components/ComDate";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextBox from "common/components/ComTextBoxWithForm";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";

const LGAccountInforExternalPage = React.forwardRef(
  ({ }
    , ref
  ) => {

    const retrieveCustInfoUrl = "/init/b2buser/retrieveCustInfo";

    const alphaNumericRegex = /^[a-zA-Z0-9 ]*$/;

    const entityBillTo = useRef(null);
    const entityAuthority = useRef(null);

    const entityStoreName = useRef(null);
    const entityStoreAddress = useRef(null);

    const [checkAuthority, setCheckAuthority] = useState("RA");

    const dispatch = useDispatch();
    const AccountProperties = createSelector(
      (state) => state.account,
      (account) => ({
        user: account.user
      })
    );
    const { user } = useSelector(AccountProperties, shallowEqual);

    React.useImperativeHandle(ref, () => ({
      isEmptyRequired: () => {       
        if (!user.paramBillTo) {
          ComNoticfication.ShowAlertUICallback(
            ComUtils._altTitleWarn, "Bill To is required."
            , () => { entityBillTo.current.setFocus(); });
        }
        else if (!user.billToCheck) {
          ComNoticfication.ShowAlertUICallback(
            ComUtils._altTitleWarn, "Please check BillTo by clicking 'Verify' button."
            , () => { entityBillTo.current.setFocus(); });
        } 
        else if (user.billToCheck && user.paramBillTo !== entityBillTo.current.getValue()) {
          user.billToCheck = false;
          dispatch(registerUserProceeding(user));
          ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Please check billTo by clicking 'Verify' button.`);
        }
      },
      getBillTo: () => {        
        return entityBillTo.current?.getValue();
      }
    }));

    const onChangeAuthorityType = (elementId, selectedValue) => {
      setCheckAuthority(selectedValue);
      user.userGroupType = selectedValue;
      dispatch(registerUserProceeding(user));
    }

    const onVerifyBillTo = async () => {

      let tmpBillTo = entityBillTo.current.getValue();
      if (tmpBillTo) {
        if (tmpBillTo.match(alphaNumericRegex)) {
          // if (idRegex.test(value)) {
          const [billToInfo] = await Promise.all([
            ApiManager.post(retrieveCustInfoUrl, { "paramBillTo": tmpBillTo }),
          ]);

          if (billToInfo && billToInfo.length > 0) {

            user.paramBillTo = billToInfo[0].billTo;
            user.textBillTo = billToInfo[0].billTo;
            user.companyName = billToInfo[0].billToName;
            user.companyAddress = billToInfo[0].addr1;
            user.billToCheck = true;
            dispatch(registerUserProceeding(user));

            entityStoreName.current.setValue(billToInfo[0].billToName);
            entityStoreAddress.current.setValue(billToInfo[0].addr1);
            ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, `You can use <b>${tmpBillTo}</b>.`);

          } else {

            user.paramBillTo = "";
            user.textBillTo = "";
            user.companyName = "";
            user.companyAddress = "";
            user.billToCheck = false;
            dispatch(registerUserProceeding(user));

            entityBillTo.current.setValue("");
            entityStoreName.current.setValue("");
            entityStoreAddress.current.setValue("");
            ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, `<b>${tmpBillTo}</b> is not a valid number.Please check again.`);

          }
        } else {
          ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Bill To value is invalid.`);
        }
      } else {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Bill To value is invalid.`);
      }

    }

    const onKeyPress = (e) => {
      // Enter를 눌렀을 때 이벤트
      if (e.key == "Enter") {
        onVerifyBillTo();
      }
    };

    const onBlurBillTo = (id, value) => {
      if (value) {
        if (!value.match(alphaNumericRegex)) {
          ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Bill To value is invalid`);
        } else if (user.billToCheck && user.paramBillTo !== value) {
          user.billToCheck = false;
          dispatch(registerUserProceeding(user));
          ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, `Please check billTo by clicking 'Verify' button.`);
        } else {
          user.paramBillTo = value;
          dispatch(registerUserProceeding(user));
        }
      }
    };

    const _isMounted = useRef(true);
    useEffect(() => {
      let abortController = new AbortController();

      const getPageInfo = async () => {

        let radioDataAuthority = [
          { Value: "RA", Description: "RA Request" },
          { Value: "CAC", Description: "IR Claim / Other Claim" },
          {
            Value: "MC",
            Description:
              "Other general use (Order Tracking, Invoice inquiry, etc...)",
          },
        ];

        entityAuthority.current.setList({
          data: radioDataAuthority,
          defaultValue: user.userGroupType
        });

        entityBillTo.current.setValue(user.paramBillTo);
        entityStoreName.current.setValue(user.companyName);
        entityStoreAddress.current.setValue(user.companyAddress);
      }

      getPageInfo();

      // ComponentWillUnmount in Class Component
      return () => {
        _isMounted.current = false;
        abortController.abort();
      };
    }, [user]);

    return (
      <div className="content-signup">
        <div className="content-text">
          <div className="input-radio-authority">
            <p>
              Which authority do you need? <span className="required">*</span>
            </p>
            <Grid item xs={12} className="radio-btn">
              <ComRadioBox
                ref={entityAuthority}
                elementId={"entityAuthority"}
                radioGroupName={"entityAuthority"}
                direction={1}
                callRelationFuntion={onChangeAuthorityType}
              />
            </Grid>
          </div>
        </div>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="mb-custombtn-flex"
        >
          <Grid>
            <p>
              Dealer Account Number (Bill To) <span className="required">*</span>
            </p>
            <ComTextBox ref={entityBillTo} elementId={"entityBillTo"} callRelationBlur={onBlurBillTo} keyPressFuction={onKeyPress} defaultMaxLength={12} placeholder="ex. US001234001B" />
          </Grid>
          <Grid className="custom-mb-btn-verify">
            <div className="btn-verify">
              <Button variant="contained" onClick={onVerifyBillTo} >{"Verify"}</Button>
            </div>
          </Grid>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid>
            <p>
              Company Name <span className="required">*</span>
            </p>
            <ComTextBox ref={entityStoreName} elementId={"entityStoreName"} defaultDisabled={true} />
          </Grid>
          <Grid>
            <p>
              Company Address <span className="required">*</span>
            </p>
            <ComTextBox ref={entityStoreAddress} elementId={"entityStoreAddress"} defaultDisabled={true} />
          </Grid>
        </Grid>
        <div className="content-text">
          {checkAuthority == "RA" ? (
            <span className="footer-info-page">
              If you have inquiries about your LG Account Number (Bill To), please
              contact <strong><a href="mailto:defective.ra@lge.com">defective.ra@lge.com</a></strong>
            </span>
          ) : checkAuthority == "CAC" ? (
            <div>
              <span className="footer-info-page">
                For lghvac.com inquiries, please contact
                <strong><a href="mailto:christina.kazantzis@lge.com"> christina.kazantzis@lge.com</a></strong>
              </span>
              <br />
              <span className="footer-info-page">
                For IR/SPIFF process registrations, please contact
                <strong><a href="mailto:ir.claim@lge.com"> ir.claim@lge.com</a></strong>
              </span>
            </div>
          ) : (
            <div>
              <span className="footer-info-page">
                For lghvac.com inquiries, please contact
                <strong><a href="mailto:christina.kazantzis@lge.com"> christina.kazantzis@lge.com</a></strong>
              </span>
              <br />
              <span className="footer-info-page">
                For IR/SPIFF process registrations, please contact
                <strong><a href="mailto:ir.claim@lge.com"> ir.claim@lge.com</a></strong>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
);
export default LGAccountInforExternalPage;
