import { Button } from '@mui/material';
import "assets/styles/pages/SignUpPage.scss";

function CompleteSignUpSpiffPage() {
    return (
        <div className='body-text-complete'>
            <div className='content-text'>
                <div>
                    <span className='title-context'>Spiff application has been completed.</span>
                </div>
                <div>
                    <p>LG will review your application and you will receive a notification by email once your Spiff account is approved.</p>
                    <p>Thank you.</p>
                </div>
                <div className='btn-footer complete-page'>
                    <Button href='/' variant="contained" className='btn-home'>{"Home"}</Button>
                </div>
            </div>
        </div>
    )
}
export default CompleteSignUpSpiffPage;