import { useEffect, useRef } from "react";
import * as wjInput from "@grapecity/wijmo.react.input";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Link } from "@mui/material";
import "assets/styles_mobile/components/layout/pages/ResponsiveModal.scss";
import "assets/styles/pages/ModalCommon.scss";

import { ComNoticfication } from "common/components/ComNoticfication";
import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";

import ComTextBox from "common/components/ComTextBox";
import ComPassword from "common/components/ComPassword";

function ShowModalPW({
  callbackFuntion
  , randomKey
  , handleForgotID
  , handleSubmit
  , isCheckCLSD
}) {

  const positionTopValue = useRef(null);
  const popComponent = useRef(null);
  const emailIdRef = useRef(null);
  const userIdRef = useRef(null);

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    // if (isOpen) {
    //   popComponent.current.show(true);
    // }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [randomKey]);

  const onCancleClick = () => {
    popComponent.current.hide();
    callbackFuntion();
  };

  const onKeyPress = (e) => {
    // Enter를 눌렀을 때 이벤트
    if (e.key == "Enter") {
      onSubmitClick();
    }
  };

  const onSubmitClick = async () => {

    if (isCheckCLSD) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "This account has been closed permanently.<br/>Please register again or contact spiff.claim@lge.com if you need additional assistance.");
    } else {

      let tmpId = userIdRef.current.getValue();
      let tmpPw = emailIdRef.current.getValue();

      if (!tmpId) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please enter your Id information");
        return;
      } else if (!tmpPw) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please enter your email information");
        return;
      } else {
        let resultData = await ApiManager.post("/init/retrieveForgotUserPassword", {
          emailId: emailIdRef.current.getValue(),
          userId: userIdRef.current.getValue(),
        });

        if (resultData?.resultCode !== "S") {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData?.resultMsg);
          return;
        }

        popComponent.current.hide();
        callbackFuntion();
        handleSubmit(tmpId);
      }
    }
  };

  const onBackForgotIDClick = () => {
    popComponent.current.hide();
    handleForgotID();
    callbackFuntion();
  };

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true);
    positionTopValue.current = popComponent.current.hostElement.style.top;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable pop-small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ maxWidth: "28.646vw" }}
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title show-fg">{"Forgot Password"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="wj-dialog-input">
        <p>
          Please enter the User ID and email. A temporary password will be sent
          to your email.
        </p>
        <div className="form-ip">
          <div className="form-ip id">
            <p className="lb-title">
              User ID <span className="required">*</span>
            </p>
            <div className="fl-ip">
              <ComTextBox ref={userIdRef} keyPressFuction={onKeyPress} defaultMaxLength={50}></ComTextBox>
              {/* <Link onClick={onBackForgotIDClick}>Forgot User ID?</Link> */}
            </div>
          </div>
          <div className="form-ip mail">
            <p className="lb-title">
              Email <span className="required">*</span>
            </p>
            <ComTextBox ref={emailIdRef} keyPressFuction={onKeyPress} defaultMaxLength={100}></ComTextBox>
          </div>
        </div>
        <div className="btn-dialog">
          <Button variant="contained" onClick={onSubmitClick}>
            {"Submit"}
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={onCancleClick}
          >
            {"Cancel"}
          </Button>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ShowModalPW;
