import { combineReducers } from "redux";

// Authentication
import account from "./auth/register/reducer";

//Menu
import openMenus from './menu/reducer';

const rootReducer = combineReducers({
    // public
     account
    ,openMenus
    
  });
  
  export default rootReducer;