import { useEffect, useRef, useState, useContext } from "react";

import * as wjInput from "@grapecity/wijmo.react.input";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Link } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import "assets/styles_mobile/components/layout/pages/ResponsiveModal.scss";

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import ComTextBox from "common/components/ComTextBox";

import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextArea from "common/components/ComTextArea";

function ShowModalAccUnlockPopup({
  callbackFuntion,
  userSaveInfo,
  isOpen,
  randomKey,
}) {

  const updateAcctUckReqUrl = "/init/b2buser/updateAcctUckReq";

  const positionTopValue = useRef(null);
  const popComponent = useRef(null);

  const entityAdminEmail = useRef(null);
  const entityIsStore = useRef(null);
  const entityIsLgCard = useRef(null);
  const entityIsChangeEmail = useRef(null);
  const entityComment = useRef(null);

  const onChangeIsStore = (elementId, selectedValue) => {

  };

  const onChangeIsLgCard = (elementId, selectedValue) => {

  };

  const onChangeIsChangeEmail = (elementId, selectedValue) => {

  };

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();
    if (isOpen) {
      popComponent.current.show(true);
      entityIsStore.current.setValue("0");
      entityIsLgCard.current.setValue("0");
      entityIsChangeEmail.current.setValue("0");
    }
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [isOpen, randomKey]);

  const onRequestClick = async () => {

    let sendEmailId = entityAdminEmail.current.getValue();

    if (sendEmailId) {

      let resultData = await ApiManager.post(updateAcctUckReqUrl, {
        userId: userSaveInfo?.userId || "",
        userName: userSaveInfo?.userName || "",
        emailId: userSaveInfo?.emailId || "",
        storeName: userSaveInfo?.storeName || "",
        adminEmailId: sendEmailId,
        otherComment: entityComment.current.getValue(),
        isStoreCheck: entityIsStore.current.getValue(),
        isLgCardCheck: entityIsLgCard.current.getValue(),
        isCheckEmail: entityIsChangeEmail.current.getValue()
      });

      if (resultData?.resultCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "System Error");
      } else if (resultData === 1) {
        // ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Saved Successfully");
        popComponent.current.hide();
        callbackFuntion(true);
      } else {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Process failed");
      }

    } else {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleWarn, "Email is required");
    }

  };

  const onCancleClick = () => {
    popComponent.current.hide();
    callbackFuntion(false);
  };

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    // popComponent.current.show(true);
    positionTopValue.current = popComponent.current.hostElement.style.top;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable pop-small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ maxWidth: "28.646vw", height: "auto" }}
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title show-fg">
          {"Your account has been locked due to 3 months of inactivity. "}
        </h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="wj-dialog-input">
        <div className="form-ip">
          <p>
            To continue using the account, administrator approval is required.
          </p>
          <p>
            Please request account unlock authorization from the administrator.
          </p>
        </div>
        <div className="info-account">
          <div className="info-item">
            {/* <span className="key-question"> */}
            {/* Email address: {userSaveInfo?.emailId || ""} */}
            Email address: <ComTextBox ref={entityAdminEmail} defaultMaxLength={100}/>
            {/* </span> */}
          </div>
          <div className="info-item">
            <span className="key-question">
              Do you still work at
              {` <${userSaveInfo?.storeName || ""}>`}?
            </span>
            <div className="d-flex">
              <ComRadioBox
                ref={entityIsStore}
                elementId={"entityIsStore"}
                radioGroupName={"entityIsStore"}
                direction={1}
                callRelationFuntion={onChangeIsStore}
                radioList={[
                  {
                    Value: "1",
                    Description: "Yes",
                  },
                  {
                    Value: "0",
                    Description: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div className="info-item">
            <span className="key-question">
              Do you still have a valid LG debit card?
            </span>
            <div className="d-flex">
              <ComRadioBox
                ref={entityIsLgCard}
                elementId={"entityIsLgCard"}
                radioGroupName={"entityIsLgCard"}
                direction={1}
                callRelationFuntion={onChangeIsLgCard}
                radioList={[
                  {
                    Value: "1",
                    Description: "Yes",
                  },
                  {
                    Value: "0",
                    Description: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div className="info-item">
            <span className="key-question">
              Has your mailing address changed
            </span>
            <div className="d-flex">
              <ComRadioBox
                ref={entityIsChangeEmail}
                elementId={"entityIsChangeEmail"}
                radioGroupName={"entityIsChangeEmail"}
                direction={1}
                callRelationFuntion={onChangeIsChangeEmail}
                radioList={[
                  {
                    Value: "1",
                    Description: "Yes",
                  },
                  {
                    Value: "0",
                    Description: "No",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div>
          <ComTextArea
            defaultStyle={{
              resize: "vertical",
              minHeight: "100px",
              minWidth: "510px"
            }}
            ref={entityComment}
            elementId={"entityComment"}
            defaultMaxLength={300}
            placeholder="Other comments:"
          ></ComTextArea>
        </div>
        <div className="btn-dialog">
          <Button variant="contained" onClick={onRequestClick}>
            {"Request"}
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={onCancleClick}
          >
            {"Cancel"}
          </Button>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ShowModalAccUnlockPopup;
