import * as wijmo from "@grapecity/wijmo";
import { InputNumber, InputDate } from "@grapecity/wijmo.input";
import { createElement, format } from "@grapecity/wijmo";
import { BaseClaim } from "./BaseClaim";
import search from "assets/icons/search.svg";

class ClaimSellOut extends BaseClaim {
  constructor(props) {
    super(props);    
  }

  getGirdFormat = () => {
    let out = [
      { 
        binding: 'isChecked2', 
        header: " ", 
        width: 30, 
        allowDragging: false, 
        isReadOnly: false, 
        allowResizing: false, 
        allowSorting: false ,
        visible: this.pageType !== 'Dtl',
      },
      {
        binding: "promotionNo",
        header: "LG Promotion#",
        width: 200,
        allowDragging: true,
        isRequired: true,
      },
      {
        binding: "modelCode",
        header: "LG Model",
        width: 200,
        allowDragging: true,
        isRequired: true,
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openModelCodePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "RequestOrg",
        header: "Org. Request",
        width: 150,
        allowDragging: true,
        visible: !this.orgRequestHidden,
        columns: [
          {
            binding: "orgRequestQty",
            header: "Qty",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
            aggregate: "Sum",
            align: "right",
            visible: !this.orgRequestHidden,
          },
          { 
            binding: "orgRequestPrice", 
            header: "Price", 
            width: 100, 
            allowDragging: true, 
            isReadOnly: true,
            format: "n2", 
            align: "right", 
            visible: !this.orgRequestHidden,
          },
          { 
            binding: "orgRequestAmt",
            header: "Amt", 
            width: 100, 
            allowDragging: true, 
            isReadOnly: true,
            align: "right", 
            format: "n2", 
            aggregate: "Sum", 
            visible: !this.orgRequestHidden,
          },
        ],
      },
      {
        binding: "request",
        header: "Request",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "requestQty",
            header: "Qty",
            width: 100,
            allowDragging: true,
            isRequired: true,
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
            aggregate: 'Sum',
          },
          {
            binding: "requestPrice",
            header: "Price",
            width: 100,
            allowDragging: true,
            isRequired: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
          {
            binding: "requestAmt",
            header: "Amt",
            width: 100,
            allowDragging: true,
            isRequired: true,
            isReadOnly: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
            aggregate: 'Sum',
          },
        ],
      },
      {
        binding: "rejectCode",
        header: "Rejection Reason",
        width: 180,
        allowDragging: true,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
        dataMap: this.rejectYn === 'Y' ? this.rejectionReasonMap : undefined,
      },
      {
        binding: "rejectDesc",
        header: "Rejection Comment",
        width: 160,
        allowDragging: true,
        isRequired: false,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
      },
      {
        binding: "activitySalesClaimDate",
        header: "Activity Date",
        width: 130,
        allowDragging: true,
        isRequired: true,
        align: "center",
        format: 'MM/dd/yyyy',
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          if (value) {
            value = wijmo.Globalize.format(new Date(value), 'MM/dd/yyyy');
            return value;
          } else {
            return '';
          }
        },
        editor: new InputDate(document.createElement("div"), {
          format: "MM/dd/yyyy",
          mask: "##/##/####",
          isRequired: false,
          invalidInput: (s, e) => {
            if (s.text === "__/__/____") {
              s.text = "";
              s.value = null;
            }
          },
        })
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openChargePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        align: "right",
        format: "n2",
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
      },
      {
        binding: "pgmReasonCode",
        header: "PGM Reason Code",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "lgPgmNo",
        header: "LG Program No",
        width: 150,
        allowDragging: true,
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;
          if (this.claimAnalystYn !== 'Y') {
            return ctx.value;
          }

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;    
          let callFunc = this.openLgProgramPopup;      

          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgPgmName",
        header: "LG Program Name",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "pgmBalance",
        header: "PGM Balance",
        width: 150,
        allowDragging: false,
        isReadOnly: true,
        format: "n2",
        visible: this.pageType !== 'Dtl'
      },
      {
        binding: "netSellInQty",
        header: "Net Sell-in Quantity",
        width: 150,
        allowDragging: false,
        isReadOnly: true,
        align: "right",
        visible: this.userType === 'E',
      },
      {
        binding: "netIrClaimQty",
        header: "Net IR Claim Quantity",
        width: 150,
        allowDragging: false,
        isReadOnly: true,
        align: "right",
        visible: this.userType === 'E',
      },
      {
        binding: "netOtherClaimQty",
        header: "Net Other Claim Quantity",
        width: 150,
        allowDragging: false,
        isReadOnly: true,
        align: "right",
        visible: this.userType === 'E',
      },
      {
        binding: "netAllowedQty",
        header: "Net Claim Allowed Quantity",
        width: 150,
        allowDragging: false,
        isReadOnly: true,
        align: "right",
        format: "n0",
        visible: this.userType === 'E',
      },
      {
        binding: "orgPgmNo",
        header: this.pageType === 'Dtl' ? "Org. Program No" : "Transfer From Program No",
        width: 200,
        allowDragging: true,
        visible: this.claimAnalystYn === 'Y' || this.pageType === 'Dtl',
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openOrgProgramPopup;

          if (this.isDisabledorgPgmNo) {
            return ctx.value;
          }
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

            
          let buttonElement = element.querySelector("img.grid_btn_find");

          buttonElement.onclick = (e) => {            
            e.preventDefault();
            callFunc.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "orgBillTo",
        header: this.pageType === 'Dtl' ? "Org. Bill To" : "Transfer From Bill To",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
        isRequired: false,
        visible: this.claimAnalystYn === 'Y' || this.pageType === 'Dtl',
      },
      {
        binding: "customerDesc",
        header: "Customer Description",
        width: "*",
        minWidth: 250,
        allowDragging: true,
        isRequired: false,
      },
      {
        binding: "disputeFlag",
        header: "Dispute",
        width: 150,
        allowDragging: true,
        dataMap: this.disputeMap,
        visible: this.pageType !== 'Dtl',
      },
      {
        binding: "approved",
        header: "Approved",
        width: 150,
        allowDragging: true,
        visible: this.pageType === 'Dtl',
        columns: [
          {
            binding: "approvedQty",
            header: "Qty",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
            aggregate: 'Sum',
            visible: this.pageType === 'Dtl',
          },
          {
            binding: "approvedPrice",
            header: "Price",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            align: "right",
            format: "n2",
            visible: this.pageType === 'Dtl',
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
          {
            binding: "approvedAmt",
            header: "Amt",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            isReadOnly: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
            aggregate: 'Sum',
            visible: this.pageType === 'Dtl',
          },
        ],
      },
    ];

    return out;
  }

  setFormatItem = (s, e) => {
    if (s.columns[e.col]?.binding === "promotionNo") {
      s.columnFooters.setCellData(0, e.col, "Total", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  }

  getData = () => {
    return [{
      isChecked2: false,
      promotionNo: "",
      modelCode: "",
      requestQty: 0,
      requestPrice: 0,
      requestAmt: 0,
      activitySalesClaimDate: null,
      lgChargebackNo: "",
      lgChargebackAmt: null,
      pgmReasonCode: "",
      lgPgmNo: "",
      lgPgmName: "",
      pgmBalance: null,
      netSellInQty: null,
      netIrClaimQty: null,
      netOtherClaimQty: null,
      netAllowedQty: null,
      orgPgmNo: "",
      orgBillTo: "",
      customerDesc: "",
      disputeFlag: ""
    }]
  }

  getTemplateFileName = () => {
    return "Sell-Out";
  }

  getTemplateGirdFormat = () => {
    return [
      {
        binding: "promotionNo",
        header: "*LG Promotion#",
        width: 200,
        allowDragging: true,
        isRequired: true,
      },
      {
        binding: "modelCode",
        header: "*LG Model",
        width: 150,
        allowDragging: true,
        isRequired: true,
      },
      {
        binding: "request",
        header: "Request",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "requestQty",
            header: "*Qty",
            width: 150,
            allowDragging: true,
            isRequired: true,
            align: "right",
          },
          {
            binding: "requestPrice",
            header: "*Price",
            width: 150,
            allowDragging: true,
            isRequired: true,
            align: "right",
          },
          {
            binding: "requestAmt",
            header: "*Amt",
            width: 150,
            allowDragging: true,
            isRequired: true,
            isReadOnly: true,
            align: "right",
          },
        ],
      },
      {
        binding: "activitySalesClaimDate",
        header: "Activity Date",
        width: 150,
        allowDragging: true,
        isRequired: true,
        align: "center",
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 200,
        allowDragging: true,
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 200,
        allowDragging: true,
        align: "right",
      },
      {
        binding: "lgPgmNo",
        header: "LG Program No",
        width: 200,
        allowDragging: true,
      },
      {
        binding: "lgPgmName",
        header: "LG Program Name",
        width: 200,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "customerDesc",
        header: "Customer Description",
        width: 250,
        allowDragging: true,
      },
    ];
  }

  getTemplateData = () => {
    return [{
      promotionNo: "input LG Promotion No",
      modelCode: "input Model",
      requestQty: "1",
      requestPrice: "1",
      requestAmt: "1",
      activitySalesClaimDate: "12/31/2015",
      lgChargebackNo: "input Chargeback No",
      lgChargebackAmt: "1",
      lgPgmNo: "input LG Program No",
      lgPgmName: "input LG Program Name",
      customerDesc: "input Customer Description",
    }]
  }

  convertTemplateToGridData = (el, idx) => {
    let gridFormat = this.getTemplateGirdFormat();

    return {
      rowNum: idx,
      promotionNo: el[this.getHeaderByBinding(gridFormat, "promotionNo")],
      modelCode: el[this.getHeaderByBinding(gridFormat, "modelCode")],
      requestQty: el[this.getHeaderByBinding(gridFormat, "requestQty")],
      requestPrice: el[this.getHeaderByBinding(gridFormat, "requestPrice")],
      requestAmt: el[this.getHeaderByBinding(gridFormat, "requestAmt")],
      activitySalesClaimDate: el[this.getHeaderByBinding(gridFormat, "activitySalesClaimDate")],
      lgChargebackNo: el[this.getHeaderByBinding(gridFormat, "lgChargebackNo")],
      lgChargebackAmt: el[this.getHeaderByBinding(gridFormat, "lgChargebackAmt")],
      lgPgmNo: el[this.getHeaderByBinding(gridFormat, "lgPgmNo")],
      lgPgmName: el[this.getHeaderByBinding(gridFormat, "lgPgmName")],
      customerDesc: el[this.getHeaderByBinding(gridFormat, "customerDesc")],
    }
  }

  isMergeNumbering() {
    return true;
  }
}
export default ClaimSellOut;