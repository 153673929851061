import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";

import Divider from "@mui/material/Divider";

import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";
import { ComUtils } from "common/lib/ComUtils";
import { CollectionView } from "@grapecity/wijmo";
import * as wjCore from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { MessageContext } from "common/lib/MessageProvider";
import close from "assets/icons/close.svg";
import ComCheckBox from 'common/components/ComCheckBox';
import FileSaver from 'file-saver';


function ESNExcelUploadPop({ open, closeFn, claimType, billToCode, systemEntryNo, divisionCode, openClaimView, randomKey, pageInfo }) {

  //session정보
  const userId = sessionStorage.getItem("userId").toString();
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userLevel = pageInfo.userLevel;

  const positionTopValue = useRef();
  const popComponent = useRef();
  const systemEntryNoUpload = useRef('');

  const gridObject = useRef();
  const filterObject = useRef(null);
  const codeFilterObject = useRef(null);
  const templateGrid = useRef(null);
  const entityChkSerial = useRef(null);

  const [chkSerialNum, setChkSerialNum] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const [templateGridData, setTemplateGridData] = useState(null);
  const [templateGridColGroup, setTemplateGridColGroup] = useState(null);
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //엑셀파일 업로드
  const entityUploadExcelFileNm = useRef(null);
  const excelUploadFileElement = useRef(null);
  const [fileNameVal, setFileNameVal] = useState("Choose an Excel file...");
  const [excelData, setExcelData] = useState(null);

  const filePrompt = useRef("Choose an Excel file...");

  //url
  const retrieveESNUploadResultListUrl = "/clm/oth/otherRequest/retrieveESNUploadResultList"
  const retrieveESNUploadResultExcelListUrl = "/clm/oth/otherRequest/retrieveESNUploadResultExcelList"

  const { messageObject } = useContext(MessageContext);
  const msgGsrErr121 = ComUtils.getMessage(messageObject, "GSR_ERR_00121");    //Please check the upload result.
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028");    //Submit Error. Please Contact System Admin.
  const msgSmsgComInf017 = ComUtils.getMessage(messageObject, "SMSG_COM_INF_017");    //Processed successfully.

  const fileExtMatch = (fileName) => {

    if (fileName) {
      let fname = fileName.split(".");
      if (fname.length > 0) {
        let extName = fname[fname.length - 1];
        switch (extName.toUpperCase()) {
          case "TXT":
            return "file-name txt";
            break;
          case "DOC":
          case "DOCX":
            return "file-name doc";
            break;
          case "XLS":
          case "XLSX":
          case "XLSB":
            return "file-name excel"
            break;
            break;
          case "PPT":
          case "PPTX":
            return "file-name ppt"
            break;
          case "PDF":
            return "file-name pdf"
            break;
          case "GIF":
            return "file-name gif"
            break;
          case "JPEG":
          case "BMP":
          case "JPG":
          case "PNG":
            return "file-name img"
            break;
          case "ZIP":
            return "file-name zip"
            break;
          default:
            return "file-name"
        }
      }
    }

    return "file-name"
  }

  const initTemplateGrid = (sender) => {
    templateGrid.current = sender;
  };

  const initTemplateGridFormat = () => {
    let gridFormat = chkSerialNum ?
      [
        { binding: "serialNoEsn", header: "Serial_No_ESN", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "modelCode", header: "Model", width: 100, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "invoiceNo", header: "Invoice_No", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "dateSold", header: "Date_Sold", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dealerName", header: "Dealer_Name", width: 250, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "endUserName", header: "End_User_Name", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
      ] :
      [
        { binding: "modelCode", header: "Model", width: 100, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "serialNoEsn", header: "Serial_No_ESN", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "invoiceNo", header: "Invoice_No", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "qty", header: "Qty", width: 50, allowDragging: true, isReadOnly: true, align: "right" },
        { binding: "dateSold", header: "Date_Sold", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dateSoldFrom", header: "Date_Sold_From", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dateSoldTo", header: "Date_Sold_To", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dealerName", header: "Dealer_Name", width: 250, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "quotationNo", header: "Quotation_No", width: 200, allowDragging: true, isReadOnly: true, align: "left" },
      ];
    setTemplateGridColGroup([...gridFormat]);
  };

  const initTemplateGridBindData = useCallback(() => {
    let resultData = chkSerialNum ?
      [
        {
          serialNoEsn: "input Serial No or ESN",
          modelCode: "input Model",
          invoiceNo: "Invoice No",
          dateSold: "20200501",
          dealerName: "Best Buy",
          endUserName: "",
        },
      ] :
      [
        {
          modelCode: "input Model",
          serialNoEsn: "input Serial No or ESN",
          invoiceNo: "Invoice No",
          qty: "1",
          dateSold: "20200501",
          dateSoldFrom: "",
          dateSoldTo: "",
          dealerName: "Best Buy",
          quotationNo: "",
        },
        {
          modelCode: "",
          serialNoEsn: "",
          invoiceNo: "",
          qty: "1",
          dateSold: "",
          dateSoldFrom: "20200501",
          dateSoldTo: "20200531",
          dealerName: "",
          quotationNo: "",
        },
      ];
    let view = new CollectionView(resultData, {
      trackChanges: true,
      refreshOnEdit: false,
    });

    setTemplateGridData(view);
  });

  const initFilter = (f) => {
    filterObject.current = f;
  };

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
    });


    sender.formatItem.addHandler((s, e) => {
      if (e.panel !== s.columnHeaders) {
        let binding = e.getColumn(e.col).binding;
        if (binding === "uploadResult") {
          let itemInfo = e.getRow();
          let innerHTML = ComUtils.UnEscapeHtml(e.cell.innerHTML);

          if (innerHTML) {
            e.cell.innerHTML = innerHTML.replace(/<a>(.*?)<\/a>/g, '<span class="grid_btn_find" style="color: blue; cursor: pointer;">$1</span>');
            e.cell.querySelectorAll("span.grid_btn_find")?.forEach(buttonElement => {
              buttonElement.onclick = function (e) {
                e.preventDefault();

                openClaimView?.(e.target.textContent);
              };
            });                         
          }
        }
      }
    });

  }


  const initGridFormat = () => {
    let gridFormat = chkSerialNum ?
      [
        { binding: "serialNoEsn", header: "Serial No", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "model", header: "Model", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "invoiceNo", header: "Invoice_No", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dateSold", header: "Date Sold", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dealerName", header: "Dealer Name", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "endUser", header: "End User Name", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "billTo", header: "Bill To", width: 120, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "billToName", header: "Bill To Name", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "modelResult", header: "Model", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "productLevel1", header: "Product Level 1", width: 100, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "lgInvoiceNo", header: "LG Invoice#", width: 150, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "lgInvoiceDate", header: "LG Invoice Date", width: 110, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "uploadResult", header: "Remark", width: 200, allowDragging: true, isReadOnly: true, align: "left", multiLine: true, wordWrap: true },
        { binding: "endUserCnt", header: "End User Name Count", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
      ] :
      [
        { binding: "model", header: "Model", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "qty", header: "Qty.", width: 80, allowDragging: true, isReadOnly: true, align: "right" },
        { binding: "dateSold", header: "Date Sold", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dateSoldFrom", header: "Date Sold From", width: 110, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "dateSoldTo", header: "Date Sold To", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "invoiceNo", header: "Invoice No", width: 100, allowDragging: true, isReadOnly: true, align: "center" },
        { binding: "serialNoEsn", header: "Serial No/IMEM(ESN)", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "quotationNo", header: "Quotation No.", width: 100, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "dealerName", header: "Dealer Name", width: 150, allowDragging: true, isReadOnly: true, align: "left" },
        { binding: "uploadResult", header: "Status", width: 250, allowDragging: true, isReadOnly: true, multiLine: true, wordWrap: true },
      ];
    setGridColGroup([...gridFormat]);
  };


  //download template
  const onDownTemplate = () => {
    let templateName = chkSerialNum ? "sample_claim.SerialNoValidation" : "sample_claim.Validation";
    initTemplateGridFormat();
    initTemplateGridBindData();
    ApiManager.ExcelDownloadSplitFile(templateGrid.current, templateName, null, templateDownComplete);
  }

  const templateDownComplete = () => {
    initTemplateGridFormat();
  }

  //엑셀파일 선택
  const onExcelUpload = () => {
    entityUploadExcelFileNm?.current?.setValue(filePrompt.current);
    setFileNameVal(filePrompt.current);
    excelUploadFileElement.current.click();
  }

  const handleExcelUploadChange = async (event) => {
    if (event.target.files.length > 0) {
      let chkFlow = true;
      let fileName = event.target.files[0].name;
      let fname = fileExtMatch(fileName);
      if (fname.length > 0) {
        if (fname !== "file-name excel") {
          chkFlow = false;
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Program attach only Excel file");
        }
      }
      if (fileName.trim().indexOf("'") > -1) {
        chkFlow = false;
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "File name shouldn't have '(apostrophe).");

      }

      if (chkFlow) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        try {
          let resultData = await ApiManager.post("/Common/ExcelUtils/ExcelUpload", formData);
          if (resultData.length !== 0) {
            setExcelData(resultData);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        } finally {
          if (fileName !== null && fileName !== "") {
            entityUploadExcelFileNm?.current?.setValue(fileName);
            setFileNameVal(fileName);
          }
        }
      }
      excelUploadFileElement.current.value = "";
    }

  };

  const onUpload = async () => {

    if (fileNameVal === "" || fileNameVal === filePrompt.current) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Choose a Excel file.");
      return;
    }


    let sendParam = {
      data: excelData
      , systemEntryNo: systemEntryNo
      , billToCode: billToCode
      , claimType: claimType ?? ""
      , divisionCode: divisionCode ?? ""
      , createId: userId
      , isChecked: chkSerialNum
    }

    let resultData = await ApiManager.post(retrieveESNUploadResultListUrl, sendParam);


    if (resultData?.resultCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Submit Error. Please Contact System Admin.");
    } else {
      // init file upload
      entityUploadExcelFileNm?.current?.setValue(null);
      setFileNameVal(null);

      if (resultData?.list?.length > 0) {
        // set grid data
        initGridFormat();
        setGridData(
          new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );
        setGridDataCount(resultData.list.length);

        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Please check the upload result.",
          () => {
            setIsUpload(true);
            setTimeout(() => {
              gridObject.current?.collectionView?.refresh();
            }, 1000);
          }
        );
      } else {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, "Processed successfully.");
        setIsUpload(false);
      }

      systemEntryNoUpload.current = systemEntryNo;
    }

  }

  const onView = async () => {
    //fncViewResult
    let excelSendParam = {
      systemEntryNo: systemEntryNo
    }

    let resultData = await ApiManager.callExcelDowonload(retrieveESNUploadResultExcelListUrl, excelSendParam);
    let disposition = resultData?.headers['content-disposition'];

    let filename = 'Validation_Upload_Result_List_' + systemEntryNo + '.csv';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    if (filename) {
      try {
        FileSaver.saveAs(new Blob([resultData.data], { type: resultData.headers['content-type'] }), decodeURI(filename));
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  //Memory
  const _isMounted = useRef(true);

  // 데이터 로딩 초기화
  useEffect(async () => {

    let abortController = new AbortController();

    const getPageInfo = async () => {
      initTemplateGrid();
      initTemplateGridFormat();
      initTemplateGridBindData();
      initGridFormat();
      positionTopValue.current = popComponent?.current?.hostElement?.style?.top;

    }
    getPageInfo();
    if (open) {
      filterObject?.current?.clear();
      codeFilterObject?.current?.clear();
    }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [open]);


  const onCancleClick = () => {
    popComponent.current.hide();
    setGridColGroup(null);
    closeFn(systemEntryNoUpload.current);
  };


  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  };


  // 체크박스 이벤트
  const onChangeCheckBox = (elementId, value) => {
    setChkSerialNum(value);
    if (!isUpload) {
      initGridFormat();
    }
  };

  return (
    <wjInput.Popup
      className="pop-resizable pop-small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ width: "1100px", height: "470px" }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Validation Upload"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="mt-10 " style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Button variant="outlined" className="btn-default" onClick={onDownTemplate}>
            {"Excel Form Download"}
          </Button>
          <div>
            <ComCheckBox
              ref={entityChkSerial}
              elementId={"entityChkSerial"}
              callRelationFuntion={onChangeCheckBox}
            />
            <span style={{ marginLeft: "3px" }}>
              {"Serial Number Validation"}
            </span>
          </div>
        </div>
        <div className="mt-10 d-flex-between">
          <div className="d-flex" style={{ width: '100%', marginTop: "5px" }}>
            <Button variant="outlined" className="btn-save" onClick={onExcelUpload} style={{ minWidth: '100px' }}>
              {"Find File"}
            </Button>
            <input type="file" onChange={handleExcelUploadChange} ref={excelUploadFileElement} style={{ display: 'none' }} /*multiple*/ />
            <ComTextBox ref={entityUploadExcelFileNm} elementId={"entityUploadExcelFileNm"}
              defaultValue={fileNameVal}
              defaultDisabled={true}
              defaultStyle={{ border: 'none', backgroundColor: 'white' }}
            />
          </div>
          <div className="d-flex">
            {/* <span style={{ color: "red", fontWeight: "bolder" }}>*</span> */}
            <div className="ml-5" style={{ display: "flex" }}>
              <Button variant="outlined" className="btn-default" onClick={onUpload}>
                {"Upload"}
              </Button>
              <Button variant="outlined" className="btn-default" style={{ marginLeft: "5px" }} onClick={onView}>
                {"View"}
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex-start">
          <div className="w-50">
            {//template - 엑셀다운로드 그리드
              <FlexGrid
                columnGroups={templateGridColGroup}
                itemsSource={templateGridData}
                initialized={initTemplateGrid}
                autoRowHeights={true}
                style={{ minheight: "200px", height: "300px", display: "none" }}
              >
              </FlexGrid>
            }
          </div>
        </div>
        {isUpload ?
          <>
            <div style={{ paddingTop: "10px" }}>Total: {gridDataCount}</div>
            <div className="bx-table mt-10">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                autoRowHeights={true}
                style={{ minheight: "250px", height: "300px" }}
              >
                <FlexGridFilter initialized={initFilter} />
              </FlexGrid>
            </div>
          </>
          :
          <>
            <div className="mt-10">
              {"1) "}
              <span className="field-required fwb">
                {" [Excel Form Download] "}
              </span>
              Please Sample DownLoad and click. and write to the downloaded file.
            </div>
            <div className="mt-10">
              {"2) "}
              Save the file must be saved in
              <span className="field-required fwb">{" [Excel Sheet] "}</span>
              format you can be uploaded.
            </div>
            <div className="mt-10">
              {"3) "}
              <span className="field-required fwb">{" [Caution] "}</span>
              The xls file you want to upload is not uploaded if it is open. Please retry after closing the xls file is being used.
            </div>
            <div className="mt-10">
              {"4) "}
              <span className="field-required fwb">{" [Caution] "}</span>
              If the file is open 'DATAFORMATERROR.' message is output.
            </div>
          </>
        }
      </div>
    </wjInput.Popup>
  );
}

export default ESNExcelUploadPop;
