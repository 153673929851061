import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUserInit } from "store/auth/register/reducer";

import { Divider } from "@mui/material";
import "assets/styles/pages/SignUpPage.scss";
import "assets/styles_mobile/components/layout/pages/ResponsiveSignUp.scss";

import HeaderSignUp from "./HeaderSignUp";
import FooterLogin from "Layout/FooterLogin";

import TermsAndConditionsPage from "./TermsAndConditionsPage";
import SignupExternalUserPage from "./SignUpExternalUserPage";

function SignUpPage() {
  const dispatch = useDispatch();
  const AccountProperties = createSelector(
    (state) => state.account,
    (account) => ({
      proceeding: account.proceeding,
      success: account.success,
    })
  );
  let { proceeding, success } = useSelector(AccountProperties, shallowEqual);

  let defaultType = false;
  const { state } = useLocation();
  if (state) defaultType = state.type;

  const [isOpenExternal, setIsOpenExternal] = useState(defaultType);

  const _isMounted = useRef(true);

  useEffect(() => {
    let abortController = new AbortController();

    const onPageLoaded = async () => {};
    onPageLoaded();

    if (proceeding || success) {
      //리덕스 개인 정보 초기화
      dispatch(registerUserInit());
    }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);

  return (
    <div className="sign-up-page">
      <HeaderSignUp />
      <Divider />
      {!isOpenExternal ? (
        <TermsAndConditionsPage
          openSignUpExternal={() => setIsOpenExternal(true)}
        />
      ) : (
        <SignupExternalUserPage
          closeSignUpExternal={() => setIsOpenExternal(false)}
        />
      )}
      <FooterLogin />
    </div>
  );
}
export default SignUpPage;
