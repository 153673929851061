import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import ComTextBox from 'common/components/ComTextBox';
import ComComboMultiAll from 'common/components/ComComboMultiAll';
import ComDate from 'common/components/ComDate';
import Divider from "@mui/material/Divider";

import * as wijmo from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView, createElement, format, addClass, removeClass, Tooltip, PopupPosition, toggleClass } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';

function LgOrgProgramSchPop({ open, closeFn, randomKey, reqBillTo, reqLgPgmNo, reqClaimType, reqModelCode, reqDivisionCode, reqRequestAmt, resData, pageInfo }) {
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const retrieveProgramList = "/com/comPop/retrieveProgramList";
  const retrieveReasonCodeList = "/com/comCode/retrieveReasonCodeList";

  const currentPgmNo = useRef(null);
  const displayCheck = useRef(false);
  const [reasonCodeList, setReasonCodeList] = useState([]);

  const entityProgramNo = useRef(null);
  const entityStartDate = useRef(null);
  const entityEndDate = useRef(null);
  const entityProgramName = useRef(null);
  const entityReasonCode = useRef(null);
  const entityTransferAmt = useRef(null);

  const filterSearch = useRef(null);

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();

  const convertUpperCase = (el, value) => {
    if (value && el.current) {
      el.current.setValue(value.toUpperCase());
    }
  };  

  const onAllChecked = (status) => {
    let tmpChecked = status.target.checked;
    if ((gridObject?.current?.collectionView?.items?.length ?? 0) > 0) {  

      let checkCountList = gridObject.current.collectionView.items.filter(e => e.isChecked2 === true);
      if (checkCountList.length === gridObject.current.collectionView.items.length) {
        tmpChecked = false;
      } else {
        tmpChecked = true;
      } 

      gridObject.current.collectionView.items.forEach((el) => {
        if (tmpChecked) {
          el.isChecked2 = true;
        } else {
          el.isChecked2 = false;
        }
      }); 

      gridObject.current.collectionView.commitEdit();
      gridObject.current.collectionView.refresh();
    } else {
      status.target.checked = false;
      tmpChecked = false;
    } 

    displayCheck.current = tmpChecked;   
  }

  const calculateTransferAmt = () => {
    let rows = gridObject.current.collectionView.items.filter(e => e.isChecked2 === true);
    let pgmBalanceSum = 0;

    let checkedCust = "";
    let firstCust = "";
		for (let i=0; i<rows.length; i++) {
			pgmBalanceSum = pgmBalanceSum + Number(rows[i].pgmBalance);
			checkedCust = checkedCust + rows[i].custCode + ",";
			if (i == 0) {
				firstCust = rows[i].custCode;
			}
		}

    let requestAmt = Number(entityTransferAmt.current.getValue()?.replaceAll(",", "") || "0");
    var transferAmtSum = 0;
    var firstCustRow = 0;
    rows = gridObject.current.collectionView.items;

    for (let i=0; i<rows.length; i++) {			
			//first checked customer
			if (firstCust == rows[i].custCode) {
				firstCustRow = i;
			}
			
			if (checkedCust.indexOf(rows[i].custCode) > -1 && pgmBalanceSum > 0) {
				if (Number(rows[i].pgmBalance) > 0) {
					let transferAmt = requestAmt * Number(rows[i].pgmBalance) / pgmBalanceSum; 

          transferAmt = transferAmt + "";
					if (transferAmt.indexOf(".") > -1) {
						transferAmt = transferAmt.substring(0,transferAmt.indexOf("."));  
					}  
          rows[i].transferAmt = Number(transferAmt);

          transferAmtSum = transferAmtSum + Number(transferAmt);
				} else {
          rows[i].transferAmt = "";		
				} 
			} else {
        rows[i].transferAmt = "";
			}
		}
		
		if (requestAmt != transferAmtSum && pgmBalanceSum > 0) {
			let transferAmt = Number(rows[firstCustRow].transferAmt);
			transferAmt = transferAmt + requestAmt - transferAmtSum;

      rows[firstCustRow].transferAmt = transferAmt;
		}

    gridObject.current.collectionView.refresh();
  }

  /***** Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {
    gridObject.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      canAreaInfo: false,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });


    // sender.addEventListener(sender.hostElement, "dblclick", (e) => {
    //   let ht = sender.hitTest(e);

    //   if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
    //   }
    // });

    sender.cellEditEnded.addHandler((s, e) => {
      let binding = e.getColumn().binding;

      if (binding === "isChecked2") {
        let checkNoneList = sender.collectionView.items.filter(e => e.isChecked2 === false);
        if (checkNoneList.length === sender.collectionView.items.length) {
          displayCheck.current = false;
        }

        let checkAllList = sender.collectionView.items.filter(e => e.isChecked2 === true);
        if (checkAllList.length === sender.collectionView.items.length) {
          displayCheck.current = true;
        } else {
          displayCheck.current = false;
        }        

        calculateTransferAmt();
        sender.refresh();
      }
    });

    sender.formatItem.addHandler((s, e) => {
      if (e.panel === s.columnHeaders && e.getColumn(e.col).binding === "isChecked2") {
        e.cell?.firstChild?.remove();
        let element = null;
        if (displayCheck.current) {
          element = createElement(format(
            `<div class="wj-align-center" tabindex="-1" aria-colindex="1" aria-selected="false" style="left: 0px; top: 0px; width: 43px; height: 15px; z-index: 0;"><label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true></label></div>`, {}), e.cell);
        }
        else {
          element = createElement(format(
            `<div class="wj-align-center" tabindex="-1" aria-colindex="1" aria-selected="false" style="left: 0px; top: 0px; width: 43px; height: 15px; z-index: 0;"><label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1"></lable></div>`, {}), e.cell);
        }
        element.onchange = function (e) {
          e.preventDefault();
          onAllChecked.apply(e, [e]);
          calculateTransferAmt();
        }
      } 
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

  }

  const onSelectionChanged = (sender) => {

  }

  const onSourceChanged = () => {
    if (gridObject.current) {
      gridObject.current.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory 
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: "isChecked2", header: " ", width: 30, allowDragging: false, isReadOnly: false, allowResizing: false, allowSorting: false, align: "center" }
      , { binding: 'custCode', header: "Customer", width: 100,  allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'custName', header: "Customer Name", width: 220, allowDragging: true, isReadOnly: true }
      , { binding: 'pgmAccrual', header: "Accrual Amt.", width: 100, allowDragging: true, isReadOnly: true, align: "right", format: "n2" }
      , { binding: 'pgmAdjust', header: "Adjust Amt.", width: 100, allowDragging: true, isReadOnly: true, align: "right", format: "n2" }
      , { binding: 'pgmPaid', header: "Paid Amt.", width: 100, allowDragging: true, isReadOnly: true, align: "right", format: "n2" }
      , { binding: 'pgmBalance', header: "PGM Balance", width: 100, allowDragging: true, isReadOnly: true, align: "right", format: "n2"}
      //, { binding: 'pgmBalanceSum', header: "PGM Balance Sum", width: 100, allowDragging: true, isReadOnly: true, align: "right", format: "n2" }
      , { binding: 'transferAmt', header: "Transfer Amt.", width: 100, allowDragging: true, isReadOnly: true, align: "right", format: "n2" }
      , { binding: 'promotionNo', header: "LG Promotion#", width: 180, allowDragging: true, isReadOnly: true }
      , { binding: 'lgPgmDesc', header: "Program Description", width: 220, allowDragging: true, isReadOnly: true }
      , { binding: 'startDateActive', header: "Start Date", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'endDateActive', header: "End Date", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'salesProgramReasonCode', header: "Reason Code", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'locDescription', header: "Reason Name", width: 180, allowDragging: true, isReadOnly: true }
      , { binding: 'discountCategoryCode', header: "Type", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'offerCode', header: "Program No", width: 180, allowDragging: true, isReadOnly: true, align: "center" }             
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();
    SearchGridFormat();

    const getPageInfo = async () => {
      let resReason = await ApiManager.post(retrieveReasonCodeList, { class: "040", claimType: reqClaimType });        

      entityReasonCode.current.setList({ data: resReason });
      entityStartDate.current.setValue("01/01/2017");
      entityProgramNo.current.setFocus();

      //조회된 내역존재 (다건)
      if (resData) {
        resData?.list?.forEach((el) => {
          el.isChecked2 = false;
        });

        setGridData(new CollectionView(resData.list, { trackChanges: true, refreshOnEdit: false }));

        entityPageNo.current = 1;
        let pageNoVal = entityPageNo.current;
        let sendParam = {
          comCode: comCode
          , claimType: reqClaimType
          , lgPgmNo: reqLgPgmNo
          , lgPgmName: ''
          , paramBillTo: reqBillTo || ''
          , modelCode: reqModelCode || ''   
          , divisionCode: reqDivisionCode || ''   
          , accuralTransfer: "Y"
          , reasonCode: []
          , startDate: entityStartDate.current?.getOriginalValue() ? wijmo.Globalize.format(entityStartDate.current.getOriginalValue(), "MM/dd/yyyy") : '01/01/2000'
          , endDate: entityEndDate.current?.getOriginalValue() ? wijmo.Globalize.format(entityEndDate.current.getOriginalValue(), "MM/dd/yyyy") : '12/31/2099'
          , pageSize: '1'
          , rowSize: pageInfo.pageRowSize
          , pageNo: pageNoVal.toString()
        }
        searchDataRef.current = sendParam;

        totCntRef.current = Number(resData.totCnt);
        pageCntRef.current = Number(resData.list.length);
      }      
    }

    if (open) {
      filterSearch?.current?.clear();
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  const onSelectedList = (elementId, checkedItems) => {
    let itemArr = checkedItems.filter(el => el.cd !== "ALL").map(el => el.nm);
    
    if (elementId === 'entityReasonCode') {
      setReasonCodeList(itemArr);
    }
  }

  const onClickApply = async () => {
    let selectedItems = gridObject.current.rows.filter(e => e.dataItem.isChecked2);

    if (selectedItems == null || selectedItems.length === 0) 
      return;

    for(let i=0; i<selectedItems.length; i++){
      let item = selectedItems[i].dataItem;

      if (!item.transferAmt) {
        ComNoticfication.ShowAlertUI("Info", "You can't select the line if Transfer Amt. is 0.");
        return;
      }
      if (Number(item.pgmBalance) < Number(item.transferAmt)) {
        ComNoticfication.ShowAlertUI("Info", "PGM Balance should be greater than Transfer Amt.");
        return;
      }
    }

    popComponent.current.hide();
    closeFn(selectedItems);    
  }

  // 조회
  const onSearch = (param, e) => {
    if (!entityProgramNo.current.getValue()) {
      ComNoticfication.ShowAlertUICallback("Info", "Please input Program No.", () => entityProgramNo.current.setFocus());
      return;
    }

    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {      
      comCode: comCode
        , claimType: reqClaimType
        , lgPgmNo: entityProgramNo.current.getValue() || ''
        , lgPgmName: entityProgramName.current.getValue() || ''
        , reasonCode: reasonCodeList
        , paramBillTo: reqBillTo || ''
        , modelCode: reqModelCode || ''   
        , divisionCode: reqDivisionCode || ''   
        , accuralTransfer: "Y"
        , startDate: entityStartDate.current.getOriginalValue() ? wijmo.Globalize.format(entityStartDate.current.getOriginalValue(), "MM/dd/yyyy") : '01/01/2000'
        , endDate: entityEndDate.current.getOriginalValue() ? wijmo.Globalize.format(entityEndDate.current.getOriginalValue(), "MM/dd/yyyy") : '12/31/2099'
        , pageSize: '1'
        , rowSize: pageInfo.pageRowSize
        , pageNo: pageNoVal.toString()
    }
    searchDataRef.current = sendParam
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    let row_index = gridObject.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveProgramList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveProgramList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
        setGridDataCount(0);
      } else {
        let resultCnt = 0;

        resultData?.list?.forEach((el) => {
          el.isChecked2 = false;
          
          let data = el?.pgmBalance?.split("|");
          el.pgmAccrual = Number(data[4]?.replaceAll(',' ,'') || 0);
          el.pgmAdjust = Number(data[5]?.replaceAll(',' ,'') || 0);
          el.pgmPaid = Number(data[6]?.replaceAll(',' ,'') || 0);
          el.pgmBalance = Number(data[7]?.replaceAll(',' ,'') || 0);
        });

        if (param.searchFlag) {
          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`LG Program Search`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Program No
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityProgramNo}
                elementId={entityProgramNo}
                callRelationFuntion={(id, value) => convertUpperCase(id, value)}
                defaultValue={reqLgPgmNo}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Start Date
              </label>
            </div>
            <div className="bx-inp combo">
              <ComDate 
                ref={entityStartDate}
                elementId={"entityStartDate"}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
                isNullable={true}
                defaultValue={"01/01/2017"}
              />
              ~
              <ComDate 
                ref={entityEndDate}
                elementId={"entityEndDate"}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
                isNullable={true}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Program Name
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityProgramName}
                elementId={entityProgramName}
                callRelationFuntion={(id, value) => convertUpperCase(id, value)}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Reason Code
              </label>
            </div>
            <div className="bx-inp combo">
              <ComComboMultiAll
                ref={entityReasonCode}
                elementId={"entityReasonCode"}
                isShowAllCheck="Y"
                callRelationFuntion={onSelectedList}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
              Transfer Amount
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityTransferAmt}
                elementId={"entityAccrualAmt"}
                defaultValue={wijmo.Globalize.format(Number(reqRequestAmt), "n2")}
                defaultDisabled={true}
              />
              <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
              <Button variant="contained" onClick={(e) => onClickApply()}>{"Apply"}</Button>
            </div>
          </div>               
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default LgOrgProgramSchPop;