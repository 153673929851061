//ActionTypes
export const REGISTER_USER_INIT = "register_user_init";
export const REGISTER_USER = "register_user";
export const REGISTER_USER_PROCEEDING = "register_user_proceeding";
export const REGISTER_USER_ISPERSONALINFOFINISH = "register_user_isPersonalInfoFinish";
export const REGISTER_USER_ISLOGININFOFINISH = "register_user_isLoginInfoFinish";
export const REGISTER_USER_ISSTOREINFOFINISH = "register_user_isStoreInfoFinish";
export const REGISTER_USER_ISIRSWQSTATEINFOFINISH = "register_user_isIRSWqStateInfoFinish";
export const REGISTER_USER_ISLGACCOUNTINFORFINISH = "register_user_isLGAccountInfoFinish";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
export const REGISTER_USER_FAILED = "register_user_failed";

//Actions
export const registerUserInit = user => {
  return { type: REGISTER_USER_INIT, payload: user }
};

export const registerUser = userType => {
  return { type: REGISTER_USER, payload: userType }
};

export const registerUserProceeding = user => {
  return { type: REGISTER_USER_PROCEEDING, payload: user }
};

export const registerUserIsPersonalInfoFinish = finishCheck => {
  return { type: REGISTER_USER_ISPERSONALINFOFINISH, payload: finishCheck }
};

export const registerUserIsLoginInfoFinish = finishCheck => {
  return { type: REGISTER_USER_ISLOGININFOFINISH, payload: finishCheck }
};

export const registerUserIsStoreInfoFinish = finishCheck => {
  return { type: REGISTER_USER_ISSTOREINFOFINISH, payload: finishCheck }
};

export const registerUserIsIRSWqStateInfoFinish = finishCheck => {
  return { type: REGISTER_USER_ISIRSWQSTATEINFOFINISH, payload: finishCheck }
};

export const registerUserIsLGAccountInfoFinish = finishCheck => {
  return { type: REGISTER_USER_ISLGACCOUNTINFORFINISH, payload: finishCheck }
};

export const registerUserSuccessful = user => {
  return { type: REGISTER_USER_SUCCESSFUL, payload: user }
};

export const registerUserFailed = err => {
  return { type: REGISTER_USER_FAILED, payload: err }
};

//State
const initialState = {
  proceeding: false,
  success: false,
  registrationError: null,
  message: null,
  isPersonalInfoFinish: false,
  isLoginInfoFinish: false,
  isStoreInfoFinish: false,
  isIRSWqStateInfoFinish: false,
  isLGAccountInfoFinish: false,
  userType: "",
  user: {
    "firstName": ""
    , "middleName": ""
    , "lastName": ""
    , "year": ""
    , "month": ""
    , "day": ""
    , "ssn": ""
    , "ssn1": ""
    , "ssn2": ""
    , "ssn3": ""
    , "isUSCitizen": "1"
    , "streetAddress": ""
    , "aptNumber": ""
    , "city": ""
    , "state": ""
    , "zipCode": ""
    , "email": ""
    , "confirmEmail": ""
    , "mobilePhone": ""
    , "officePhone": ""
    , "dob": ""
    , "driverLicenseState": ""
    , "driverLicenseNum": ""
    , "userId": ""
    , "userIdCheck": false
    , "billToCheck": false
    , "password": "lgsp2_us"
    , "repassword": ""
    , "paymentMethod": ""
    , "userGroupType": "RA"
    , "userGroup": ""
    , "companyName": ""
    , "paramBillTo": ""
    , "textBillTo": ""
    , "storeNo": ""
    , "reviewRemark": ""
    , "bizUnit": ""
    , "companyAddress": ""
    , "storeCity": ""
    , "storeState": ""
    , "storeZipcode": ""
    , "jobtitle": ""
    , "dept": ""
    , "esignature": ""
    , "userName": ""
    , "fName": ""
    , "mName": ""
    , "lName": ""
    , "cardCrossChecked": ""
    , "matriculaNum": ""
    , "passportID": ""
    , "passportCountry": ""
    , "hintId": ""
    , "hintAnswer": ""
    , "approveStatus": ""
    , "tc": ""
    , "comCode": "LGEUS"
    , "language": "ENG"
    , "langSel": "ENG"
  }
};

//Reducer
const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_INIT:
      state = {
        ...state,
        proceeding: false,
        isPersonalInfoFinish: false,
        isLoginInfoFinish: false,
        isStoreInfoFinish: false,
        isIRSWqStateInfoFinish: false,
        isLGAccountInfoFinish: false,
        userType: "",
        user: {
          "firstName": ""
          , "middleName": ""
          , "lastName": ""
          , "year": ""
          , "month": ""
          , "day": ""
          , "ssn": ""
          , "ssn1": ""
          , "ssn2": ""
          , "ssn3": ""
          , "isUSCitizen": "1"
          , "streetAddress": ""
          , "aptNumber": ""
          , "city": ""
          , "state": ""
          , "zipCode": ""
          , "email": ""
          , "confirmEmail": ""
          , "mobilePhone": ""
          , "officePhone": ""
          , "dob": ""
          , "driverLicenseState": ""
          , "driverLicenseNum": ""
          , "userId": ""
          , "userIdCheck": false
          , "billToCheck": false
          , "password": "lgsp2_us"
          , "repassword": ""
          , "paymentMethod": ""
          , "userGroupType": "RA"
          , "userGroup": ""
          , "companyName": ""
          , "paramBillTo": ""
          , "textBillTo": ""
          , "storeNo": ""
          , "reviewRemark": ""
          , "bizUnit": ""
          , "companyAddress": ""
          , "storeCity": ""
          , "storeState": ""
          , "storeZipcode": ""
          , "jobtitle": ""
          , "dept": ""
          , "esignature": ""
          , "userName": ""
          , "fName": ""
          , "mName": ""
          , "lName": ""
          , "cardCrossChecked": ""
          , "matriculaNum": ""
          , "passportID": ""
          , "passportCountry": ""
          , "hintId": ""
          , "hintAnswer": ""
          , "approveStatus": ""
          , "tc": ""
          , "comCode": "LGEUS"
          , "language": "ENG"
          , "langSel": "ENG"
        },
        success: false,
        message: null,
        registrationError: null,
      }
      break;
    case REGISTER_USER:
      state = {
        ...state,
        proceeding: true,
        success: false,
        userType: action.payload,
        registrationError: null,
      }
      break;
    case REGISTER_USER_PROCEEDING:
      state = {
        ...state,
        proceeding: true,
        user: action.payload,
        success: false,
        registrationError: null,
      }
      break;
    case REGISTER_USER_ISPERSONALINFOFINISH:
      state = {
        ...state,
        proceeding: true,
        success: false,
        isPersonalInfoFinish: action.payload,
        registrationError: null,
      }
      break;
    case REGISTER_USER_ISLOGININFOFINISH:
      state = {
        ...state,
        proceeding: true,
        success: false,
        isLoginInfoFinish: action.payload,
        registrationError: null,
      }
      break;
    case REGISTER_USER_ISSTOREINFOFINISH:
      state = {
        ...state,
        proceeding: true,
        success: false,
        isStoreInfoFinish: action.payload,
        registrationError: null,
      }
      break;
    case REGISTER_USER_ISIRSWQSTATEINFOFINISH:
      state = {
        ...state,
        proceeding: true,
        success: false,
        isIRSWqStateInfoFinish: action.payload,
        registrationError: null,
      }
      break;
    case REGISTER_USER_ISLGACCOUNTINFORFINISH:
      state = {
        ...state,
        proceeding: true,
        success: false,
        isLGAccountInfoFinish: action.payload,
        registrationError: null,
      }
      break;
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        proceeding: false,
        user: action.payload,
        success: true,
        registrationError: null,
      }
      break;
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        proceeding: false,
        success: false,
        registrationError: action.payload,
      }
      break;
    default:
      state = { ...state }
      break;
  }
  return state
};

export default account;
