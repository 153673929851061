import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import ComTextBox from 'common/components/ComTextBox';
import ComComboMultiAll from 'common/components/ComComboMultiAll';
import ComDate from 'common/components/ComDate';
import Divider from "@mui/material/Divider";

import * as wijmo from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView, createElement, format, addClass, removeClass, Tooltip, PopupPosition, toggleClass } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';

function InvoiceTaxSchPop({ open, closeFn, randomKey, reqBillTo, reqInvoiceNo, reqClaimStatus, reqSystemEntryNo, resData, pageInfo }) {
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const retrieveTaxInvoiceList = "/com/comPop/retrieveTaxInvoiceList";
  

  const entityInvoiceNo = useRef(null);
  const entityStartDate = useRef(null);
  const entityEndDate = useRef(null);

  const filterSearch = useRef(null);

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();

  const convertUpperCase = (el, value) => {
    if (value && el.current) {
      el.current.setValue(value.toUpperCase());
    }
  };  

  /***** Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {
    gridObject.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      canAreaInfo: false,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });

    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
        popComponent.current.hide();
        closeFn(sender.selectedItems[0]);
      }
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

  }

  const onSelectionChanged = (sender) => {

  }

  const onSourceChanged = () => {
    if (gridObject.current) {
      gridObject.current.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory 
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {
    let gridFormat = [
      { binding: 'invoiceNo', header: "Invoice#", width: 120,  allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'invoiceDate', header: "Invoice Date", width: 140, allowDragging: true, isReadOnly: true, align: "center"}
      , { binding: 'totalNetAmount', header: "Net Amount", width: 140, allowDragging: true, isReadOnly: true, align: "right", format: "n2"}
      , { binding: 'totalTaxAmount', header: "Tax", width: 140, allowDragging: true, isReadOnly: true, align: "right", format: "n2"}
      , { binding: 'totalInvoiceAmount', header: "Invoice Amount", width: 140, allowDragging: true, isReadOnly: true, align: "right", format: "n2"}
      , { binding: 'invoiceType', header: "Invoice Type", width: 120, allowDragging: true, isReadOnly: true, align: "center"}
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();

    SearchGridFormat();


    const getPageInfo = async () => {
      const [endDate, startDate] = await Promise.all([
        ApiManager.getServerDate("MM/DD/YYYY"),
        ApiManager.getServerDate("MM/DD/YYYY", "0", "-6"),
      ]);

      entityStartDate.current?.setValue(startDate);
      entityEndDate.current?.setValue(endDate);
      entityInvoiceNo.current?.setFocus();      

      //조회된 내역존재 (다건)
      if (resData) {
        setGridData(new CollectionView(resData.list, { trackChanges: true, refreshOnEdit: false }));

        entityPageNo.current = 1;
        let pageNoVal = entityPageNo.current;
        let sendParam = {
          comCode: comCode
          , invoiceNo: reqInvoiceNo
          , paramBillTo: reqBillTo || ''
          , claimStatus: reqClaimStatus || ''
          , systemEntryNo: reqSystemEntryNo || ''
          , startDate: entityStartDate.current?.getOriginalValue() ? wijmo.Globalize.format(entityStartDate.current.getOriginalValue(), "yyyyMMdd") : ''
          , endDate: entityEndDate.current?.getOriginalValue() ? wijmo.Globalize.format(entityEndDate.current.getOriginalValue(), "yyyyMMdd") : ''
          , pageSize: '1'
          , rowSize: pageInfo.pageRowSize
          , pageNo: pageNoVal.toString()
        }
        searchDataRef.current = sendParam;

        totCntRef.current = Number(resData.totCnt);
        pageCntRef.current = Number(resData.list.length);
      }      
    }

    if (open) {
      filterSearch?.current?.clear();
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);  

  // 조회
  const onSearch = (param, e) => {
    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {      
      comCode: comCode
        , invoiceNo: entityInvoiceNo.current.getValue() ?? ""
        , paramBillTo: reqBillTo || ''
        , claimStatus: reqClaimStatus || ''
        , systemEntryNo: reqSystemEntryNo || ''
        , startDate: entityStartDate.current.getOriginalValue() ? wijmo.Globalize.format(entityStartDate.current.getOriginalValue(), "yyyyMMdd") : ''
        , endDate: entityEndDate.current.getOriginalValue() ? wijmo.Globalize.format(entityEndDate.current.getOriginalValue(), "yyyyMMdd") : ''
        , pageSize: '1'
        , rowSize: pageInfo.pageRowSize
        , pageNo: pageNoVal.toString()
    }
    searchDataRef.current = sendParam
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    let row_index = gridObject.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveTaxInvoiceList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveTaxInvoiceList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
        setGridDataCount(0);
      } else {
        let resultCnt = 0;

        if (param.searchFlag) {
          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`Tax Invoice Search`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Invoice#
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityInvoiceNo}
                elementId={entityInvoiceNo}
                callRelationFuntion={(id, value) => convertUpperCase(id, value)}
                defaultValue={reqInvoiceNo}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Invoice Date
              </label>
            </div>
            <div className="bx-inp combo">
              <ComDate 
                ref={entityStartDate}
                elementId={"entityStartDate"}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
                isNullable={true}
              />
              ~
              <ComDate 
                ref={entityEndDate}
                elementId={"entityEndDate"}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
                isNullable={true}
              />
              <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default InvoiceTaxSchPop;