import _ from 'lodash';
import moment from 'moment/moment';
import * as wijmo from "@grapecity/wijmo";
import { ComUtils } from "common/lib/ComUtils";
import { FileUtils } from "common/lib/FileUtils";
import { ComNoticfication } from "common/components/ComNoticfication";
import { ApiManager } from 'common/lib/ApiUtil';


export const SampleUtils = () => { };

SampleUtils.interval = (startDate, endDate) => {
  var d1 = new Date(startDate.replace(/-/g, "/")); 
  var d2 = new Date(endDate.replace(/-/g, "/")); 
  var time = d2.getTime() - d1.getTime(); 
  return parseInt(time / (1000 * 60 * 60 * 24));
}

SampleUtils.currentDate = () => {
  wijmo.Globalize.format(new Date(), "MM/dd/yyyy");
}

SampleUtils.clearNoNumVal = (objVal) => {
  return objVal.replace(/[^\d.-]/g,"").replace(/^\./g,"").replace(/\.{2,}/g,".").replace(".","$#$").replace(/\./g,"").replace("$#$",".");
}

SampleUtils.checkFile = (event, fileList, excuteUpload, setFileList, fileElement, userId, maxFileName=92, checkExt=true) => {
  const allowFileExt = ["gif", "jpeg", "bmp", "jpg", "png", "doc", "hwp", "xls", "xlsx", "xlsb", "ppt", "pptx", "csv", "txt", "doc", "pdf", "docx"];
  
  if (event.target.files.length > 0) {

    // file 길이 체크
    for (let i = 0; i < event.target.files.length; i++) {
      let fullFileName = event.target.files[i].name;
      let tmpFileName = event.target.files[i].name.split(".");
      let extName = tmpFileName[tmpFileName.length - 1];
      // console.log("filelength:" + fullFileName.replace(extName, "").length);
      if (fullFileName.replace(extName, "").length > maxFileName) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "The file name is too long");
        return;
      }
    }
    // file 중복 체크
    if (fileList) {
      for (let i = 0; i < event.target.files.length; i++) {
        let isDupCheck = fileList.some((el) => el.orgFileName === event.target.files[i].name);
        if (isDupCheck) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Duplicated record. Please check it");
          return;
        }
      }
    }

    for (let i = 0; i < event.target.files.length; i++) {

      let formData = new FormData();
      let extPass = false;
      let fname = event.target.files[i].name.split(".");

      if (fname.length > 0) {
        let extName = fname[fname.length - 1];
        if (extName !== undefined && extName != null) {
          extName = extName.toLowerCase();
        }
        if (FileUtils.allowFileExt.indexOf(extName) > -1) {
          extPass = true;
        }
      }
      if (extPass || !checkExt) {
          if (excuteUpload) {
              excuteUpload(event.target.files[i], fileList, setFileList, fileElement, userId)
          }
      }
    }
  }
};

/**
 * array의 속성의 값이 같은값이 있는지 확인
 * @param {*} array object array
 * @param {*} property 중복값을 확인할 property
 * @returns 
 */
SampleUtils.hasDuplicateProperty = (array, property) => {
  const seen = new Set();
  for (const item of array) {
    if (seen.has(item[property])) {
      return true;
    }
    seen.add(item[property]);
  }
  return false;
};

SampleUtils.checkSalesPersonEvaluation = async (headerId, modelSuffix, lineIdArray, reqQtyArray) => {
  let resultData = await ApiManager.post("/order/sampleFollowUp/checkSalesPersonEvaluation", {
    headerId,
    modelSuffix,
    lineIdArray,
    reqQtyArray,
  });

  if (resultData?.statusCode) {
    ComNoticfication.ShowErrorUI("error", "An error occurred during check");
    return false;
  }

  if (resultData.returnText == "N") {
    ComNoticfication.ShowAlertUI("error", "Pickup Date Should Be Same");
    return false;
  } else if (resultData.returnText == "X") {
    ComNoticfication.ShowAlertUI("error", "Model [" + resultData.returnModel + "] not available,please check!");
    return false;
  } else if (resultData.returnText == "Q" || resultData.returnText == "E") {
    ComNoticfication.ShowAlertUI("error", "Transact Qty is more than avaliable!");
    return false;
  }
  return true;
};

/**
 * 오브젝트의 내부 속성 값이 null인것을 공백 문자열로 변경
 * @param {*} obj 타겟 object
 * @returns 
 */
SampleUtils.replaceNullsWithEmptyString = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value === null ? '' : value])
  );
};