import { useEffect } from "react";

import "assets/styles/layout/Header.scss";
import { useDispatch } from "react-redux";

import MenuLayer from "./MenuLayer";
import TabLayer from "./TabLayer";
import MenuIcon from "assets/icons/menu-hamburger.svg";
import closeIcon from "assets/icons/close.svg";
import pin from "assets/icons/pin.svg";
import pinActive from "assets/icons/pin-active.svg";
import logo from "assets/images/common/lg-logo.jpg";
import MenuLayerMobile from "./MenuLayerMobile";
import { useState } from "react";
import { closeMenu, openMenu } from "reducer/OpenMenus";
import { useRef } from "react";
import _ from "lodash";
import { ApiManager } from "common/lib/ApiUtil";

function Header(props) {
  const [isPin, setIsPin] = useState(false);
  const dispatch = useDispatch();
  const refMenuMb = useRef();

  useEffect(() => {

    sessionStorage.setItem("pin-menu", "unpin");
    
    //ep login IR/SPIFF Confirm open
    if (sessionStorage.getItem("epLogin") === "Y") {
      props.handleToggle();
      setTimeout(() => {
        let now = new Date();
        let timeKey = now.toISOString().replace('T', ' ').substring(0, 19).replace(/[^0-9]/g, '') + now.getMilliseconds().toString();

        let t = {};
        t["origSysDocumentRef"] = "";
        t["copyFlag"] = false;
        t["editFlag"] = false;
        t["timeKey"] = timeKey;
        t["Active"] = true;
        t["pageRowSize"] = sessionStorage.getItem("pageRowSize");


        if (sessionStorage.getItem("type") === "other") {
          let menuItem = {
            menuId: "M9105",
            menuName: "Other Claim Confirm",
            menuUrl: "Clm/Oth/Other/OtherClaimConfirmList",
            tabUniqueKey: "M9105",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Customer Claims > Other Claims > Other Claim Confirm";

          t["claimType"] = sessionStorage.getItem("type");
          t["systemEntryNo"] = sessionStorage.getItem("reqId");
          t["viewType"] = sessionStorage.getItem("viewType");
        } else if (sessionStorage.getItem("type") === "spl") {
          let menuItem = {
            menuId: "M0138",
            menuName: "Sample Order Tracking",
            menuUrl: "Spl/SampleOrderTracking",
            tabUniqueKey: "M0138",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Sample > Sample Order Tracking";

          t["actionType"] = sessionStorage.getItem("actionType");
          t["headerId"] = sessionStorage.getItem("reqId");
          t["dealType"] = sessionStorage.getItem("dealType");
          t["readType"] = sessionStorage.getItem("readType");
          t["targetEmpNo"] = sessionStorage.getItem("targetEmpNo");
        } else if (sessionStorage.getItem("type") === "cac") {
          let menuItem = {
            menuId: "M9011",
            menuName: "Claim Agreement Status Tracking",
            menuUrl: "Agr/ClaimAgreementStatusTracking",
            tabUniqueKey: "M9011",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Agreement > Claim Agreement Status Tracking";

          t["type"] = sessionStorage.getItem("type");
          t["ssosalesid"] = sessionStorage.getItem("ssosalesid");
          t["viewType"] = sessionStorage.getItem("viewType");
          t["entryNo"] = sessionStorage.getItem("entryNo");
        } else {
          let menuItem = {
            menuId: "M0116",
            menuName: "IR/SPIFF Confirm",
            menuUrl: "Clm/Isf/IrSpiffConfList",
            tabUniqueKey: "M0116",
          };
          Object.assign(t, menuItem);

          t["tabUniqueKey"] = menuItem.menuId;
          t["parentNamePath"] = "Customer Claims > IR/Dealer SPIFF > IR/SPIFF Confirm"

          t["claimType"] = sessionStorage.getItem("type");
          t["claimId"] = sessionStorage.getItem("reqId");
          t["viewType"] = false;
        }

        dispatch(openMenu(t));

      }, 200);
    }


  }, []);

  const handlePinMenu = () => {
    setIsPin(!isPin);
    let pin = sessionStorage.getItem("pin-menu");
    sessionStorage.setItem(
      "pin-menu",
      !pin || pin == "unpin" ? "pin" : "unpin"
    );
  };

  const redirectHomePage = () => {
    let t = {};
    let now = new Date();
    let timeKey = now.toISOString().replace('T', ' ').substring(0, 19).replace(/[^0-9]/g, '') + now.getMilliseconds().toString()
    let menuItem = {
      menuId: "0",
      menuName: "Home",
      menuUrl: "Landing/RSALanding",
      tabUniqueKey: "0",
    };
    Object.assign(t, menuItem);
    t["origSysDocumentRef"] = "";
    t["copyFlag"] = false;
    t["editFlag"] = false;
    t["timeKey"] = timeKey;
    t["tabUniqueKey"] = menuItem.menuId;
    t["Active"] = true;
    dispatch(openMenu(t));
    let isMobileScreen = window.innerWidth <= 500 ? true : false;
    if (isMobileScreen) {
      props.handleToggle();
    }
  };
  return (
    <div
      className={
        props.menuFixed === false ? "header-container min" : "header-container"
      }
      style={
        props.menuFixed === false && props.position > 20
          ? {
            boxShadow:
              "rgba(58, 53, 65, .2) 0px 5px 6px -3px, rgba(58, 53, 65, .14) 0px 9px 12px 1px, rgba(58, 53, 65, .12) 0px 3px 16px 2px",
          }
          : {}
      }
    >
      <div className="in-wrap">
        <div className="bx-logo">
          {props.menuFixed ? (
            // <Link href="#none" className="lk-logo"></Link>
            <img
              src={logo}
              width="80px"
              style={{ position: "relative", left: "2%" }}
              className="cursor-pointer"
              onClick={redirectHomePage}
            />
          ) : null}
          <p
            className="txt-sub"
          // style={{ color: "#A50034", fontSize: "18px", fontWeight: "600" }}
          >
            {/* <span
              className="ml-10"
              style={{ color: "#44403C", fontSize: "18px", fontWeight: "600" }}
            >
              SALES PORTAL
            </span> */}
          </p>
          {props.menuFixed && (
            <img
              src={isPin ? pinActive : pin}
              alt="menuicon"
              width="20px"
              height="20px"
              className="mr-10 cursor-pointer icon-pin-action"
              onClick={handlePinMenu}
            />
          )}

          <img
            src={props.menuFixed ? closeIcon : MenuIcon}
            onClick={() => {
              props.handleToggle();
              sessionStorage.setItem("pin-menu", "unpin");
              setIsPin(false);
            }}
            alt="menuicon"
            className="toggle-menu-icon"
          />
        </div>

        <div className="bx-nav">
          <div className="lk-nav">
            <div className="menu-pc h-100">
              <MenuLayer
                menuFixed={props.menuFixed}
                onCollapseClick={props.handleToggle}
              />
            </div>
            <div className="menu-mb" ref={refMenuMb}>
              <MenuLayerMobile
                menuFixed={props.menuFixed}
                onCollapseClick={props.handleToggle}
              />
            </div>
            <TabLayer />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
