import React, { useState, useEffect, useRef } from 'react';
import * as wjInput from '@grapecity/wijmo.react.input';

const ComNumberInput = React.forwardRef(({
    callRelationFuntion     // 부모에서 호출 할 함수
    , defaultValue            // 기본 값
    , elementId               // 객체 아이디
    , defualtPlaceholder
    , defualtIsRequired
    // 스타일 관련
    , defaultDisabled           // 활성화여부
    , defaultReadonly
    , defaultStyle              // 스타일
    , defaultClassName          // CSS 클래스명

    , minValue
    , maxValue
    , stepValue
    , formatValue
}
    , ref) => {

    const numberInputRef = useRef(null);
    const focusRef = useRef(null);
    const [textValue, setTextValue] = useState(0);
    const [userDisable, setUserDisable] = useState(false);
    const [userReadonly, setUserReadonly] = useState(false);
    const [userIsRequired, setUserIsRequired] = useState(false);
    const [userPlaceholder, setUserPlaceholdere] = useState(false);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userClassName, setUserClassName] = useState(false);


    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    const [minNumber, setMinNumber] = useState(null);
    const [maxNumber, setMaxNumber] = useState(null);
    const [stepNumber, setStepNumber] = useState(null);
    const [formatType, setFormatType] = useState(null);

    React.useImperativeHandle(ref, () => (
        {
            getValue: () => numberInputRef.current
            , getCheckedValues: () => numberInputRef.current
            , setValue: (v) => { numberInputRef.current = v; setTextValue(v); }
            , setFocus: () => { focusRef.current.control.hostElement.focus(); },
        }
    ));


    // 전달 파라미터가 변경될때
    useEffect(() => {


        if (defaultValue !== undefined && defaultValue !== null) {

            if (!isNaN(defaultValue)) {
                numberInputRef.current = defaultValue;
                setTextValue(defaultValue);
            }
        }
        else {
            if (minValue !== undefined && minValue !== null) {
                setTextValue(minValue);
            }
            else {
                setTextValue(0);
            }
        }


        if (minValue !== undefined && minValue !== null) {
            setMinNumber(minValue);
        }

        if (maxValue !== undefined && maxValue !== null) {
            setMaxNumber(maxValue);
        }
        if (stepValue !== undefined && stepValue !== null) {
            setStepNumber(stepValue);
        }
        if (formatValue !== undefined && formatValue !== null) {
            setFormatType(formatValue);
        }


        /* if (defaultStyle) {
            setUserStyle(defaultStyle);
            setHasStyle(true);
        } */

        if (defaultClassName) {
            setUserClassName(defaultClassName)
            setHasClassName(true)
        }
        if (defaultDisabled === undefined || defaultDisabled === null) {
            setUserDisable(false);
        }
        else {
            setUserDisable(defaultDisabled);
        }

        if (defaultReadonly === undefined || defaultReadonly === null) {
            setUserReadonly(false);
        }
        else {
            setUserReadonly(defaultReadonly);
        }



        if (defualtPlaceholder === undefined || defualtPlaceholder === null) {
            setUserPlaceholdere("");
        }
        else {
            setUserPlaceholdere(defualtPlaceholder);
        }

        if (defualtIsRequired === undefined || defualtIsRequired === null) {
            setUserIsRequired(true); // wijmo에서 deault가 true
        }
        else {
            setUserIsRequired(defualtIsRequired);
        }

    }, [defaultValue, minValue, maxValue, stepValue, formatValue, userClassName, defaultDisabled, defaultReadonly, defaultClassName /* , defaultStyle */]);

    const onlostFocus = e => {
        numberInputRef.current = e.value;
        if (callRelationFuntion) {
            callRelationFuntion(elementId, e.value);
        }
    }

    const onValueChanged = e => {
        numberInputRef.current = e.value;
        setTextValue(e.value);
    }



    return (
        <wjInput.InputNumber
            value={textValue}
            ref={focusRef}
            min={minNumber || null}
            max={maxNumber || null}
            step={stepNumber || null}
            format={formatType}
            lostFocus={onlostFocus}
            valueChanged={onValueChanged}
            isDisabled={userDisable}
            isReadOnly={userReadonly}
            className={hasClassName ? userClassName : "wj_with10"}
            placeholder={userPlaceholder}
            isRequired={userIsRequired}
            style={defaultStyle ? defaultStyle : {}}
        />
    )
});
export default React.memo(ComNumberInput);
