import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";

import * as wjInput from '@grapecity/wijmo.react.input';

import logo from "assets/images/common/lge-logo.gif";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComNoticfication } from 'common/components/ComNoticfication';
import ReactDOMServer from 'react-dom/server';

function CommercialInvoicePop({ open, closeFn, headerId, lineId, pageInfo }) {

  //url
  const retrieveAddressInfo = "/com/splComPop/retrieveAddressInfo";

  const printArea = useRef();

  const cssStyles = `
    .table_title {font-family: "Arial"; font-size: 12px; letter-spacing: 0px;  color: #000000; font-weight: bold; background-color: #f8f8e9;; padding-right: 5px; padding-left: 5px}
    .table_text_td {font-size: 14px}
    .table_text_hd_sp {font-size: 12px; font-weight: bold; }
    .table_bg2 {border: 1px  solid #777777;}
    .table_text {
      font-family: "Arial"; 
      font-size: 12px;
      letter-spacing: 0px; 
      color: #000000; 
      background-color: #FFFFFF; 
      padding-right: 5px; 
      padding-left: 5px;
    }

    .navFix{background:#FFF; position:fixed; left:0px; top:0px;width:950px; _position:absolute; top:expression((offsetParent.scrollTop)+0); z-index:2;}
    .waiting{position:fixed;_position:absolute;top:50%;left:50%;width:124px;height:124px;overflow:hidden;background:url(/images/loaderc.gif) no-repeat;z-index:7; margin:-62px 0 0 -62px;}
    .overString{width:240px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;} 
    .overStringA{width:160px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;} 
    .overStringB{width:120px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;} 
    .overStringC{width:815px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;} 
    .overStringD{width:100px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;} 

    .page_title {
      line-height:36px;
      font-size: 36px;
      font-family:Arial, sans-serif;
    }
    .page_text12 {font-size: 12px;line-height:14px;}
    .page_text12b {font-size: 12px;font-weight: bold;line-height:14px;}
    .page_text14 {font-size: 14px;line-height:16px;}
    .page_text14b {font-size: 14px;font-weight: bold;line-height:16px;}
    .page_text16 {font-size: 16px;line-height:16px;}
    .page_text16b {font-size: 16px;font-weight: bold;line-height:18px;}

    .tr_hover{background-color: #E6E6E6;}
    .tr_hover td{
      font-family: "Arial"; 
      font-size: 12px;
      letter-spacing: 0px; 
      color: 000000; 
      padding-right: 5px; 
      padding-left: 5px;
    }
    .tr_hover input{background-color: #E6E6E6;}
    .tr_click{background-color: #FFFBD9;}
    .tr_click td{
      font-family: "Arial"; 
      font-size: 12px;
      letter-spacing: 0px; 
      color: #000000;
      padding-right: 5px; 
      padding-left: 5px;
    }
    .tr_click input{background-color: #FFFBD9;}
    .tr_click input{background-color: #FFFBD9;}
  
    .imgSearchCityStateZip-hover{cursor: hand;}
    .sm_title {font-family: "Arial"; font-size: 12px; letter-spacing: 0px;  color: #000000; font-weight: bold; background-color: #f8f8e9;; padding-right: 1px; padding-left: 1px}
    .pl-20 {padding-left: 20px;}
  `;

  const popComponent = useRef();


  //Memory 
  const _isMounted = useRef(true);

  // onClickText 함수 정의
  const startPrint = () => {
    console.log('startPrint 함수가 실행됨');
  };

  const onClickPrint = () => {
    //1안) 리액트 팝업으로 한번 띄우고 innerHTML에 문자열을 찾아 value를 직접 넣어주는법
    //2안) 바로 window로 띄우고 그안에 버튼을 추가해 클릭시 프린트 하는법(예외처리도 내부에서)

    const printContents = printArea.current.innerHTML;
    const printWindow = window.open('', '', 'width=1000,height=800');

    let script = `<script>
                    window.onload = function() {
                      document.getElementById("printButton").onclick = function() {
                        debugger;
                        var checkFlag = true;
                        
                        if(document.getElementById("realBillTo1").value==""){
                          alert("Real Bill To Name Should Be Input!");
                          document.getElementById("realBillTo1").focus();
                          return;
                        }
                        if(document.getElementById("realBillTo2").value==""){
                          alert("Real Bill To Name Should Be Input!");
                          document.getElementById("realBillTo2").focus();
                          return;
                        }
                        if(document.getElementById("realBillTo3").value==""){
                          alert("Real Bill To Name Should Be Input!");
                          document.getElementById("realBillTo3").focus();
                          return;
                        }

                        document.getElementById("printButton").style.display = "none";
                        window.print();
                        document.getElementById("printButton").style.display = "block";
                      };
                    };
                  </script>`;

    let printHtml = printContents + `<div><button id="printButton"'>Print</button></div>` + script;
    printWindow.document.writeln(printHtml);
    printWindow.document.close();
    printWindow.document.title = "LG Electronics :: AIC";
    printWindow.focus();
  }


  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();

    const getPageInfo = async () => {
    }

    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{ height: '500px', overflow: 'auto' }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {``}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <div id="printArea" ref={printArea}>
          <style>{cssStyles}</style>
          <table width="100%" border={0} cellSpacing={0} cellPadding={0} height="100%">
            <tbody>
              <tr>
                <td style={{ padding: "5 20 0 20" }} valign="top" height={25}>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td align="center" className="page_title">
                          COMMERCIAL INVOICE
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td height={20} />
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td width="360px;">
                          <table border={0} cellPadding={0}>
                            <tbody>
                              <tr>
                                <td>
                                  <img src={logo} height={24} />
                                </td>
                              </tr>
                              <tr>
                                <td className="page_text14 pl-20">111 Sylvan Ave.</td>
                              </tr>
                              <tr>
                                <td className="page_text14 pl-20">North Building</td>
                              </tr>
                              <tr>
                                <td className="page_text14 pl-20">
                                  Englewood Cliffs, NJ 07632
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td height={20} />
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td>
                          <strong>BILL TO</strong>
                        </td>
                        <td />
                        <td />
                      </tr>
                      <tr>
                        <td width={350}>
                          <table
                            width="100%"
                            align="center"
                            height="100px;"
                            className="table_bg2"
                          >
                            <tbody>
                              <tr>
                                <td align="center" />
                              </tr>
                              <tr>
                                <td align="center">
                                  Real Bill To Name:
                                  <input
                                    type="text"
                                    name="realBillTo1"
                                    id="realBillTo1"
                                    defaultValue=""
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td align="center">,</td>
                              </tr>
                              <tr>
                                <td align="center">, ,</td>
                              </tr>
                              <tr>
                                <td align="center" />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className="table_text">&nbsp;</td>
                        <td width={390} className="table_text">
                          <table width="100%" border="0px;">
                            <tbody>
                              <tr>
                                <td />
                                <td width={189} align="right">
                                  INVOICE NO
                                </td>
                                <td width={148} align="left">
                                  <input
                                    name="textfield"
                                    type="text"
                                    size={20}
                                    maxLength={50}
                                    defaultValue=""
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td />
                                <td align="right">Date</td>
                                <td>
                                  <input
                                    name="textfield2"
                                    type="text"
                                    size={20}
                                    maxLength={50}
                                    defaultValue=""
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td height={20} />
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td className="table_text">
                          <strong>INVOICE MAIL TO</strong>
                        </td>
                        <td className="table_text">&nbsp;</td>
                        <td className="table_text">
                          <strong>SOLD LOCATION</strong>
                        </td>
                      </tr>
                      <tr>
                        <td width={350}>
                          <table
                            width="100%"
                            align="center"
                            height="100px;"
                            className="table_bg2"
                          >
                            <tbody>
                              <tr>
                                <td align="center" />
                              </tr>
                              <tr>
                                <td align="center">
                                  Real Bill To Name:
                                  <input
                                    type="text"
                                    name="realBillTo2"
                                    id="realBillTo2"
                                    defaultValue=""
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td align="center">,</td>
                              </tr>
                              <tr>
                                <td align="center">, ,</td>
                              </tr>
                              <tr>
                                <td align="center" />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>&nbsp;</td>
                        <td width={390} valign="top">
                          <table
                            width="100%"
                            align="center"
                            height="100px;"
                            className="table_bg2"
                          >
                            <tbody>
                              <tr>
                                <td align="center" />
                              </tr>
                              <tr>
                                <td align="center">
                                  Real Bill To Name:
                                  <input
                                    type="text"
                                    name="realBillTo3"
                                    id="realBillTo3"
                                    defaultValue=""
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td align="center">,</td>
                              </tr>
                              <tr>
                                <td align="center" />
                              </tr>
                              <tr>
                                <td align="center" />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td height={20} />
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="862px"
                    border={1}
                    cellPadding={0}
                    cellSpacing={0}
                    className="table_bg2"
                  >
                    <tbody>
                      <tr align="center">
                        <td className="table_title" width={200}>
                          <strong>CUSTOMER NO.</strong>
                        </td>
                        <td className="table_title" width={210}>
                          <strong>REFERENCE / P.O. NO.</strong>
                        </td>
                        <td className="table_title">
                          <strong>TERMS</strong>
                        </td>
                        <td className="table_title" width={200}>
                          <strong>DUE DATE</strong>
                        </td>
                      </tr>
                      <tr align="center">
                        <td className="table_text" width={200} />
                        <td className="table_text" width={210} />
                        <td className="table_text">DUE UPON RECEIPT</td>
                        <td className="table_text" width={200}>
                          DUE UPON RECEIPT
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td height={5} />
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="862px"
                    border={1}
                    cellPadding={0}
                    cellSpacing={0}
                    className="table_bg2"
                  >
                    <tbody>
                      <tr align="center">
                        <td className="table_title" width={200}>
                          <strong>CREDIT APPROVAL NO.</strong>
                        </td>
                        <td className="table_title" width={210}>
                          <strong>CARRIER PRO NO.</strong>
                        </td>
                        <td className="table_title">
                          <strong>DATE SHIPPED</strong>
                        </td>
                        <td className="table_title">
                          <strong>SHIPPED VIA</strong>
                        </td>
                        <td className="table_title" width={80}>
                          <strong>W/H</strong>
                        </td>
                      </tr>
                      <tr align="center">
                        <td className="table_text" />
                        <td className="table_text" />
                        <td className="table_text" />
                        <td className="table_text">FED EX</td>
                        <td className="table_text" />
                      </tr>
                    </tbody>
                  </table>
                  <table width="862px" border={0} cellPadding={0} cellSpacing={1}>
                    <tbody>
                      <tr>
                        <td height={5} />
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="862px"
                    border={1}
                    cellPadding={0}
                    cellSpacing={0}
                    className="table_bg2"
                  >
                    <tbody>
                      <tr align="center">
                        <td
                          className="table_title"
                          width={200}
                          rowSpan={2}
                          align="center"
                        >
                          <strong>MODEL / SUFFIX</strong>
                        </td>
                        <td
                          className="table_title"
                          width={170}
                          rowSpan={2}
                          align="center"
                        >
                          <strong>DESCRIPTION</strong>
                        </td>
                        <td className="table_title" colSpan={3} align="center">
                          <strong>QUANTITY</strong>
                        </td>
                        <td className="table_title" width={93} rowSpan={2} align="center">
                          <strong>UNIT PRICE</strong>
                        </td>
                        <td className="table_title" width={93} rowSpan={2} align="center">
                          <strong>AMOUNT</strong>
                        </td>
                      </tr>
                      <tr align="center">
                        <td className="table_title" align="center">
                          <strong>ORDERED</strong>
                        </td>
                        <td className="table_title" align="center">
                          <strong>B/ORDERED</strong>
                        </td>
                        <td className="table_title" align="center">
                          <strong>SHIPPED</strong>
                        </td>
                      </tr>
                      <tr id="tr_0">
                        <td className="table_text">
                          <input type="hidden" name="lineId" defaultValue="" />
                        </td>
                        <td className="table_text" />
                        <td className="table_text" />
                        <td className="table_text">&nbsp;</td>
                        <td className="table_text" />
                        <td className="table_text" />
                        <td className="table_text" />
                      </tr>
                      <tr align="center">
                        <td colSpan={4} rowSpan={3} className="table_text">
                          <table width="100%" border={0}>
                            <tbody>
                              <tr>
                                <td className="table_text">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span style={{ backgroundColor: "#EAF1DD" }}>
                                    PLEASE SEND REMITTANCE TO:
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td align="center">LG ELECTRONICS</td>
                              </tr>
                              <tr>
                                <td align="center">AR DEPARTMENT</td>
                              </tr>
                              <tr>
                                <td align="center">111 SYLVAN AVENUE</td>
                              </tr>
                              <tr>
                                <td align="center">NORTH BUILDING</td>
                              </tr>
                              <tr>
                                <td align="center">ENGLEWOOD CLIFFS, NJ 07632</td>
                              </tr>
                              <tr>
                                <td align="center">ATTN: MARIBEL PUJOLS</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className="table_text" rowSpan={2}>
                          &nbsp;
                        </td>
                        <td className="table_text">NET AMOUNT</td>
                        <td className="table_text">
                          <input
                            name="textfield3"
                            style={{ textAlign: "right" }}
                            type="text"
                            size={15}
                            maxLength={50}
                            readOnly=""
                            id="netAmount"
                            defaultValue=""
                          />
                        </td>
                      </tr>
                      <tr align="center">
                        <td className="table_text">TAX AMOUNT</td>
                        <td className="table_text">
                          <input
                            name="textfield3"
                            type="text"
                            style={{ textAlign: "right" }}
                            size={15}
                            maxLength={50}
                            id="taxAmount"
                          />
                        </td>
                      </tr>
                      <tr align="center">
                        <td className="table_text" colSpan={2}>
                          <table width="100%" border={0}>
                            <tbody>
                              <tr>
                                <td className="table_text" width="58%">
                                  PLEASE PAY
                                  <br />
                                  THIS AMOUNT
                                </td>
                                <td
                                  className="table_text page_text14b"
                                  width="22%"
                                  align="center"
                                >
                                </td>
                                <td
                                  className="table_text page_text14b"
                                  width="20%"
                                  align="center"
                                >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className="table_text">
                          <input
                            name="textfield32"
                            type="text"
                            style={{ textAlign: "right" }}
                            size={15}
                            maxLength={50}
                            id="totAmount"
                            defaultValue=""
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="862px"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    className="table_bg2"
                  >
                    <tbody>
                      <tr>
                        <td className="table_text">
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td align="center">
                                  ITEMS ORDERED BUT NOT SHIPPED HAVE BEEN BACKORDERED AND
                                  WILL BE SHIPPED AS SOON AS POSSIBLE.
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  CONSIGNEE : NOTE CAREFULLY CONDITION OF PACKAGES BEFORE
                                  RECEIPTING IN GOOD ORDER.
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  The merchandise hereon was delivered to the carrier in
                                  good condition. If any shortage or damage is discovered,
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  either at time of delivery or later, it is incumbent
                                  upon the consignee to file claim for redress against the
                                  carrier.
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  No returns accepted unless specifically authorized.
                                </td>
                              </tr>
                              <tr>
                                <td align="center">
                                  Seller's other standard terms and conditions apply and
                                  supercede any additional or conflicting provisions in
                                  puchaser's order form.
                                </td>
                              </tr>
                              <tr>
                                <td align="center" className="page_text12b">
                                  NOTE : PAST DUE BALANCES ARE SUBJECT TO LATE PAYMENT
                                  CHARGES OF 1 1/2% PER MONTH.
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div>
          <Button className="btn-default" variant="outlined" onClick={e => onClickPrint()}>{"Print"}</Button>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default CommercialInvoicePop;