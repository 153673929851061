import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import CloseIcon from "@mui/icons-material/Close";

import { ComUtils } from "common/lib/ComUtils";
import { FileUtils } from "common/lib/FileUtils";
import Divider from "@mui/material/Divider";
import { CollectionView } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextArea from "common/components/ComTextArea";
import ComTextBox from "common/components/ComTextBox";
import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import { MessageContext } from "common/lib/MessageProvider";
import FileSaver from 'file-saver';

function OtherClaimExcelUploadPop({ closeFn, open, randomKey, pageInfo, claimType, claimDesc, billToCode, systemEntryNo, divisionCode, reverseFlag, claimObj, applyDataFn }) {
  //session정보
  const userId = sessionStorage.getItem("userId").toString();
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userLevel = pageInfo.userLevel;

  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  const [templateGridData, setTemplateGridData] = useState(null);
  const [templateGridColGroup, setTemplateGridColGroup] = useState(null);

  const templateGrid = useRef();
  const filterObject = useRef();

  //엑셀파일 업로드
  const entityUploadExcelFileNm = useRef(null);
  const excelUploadFileElement = useRef(null);
  const [fileNameVal, setFileNameVal] = useState("No file selected");
  const [excelData, setExcelData] = useState(null);

  //url
  const retrieveClaimUploadChkUrl = "/clm/oth/otherRequest/retrieveClaimUploadChk"
  const retrieveESNUploadResultExcelListUrl = "/clm/oth/otherRequest/retrieveESNUploadResultExcelList"

  const { messageObject } = useContext(MessageContext);
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028");    //Submit Error. Please Contact System Admin.

  const initFilter = (f) => {
    filterObject.current = f;
  };

  //download template
  const onDownTemplate = () => {
    let templateName = "sample_claim." + claimObj?.getTemplateFileName();
    ApiManager.ExcelDownloadSplitFile(templateGrid.current, templateName, null, templateDownComplete);
  }

  const onView = async () => {
    //fncViewResult
    let excelSendParam = {
      systemEntryNo: systemEntryNo
    }

    let resultData = await ApiManager.callExcelDowonload(retrieveESNUploadResultExcelListUrl, excelSendParam);
    let disposition = resultData?.headers['content-disposition'];

    let filename = 'Validation_Upload_Result_List_' + systemEntryNo + '.csv';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    if (filename) {
      try {
        FileSaver.saveAs(new Blob([resultData.data], { type: resultData.headers['content-type'] }), decodeURI(filename));
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  const templateDownComplete = () => {
    initTemplateGridFormat();
  }

  //엑셀파일 선택
  const onExcelUpload = () => {
    entityUploadExcelFileNm?.current?.setValue("No file selected");
    setFileNameVal("No file selected");
    excelUploadFileElement.current.click();
  }

  const handleExcelUploadChange = async (event) => {
    if (event.target.files.length > 0) {
      let chkFlow = true;
      let fileName = event.target.files[0].name;
      let fname = FileUtils.fileExtMatch(fileName);
      if (fname.length > 0) {
        if (fname !== "file-name excel") {
          chkFlow = false;
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Program attach only Excel file");
        }
      }
      if (chkFlow) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('multiHeader', claimObj.isMergeNumbering() ? 2 : 1);
        try {
          let resultData = await ApiManager.post("/Common/ExcelUtils/MultiHeaderExcelUpload", formData);
          console.log(resultData, '< --- result')
          if (resultData.length !== 0) {
            setExcelData(resultData);
          }
        } catch (error) {
          console.log('Error uploading file:', error);
        } finally {
          if (fileName !== null && fileName !== "") {
            entityUploadExcelFileNm?.current?.setValue(fileName);
            setFileNameVal(fileName);
          }
        }
      }
      excelUploadFileElement.current.value = "";
    }
  };

  const onUpload = async () => {
    if (fileNameVal === "" || fileNameVal === "No file selected") {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00118"));
      return;
    }

    const convertData = new Array;

    let excelList = excelData.map((el, idx) => {
      let newList = claimObj.convertTemplateToGridData(el, idx);
      convertData.push(newList);
      return newList;
    });

    let sendParam = {
      excelList: excelList,
      claimType, billToCode, divisionCode, systemEntryNo
    }

    let resultData = await ApiManager.post(retrieveClaimUploadChkUrl, sendParam);

    if (resultData?.resultCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgGsrErr028);
    } else {
      let rows = resultData.list;
      let rowLen = rows.length;
      let sMsg = "";
      let sRowMsg = "";
      let successRow = [];
      let systemEntryNoUpload = "";
      let validationResult = resultData?.validationResult ?? "N";

      for (let i = rowLen - 1; i > -1; i--) {
        if (rows[i].lgChargebackNo) {
          let nchargebackNoCount = parseFloat(rows[i].chargebackNoCount);
          let nrequestAmt = rows[i].requestAmt ? Number(rows[i].requestAmt) : 0;
          let nLgChargebackAmt = rows[i].lgChargebackAmt ? Number(rows[i].lgChargebackAmt) : 0;
          let nChargebackAmt = rows[i].chargebackAmount ? Number(rows[i].chargebackAmount) : 0;

          if (nchargebackNoCount < 1.0) {
            sRowMsg = sRowMsg + "Invalid LG Chargeback#, ";
          }
          if (nLgChargebackAmt > nrequestAmt) {
            sRowMsg = sRowMsg + "Chargebeck Amt should not be greater than Request Amt, ";
          }
          if (nLgChargebackAmt > nChargebackAmt) {
            sRowMsg = sRowMsg + "Chargebeck Amt should not be greater than [" + nChargebackAmt + "], ";
          }
        }

        if (claimType == "1" || claimType == "2" || claimType == "14" || claimType == "3" || claimType == "13" || claimType == "4") {
          let nProgramNoCount = Number(rows[i].programNoCount);
          if (nProgramNoCount < 1.0) {
            sRowMsg = sRowMsg + "Invalid LG Program No, ";
          }

          if (rows[i].lgPgmNo) {
            let nLgPgmNo = rows[i].lgPgmNo;
            let nRequestAmt = rows[i].requestAmt;
            let nMmodelCode = rows[i].modelCode;

            if (nRequestAmt <= 0) {
              sRowMsg = sRowMsg + "Request Amt should be greater than zero, ";
            }
          }
        }

        function fncCheckDateFormat(chk, msg) {
          if (chk) {
            let date = new Date(chk);
            let chkDate = date instanceof Date && !isNaN(date.valueOf());
            if (chkDate) {
              let tmp = chk.split("/");
              if (tmp.length == 3) {
                if (tmp[0].length != 2) sRowMsg = sRowMsg + msg;
              } else {
                sRowMsg = sRowMsg + msg;
              }
            } else {
              sRowMsg = sRowMsg + msg;
            }
          }
        }
        function fncCheckNumber(chk, msg) {
          if (chk) {
            let s = chk.replaceAll(',', '');
            s += '';
            s = s.replace(/^\s*|\s*$/g, '');
            if (s == '' || isNaN(s)) {
              sRowMsg = sRowMsg + msg;
            } else {
              return Number(s);
            }
          }
          return null;
        }

        switch (claimType) {
          case "1":
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "2":
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "14":
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "3":
            if (divisionCode == "B2B") {
              systemEntryNoUpload = rows[i].systemEntryNo;
            }
            fncCheckDateFormat(rows[i].priceDropInvDate, "Price Drop / Inventory Date is invalid. (mm/dd/yyyy, ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "13":
            if (divisionCode == "B2B") {
              systemEntryNoUpload = rows[i].systemEntryNo;
            }
            fncCheckDateFormat(rows[i].priceDropInvDate, "Price Drop / Inventory Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "4":
            fncCheckDateFormat(rows[i].priceProtectionDate, "LG Price Protection Date is invalid. (mm/dd/yyyy), ");
            fncCheckDateFormat(rows[i].priceDropInvDate, "Price Drop / Inventory Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].oldInvoicePrice = fncCheckNumber(rows[i].oldInvoicePrice, "Price Old Amt should be number., ");
            rows[i].newInvoicePrice = fncCheckNumber(rows[i].newInvoicePrice, "Price New Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "5":
            fncCheckDateFormat(rows[i].invoiceDate, "LG Invoice Date is invalid. (mm/dd/yyyy), ");
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].invoiceAmt = fncCheckNumber(rows[i].invoiceAmt, "LG Invoice Amt should be number., ");
            rows[i].oldInvoicePrice = fncCheckNumber(rows[i].oldInvoicePrice, "Invoice Price Old Amt should be number., ");
            rows[i].newInvoicePrice = fncCheckNumber(rows[i].newInvoicePrice, "Invoice Price New Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "6":
            fncCheckDateFormat(rows[i].invoiceDate, "LG Invoice Date is invalid. (mm/dd/yyyy), ");
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].invoiceFreight = fncCheckNumber(rows[i].invoiceFreight, "LG Invoice Freight TTL should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "7":
            fncCheckDateFormat(rows[i].invoiceDate, "LG Invoice Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].invoiceTax = fncCheckNumber(rows[i].invoiceTax, "LG Invoice Tax should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "8":
            fncCheckDateFormat(rows[i].invoiceDate, "LG Invoice Date is invalid. (mm/dd/yyyy), ");
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].invoiceFreight = fncCheckNumber(rows[i].invoiceFreight, "LG Invoice Freight TTL should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "9":
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].damageValue = fncCheckNumber(rows[i].damageValue, "Approved Value (%/Amt) should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
          case "10":
            fncCheckDateFormat(rows[i].activitySalesClaimDate, "Activity Date is invalid. (mm/dd/yyyy), ");
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            break;
          case "11":
            rows[i].requestQty = fncCheckNumber(rows[i].requestQty, "Request Qty should be number., ");
            rows[i].requestPrice = fncCheckNumber(rows[i].requestPrice, "Request Price should be number., ");
            rows[i].requestAmt = fncCheckNumber(rows[i].requestAmt, "Request Amt should be number., ");
            rows[i].lgChargebackAmt = fncCheckNumber(rows[i].lgChargebackAmt, "Chargeback Amt should be number., ");
            break;
        }

        if (claimType == "4") {
          if (!rows[i]?.priceProtectionDate) {
            sRowMsg = sRowMsg + "LG Price Protection Date(mm/dd/yyyy) is required, ";
          } else {
            if ((rows[i]?.priceProtectionDate)?.indexOf('ERROR') > -1) {
              sRowMsg = sRowMsg + "Invalid LG Price Protection Date.(" + rows[i].priceProtectionDate.substring(6) + "), ";
            }
          }

          if (!rows[i]?.priceDropInvDate) {
            rows[i].priceDropInvDate = rows[i].priceProtectionDate;
          }
        }

        if (claimType === "5" || claimType === "6" || claimType === "7" || claimType === "8" || claimType === "10") {
          if ((rows[i]?.invoiceNo)?.indexOf('ERROR') > -1) {
            sRowMsg = sRowMsg + "Invalid Invoice No.(" + rows[i].invoiceNo.substring(6) + "), ";
          }
        }

        if (claimType === "2" || claimType === "14" || claimType === "3" || claimType === "13" || claimType === "4" || claimType === "5" || claimType === "6" || claimType === "9" || claimType === "10" || claimType === "11") {
          if ((rows[i]?.modelCode)?.indexOf('ERROR') > -1) {
            sRowMsg = sRowMsg + "Invalid LG Model(" + rows[i].modelCode.substring(6) + "), ";
          }
        }

        if (claimType === "2" || claimType === "14" || claimType === "3" || claimType === "13" || claimType === "4" || claimType === "5" || claimType === "11") {
          if (!rows[i]?.requestQty) {
            sRowMsg = sRowMsg + "Request Qty. is required, ";
          } else {
            // if (rows[i]?.requestQty.indexOf(".") > -1) {
            //   sRowMsg = sRowMsg + "Decimal point is not allowed for Request Qty., ";
            // }
          }

          if (!rows[i]?.requestPrice) {
            sRowMsg = sRowMsg + "Request Price is required, ";
          }
          if (!rows[i].requestAmt) {
            sRowMsg = sRowMsg + "Request Amount is required, ";
          }

          let nRequestQty = rows[i].requestQty || 0;
          let nRequestPrice = rows[i].requestPrice || 0;
          let nrequestAmt = rows[i].requestAmt || 0;

          if ((nRequestQty * Number(nRequestPrice.toFixed(2))).toFixed(2) != nrequestAmt.toFixed(2)) {
            sRowMsg = sRowMsg + "Request Qty*Request Price is not equal to Request Amount, ";
          }
        }

        if (claimType === "9") {
          if (!rows[i]?.serialNo) {
            sRowMsg = sRowMsg + "Serial No. is required, ";
          } else {
            if ((rows[i]?.serialNo).indexOf('ERROR') > -1) {
              sRowMsg = sRowMsg + "Invalid Serial No.(" + rows[i].serialNo.substring(6) + "), ";
            }
          }

          if (!rows[i].damageValue) {
            sRowMsg = sRowMsg + "%/Amt. is required, ";
          }
          if (!rows[i].approvedBy) {
            sRowMsg = sRowMsg + "By(Person Name) is required, ";
          }
        }
        if (claimType === "11") {
          if (!rows[i].serialNo) {
            sRowMsg = sRowMsg + "Reference No. is required, ";
          } else {
            if ((rows[i].serialNo).indexOf('ERROR') > -1) {
              sRowMsg = sRowMsg + "Invalid Reference No.(" + rows[i].serialNo.substring(6) + "), ";
            }
          }
        }

        if (sRowMsg == "") {
          if (claimType === "5") {//Price Difference
            if (reverseFlag === "N") {
              let cost = rows[i].invoiceAmt || 0;
              let requestAmt = rows[i].requestAmt || 0;
              if (requestAmt > cost) {
                sRowMsg = sRowMsg + "Request Amount will not exceed the Invoice Amount., ";
              }
            }
          }
          if (claimType == "6") {//Freight
            if (reverseFlag == "N") {
              let cost = rows[i].invoiceFreight || 0;
              let requestAmt = rows[i].requestAmt || 0;
              if (requestAmt > cost) {
                sRowMsg = sRowMsg + "Request Amount will not exceed the Invoice Freight., ";
              }
            }
          }
          if (claimType == "7") {//Tax
            let cost = rows[i].invoiceTax || 0;
            let requestAmt = rows[i].requestAmt || 0;
            if (requestAmt > cost) {
              sRowMsg = sRowMsg + "Request Amount will not exceed the Invoice Tax., ";
            }
          }
          if (claimType == "8") {//Recycling
            if (reverseFlag == "N") {
              let cost = rows[i].invoiceFreight || 0;
              let requestAmt = rows[i].requestAmt || 0;
              if (requestAmt > cost) {
                sRowMsg = sRowMsg + "Request Amount will not exceed the Invoice Recycling., ";
              }
            }
          }
          if (claimType == "9") {
            for (let j = 0; j < rows.length; j++) {
              if (i != j) {
                if (rows[j].serialNo == rows[i].serialNo) {
                  sRowMsg = sRowMsg + "Invalid Serial No.(" + rows[i].serialNo + "), ";
                  break;
                }
              }
            }
          }
          if (claimType == "11") {
            for (let j = 0; j < rows.length; j++) {
              if (i != j) {
                if (rows[j].serialNo == rows[i].serialNo) {
                  sRowMsg = sRowMsg + "Invalid Reference No.(" + rows[i].serialNo + "), ";
                  break;
                }
              }
            }
          }
        }

        if (sRowMsg != "") {
          sRowMsg = "Row " + (i + 3) + ": " + sRowMsg.substring(0, sRowMsg.length - 2);
          sMsg = sRowMsg + "<br>" + sMsg;
        } else {
          successRow.push(rows[i]);
        }
        sRowMsg = "";
        //successRow.push(rows[i]);
      }

      if (sMsg != "") {

        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, sMsg,
          () => {

            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00121")); // Please check the upload result.
          }
        );

        return;
      }

      if (validationResult === "Y") {

        // Please check the upload result.
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00121"),
          () => {
            applyDataFn(successRow, systemEntryNoUpload);
          }

        );

      } else {

        successRow = successRow?.reverse();
        popComponent.current.hide();
        closeFn(successRow, systemEntryNoUpload);

      }



    }
  }

  const initTemplateGrid = (sender) => {
    templateGrid.current = sender;
  };

  const initTemplateGridFormat = () => {
    let gridFormat = claimObj?.getTemplateGirdFormat();
    setTemplateGridColGroup([...gridFormat]);
  };

  const initTemplateGridBindData = useCallback(() => {
    let resultData = claimObj?.getTemplateData();

    let view = new CollectionView(resultData, {
      trackChanges: true,
      refreshOnEdit: false,
    });

    setTemplateGridData(view);
  });

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    positionTopValue.current = popComponent?.current?.hostElement?.style?.top;

    initTemplateGridFormat();
    initTemplateGridBindData();

    if (open) {
      filterObject?.current?.clear();
    }

    setPopuView(() => open);

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [open, randomKey]);

  const onCancelClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null);
  };

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(false, null);
    });
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable pop-small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ width: "200px", maxWidth: "80vw" }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Excel Upload"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancelClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="mt-10 ">
          <Button variant="outlined" className="btn-default" onClick={onDownTemplate}>
            {`${claimDesc} Excel Form Download`}
          </Button>

          {(claimType === "3" && userType === "E") && <Button variant="outlined" className="btn-default ml-5" style={{ marginLeft: "10px" }} onClick={onView}>
            {"View"}
          </Button>
          }
        </div>
        <div className="mt-10 d-flex-between">
          <div className="d-flex" style={{ width: '100%' }}>
            <Button variant="outlined" className="btn-save" onClick={onExcelUpload} style={{ minWidth: '100px' }}>
              {"Find File"}
            </Button>
            <input type="file" onChange={handleExcelUploadChange} ref={excelUploadFileElement} style={{ display: 'none' }} /*multiple*/ />
            <ComTextBox ref={entityUploadExcelFileNm} elementId={"entityUploadExcelFileNm"}
              defaultValue={fileNameVal}
              defaultDisabled={true}
              defaultStyle={{ border: 'none', backgroundColor: 'white' }}
            />
          </div>
          <div className="d-flex">
            <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            <div className="ml-5">
              <Button variant="outlined" className="btn-default" onClick={onUpload}>
                Excel Upload
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex-start">
          <div className="w-50">
            {//template - 엑셀다운로드 그리드
              <FlexGrid
                columnGroups={templateGridColGroup}
                itemsSource={templateGridData}
                initialized={initTemplateGrid}
                style={{ minheight: "200px", height: "300px", display: "none" }}
              >
              </FlexGrid>
            }
          </div>
        </div>
        <div className="mt-10">
          {"1) "}
          <span className="field-required fwb">
            {" [Excel Form Download] "}
          </span>
          Please Sample Download and click, and write to the downloaded file.
        </div>
        <div className="mt-10">
          {"2) "}
          Save the file must be saved in
          <span className="field-required fwb">{" [Excel Sheet] "}</span>
          format you can be uploaded.
        </div>
        <div className="mt-10">
          {"3) "}
          {
            "[Caution] The xls file you want to upload is not uploaded if it is open. Please retry after closing the xls file is being used."
          }
        </div>
        <div className="mt-10">
          {"4) "}
          {
            "[Caution] If the file is open 'DATAFORMATERROR.' message is output."
          }
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default OtherClaimExcelUploadPop;
