import * as wijmo from "@grapecity/wijmo";
import { InputNumber, InputDate } from "@grapecity/wijmo.input";
import { createElement, format } from "@grapecity/wijmo";
import { BaseClaim } from "./BaseClaim";
import search from "assets/icons/search.svg";

class ClaimVLPriceDiff extends BaseClaim {
  constructor(props) {
    super(props);    
  }

  getGirdFormat = () => {
    let out = [
      { 
        binding: 'isChecked2', 
        header: " ", 
        width: 30, 
        allowDragging: false, 
        isReadOnly: false, 
        allowResizing: false, 
        allowSorting: false ,
        visible: this.pageType !== 'Dtl',
      },
      {
        binding: "modelCode",
        header: "LG Model",
        width: 200,
        allowDragging: true,
        isRequired: true,
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openModelCodePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "RequestOrg",
        header: "Org. Request",
        width: 150,
        allowDragging: true,
        visible: !this.orgRequestHidden,
        columns: [
          {
            binding: "orgRequestQty",
            header: "Qty",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
            aggregate: "Sum",
            align: "right",
            visible: !this.orgRequestHidden,
          },
          { 
            binding: "orgRequestPrice", 
            header: "Price", 
            width: 100, 
            allowDragging: true, 
            isReadOnly: true,
            format: "n2", 
            align: "right", 
            visible: !this.orgRequestHidden,
          },
          { 
            binding: "orgRequestAmt",
            header: "Amt", 
            width: 100, 
            allowDragging: true, 
            isReadOnly: true,
            align: "right", 
            format: "n2", 
            aggregate: "Sum", 
            visible: !this.orgRequestHidden,
          },
        ],
      },
      {
        binding: "request",
        header: "Request",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "requestQty",
            header: "Qty",
            width: 100,
            allowDragging: true,
            isRequired: true,
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
            aggregate: 'Sum',
          },
          {
            binding: "requestPrice",
            header: "Price",
            width: 100,
            allowDragging: true,
            isRequired: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
          {
            binding: "requestAmt",
            header: "Amt",
            width: 100,
            allowDragging: true,
            isRequired: true,
            isReadOnly: true,
            align: "right",
            format: "n2",
            aggregate: 'Sum',
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
        ],
      },
      {
        binding: "rejectCode",
        header: "Rejection Reason",
        width: 180,
        allowDragging: true,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
        dataMap: this.rejectYn === 'Y' ? this.rejectionReasonMap : undefined,
      },
      {
        binding: "rejectDesc",
        header: "Rejection Comment",
        width: 160,
        allowDragging: true,
        isRequired: false,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
      },
      {
        binding: "reference",
        header: "Reference",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "serialNo",
            header: "No",
            width: 150,
            allowDragging: true,
            isRequired: true,
          },
          {
            binding: "serialChk",
            header: "Dup",
            width: 100,
            allowDragging: true,
            isReadOnly: true,
          },
        ],
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openChargePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc?.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        align: "right",
        format: "n2",
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
      },

      {
        binding: "customerDesc",
        header: "Customer Description",
        width: "*",
        minWidth: 250,
        allowDragging: true,
        isRequired: false,
      },
      {
        binding: "approved",
        header: "Approved",
        width: 150,
        allowDragging: true,
        visible: this.pageType === 'Dtl',
        columns: [
          {
            binding: "approvedQty",
            header: "Qty",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            align: "right",
            editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0 })),
            aggregate: 'Sum',
            visible: this.pageType === 'Dtl',
          },
          {
            binding: "approvedPrice",
            header: "Price",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            align: "right",
            format: "n2",
            visible: this.pageType === 'Dtl',
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
          },
          {
            binding: "approvedAmt",
            header: "Amt",
            width: 150,
            allowDragging: true,
            isRequired: this.pageType === 'Dtl',
            isReadOnly: true,
            align: "right",
            format: "n2",
            editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
            aggregate: 'Sum',
            visible: this.pageType === 'Dtl',
          },
        ],
      },
    ];

    return out;
  }

  setFormatItem = (s, e) => {
    if (s.columns[e.col]?.binding === "modelCode") {
      s.columnFooters.setCellData(0, e.col, "Total", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  }

  getData = () => {
    return [{
      isChecked2: false,
      modelCode: "",
      requestQty: null,
      requestPrice: null,
      requestAmt: null,
      serialNo: "",
      serialChk: "",
      lgChargebackNo: "",
      lgChargebackAmt: null,
      customerDesc: "",
    }];
  }


  getTemplateFileName = () => {
    return "VLPriceDiff";
  }

  getTemplateGirdFormat = () => {    
    return [
      {
        binding: "modelCode",
        header: "*LG Model",
        width: 150,
      },
      {
        binding: "request",
        header: "Request",
        width: 150,
        allowDragging: true,
        columns: [
          {
            binding: "requestQty",
            header: "*Qty",
            width: 150,
            align: "right",
          },
          {
            binding: "requestPrice",
            header: "*Price",
            width: 150,
            align: "right",
          },
          {
            binding: "requestAmt",
            header: "*Amt",
            width: 150,
            align: "right",
          },
        ],
      },
      {
        binding: "reference",
        header: "Reference",
        width: 150,
        columns: [
          {
            binding: "serialNo",
            header: "*No",
            width: 200,
          },
          {
            binding: "serialChk",
            header: "*Dup",
            width: 100,
          },
        ],
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 200,
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 200,
        align: "right",
      },
      {
        binding: "customerDesc",
        header: "Customer Description",
        width: 250,
      },
    ];
  }

  getTemplateData = () => {
    return [{
      modelCode: "input LG Model Code",
      requestQty: "1",
      requestPrice: "1",
      requestAmt: "1",
      serialNo: "input Serial No",
      serialChk: "Y",
      lgChargebackNo: "input Chargeback No",
      lgChargebackAmt: "1",
      customerDesc: "input Customer Description",
    }];
  }

  convertTemplateToGridData = (el, idx) => {
    let gridFormat = this.getTemplateGirdFormat();

    return {
      rowNum: idx,
      modelCode: el[this.getHeaderByBinding(gridFormat, "modelCode")],
      requestQty: el[this.getHeaderByBinding(gridFormat, "requestQty")],
      requestPrice: el[this.getHeaderByBinding(gridFormat, "requestPrice")],
      requestAmt: el[this.getHeaderByBinding(gridFormat, "requestAmt")],
      serialNo: el[this.getHeaderByBinding(gridFormat, "serialNo")],
      serialChk: el[this.getHeaderByBinding(gridFormat, "serialChk")],
      lgChargebackNo: el[this.getHeaderByBinding(gridFormat, "lgChargebackNo")],
      lgChargebackAmt: el[this.getHeaderByBinding(gridFormat, "lgChargebackAmt")],
      customerDesc: el[this.getHeaderByBinding(gridFormat, "customerDesc")],
    }
  }

  isMergeNumbering() {
    return true;
  }
}
export default ClaimVLPriceDiff;