import React, { useState, useEffect, useRef, useCallback } from "react";
import { ApiManager } from "../lib/ApiUtil";
import * as wjInput from "@grapecity/wijmo.react.input";

const ComComboHeaderTable = React.forwardRef(
  (
    {
      elementId, // 객체 아이디
      callRelationFuntion, // 부모에서 호출 할 함수
      loadCompleteCallBack, // 데이터가 로드됐을때 부모에서 호출할 함수
      defaultValue, // 기본 값
      defaultIndex, // 기본 인덱스
      defalultList,
      defaultDisabled, // 활성화여부
      defaultStyle, // 스타일
      defaultClassName, // CSS 클래스명
      dropdownClass,
      colTitles, // 컬럼타이틀

      isDisplayCode,         // 콤보박스 Code or Name 우선 표시(default false(name))
      isSetValCode,          // select시 Value에 Code or Name 세팅(default true(code))

      isSmallCode,           // 콤보박스 Code 작게 표시

      cdnmFormat, //header display format 지정 (true => cd-nm or nm-cd /// false => cd or nm)

      hasHeader,
      displayKeys,
      displayValue,
      settingValue,
      initAll,
      initSelect,
      styleHeader,
      styleValue
    },
    ref
  ) => {
    // 부모 Ref 를 내부 Ref로 변경 처리

    const cboRef = useRef();
    const cboIndexRef = useRef(null);
    const focusRef = useRef(null);

    const cboInitValue = useRef(null);
    const cboInitIndex = useRef(null);

    const cboCode = useRef();
    const cboName = useRef();

    // Combo 객체
    const [comboObject, setComboObject] = useState();
    // Combo Data
    const [comboData, setComboData] = useState([]);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    const [userHeight, setUserHeight] = useState(null);
    const [hasMaxHeightStyle, setHasMaxHeightStyle] = useState(false);

    // Code Or Name 관련
    const [displayCode, setDisplayCode] = useState(false);
    const [setValCode, setSetValCode] = useState(true);
    const [displayCdnm, setDisplayCdnm] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [keys, setKeys] = useState([]);
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [isAll, setIsAll] = useState(false);
    const [isSelect, setIsSelect] = useState(false);
    const [styles, setStyleValue] = useState([]);
    const [styleHd, setStyleHeader] = useState([]);
    const [shouldFocus, setShouldFocus] = useState(false);


    useEffect(() => {
      if (shouldFocus && focusRef.current) {
        focusRef.current.control.hostElement.focus();
        setShouldFocus(false);
      }
    }, [shouldFocus])

    React.useImperativeHandle(ref, () => ({
      getValue: (codeField) => {
        if (cboRef.current) {
          let checkedItem = cboRef.current;
          let tempCode = setValCode ? codeField || cboCode.current || settingValue : cboName.current || displayValue;
          if (checkedItem) {
            let idx = cboIndexRef?.current ?? 0;
            if (idx === 0 && (checkedItem[tempCode] === (initAll ? "-- ALL --" : (initSelect ? "-- Select --" : "")))) {
              return "";
            }
            return checkedItem[tempCode];
          }
          return "";
        }
      },
      getValueItem: () => cboRef.current,
      getSelectedIndex: () => cboIndexRef.current,

      setValue: (v) => {
        if (comboObject) {
          if (v) {
            comboObject.selectedValue = v;
            cboInitValue.current = v;
          } else {
            comboObject.selectedValue = "";
            cboInitValue.current = "";
          }
        }
      },
      setIndex: (v) => {
        if (comboObject) {
          comboObject.selectedIndex = v;
        }
        cboInitIndex.current = v;
      },
      setStyle: (v) => {
        setHasStyle(true);
        setUserStyle(v);
      },
      setClassName: (v) => {
        setHasClassName(true);
        setUserClassName(v);
      },
      setDisabled: (v) => {
        setUserDisable(v);
      },

      setList: (v) => {
        let labelDesciption = initAll ? "-- ALL --" : initSelect ? "-- Select --" : "";
        // if(v !== null){
        if (
          v["initValue"] &&
          v.initValue !== undefined &&
          v.initValue !== null
        ) {
          cboInitIndex.current = null;
          cboInitValue.current = v.initValue;
        } else if (
          v["initIndex"] &&
          v.initIndex !== undefined &&
          v.initIndex !== null
        ) {
          cboInitValue.current = null;
          cboInitIndex.current = v.initIndex;
        }

        if (v[code]) {
          cboCode.current = v[code];
        }
        if (v[name]) {
          cboName.current = v[name];
        }
        if (v["data"]) {
          // if (isAll) {
          //   const allCombo = [{ [code]: "", [name]: "ALL" }]
          //   setComboData(allCombo.concat(v.data));
          // }else if (isSelect) {
          //   const selCombo = [{ [code]: "", [name]: "Select" }]
          //   setComboData(selCombo.concat(v.data));
          if (labelDesciption !== "") {
            const selCombo = [{ [code]: "", [name]: labelDesciption }]
            setComboData(selCombo.concat(v.data));
          } else {
            cboInitValue.current = null;
            cboInitIndex.current = 1;
            const defaultCombo = [{ [code]: "", [name]: "" }];
            setComboData(defaultCombo.concat(v.data));
            // setComboData(v.data);
          }
        } else {
          const selCombo = [{ [code]: "", [name]: labelDesciption }]
          setComboData(selCombo);
        }
        // }else{
        //   if (isAll) {
        //     const allCombo = [{ [code]: "", [name]: "ALL" }]
        //     setComboData(allCombo);
        //   }else if (isSelect) {
        //     const selCombo = [{ [code]: "", [name]: "Select" }]
        //     setComboData(selCombo);
        //   }

        // }

      },
      setBindColumInfo: (v) => {
        if (v[code]) {
          cboCode.current = v[code];
        }
        if (v[name]) {
          cboName.current = v[name];
        }
      },
      setLoadData: (v) => {
        if (
          v["initValue"] &&
          v.initValue !== undefined &&
          v.initValue !== null
        ) {
          cboInitIndex.current = null;
          cboInitValue.current = v.initValue;
        } else if (
          v["initIndex"] &&
          v.initIndex !== undefined &&
          v.initIndex !== null
        ) {
          cboInitValue.current = null;
          cboInitIndex.current = v.initIndex;
        }
        let url = null;
        let param = null;
        let label = null;
        let labelDesciption = initAll ? "ALL" : initSelect ? "Select " : "";
        let cd = null;
        let nm = null;
        if (v["url"]) {
          url = v.url;
        }
        if (v["param"]) {
          param = v.param;
        }
        if (v["label"]) {
          label = v.label;
        }
        if (v["labelDesciption"]) {
          labelDesciption = v.labelDesciption;
        }
        if (v[code]) {
          cboCode.current = v[code];
          cd = v[code];
        }
        if (v[name]) {
          cboName.current = v[name];
          nm = v[name];
        }

        loadCobmoData(url, param, label, labelDesciption, cd, nm);
      },
      setFocusCode: (v) => {
        setShouldFocus(true);
      },
    }));

    const loadCobmoData = useCallback(
      async (url, param, label, labelDesciption, cdColumn, nmCloumn) => {
        try {
          let retrieveComboData = null;
          if (param) {
            retrieveComboData = await ApiManager.post(url, param);
          } else {
            retrieveComboData = await ApiManager.post(url);
          }

          if (label) {
            let tempCode = cdColumn || settingValue;
            let tempName = nmCloumn || displayValue;

            let tempAll = {};
            tempAll[tempName] = labelDesciption || initAll ? "-- ALL --" : initSelect ? "-- Select --" : "";
            tempAll[tempCode] = "";

            let allArray = [];
            allArray.push(tempAll);

            setComboData(allArray.concat(retrieveComboData));
          } else {
            setComboData(retrieveComboData);
          }

          if (comboObject) {
            if (defaultValue !== undefined && defaultValue !== null) {
              comboObject.selectedValue = defaultValue;
            } else if (defaultIndex !== undefined && defaultIndex !== null) {
              comboObject.selectedIndex = defaultIndex;
            }
          }
        } catch (ex) {
          console.log(ex.message);
        }
      },
      [comboObject, defaultValue, defaultIndex]
    );

    useEffect(() => {
      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }
      if (dropdownClass) {
        setUserHeight(dropdownClass);
        setHasMaxHeightStyle(true);
      } else {
        setHasMaxHeightStyle(false);
      }

      if (isDisplayCode !== undefined) {
        isDisplayCode ? setDisplayCode(true) : setDisplayCode(false);
      }

      if (isSetValCode !== undefined) {
        isSetValCode ? setSetValCode(true) : setSetValCode(false);
      }

      if (cdnmFormat !== undefined) {
        cdnmFormat ? setDisplayCdnm(true) : setDisplayCdnm(false);
      }

      if (hasHeader !== undefined) {
        setHeaders(hasHeader);
      }

      if (displayKeys !== undefined) {
        setKeys(displayKeys);
      }

      if (displayValue !== undefined) {
        setName(displayValue);
      }

      if (settingValue !== undefined) {
        setCode(settingValue);
      }

      if (initAll !== undefined) {
        initAll ? setIsAll(true) : setIsAll(false);
      }
      if (initSelect !== undefined) {
        initSelect ? setIsSelect(true) : setIsSelect(false);
      }
      if (styleValue !== undefined) {
        setStyleValue(styleValue);
      }
      if (styleHeader !== undefined) {
        setStyleHeader(styleHeader);
      }
      if (defalultList) {
        setComboData(defalultList);
      }

      if (comboObject) {
        if (defaultValue !== undefined && defaultValue !== null) {
          comboObject.selectedValue = defaultValue;
          //setUserValue(defaultValue);
        } else if (defaultIndex !== undefined && defaultIndex !== null) {
          comboObject.selectedIndex = defaultIndex;
          //setUserSelectedIndex(defaultIndex);
        }
      }
    }, [
      defaultValue,
      defaultIndex,
      comboObject,
      defaultStyle,
      defaultClassName,
      defaultDisabled,
      dropdownClass,
      isDisplayCode,
      isSetValCode,
      hasHeader,
      displayKeys,
      defalultList,
      initAll,
      initSelect,
      styleValue
    ]);

    const comboInit = (sender) => {
      setComboObject(sender);
    };

    const onComboSelectedIndexChanged = (e) => {
      /* cboIndexRef.current = e.selectedIndex;
      cboRef.current = e.selectedItem; */
      if (!(initAll || initSelect) && e.selectedIndex === 0) {
        e.selectedIndex = cboIndexRef.current;
        e.selectedItem = cboRef.current;
      } else {
        cboIndexRef.current = e.selectedIndex;
        cboRef.current = e.selectedItem;
      }

      if (callRelationFuntion) {
        callRelationFuntion(
          elementId,
          e.selectedValue,
          e.selectedIndex,
          e.selectedItem
        );
      }
    };

    const onItemSourceChanged = (cbo) => {
      if (cboInitValue.current) {
        cbo.selectedValue = cboInitValue.current || "";
      } else if (
        cboInitIndex.current !== undefined &&
        cboInitIndex.current !== null
      ) {
        cbo.selectedIndex = cboInitIndex.current;
      }

      if (loadCompleteCallBack) {
        loadCompleteCallBack(elementId, cbo.selectedValue, cbo.selectedIndex);
      }
    };

    return (
      <wjInput.ComboBox
        dropDownCssClass={`${hasMaxHeightStyle ? userHeight : "comobo-default-max-height"
          } combo-table-comboheaderTable`}
        name="ComComboHeaderTable"
        displayMemberPath={name}
        selectedValuePath={code}
        initialized={comboInit}
        itemsSource={comboData}
        itemsSourceChanged={onItemSourceChanged}
        selectedIndexChanged={onComboSelectedIndexChanged}
        isEditable
        headerPath={name}
        ref={focusRef}
        wjItemTemplate={(context) => (
          context.itemIndex === 0 ?
            <div className="item-wrapper-header" key={code + "header"}>
              {headers.map((header, idx) => {
                return <span
                  // className={idx === 0 ? "first-column" : "after-column"}
                  className={
                    styleHd.length === 0 ? idx === 0 ? "first-column" : "after-column"
                      : styleHd[idx]
                  }

                >{header}</span>
              })}
            </div> :
            <div className="item-wrapper-multi" style={{ "alignItems": "center" }} key={code + "body"}>
              {keys.map((key, idx) => {
                return <span
                  className={
                    styles.length === 0 ? idx === 0 ? "first-data" : "after-data"
                      : styles[idx]
                  }
                // className={idx === 0 ? "first-data" : "after-data" }
                >{context.item[key]}</span>
              })}</div>
        )}
        isDisabled={userDisable}
        className={hasClassName ? userClassName : "wj_with10"}
        style={hasStyle ? userStyle : {}}
      />
    );
  }
);
//export default ComCombo;
export default React.memo(ComComboHeaderTable);
//export default React.memo(ComCombo,(prevProps, nextProps) => prevProps === nextProps);
