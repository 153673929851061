import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ComTextBox from 'common/components/ComTextBox';
import ComRadioBox from 'common/components/ComRadioBox';
import PanelGroup from "react-panelgroup";

import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import Divider from "@mui/material/Divider";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';
import ComTextArea from 'common/components/ComTextArea';

function ARCommentPop({ open, closeFn, randomKey, headerId, recipient, recipientName, pageInfo}) {

  //url
  const retrieveARCommentList = "/com/splComPop/retrieveARCommentList";
  const saveARCommnet = "/com/splComPop/saveARCommnet";

  const entitySubject = useRef(null);
  const entityArComment = useRef(null);

  const filterSearch = useRef(null);

  const gridRef = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();

  /***** BillToSearch Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {

    setGridObject(sender);
    gridRef.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, { selectionMode: 6, canAreaInfo: false, hasShowNumbering: true, hasCheckBox: false, hasDisableCheck: true, hasReadOnlyCombo: true, readOnlyComboList: ["langCode", "effectiveStartDate"] })

    sender.selectionChanged.addHandler((s, e) => {
      if (s.selection.topRow !== -1 && s.selection.isSingleCell) {
        entityArComment.current.setValue(s.selectedItems[0].arComment);
        entitySubject.current.setValue(s.selectedItems[0].subject);
      }   
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

  }

  const onSelectionChanged = (sender) => {
  }

  const onSourceChanged = () => {
    if (gridObject) {
      gridObject.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory 
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: 'sendDate', header: "Sent Date", width: 120, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'sendBy', header: "Sent By",  width: 160, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'subject', header: "Subject", minWidth: 360, width: "*", allowDragging: true, isReadOnly: true, align: "left" }
      , { binding: 'arComment', header: "ArComment", width: 10, allowDragging: true, isReadOnly: true, visible: false }
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();
    SearchGridFormat();

    const getPageInfo = async () => {

      let sendParam = {
        headerId: headerId
        , pageSize: '1'
        , rowSize: pageInfo.pageRowSize
        , pageNo: '1'
      }

      // send
      let resultData = await ApiManager.post(retrieveARCommentList, sendParam);

      if (resultData) {        
        setGridData(new CollectionView(resultData.list, { trackChanges: true, refreshOnEdit: false }));

        searchDataRef.current = sendParam;
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = resultData.list.length;

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current);
        } else {
          setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
        }
      }
    }

    if (open) {
      filterSearch?.current?.clear();
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  // 저장
  const onClickSend = () => {
    // validation
    if (!entitySubject.current.getValue()) {
      ComNoticfication.ShowErrorUI("Error", "Please input Subject!");            
      return;
    }

    if (!entityArComment.current.getValue()) {
      ComNoticfication.ShowErrorUI("Error", "Please input Comment!");            
      return;
    }

    if (entityArComment.current.getValue().length > 4000) {
      ComNoticfication.ShowErrorUI("Error", "AR Comment's maximum length is 4000.");            
      return;
    }

    ComNoticfication.ShowConfirmUI("Info", "Are you sure you want to send this comment?", async (r) => {
      if (r) {
        let sendParam = {
          headerId: headerId,
          recipient: recipient,
          recipientName: recipientName,
          arComment: entityArComment.current.getValue(),
          subject: entitySubject.current.getValue()
        };
        let resultData = await ApiManager.post(saveARCommnet, sendParam);
  
        // 성공이면 리스트 변경
        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI("Error", "An error occurred during processing");
        } else {
          setGridData(new CollectionView(resultData.result, { trackChanges: true, refreshOnEdit: false }));
  
          totCntRef.current = Number(resultData.totCnt);
          pageCntRef.current = resultData.result.length;
  
          if (totCntRef.current == pageCntRef.current) {
            setGridDataCount(totCntRef.current);
          } else {
            setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
          }
        }
      }      
    });
  }

  
  const onSearchExecute = async (sendParam, param) => {
    let row_index = gridRef.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveARCommentList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveARCommentList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
        setGridDataCount(0);
      } else {
        let resultCnt = 0;
        if (param.searchFlag) {

          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current);
        } else {
          setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
        }
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const closePopup = () => {
    popComponent.current.hide();
    closeFn && closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup 
      className="pop-resizable_small"
      isDraggable={true} 
      isResizable={true} 
      initialized={initForm} 
      hideTrigger="None" 
      positionChanging={popupPositionChanging} 
      positionChanged={positionChanged} 
      shown={shown} 
      sizeChanged={sizeChanged} >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> 
          AR Comment
        </h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Subject
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox 
                  ref={entitySubject} 
                  elementId={"entitySubject"} 
              />
              <Button variant="contained" className="btn-save" onClick={(e) => onClickSend()}>
                {"Send"}
              </Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <ComTextArea 
            ref={entityArComment} 
            elementId={"entityArComment"} 
            defaultMaxLength={4000}
            defaultStyle={{height: '140px'}}
          />   
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "160px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default ARCommentPop;