import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import _ from 'lodash';

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from 'react-router-dom'
import {
  registerUser, registerUserIsPersonalInfoFinish, registerUserIsLoginInfoFinish,
  registerUserIsLGAccountInfoFinish, registerUserProceeding, registerUserSuccessful, registerUserFailed
} from 'store/auth/register/reducer';

import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import "assets/styles/pages/SignUpPage.scss";

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import HeaderSignUp from "./HeaderSignUp";
import FooterLogin from "Layout/FooterLogin";
import Breadcrumb from "common/components/ComBreadcrumb";

import LGAccountInforExternalPage from "./LGAccountInforExternalPage";
import LoginInforExternalPage from "./LoginInforExternalPage";
import PersonalInforExternalPage from "./PersonalInforExternalPage";

import CompleteSignUpExternalPage from "./CompleteSignUpExternalPage";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";

const steps = [
  "LG Account Information",
  "Login Information",
  "Personal Information",
];

function SignUpExternal() {

  const insertB2BUserUrl = "/init/b2buser/insertB2BUser";

  const navigate = new useNavigate();

  const lgAccountInfoRef = useRef(null);
  const loginInfoRef = useRef(null);
  const personalInfoRef = useRef(null);

  const dispatch = useDispatch();
  const AccountProperties = createSelector(
    (state) => state.account,
    (account) => ({
      user: account.user,
      isLGAccountInfoFinish: account.isLGAccountInfoFinish,
      isLoginInfoFinish: account.isLoginInfoFinish,
      isPersonalInfoFinish: account.isPersonalInfoFinish,
      success: account.success
    })
  );
  const { user, isLGAccountInfoFinish, isLoginInfoFinish, isPersonalInfoFinish, success } = useSelector(AccountProperties);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        if (
          user.paramBillTo && user.billToCheck
        ) {
          if (lgAccountInfoRef.current.getBillTo() !== user.paramBillTo) {
            dispatch(registerUserIsLGAccountInfoFinish(false));
            lgAccountInfoRef.current.isEmptyRequired();
          } else {
            dispatch(registerUserProceeding(user));
            dispatch(registerUserIsLGAccountInfoFinish(true));
            setActiveStep((prevActiveStep) => prevActiveStep + 1); 
          }
        } else {
          dispatch(registerUserIsLGAccountInfoFinish(false));
          lgAccountInfoRef.current.isEmptyRequired();
        }
        break;
      case 1:
        if (
          user.userId && user.userIdCheck && user.password && user.repassword && user.userGroup
        ) {
          dispatch(registerUserIsLoginInfoFinish(true));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          dispatch(registerUserIsLoginInfoFinish(false));
          loginInfoRef.current.isEmptyRequired();
        }
        break;
      case 2:
        if (
          user.userName && user.email && user.officePhone
        ) {
          dispatch(registerUserIsPersonalInfoFinish(true));
          if (isPersonalInfoFinish && isLoginInfoFinish) {

            let userInfo = _.cloneDeep(user);
            userInfo.txtBillTo = userInfo.paramBillTo;

            let resultData = await ApiManager.post(insertB2BUserUrl, userInfo);
            console.log("resultData:", resultData);

            if (resultData?.resultCode) {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData?.resultMsg);
              dispatch(registerUserFailed(resultData?.resultMsg));
            }
            else {
              dispatch(registerUserSuccessful(user));
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }

          }
        } else {
          dispatch(registerUserIsPersonalInfoFinish(false));
          personalInfoRef.current.isEmptyRequired();
        }
        break;
      default:
        break;
    }
    // if (activeStep !== 3) {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  const handleBack = () => {
    if (activeStep == 0) {
      navigate("/sign-up", { state: { type: true } });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const breadCrumbs = [
    {
      text: "Login",
      link: "#",
    },
    {
      text: "Sign up",
      link: "#",
    },
  ];
  const mapSteps = () => {
    switch (activeStep) {
      case 0:
        return <LGAccountInforExternalPage ref={lgAccountInfoRef} />;
      case 1:
        return <LoginInforExternalPage ref={loginInfoRef} />;
      case 2:
        return <PersonalInforExternalPage ref={personalInfoRef} />;
    }
  };

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    dispatch(registerUser("EXTERNAL"));

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);

  return (

    <div className="sign-up-page">
      <HeaderSignUp />
      <Divider />
      <div className="container-signup">
        {/* <Breadcrumb items={breadCrumbs} /> */}
        <div className="stepper-signup">
          <Box className="box-signup">
            {activeStep !== 3 ? (
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  return (
                    <Step
                      key={index}
                      className={
                        index < activeStep
                          ? "is-completed"
                          : index === activeStep
                            ? "is-active"
                            : "is-default"
                      }
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            ) : (
              <></>
            )}
            {activeStep === 3 ? (
              <React.Fragment>
                <CompleteSignUpExternalPage />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="body-text">
                  <div className="boder-text">
                    <Typography component={"span"} variant={"body2"}>
                      {mapSteps()}
                    </Typography>
                    <Box className="btn-footer">
                      <Button
                        variant="outlined"
                        className="btn-pre"
                        onClick={handleBack}
                        disabled={activeStep === 3}
                      >
                        Previous
                      </Button>
                      <Button variant="contained" onClick={handleNext}>
                        {activeStep === 2 ? "Submit" : "Next"}
                      </Button>
                    </Box>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Box>
        </div>
      </div>

      <FooterLogin />
    </div>
  );
}
export default SignUpExternal;
