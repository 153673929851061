import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUser, registerUserProceeding, registerUserSuccessful, registerUserFailed } from 'store/auth/register/reducer';

import "assets/styles/pages/SignUpPage.scss";
import Grid from '@mui/material/Grid';

// import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import ComTextBox from "common/components/ComTextBoxWithForm";
import ComTextBoxFind from "common/components/ComTextBoxFindWithForm";
import ComCombo from 'common/components/ComCombo';

// import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";

import CityZipSchPopWithRegister from "Pages/Pop/CityZipSchPopWithRegister";

const StoreInforSpiffPage = React.forwardRef(
    ({ }
        , ref
    ) => {

        const alphaNumericRegex = /^[a-zA-Z0-9 #.\-]*$/;

        // 메세지 추가
        // const { messageObject } = useContext(MessageContext);
        // const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");
        // const errorMessage = ComUtils.getMessage(messageObject, "JMSG_COM_ERR_001");

        const entityStoreName = useRef(null);
        const entityReviewRemark = useRef(null);
        const entityBizUnit = useRef(null);
        const entityStoreAddress = useRef(null);

        const entityCity = useRef(null);
        const entityState = useRef(null);
        const entityZip = useRef(null);

        const randomKey = useRef(null);
        const zipResData = useRef(null);
        const [openModalZip, setOpenModalZip] = useState(false);

        const dispatch = useDispatch();
        const AccountProperties = createSelector(
            (state) => state.account,
            (account) => ({
                user: account.user
            })
        );
        const { user } = useSelector(AccountProperties, shallowEqual);

        React.useImperativeHandle(ref, () => ({
            isEmptyRequired: () => {
                if (!user.companyName) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Store/Company name is required."
                        , () => { entityStoreName.current.setFocus(); });
                }
                else if (!user.companyAddress) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Store Street Address is required."
                        , () => { entityStoreAddress.current.setFocus(); });
                }
                else if (!user.storeZipcode) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Zip Code is required."
                        , () => { entityZip.current.setFocus(); });
                }
                else if (!user.reviewRemark) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Which LG Distributor does your store purchase from? is required."
                        , () => { entityReviewRemark.current.setFocus(); });
                } else { }
            }
        }));

        const defaultZipSearchParam = useRef({
            url: "/init/b2buser/retrieveCityZipList"
            , comCode: user.comCode
            , searchBizType: ''
            , searchText: ''
            , searchType: 'zip'
            , pageSize: '1'
            , rowSize: '100'
            , pageNo: '1'
        });

        const openZipPopup = (elementId, userCode, resultData) => {
            randomKey.current = ComUtils.setRefRandomKey();

            //조회결과 존재
            if (resultData?.list) {
                if (resultData.list.length == 1) {
                    //단건이면 세팅 (팝업 오픈 X)
                    entityZip.current.setValue(resultData.list[0].postalCode);
                    entityCity.current.setValue(resultData.list[0].cityName);
                    entityState.current.setValue(resultData.list[0].stateCode);
                    user.storeCity = resultData.list[0].cityName;
                    user.storeState = resultData.list[0].stateCode;
                    user.storeZipcode = resultData.list[0].postalCode;
                    dispatch(registerUserProceeding(user));
                } else {
                    //여러건이면 팝업으로 전달
                    zipResData.current = resultData;
                    setOpenModalZip(true);
                }
            } else {
                setOpenModalZip(true);
            }
        };

        const callZipPopup = (elementId, value) => {
            defaultZipSearchParam.current.searchText = value.toUpperCase();
            entityZip.current.setValue(value.toUpperCase());
        };

        const clearZipPopup = (elementId, value) => {
            entityZip.current.setValue(value);
            entityCity.current.setValue(value);
            entityState.current.setValue(value);
            user.storeCity = value;
            user.storeState = value;
            user.storeZipcode = value;
            dispatch(registerUserProceeding(user));
        };

        const closeZipPopup = (selectedItems) => {
            if (selectedItems !== undefined && selectedItems != null) {
                entityZip.current.setValue(selectedItems.postalCode);
                entityCity.current.setValue(selectedItems.cityName);
                entityState.current.setValue(selectedItems.stateCode);

                user.storeCity = selectedItems.cityName;
                user.storeState = selectedItems.stateCode;
                user.storeZipcode = selectedItems.postalCode;
                dispatch(registerUserProceeding(user));

                defaultZipSearchParam.current.searchText = selectedItems.userName;
            }
            zipResData.current = null;
            setOpenModalZip(false);
        };

        const onBlurStoreAddress = (id, value) => {
            if (alphaNumericRegex.test(value)) {
                user.companyAddress = value;
                dispatch(registerUserProceeding(user));
            } else {
                if (value) {
                    user.companyAddress = null;
                    entityStoreAddress.current.setValue(null);
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "The Store Street Address value is invalid."
                        , () => {
                            entityStoreAddress.current.setFocus();
                        }
                    );
                }
            }
        };

        const onBlurReviewRemark = (id, value) => {
            if (value) {
                user.reviewRemark = value;
                dispatch(registerUserProceeding(user));
            } else {
                // ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "Which LG Distributor does your store purchase from? is required.");
            }
        };

        const onChangeonChangeBizUnit = (elementId, selectedValue, selectedIndex) => {
            user.bizUnit = selectedValue;
            dispatch(registerUserProceeding(user));
        }

        const onBlurStoreName = (id, value) => {
            user.companyName = value;
            dispatch(registerUserProceeding(user));
            // if (alphaNumericRegex.test(value)) {
            //     user.companyName = value;
            //     dispatch(registerUserProceeding(user));
            // } else {
            //     if (value) ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "Store/Company name is invalid.");
            // }
        };

        const _isMounted = useRef(true);
        useEffect(() => {
            let abortController = new AbortController();

            const getPageInfo = async () => {
                let entityBizUnitType = [
                    { cd: "HA", nm: "HA - Home Appliance (Refridgerator, washer & dryer, etc.)" }
                    , { cd: "HE", nm: "HE - Consumer Electronics (TV, monitor, blu-ray, DVD, etc.)" }
                    , { cd: "ALL", nm: "HA and HE Both" }
                    , { cd: "B2B", nm: "B2B - Commercial Busniess (Commercial TV, monitor, etc.)" }
                ];

                entityBizUnit.current.setList({
                    data: entityBizUnitType,
                    defaultValue: "HA",
                });

                entityStoreName.current.setValue(user.companyName);
                entityReviewRemark.current.setValue(user.reviewRemark);
                entityStoreAddress.current.setValue(user.companyAddress);

                if (user.bizUnit) entityBizUnit.current.setValue(user.bizUnit);

                entityCity.current.setValue(user.storeCity);
                entityState.current.setValue(user.storeState);
                entityZip.current.setValue(user.storeZipcode);
            }

            getPageInfo();

            // ComponentWillUnmount in Class Component
            return () => {
                _isMounted.current = false;
                abortController.abort();
            };
        }, [user]);
        return (
            <div className="content-signup">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid>
                        <p>
                            Store/Company name <span className="required">*</span>
                        </p>
                        <ComTextBox ref={entityStoreName} elementId={"entityStoreName"} callRelationBlur={onBlurStoreName} defaultMaxLength={100} />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid className="custom-inputcombo">
                        <p>
                            SPIFF Product Model Category <span className="required">*</span>
                        </p>
                        <ComCombo
                            ref={entityBizUnit}
                            elementId={"entityBizUnit"}
                            callRelationFuntion={onChangeonChangeBizUnit}
                        />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid>
                        <p>
                            Store Street Address <span className="required">*</span>
                        </p>
                        <ComTextBox ref={entityStoreAddress} elementId={"entityStoreAddress"} callRelationBlur={onBlurStoreAddress} defaultMaxLength={100} />
                    </Grid>
                </Grid>
                <div className="input-content-signup">
                    <div className="item-input3">
                        <p>
                            Zip Code <span className="required">*</span>
                        </p>
                        <ComTextBoxFind
                            ref={entityZip}
                            elementId={"entityZip"}
                            callOpenPopupFunction={openZipPopup}
                            callClearFunction={clearZipPopup}
                            callRelationFuntion={callZipPopup}
                            defaultRaiseClick={false}
                            defaultReadOnly={false}
                            defaultSearch={defaultZipSearchParam}
                        />
                    </div>
                    <div className="item-input3">
                        <p>
                            City <span className="required">*</span>
                        </p>
                        <ComTextBox ref={entityCity} elementId={"entityCity"} defaultDisabled={true} />
                    </div>
                    <div className="item-input3">
                        <p>
                            State <span className="required">*</span>
                        </p>
                        <ComTextBox ref={entityState} elementId={"entityState"} defaultDisabled={true} />
                    </div>
                </div>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid>
                        <p>
                            Which LG Distributor does your store purchase from?{" "}
                            <span className="required">*</span>
                        </p>
                        <ComTextBox ref={entityReviewRemark} elementId={"entityReviewRemark"} callRelationBlur={onBlurReviewRemark} defaultMaxLength={50} />
                    </Grid>
                </Grid>
                {openModalZip && <CityZipSchPopWithRegister closeFn={closeZipPopup} randomKey={randomKey.current} reqSearchText={entityZip?.current?.getValue()} resultData={zipResData?.current} reqComCode={user.comCode} />}
            </div>
        )
    }
);
export default StoreInforSpiffPage;