import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import ComTextBox from 'common/components/ComTextBox';
import ComComboMultiAll from 'common/components/ComComboMultiAll';
import ComDate from 'common/components/ComDate';
import Divider from "@mui/material/Divider";

import * as wijmo from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView, createElement, format, addClass, removeClass, Tooltip, PopupPosition, toggleClass } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';

function InvoiceUsedListPop({ open, closeFn, randomKey, requstType, invoiceReturnList, handleUsedPopup, pageInfo }) {
  const comCode = sessionStorage.getItem("comCode").toString();  

  const filterSearch = useRef(null);

  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();

  const convertUpperCase = (el, value) => {
    if (value && el.current) {
      el.current.setValue(value.toUpperCase());
    }
  };  

  /***** Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {
    gridObject.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      canAreaInfo: false,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });

    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {  	 
        handleUsedPopup(sender.selectedItems[0].systemEntryNo);
      }
    });
  }

  const onSelectionChanged = (sender) => {

  }

  const onSourceChanged = () => {
    if (gridObject.current) {
      gridObject.current.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory 
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {
    let gridFormat = [
      { binding: 'invoiceNo', header: "Invoice#", width: 120,  allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'systemEntryNo', header: "Portal Entry#", width: 140, allowDragging: true, isReadOnly: true, align: "center"}
      , { binding: 'claimType', header: "Claim Type", width: 200, allowDragging: true, isReadOnly: true}
      , { binding: 'claimStatus', header: "Claim Status", width: 200, allowDragging: true, isReadOnly: true}
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    SearchGridFormat();

    ComNoticfication.ShowAlertUI("Info", "Duplicate Invoice.\n\nPlease click 'Confirm' button if you want to proceed.");

    if (invoiceReturnList) {
      setGridData(new CollectionView(invoiceReturnList, { trackChanges: true, refreshOnEdit: false }));
    }

    if (open) {
      filterSearch?.current?.clear();
    }

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);  

  const onClickConfirm = () => {
    popComponent.current.hide();
    closeFn(requstType);
  }
 
  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`Invoice Used List`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>

      <div className="wj-dialog-footer">
        <Button variant="contained" onClick={() => onClickConfirm()}>
          Confirm
        </Button>
      </div>
    </wjInput.Popup>
  )
}

export default InvoiceUsedListPop;