import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "assets/styles/pages/PagesCommon.scss";
import BillToSchPop from "Pages/Pop/BillToSchPop";
import LgProgramSchPop from "Pages/Pop/LgProgramSchPop";
import UserSchPop from "Pages/Pop/UserSchPop";
import ComTypeOfChecks from "./ComTypeOfChecks";

import { ComUtils } from "common/lib/ComUtils";
import ComTextArea from "common/components/ComTextArea";
import ComTextBox from "common/components/ComTextBox";
import ComRadioBox from "common/components/ComRadioBox";
import ComDate from "common/components/ComDate";
import ComComboHeaderTable from "common/components/ComComboHeaderTable";
import ComNumberInput from "common/components/ComNumberInput";
import ComMaskInput from "common/components/ComMaskInput";
import ComCheckBox from "common/components/ComCheckBox";
import { CollectionView, createElement, format } from "@grapecity/wijmo";
import { ApiManager } from "common/lib/ApiUtil";
import { ComNoticfication } from "common/components/ComNoticfication";
import { MessageContext } from "common/lib/MessageProvider";
import _ from "lodash";
import { FileUtils } from "common/lib/FileUtils";
import { useDispatch } from "react-redux";

import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
// 컬럼

import Breadcrumb from "common/components/ComBreadcrumb";
import { Button } from "@mui/material";
import { closeMenu, openMenu } from "reducer/OpenMenus";

import search from "assets/icons/search.svg";
import close from "assets/icons/close.svg";
import attach from "assets/icons/attach.svg";
import { Checklist } from "@mui/icons-material";
import ComDateNotRequired from "common/components/ComDateNotRequired";

function ClaimAgreementApprovalListMod({ pageInfo, closeFn, modRandomKey, reqEntryNo, reqBillTo, setViewType, setDtlViewType, epLoginFlag }) {

  const dispatch = useDispatch();

  // const userLevel = pageInfo.userLevel;
  const userLevel = "1";
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const userId = sessionStorage.getItem("userId").toString();
  const userName = sessionStorage.getItem("userName").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const empNo = sessionStorage.getItem("empNo").toString();


  // 메세지 추가
  const { messageObject } = useContext(MessageContext);

  const msgComErr003 = ComUtils.getMessage(messageObject, "COM_ERR_00003");           //The data is not available
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028");           //Submit Error. Please Contact System Admin.

  //info 메시지
  const msgAgrInf001 = ComUtils.getMessage(messageObject, "AGR_INF_00001");           //Activity start date should be earlier than activity end date.
  const msgAgrInf002 = ComUtils.getMessage(messageObject, "AGR_INF_00002");           //Activity end date should be later than activity start date.
  const msgAgrInf008 = ComUtils.getMessage(messageObject, "AGR_INF_00008");           //LG Agreement Request has been saved.
  const msgAgrInf009 = ComUtils.getMessage(messageObject, "AGR_INF_00009");           //Submit Successfully.

  //error
  const msgAgrErr001 = ComUtils.getMessage(messageObject, "AGR_ERR_00001");           //An error occurred during processing.
  const msgAgrErr005 = ComUtils.getMessage(messageObject, "AGR_ERR_00005");           //The file name is too long.
  const msgAgrErr006 = ComUtils.getMessage(messageObject, "AGR_ERR_00006");           //This file name has been duplicated.
  const msgAgrErr007 = ComUtils.getMessage(messageObject, "AGR_ERR_00007");           //Please select one or more items[Type of Activity & Expense Details]
  const msgAgrErr008 = ComUtils.getMessage(messageObject, "AGR_ERR_00008");           //Please input [Other Desc] in Type of Activity & Expense Details.
  const msgAgrErr013 = ComUtils.getMessage(messageObject, "AGR_ERR_00013");           //Attach File Empty.

  //confirm
  const msgAgrCnf002 = ComUtils.getMessage(messageObject, "AGR_CNF_00002");           //No changes can be made after submit.


  const entityDivision = useRef(null);
  const entityClaimType = useRef(null);
  const entityEntryNo = useRef(null);
  const entityRequestor = useRef(null);
  const entityRequestorEmail = useRef(null);
  const entitySubmitDate = useRef(null);
  // Customer(Account) Information
  const entityBillToCode = useRef(null);
  const entityBillToName = useRef(null);
  const entityLgPgmNo = useRef(null);
  const entityLgPgmName = useRef(null);
  const entityPgmBalance = useRef(null);
  const entityAddr1 = useRef(null);
  const entityAddr2 = useRef(null);
  const entityName = useRef(null);
  const entitySalesPerson = useRef(null);
  const entityCity = useRef(null);
  const entityContactEmail = useRef(null);
  const entitySalesrepEmail = useRef(null);
  const entityState = useRef(null);
  const entityZip = useRef(null);
  const entityContactPhone = useRef(null);
  const entitySalesrepPhone = useRef(null);
  //Type of Activity & Expense Details
  const entityOccurDateFrom = useRef(null);
  const entityOccurDateTo = useRef(null);
  const entityDesc = useRef(null);
  const entityComment = useRef(null);
  //Expense
  const entityReferenceNo = useRef(null);
  const entityAccountCost = useRef(null);
  const entityExpenseTotal = useRef(null);
  const entityLgCost = useRef(null);

  const entityAccrualAmt = useRef(null);
  const entityAdjustAmt = useRef(null);
  const entityPaidAmt = useRef(null);
  const entityProcessingAmt = useRef(null);
  const entityMdfAmt = useRef(null);
  const entityStatus = useRef(null);
  const entityAccountRate = useRef(null);
  const entityLGRate = useRef(null);

  const entityCheckGroup = useRef(null);

  const salepersonListRef = useRef(null);
  const initSalepersonEmail = useRef(null);
  const initSalepersonTel = useRef(null);

  const divRef = useRef();
  const [divValue, setDivValue] = useState("AE(CAC)");
  const [typeValue, setTypeValue] = useState("DFS and PTAC Co-op");
  const [billToCode, setBillToCode] = useState(null);

  const [contDisabled, setContDisabled] = useState(true);

  //파일관련
  const fileElement = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [removeFileList, setRemoveFileList] = useState([]);
  const entityFileList = useRef(null);

  //url
  const retrieveClaimAgreementApprovalListInfo = "/agr/approvalList/retrieveClaimAgreementApprovalListInfo";
  const retrieveClaimAgreementRequestDtl = "/agr/statusTracking/retrieveClaimAgreementRequestDtl";
  const retrieveApprovalList = "/agr/approvalList/retrieveApprovalList";
  const retrieveAttachInfo = "/agr/statusTracking/retrieveAttachInfo";
  const updateCacRequestUrl = "/agr/statusTracking/updateCacRequest";
  const retrievePgmBalanceUrl = "/agr/request/retrievePgmBalance";
  const retrieveDebitMemoInfoUrl = "/agr/request/retrieveDebitMemoInfo";
  const retrieveApprRoleTypeUrl = "/agr/request/retrieveApprRoleType";

  const retrieveBillToList = "/com/comPop/retrieveBillToList";
  const retrieveSalePersonInfo = "/agr/statusTracking/retrieveSalePersonInfo";
  const retrieveSalePersonJsonUrl = "/agr/request/retrieveSalePersonJson";
  const retrieveUserList = "/com/comPop/retrieveUserList";
  const retrieveProgramList = "/com/comPop/retrieveProgramList";

  const fileDownload = "/agr/request/fileDownload";
  const deleteFile = "/Common/File/FileDelete"

  const updateCacApprovalUrl = "/agr/approve/updateCacApproval"

  //grid
  const gridObject = useRef();
  const filterObject = useRef();
  const [gridData, setGridData] = useState(null);
  const [gridColGroup, setGridColGroup] = useState(null);
  const [approvalList, setApprovalList] = useState([]);
  const entityApprovalData = useRef(null);
  const entityJsonList = useRef(null);
  const [descTitle, setDescTitle] = useState("Description of Expense");

  const loadingRef = useRef(null);
  const firstRef = useRef(true);

  //popup
  const [openModalBillTo, setOpenModalBillTo] = useState(false);
  const [openLgPgmSchPop, setOpenLgPgmSchPop] = useState(null);
  const [openUserSchPop, setOpenUserSchPop] = useState(null);

  const randomKey = useRef(null);

  //billToPopup[S]
  const defaultBillToSearchParam = useRef({
    url: retrieveBillToList,
    comCode: comCode,
    userType: userType,
    userGroup: userGroup,
    billToCode: "", //changeValue
    billToName: "",
    pageSize: "1",
    rowSize: "100",
    pageNo: "1",
    userLevel: "1" /* userLevel */,
    userId: userId,
  });

  const billToResData = useRef(null);

  //billTo팝업
  const openBillToPopup = async (elementId, userCode) => {
    defaultBillToSearchParam.current.billToCode =
      entityBillToCode.current.getValue();
    let resultData = await ApiManager.post(
      retrieveBillToList,
      defaultBillToSearchParam.current
    );

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        fncFinishedBillTo(resultData.list[0]);
      } else {
        //여러건이면 팝업으로 전달
        billToResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalBillTo(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalBillTo(true);
    }
  };

  const callBillToPopup = (elementId, value) => {
    defaultBillToSearchParam.current.billToCode = value;
    entityBillToCode.current.setValue(value);
  };

  const closeBillToPopup = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      fncFinishedBillTo(selectedItems);
    }
    billToResData.current = null;
    setOpenModalBillTo(false);
  };

  const fncFinishedBillTo = async (resultData) => {
    let salesInfo = await ApiManager.post(retrieveSalePersonInfo, { "billToCode": resultData.billTo });
    let concatAddr = null;

    if (resultData?.addr3 !== null && resultData?.addr3 !== "") {
      concatAddr = resultData.addr2?.concat(resultData.addr3);
    } else {
      concatAddr = resultData?.addr2;
    }

    entityBillToCode.current?.setValue(resultData.billTo);
    entityBillToName.current?.setValue(resultData.billToName);
    entityAddr1.current?.setValue(resultData.addr1);
    entityAddr2.current?.setValue(concatAddr);
    entityCity.current?.setValue(resultData.city);
    entityState.current?.setValue(resultData.state);
    entityZip.current?.setValue(resultData.zip);

    entitySalesPerson.current?.setList({ data: salesInfo, initValue: salesInfo[0].salesmanCode });

    if (initSalepersonEmail.current && initSalepersonTel.current) {
      entitySalesrepEmail?.current?.setValue(
        initSalepersonEmail.current
      );
      entitySalesrepPhone?.current?.setValue(
        initSalepersonTel.current
      );

      initSalepersonTel.current = null;
      initSalepersonEmail.current = null;
    } else {
      entitySalesrepEmail.current?.setValue(salesInfo[0].emailId);
      entitySalesrepPhone.current?.setValue(salesInfo[0].tel);
    }
  }
  //billToPopup[E]

  //lgProgramSchPop[S]
  const defaultLgPgmSearchParam = useRef({
    comCode: comCode
    , paramBillTo: ""
    , modelCode: ""
    , claimType: "1"
    , lgPgmNo: ""
    , lgPgmName: ''
    , reasonCode: []
    , startDate: '01/01/2000'
    , endDate: '12/31/2099'
    , pageSize: '1'
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  })

  const lgPgmResData = useRef(null);

  const openLgPgmPopup = async (elementId, userCode) => {

    let txtAccountNo = entityBillToCode.current?.getValue();
    if (txtAccountNo === null || txtAccountNo === "") {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        ComUtils.getMessage(messageObject, "AGR_ERR_00003", ["Cust. No(Bill To) first"]),
        () => {
          setTimeout(() => {
            entityLgPgmNo?.current?.setValue(null);
            entityBillToCode?.current?.setFocus();
          }, 100);
        }
      );

      return;
    }

    defaultLgPgmSearchParam.current.paramBillTo = entityBillToCode.current?.getValue();
    defaultLgPgmSearchParam.current.lgPgmNo = entityLgPgmNo.current?.getValue();

    let resultData = await ApiManager.post(
      retrieveProgramList,
      defaultLgPgmSearchParam.current
    );

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length === 1) {
        //단건이면 세팅 (팝업 오픈 X)
        entityLgPgmNo.current.setValue(resultData.list[0].offerCode);
        entityLgPgmName.current.setValue(resultData.list[0].offerName);
      } else {
        //여러건이면 팝업으로 전달
        lgPgmResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenLgPgmSchPop(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenLgPgmSchPop(true);
    }
  };

  const callLgPgmPopup = (elementId, value) => {
    defaultLgPgmSearchParam.current.lgPgmNo = value.toUpperCase();
    entityLgPgmNo.current.setValue(value.toUpperCase());
  };

  const fncProgramNoChange = async () => {
    if (divValue === "B2B" && typeValue === "B2B MDF") {
      entityLgPgmName.current?.setValue('');
      entityPgmBalance.current?.setValue('0');

      let txtAccountNo = entityBillToCode.current?.getValue() ?? "";
      let txtProgramNo = entityLgPgmNo.current?.getValue() ?? "";
      let txtEntryNo = entityEntryNo.current?.getValue() ?? "";

      if (txtAccountNo === null || txtAccountNo === "") {
        entityLgPgmNo.current?.setValue('');
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleInfo,
          ComUtils.getMessage(messageObject, "AGR_ERR_00003", ["Cust. No(Bill To) first"]),
          () => {
            setTimeout(() => {
              entityBillToCode?.current?.setFocus();
            }, 100);
          }
        );

        return;
      }

      let sendParam = {
        txtAccountNo: txtAccountNo
        , txtProgramNo: txtProgramNo
        , txtEntryNo: txtEntryNo
      };

      let resultData = await ApiManager.post(retrievePgmBalanceUrl, sendParam);

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(
          ComUtils._altTitleError,
          msgAgrErr001
        );
      } else {
        let res = resultData?.balanceInfo.split("|");
        let errCode = res[0];
        let errMsg = res[1];

        if (errCode === "E") {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            errMsg
          );
        } else if (errCode === "S") {
          entityAccrualAmt.current = parseFloat(res[4]);
          entityAdjustAmt.current = parseFloat(res[5]);
          entityPaidAmt.current = parseFloat(res[6]);
          entityProcessingAmt.current = parseFloat(res[10]);
          entityMdfAmt.current = resultData?.mdfAmt;
          entityLgPgmName.current.setValue(res[3]);
          let balanceAmt = parseFloat(res[7]);
          balanceAmt = parseFloat(balanceAmt - resultData?.mdfAmt);
          entityPgmBalance.current?.setValue(balanceAmt.toLocaleString());
        } else {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            msgAgrErr001
          );
        }

      }
    } else {
      openLgPgmPopup();
    }
  }

  const closeLgPgmSchPop = (result) => {
    if (result) {
      if (divValue === "B2B" && typeValue === "B2B MDF") {
        entityLgPgmNo.current?.setValue(result?.offerCode);
        fncProgramNoChange();
      } else {
        entityLgPgmNo.current?.setValue(result?.offerCode);
        entityLgPgmName.current?.setValue(result?.offerName);
      }
    }
    lgPgmResData.current = null;
    setOpenLgPgmSchPop(false);
  }
  //lgProgramSchPop[E]

  //approvalSchPop[S]
  const defaultUserSearchParam = useRef({
    comCode: comCode
    , empNo: ''
    , userName: ''
    , pageSize: '1'
    , rowSize: pageInfo.pageRowSize
    , pageNo: '1'
  })

  const entityUserName = useRef(null);
  const userResData = useRef(null);
  const mdfRow = useRef(null);

  const fncOpenUserSchPop = async (row, value) => {
    mdfRow.current = row;
    entityUserName.current = value;
    defaultUserSearchParam.current.userName = value;

    let resultData = await ApiManager.post(
      retrieveUserList,
      defaultUserSearchParam.current
    );

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length === 1) {
        //단건이면 세팅 (팝업 오픈 X)
        let empNo = resultData?.list[0]?.empNo;
        let userName = resultData?.list[0]?.userName;
        let emailId = resultData?.list[0]?.emailId;
        let view = gridObject?.current?.collectionView;
        view.items[mdfRow.current].empNo = empNo;
        view.items[mdfRow.current].userName = userName;
        view.items[mdfRow.current].txtValue2 = emailId;

        view.commitEdit();
        view.refresh();
      } else {
        //여러건이면 팝업으로 전달
        if (resultData.list.length === 0) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgComErr003);
        }

        userResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenUserSchPop(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenUserSchPop(true);
    }
  }

  const closeUserSchPop = (result) => {
    if (result) {
      let empNo = result?.empNo;
      let userName = result?.userName;
      let emailId = result?.emailId;
      let view = gridObject?.current?.collectionView;
      view.items[mdfRow.current].empNo = empNo;
      view.items[mdfRow.current].userName = userName;
      view.items[mdfRow.current].txtValue2 = emailId;

      view.commitEdit();
      view.refresh();
    }

    mdfRow.current = null;
    entityUserName.current = null;
    userResData.current = null;
    setOpenUserSchPop(false);
  }
  //approvalSchPop[E]

  // sales combo 선택 이벤트
  const fncSalesRepOnSelect = (elementId, selectedValue) => {
    let salepersonList = salepersonListRef?.current ?? [];

    if (salepersonList.length > 0) {
      let nm = entitySalesPerson?.current?.getValueItem()?.salesmanName ?? "";
      let idx = salepersonList.findIndex((item) => (item.salesmanCode + "" + item.salesmanName) === (selectedValue + "" + nm));
      if (idx > -1) {

        if (initSalepersonEmail.current && initSalepersonTel.current) {
          entitySalesrepEmail?.current?.setValue(
            initSalepersonEmail.current
          );
          entitySalesrepPhone?.current?.setValue(
            initSalepersonTel.current
          );

          initSalepersonTel.current = null;
          initSalepersonEmail.current = null;
        } else {
          entitySalesrepEmail?.current?.setValue(
            salepersonList[idx].emailId ?? ""
          );
          entitySalesrepPhone?.current?.setValue(
            salepersonList[idx].tel ?? ""
          );
        }
      }
    }
  };

  const listDivision = [
    { Value: "AE(CAC)", Description: "AE(CAC)" },
    { Value: "B2B", Description: "B2B" },
    { Value: "HA", Description: "HA", Disable: true },
    { Value: "HE", Description: "HE", Disable: true },
    { Value: "OEM", Description: "OEM", Disable: true },
  ]

  const listClaimTypeAE = [
    { Value: "DFS and PTAC Co-op", Description: "Co-op Request Form" },
    { Value: "DFS and PTAC MDF", Description: "Champion Request Form" },
    { Value: "TSA", Description: "TSA Funds Request Form" },
    { Value: "Customer Satisfaction", Description: "CSF Request Form" },
  ]

  const listClaimTypeB2B = [
    { Value: "B2B MDF", Description: "B2B MDF Planning Form" },
  ];

  const listClaimType = divValue === 'AE(CAC)' ? listClaimTypeAE : listClaimTypeB2B;

  listDivision.forEach(item => {
    if (item.Value !== divValue) {
      item.Disable = true;
    }
  });

  listClaimTypeAE.forEach(item => {
    if (item.Value !== typeValue) {
      item.Disable = true;
    }
  });

  const showDesc = () => {
    if (divValue == "AE(CAC)") {
      switch (typeValue) {
        case "DFS and PTAC MDF":
          return (
            <>
              <p className="fwb">Required documents includes:</p>
              <p>- Proof of employment and salary</p>
              <p>- Fully excuted Champions agrument</p>
              <p>- Proof that quariely KPIs were met</p>
              <p>- Copy of the LGEP New Champion Pre-Approval</p>
            </>
          );
        case "TSA":
          return (
            <>
              <p className="fwb">Required documents includes:</p>
              <p>
                - Proof that validates the amount being claimed (i.e expense
                receipts, work orders, part order confirmations)
              </p>
              <p>
                - Proof of performance that documents the cause of service
                issues or training materials for non-service related claims(i.e
                pictures case numbers, work order histories, training materials,
                hobsite report)
              </p>
            </>
          );
        case "Customer Satisfaction":
          return (
            <>
              <p className="fwb">Required documents includes:</p>
              <p>
                - Proof that validates the amount being claimed (i.e expense
                receipts, work orders, part order confirmations)
              </p>
              <p>
                - Proof of performance that documents the cause of customer
                dissatisfaction(i.e pictures case numbers, work order histories)
              </p>
              <p>- Only Aftermarket Operations should submit</p>
            </>
          );

        default:
          return (
            <>
              <p>
                All claims must be submitted with required documentation in the
                same year that activities were sccrued. No funds will be carried
                over into the next year.
              </p>
              <p className="fwb">Required documents includes:</p>
              <p>- Copy of the actual billing for expense(s)</p>
              <p>
                - Proof of performance that verities the items shown on the
                invoice were used/given away/happend
              </p>
            </>
          );
      }
    } else {
      return (
        <>
          <p>
            All activities must be approved prior to occurring using this form.
            Claim on approved activities must be submitted with proper proof of
            performance for reimbursement, prior to the program expiration date.
          </p>
        </>
      );
    }
  };

  //페이지 이동
  const moveRequestPage = () => {
    setGridData(null);
    let newMenuInfo = _.cloneDeep(pageInfo);
    let now = new Date();
    let timeKey = now.toISOString().replace('T', ' ').substring(0, 19).replace(/[^0-9]/g, '') + now.getMilliseconds().toString()
    newMenuInfo["menuName"] = "Claim Agreement Request";
    newMenuInfo["menuLocName"] = "Claim Agreement Request";
    newMenuInfo["tabUniqueKey"] = "M9012";
    newMenuInfo["menuId"] = "M9012";
    newMenuInfo["timeKey"] = timeKey;
    newMenuInfo["Active"] = true;
    newMenuInfo["copyFlag"] = false;
    newMenuInfo["editFlag"] = false;
    newMenuInfo["fromMenuCode"] = pageInfo.menuId;
    newMenuInfo["menuUrl"] = "Agr/ClaimAgreementRequest";
    newMenuInfo["parentNamePath"] = "Agreement > Claim Agreement Request";
    dispatch(closeMenu(pageInfo));
    dispatch(openMenu(newMenuInfo));

  };

  const initFilter = (f) => {
    filterObject.current = f;
  };

  // Approval Line Grid
  const initGrid = (sender) => {

    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      canAreaInfo: false,
      hasDisableCheck: false,
      allowPinning: false,

    });

  };

  const initGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: "empNo", header: "", width: 200, allowDragging: true, isReadOnly: true, visible: false },
      { binding: "apprRoleType", header: "", width: 200, allowDragging: true, isReadOnly: true, visible: false },
      { binding: "personName", header: "Name", width: 200, allowDragging: true, isReadOnly: true },
      { binding: "email", header: "Email", width: 300, allowDragging: true, isReadOnly: true },
      { binding: "status", header: "Status", width: 200, allowDragging: true, isReadOnly: true },
      { binding: "apprDate", header: "Approval Date", width: 300, allowDragging: true, isReadOnly: true },
      { binding: "comments", header: "Comments", width: 300, allowDragging: true, isReadOnly: true },
      { binding: "apprRoleNm", header: "R&R", width: "*", minWidth: 150, allowDragging: true, isReadOnly: true },
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  const onChangeDiv = (value) => {
    setDivValue(value);
    if (value === "AE(CAC)") {
      setTypeValue("DFS and PTAC Co-op");
      entityClaimType.current?.setValue("DFS and PTAC Co-op");
      fncApprovalList(value, "DFS and PTAC Co-op");
    } else {
      setTypeValue("B2B MDF");
      entityClaimType.current?.setValue("B2B MDF");
      fncApprovalList(value, "B2B MDF");
    }
    entityCheckGroup.current?.setNull()
  }

  const onChangeType = (value) => {
    setTypeValue(value);
    entityCheckGroup.current?.setNull();
    fncApprovalList(divValue, value);

    if (divValue === "AE(CAC)" && value === "DFS and PTAC MDF") {
      setDescTitle("Name of Champion");
    } else {
      setDescTitle("Description of Expense");
    }
  }

  const fncApprovalList = (div, type) => {
    let apprType = "CAC23";

    if (div === "AE(CAC)") {
      if (type === "DFS and PTAC Co-op") {
        apprType = "CAC23";
      }
      if (type === "DFS and PTAC MDF") {
        apprType = "CAC24";
      }
      if (type === "TSA") {
        apprType = "CAC18";
      }
      if (type === "Customer Satisfaction") {
        apprType = "CAC18";
      }
    } else {
      setGridData(
        new CollectionView(approvalList.filter(el => el.code === "CAC27" || el.code === "CAC28")
          .sort((a, b) => b.code.localeCompare(a.code)), { // CAC28를 먼저 출력
          trackChanges: true,
          refreshOnEdit: false,
        })
      );

      return;
    }

    setGridData(
      new CollectionView(approvalList.filter(el => el.code === apprType), {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );

  };


  /*******Grid Format *********************************************************************************/
  //Memory
  const _isMounted = useRef(true);

  /**********************************  Page Load 설정 **********************************/

  const onFileUpload = () => {
    fileElement.current.click();
  }
  const handleChange = (event) => {

    if (event.target.files.length > 0) {
      let chkFlow = true;
      // file 길이 체크
      for (let i = 0; i < event.target.files.length; i++) {
        let fullFileName = event.target.files[i].name;
        let tmpFileName = event.target.files[i].name.split(".");
        let extName = tmpFileName[tmpFileName.length - 1];
        if (fullFileName.replace(extName, "").length > 92) {
          chkFlow = false;
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgAgrErr005);
          break;
        }
      }
      // file 중복 체크
      if (fileList) {
        for (let i = 0; i < event.target.files.length; i++) {
          let isDupCheck = fileList.some((el) => el.originalFileName === event.target.files[i].name);
          if (isDupCheck) {
            chkFlow = false;
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgAgrErr006);
            break;
          }
        }
      }
      if (chkFlow) {

        for (let i = 0; i < event.target.files.length; i++) {

          let formData = new FormData();
          let extPass = false;
          let fname = event.target.files[i].name.split(".");

          if (fname.length > 0) {
            let extName = fname[fname.length - 1];
            if (extName !== undefined && extName != null) {
              extName = extName.toLowerCase();
            }
            extPass = true;
            if (FileUtils.allowFileExt?.indexOf(extName) > -1) {
              extPass = true;
            }
          }
          if (extPass) {
            upLoadFileExcute(event.target.files[i])
          } else {
            // 확장자명 미일치 시 action
          }
        }
      }

    }
  };

  const upLoadFileExcute = async (uploadFileInfo) => {
    let formData = new FormData();
    formData.append('UploadFile', uploadFileInfo);
    formData.append("customPath", "claim");
    formData.append("createDate", "T");
    try {
      let retrieveFileData = await ApiManager.post("/Common/File/FileUploadCustomPath", formData);
      // retrieveFileDataList.push(retrieveFileData[0]);
      let uploadedFileInfo = { "fileName": retrieveFileData[0].savedFileName, "filePath": retrieveFileData[0].savedFilePath, "fileSize": uploadFileInfo.size, "originalFileName": retrieveFileData[0].orgFileName, "userId": userId }

      if (entityFileList?.current == null) {
        setFileList([uploadedFileInfo]);
        entityFileList.current = [uploadedFileInfo];
      } else {
        let allList = [];
        allList = [...entityFileList.current, uploadedFileInfo];
        entityFileList.current = allList;
        setFileList(allList);
      }
    }
    catch (ex) {
      console.log(ex);
    }
    // }


    fileElement.current.value = "";

  }

  const fncDownloadAttach = async (fileInfo, event) => {

    let sendParam = {
      "orgFileName": fileInfo.originalFileName
      , "serverFileName": fileInfo.fileName
      , "filePathName": fileInfo.filePath
      , "customPath": "claim"
    }

    FileUtils.callServerFileDownload(fileDownload, sendParam, fileInfo.fileName)
  }

  const removeFile = async (fileInfo, event) => {

    if (!fileInfo.isOld) {
      const response = await ApiManager.post(deleteFile, { "filePath": fileInfo.filePath, "serverFileName": fileInfo.fileName, "customPath": "claim" });
    } else {
      setRemoveFileList([...removeFileList, fileInfo]);
    }

    let newFileList = fileList.filter(f => f.fileName !== fileInfo.fileName);
    entityFileList.current = newFileList;
    setFileList(newFileList);
  }

  const onChangeOccurDate = (elementId, text, date, value) => {

    if (loadingRef.current) {
      if (elementId === "entityOccurDateFrom") {
        entityOccurDateFrom.current?.setValue(text);
      } else {
        entityOccurDateTo.current?.setValue(text);
      }

      let toDate = entityOccurDateTo.current?.getOriginalValue();
      if (toDate === null) {
        entityOccurDateFrom.current?.setValue(text);
        entityOccurDateTo.current?.setValue(text);
        firstRef.current = false;

        return;
      }

      if (!firstRef.current) {
        let fromDate = entityOccurDateFrom.current?.getOriginalValue();
        if (toDate && fromDate) {
          let diff = ComUtils.DateDiff(entityOccurDateFrom?.current?.getOriginalValue(), entityOccurDateTo?.current?.getOriginalValue());
          if (diff === 0) {
            let isFrom = elementId === "entityOccurDateFrom" ? true : false;
            let msg = isFrom ? msgAgrInf001 : msgAgrInf002
            ComNoticfication.ShowAlertUICallback(
              ComUtils._altTitleInfo,
              msg,
              () => {
                setTimeout(() => {
                  if (isFrom) {
                    entityOccurDateFrom?.current?.setValue(null);
                  } else {
                    entityOccurDateTo?.current?.setValue(null);
                  }
                }, 100);
              }
            );
          }
        }
      }
    }
  }

  const fncDebitMemoSearch = async (elementId, value) => {
    let billToCode = entityBillToCode.current?.getValue();
    let debitMemoNo = entityReferenceNo.current?.getValue();

    if (billToCode === null || billToCode === '') {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        ComUtils.getMessage(messageObject, "AGR_INF_00003", ["Cust. No(Bill To)"]),
        () => {
          setTimeout(() => {
            entityReferenceNo?.current?.setValue(null);
            entityBillToCode?.current?.setFocus();
          }, 100);
        }
      );

      return;
    }

    let param = {
      billToCode: billToCode
      , refNo: debitMemoNo
      , entryNo: ''
    }

    let resultData = await ApiManager.post(retrieveDebitMemoInfoUrl, param);
    if (resultData !== null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        ComUtils.getMessage(messageObject, "AGR_INF_00004", [resultData?.entryNo]),
        () => {
          setTimeout(() => {
            entityReferenceNo?.current?.setValue(null);
            entityReferenceNo?.current?.setFocus();
          }, 100);
        }
      );
    }


  }

  const onChangeTotalCost = (elementId, value) => {
    if (value !== null && value !== 0) {
      setContDisabled(false);
      entityAccountCost.current?.setValue(0);
      entityLgCost.current?.setValue(0);
      entityAccountRate.current = 0;
      entityLGRate.current = 0;
    } else {
      setContDisabled(true);
    }
  }

  const fncCalculator = (elementId, value) => {
    let totalCost = entityExpenseTotal.current?.getValue();
    let accountCost = entityAccountCost.current?.getValue();
    let lgCost = entityLgCost.current?.getValue();
    let diff = totalCost - value;

    if (elementId === "entityAccountCost") {
      if (diff > 0) {
        entityLgCost.current?.setValue(diff);
      } else {
        entityAccountCost.current?.setValue(totalCost);
        entityLgCost.current?.setValue(0);
      }
      entityAccountRate.current = parseFloat(accountCost / totalCost * 100).toFixed(2);
      entityLGRate.current = parseFloat(100 - entityAccountRate.current).toFixed(2);
    } else {
      if (diff > 0) {
        entityAccountCost.current?.setValue(diff);
      } else {
        entityLgCost.current?.setValue(totalCost);
        entityAccountCost.current?.setValue(0);
      }
      entityLGRate.current = parseFloat(lgCost / totalCost * 100).toFixed(2);
      entityAccountRate.current = parseFloat(100 - entityLGRate.current).toFixed(2);
    }
  }

  const fncReplaceData = () => {
    let radioType = entityCheckGroup.current?.getTypes();
    let checkBoxList = entityCheckGroup.current?.getActivityCheckBox();
    let activityCheckBoxList = entityCheckGroup.current.getCheckedList();
    let otherDescVal = entityCheckGroup.current?.getOtherDescValue();
    let chkLen = 0;
    let chkDesc = 0;
    let array = new Array();
    let objInit = new Object();
    let obj = null;

    if (radioType === "typeCoop") {
      objInit.inputId = "txtMdfOther";
    } else if (radioType === "typeMDF") {
      objInit.inputId = "txtMdfOther";
    } else if (radioType === "typeTSA") {
      objInit.inputId = "txtTsaOther";
    } else if (radioType === "typeCSF") {
      objInit.inputId = "txtCsfOther";
    } else if (radioType === "typeB2BMDF") {
      objInit.inputId = "txtB2BMdfOther";
    }

    objInit.inputType = "text";
    objInit.inputText = "";
    objInit.inputValue = otherDescVal ?? "";

    array.push(objInit);

    for (let i = 0; i <  checkBoxList.length; i++) {
      obj = new Object();
      obj.inputValue = "N";
      obj.value = "N";

      for (let j = 0; j <  activityCheckBoxList.length; j++) {
        if (checkBoxList[i].value == activityCheckBoxList[j]) {
          chkLen++;
          obj.inputValue = "Y";
          obj.value = "Y";

          if (checkBoxList[i].value === "Other Desc" && (otherDescVal === null || otherDescVal === "")) {
            chkDesc++;
          }
        }
      }
      obj.inputType = "checkbox";
      obj.inputId = checkBoxList[i].id;
      obj.inputText = checkBoxList[i].value;
      obj.key = checkBoxList[i].value;

      array.push(obj);
    }

    if (chkLen === 0) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleError,
        // msgAgrErr007
        "Please select one or more items[Type of Activity & Expense Details]"
      );

      return false;
    }

    if (chkDesc > 0) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleError,
        // msgAgrErr008
        "Please input [Other Desc] in Type of Activity & Expense Details."
      );

      return false;
    }

    // setJsonList(array);
    entityJsonList.current = array;

    return true;
  }

  const fncCheckValidation = () => {
    let txtComment = entityComment.current?.getValue() ?? "";
    if (txtComment === null || txtComment === "") {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_ERR_00003", ["comment"]),
        "Please Input comment.",
        () => {
          setTimeout(() => {
            entityComment?.current?.setFocus();
          }, 100);
        }
      );
    }

    let txtAccountNo = entityBillToCode.current?.getValue() ?? "";
    let txtAccountName = entityBillToName.current?.getValue() ?? "";
    if (txtAccountNo === null || txtAccountNo === "") {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_ERR_00003", ["[Account Number]"]),
        "Please Input Account Number.",
        () => {
          setTimeout(() => {
            entityBillToCode?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    // let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    // let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    let date_regex = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;

    let requestorName = entityRequestor.current?.getValue();
    let emailAddr = entityRequestorEmail.current?.getValue();
    let date = entitySubmitDate.current?.getDateValueInteger();

    if (requestorName === null || requestorName === "") {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00005", ["Requestor"]),
        "Check [Requestor] Name.",
        () => {
          setTimeout(() => {
            entityRequestor?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    if (emailAddr === '' || emailAddr === null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00006", ["Requestor"]),
        "Check [Requestor] Email Address.",
        () => {
          setTimeout(() => {
            entityRequestorEmail?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    if (!date_regex.test(date) || date === null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["Submit Date"]),
        "Check [Submit Date].",
        () => {
          setTimeout(() => {
            entitySubmitDate?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let contactName = entityName.current?.getValue();
    let emailAddr1 = entityContactEmail.current?.getValue();

    if (contactName === null || contactName === '') {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00005", ["Contact"]),
        "Check [Contact] Name.",
        () => {
          setTimeout(() => {
            entityName?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    if (emailAddr1 === '' || emailAddr1 === null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00006", ["Contact"]),
        "Check [Contact] Email Address.",
        () => {
          setTimeout(() => {
            entityContactEmail?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let contactPhone = entityContactPhone.current?.getValue();
    let emailAddr2 = entitySalesrepEmail.current?.getValue();

    if (contactPhone === null || contactPhone === '') {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["Contact Tel"]),
        "Check [Contact Tel].",
        () => {
          setTimeout(() => {
            entityContactPhone?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    if (emailAddr2 === '' || emailAddr2 === null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00006", ["Salesperson"]),
        "Check [Salesperson] Email Address.",
        () => {
          setTimeout(() => {
            entitySalesrepEmail?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }
    let occurDate = entityOccurDateFrom.current?.getDateValueInteger();
    if (!date_regex.test(occurDate) || occurDate === null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["Occur Date"]),
        "Check [Occur Date].",
        () => {
          setTimeout(() => {
            entityOccurDateFrom?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let desc = entityDesc.current?.getValue();
    if (desc === null || desc === '') {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_ERR_00003", ['[' + descTitle + ']']),
        "Please Input " + '[' + descTitle + '].',
        () => {
          setTimeout(() => {
            entityDesc?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let refNo = entityReferenceNo.current?.getValue();
    if (refNo === null || refNo === '') {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["To be Claim Reference No"]),
        "Check [To be Claim Reference No].",
        () => {
          setTimeout(() => {
            entityReferenceNo?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let total = entityExpenseTotal.current?.getValue();
    if (total === null || total === 0) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["Expense Total"]),
        "Check [Expense Total].",
        () => {
          setTimeout(() => {
            entityExpenseTotal?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let custCost = entityAccountCost.current?.getValue();
    if (custCost === null) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["Account Cost"]),
        "Check [Account Cost].",
        () => {
          setTimeout(() => {
            entityAccountCost?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    let lgCost = entityLgCost.current?.getValue();
    if (lgCost === null || lgCost === 0) {
      ComNoticfication.ShowAlertUICallback(
        ComUtils._altTitleInfo,
        // ComUtils.getMessage(messageObject, "AGR_INF_00007", ["LG Cost"]),
        "Check [LG Cost].",
        () => {
          setTimeout(() => {
            entityLgCost?.current?.setFocus();
          }, 100);
        }
      );

      return false;
    }

    if (userType === "E") {
      let txtProgramNo = entityLgPgmNo.current?.getValue() ?? "";
      let txtProgramName = entityLgPgmName.current?.getValue() ?? "";

      if (txtProgramNo === null || txtProgramNo === '') {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleInfo,
          // ComUtils.getMessage(messageObject, "AGR_INF_00005", ["Program NO"]),
          "Check [Program NO] Name.",
          () => {
            setTimeout(() => {
              entityLgPgmNo?.current?.setFocus();
            }, 100);
          }
        );

        return false;
      }

      if (txtProgramName === null || txtProgramName === '') {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleInfo,
          // ComUtils.getMessage(messageObject, "AGR_INF_00005", ["Program Name"]),
          "Check [Program Name] Name.",
          () => {
            setTimeout(() => {
              entityLgPgmName?.current?.setFocus();
            }, 100);
          }
        );

        return false;
      }

      let apprList = gridObject.current?.rows;
      let array = new Array();
      let obj = null;

      for (let i = 0; i < apprList.length; i++) {
        obj = new Object();

        let dataItem = apprList[i]?.dataItem;
        if (dataItem?.empNo === null || dataItem?.empNo === '') {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            // ComUtils.getMessage(messageObject, "AGR_ERR_00009", [(i + 1)])
            (i + 1) + " line : Please Input approver"
          );

          return false;
        }

        if (dataItem?.email === entityRequestorEmail.current?.getValue()) {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            // ComUtils.getMessage(messageObject, "AGR_ERR_00010", [(i + 1)])
            (i + 1) + " line : Can not Input yourself"
          );

          return false;
        }

        for (let j = 0; j < apprList.length; j++) {
          let dataItem2 = apprList[j]?.dataItem;
          if (i !== j && dataItem?.empNo === dataItem2?.empNo) {
            ComNoticfication.ShowErrorUI(
              ComUtils._altTitleError,
              // ComUtils.getMessage(messageObject, "AGR_ERR_00011", [(i + 1), (j + 1)])
              (i + 1) + " line and " + (j + 1) + " line : There is a duplicate."
            );

            return false;
          }
        }

        obj.approvalId = dataItem?.empNo;
        obj.approvalName = dataItem?.personName;
        obj.approvalEmail = dataItem?.email;
        obj.approvalRnr = dataItem?.apprRoleNm;
        obj.approvalRnrId = dataItem?.apprRoleType;

        array.push(obj);
      }

      // setApprovalData(array);
      entityApprovalData.current = array;
    }


    return true;
  }

  // approve 버튼
  const fncApprove = (e) => {

    if (!fncCheckValidation()) {
      return;
    }

    if (!fncReplaceData()) {
      return;
    }

    ComNoticfication.ShowConfirmUI(
      ComUtils._altTitleInfo,
      // ComUtils.getMessage(messageObject, "AGR_CNF_00005", ["Approve"]),
      "Do you want to Approve?",
      fncUpdateCacApproval,
      "approve"
    );

  }

  // reject 버튼
  const fncReject = (e) => {

    if (!fncCheckValidation()) {
      return;
    }

    if (!fncReplaceData()) {
      return;
    }

    ComNoticfication.ShowConfirmUI(
      ComUtils._altTitleInfo,
      // ComUtils.getMessage(messageObject, "AGR_CNF_00005", ["Reject"]),
      "Do you want to Reject?",
      fncUpdateCacApproval,
      "reject"
    );
  }

  const fncUpdateCacApproval = async (isConfirmed, confirmType) => {
    if (isConfirmed) {

      let submitDate = entitySubmitDate?.current?.getDateValueInteger() === null ? "" : ComUtils.DateToInteger(entitySubmitDate?.current?.getOriginalValue());
      let occurDateFrom = entityOccurDateFrom?.current?.getDateValueInteger() === null ? "" : ComUtils.DateToInteger(entityOccurDateFrom?.current?.getOriginalValue());
      let salesmanName = entitySalesPerson?.current?.getValueItem()?.salesmanName ?? "";

      let sendParam = {
        confirmType: confirmType
        , viewType: setDtlViewType ?? "U"
        , txtRequestorEmail: entityRequestorEmail.current?.getValue() ?? ""
        , txtRequestor: entityRequestor.current?.getValue() ?? ""
        , submitDate: submitDate.toString()
        , occurDate: occurDateFrom.toString()
        , txtProgramName: entityLgPgmName.current?.getValue() ?? ""
        , txtProgramNo: entityLgPgmNo.current?.getValue() ?? ""
        , txtAccountNo: entityBillToCode.current?.getValue() ?? ""
        , txtAccountName: entityBillToName.current?.getValue() ?? ""
        , txtAddress: entityAddr1.current?.getValue() ?? ""
        , txtAddress2: entityAddr2.current?.getValue() ?? ""
        , txtCity: entityCity.current?.getValue() ?? ""
        , txtState: entityState.current?.getValue() ?? ""
        , txtZip: entityZip.current?.getValue() ?? ""
        , txtContactPerson: entityName.current?.getValue() ?? ""
        , txtContactPhone: entityContactPhone.current?.getValue() ?? ""
        , txtContactEmail: entityContactEmail.current?.getValue() ?? ""
        , txtComment: entityComment.current?.getValue() ?? ""
        , txtDescription: entityDesc.current?.getValue() ?? ""
        , txtExpenseTotal: entityExpenseTotal.current?.getValue() ?? 0
        , txtAccountRate: entityAccountRate.current ?? 0
        , txtAccountCost: entityAccountCost.current?.getValue() ?? 0
        , txtLGRate: entityLGRate.current ?? 0
        , txtLGCost: entityLgCost.current?.getValue() ?? 0
        , salesrepId: entitySalesPerson.current?.getValue() ?? ""
        , salesrepName: salesmanName ?? ""
        , txtSalespersonPhone: entitySalesrepPhone.current?.getValue() ?? ""
        , txtSalespersonEmail: entitySalesrepEmail.current?.getValue() ?? ""
        , status: "SUBMITTED"
        , txtRefNo: entityReferenceNo.current?.getValue() ?? ""
        , txtProgramBalance: entityPgmBalance.current?.getValue() ?? ""
        , jsonData: entityJsonList.current ?? []
        , approvalData: entityApprovalData.current ?? []
        , attachData: fileList ?? []
        , entryNo: entityEntryNo.current?.getValue() ?? ""
      };

      let resultData = await ApiManager.post(updateCacApprovalUrl, sendParam);

      if (resultData?.resultCode) {
        ComNoticfication.ShowErrorUI(
          ComUtils._altTitleError,
          resultData?.resultMsg
        );
      } else {

        removeFileList.forEach(async (file) => {
          await Promise.all([
            ApiManager.multiPost(deleteFile, { "filePath": file.filePath, "serverFileName": file.fileName, "customPath": "claim" }),
          ])
        });

        if (epLoginFlag) {
          //팝업 close
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, "Processed Successfully", () => {
            window.close();
          });
        } else {
          closeFn(true);
        }
      }
    }
  }

  const getPageInfo = async () => {
    if (reqEntryNo) {
      loadingRef.current = null;
      const [resultData, checkList, approvalGridData,
        uploadFileList, salePersonList, approvalListInfo] = await Promise.all([
          ApiManager.post(retrieveClaimAgreementApprovalListInfo, { entryNo: reqEntryNo }),
          ApiManager.post(retrieveClaimAgreementRequestDtl, { entryNo: reqEntryNo }),
          ApiManager.post(retrieveApprovalList, { entryNo: reqEntryNo }),
          ApiManager.post(retrieveAttachInfo, { entryNo: reqEntryNo }),
          ApiManager.post(retrieveSalePersonJsonUrl, { billTo: reqBillTo }),
          ApiManager.post(retrieveApprRoleTypeUrl, { "userId": userId }),
        ]);

      setBillToCode(resultData?.billToCode);
      setApprovalList(approvalListInfo?.apprLine);

      //초기화
      entityDivision?.current?.setValue(null);
      entityClaimType?.current?.setValue(null);
      entityEntryNo?.current?.setValue(null);
      entityRequestor?.current?.setValue(null);
      entityRequestorEmail?.current?.setValue(null);
      entitySubmitDate?.current?.setValue(null);
      entityBillToCode?.current?.setValue(null);
      entityBillToName?.current?.setValue(null);
      entityLgPgmNo?.current?.setValue(null);
      entityLgPgmName?.current?.setValue(null);
      entityPgmBalance?.current?.setValue(null);
      entityAddr1?.current?.setValue(null);
      entityAddr2?.current?.setValue(null);
      entityName?.current?.setValue(null);
      entitySalesPerson?.current?.setValue(null);
      entityCity?.current?.setValue(null);
      entityContactEmail?.current?.setValue(null);
      entitySalesrepEmail?.current?.setValue(null);
      entityState?.current?.setValue(null);
      entityZip?.current?.setValue(null);
      entityContactPhone?.current?.setValue(null);
      entitySalesrepPhone?.current?.setValue(null);
      entityOccurDateFrom?.current?.setValue(null);
      entityOccurDateTo?.current?.setValue(null);
      entityDesc?.current?.setValue(null);
      entityReferenceNo?.current?.setValue(null);
      entityAccountCost?.current?.setValue(0);
      entityExpenseTotal?.current?.setValue(0);
      entityLgCost?.current?.setValue(0);
      entityComment?.current?.setValue(null);
      // entityAttach?.current?.setValue(null);

      entityDivision?.current?.setValue(resultData?.selectLarge);
      entityClaimType?.current?.setValue(resultData?.selectSmall);
      entityEntryNo?.current?.setValue(resultData?.entryNo);
      entityRequestor?.current?.setValue(resultData?.requestorName);
      entityRequestorEmail?.current?.setValue(resultData?.requestor);
      entitySubmitDate?.current?.setinit({ defaultValue: resultData?.requestDate, format: "MM/dd/yyyy", mask: "##/##/####", validFormat: "__/__/____", style: { width: '50%' } });
      entityBillToCode?.current?.setValue(resultData?.billToCode);
      entityBillToName?.current?.setValue(resultData?.billToName);
      entityLgPgmNo?.current?.setValue(resultData?.lgProgramNumbers);
      entityLgPgmName?.current?.setValue(resultData?.lgProgramName);
      entityPgmBalance?.current?.setValue(resultData?.lgProgramBalance);
      entityAddr1?.current?.setValue(resultData?.address);
      entityAddr2?.current?.setValue(resultData?.address2);
      entityName?.current?.setValue(resultData?.contactPerson);
      entitySalesPerson?.current?.setList({ data: salePersonList.list, initValue: resultData?.salesrepId });
      entityCity?.current?.setValue(resultData?.city);
      entityContactEmail?.current?.setValue(resultData?.contactEmail);
      entityState?.current?.setValue(resultData?.state);
      entityZip?.current?.setValue(resultData?.zip);
      entityContactPhone?.current?.setValue(resultData?.contactPhone);
      entityOccurDateFrom?.current?.setinit({ defaultValue: resultData?.occurDate, format: "MM/dd/yyyy", mask: "##/##/####", validFormat: "__/__/____", style: { width: '50%' } });
      entityOccurDateTo?.current?.setinit({ defaultValue: resultData?.occurDateEnd, format: "MM/dd/yyyy", mask: "##/##/####", validFormat: "__/__/____", style: { width: '50%' } });
      entityDesc?.current?.setValue(resultData?.description);
      entityReferenceNo?.current?.setValue(resultData?.referenceNo);
      entityAccountCost?.current?.setValue(parseFloat(resultData?.accountCost.replace(/,/g, '')) || 0);
      entityExpenseTotal?.current?.setValue(parseFloat(resultData?.expenseTotal.replace(/,/g, '')) || 0);
      entityLgCost?.current?.setValue(parseFloat(resultData?.lgCost.replace(/,/g, '')) || 0);

      initSalepersonEmail.current = resultData?.salesrepEmail;
      initSalepersonTel.current = resultData?.salesrepPhone;

      entitySalesrepEmail?.current?.setValue(initSalepersonEmail.current);
      entitySalesrepPhone?.current?.setValue(initSalepersonTel.current);
      // setTimeout(() => {
      //   entitySalesrepPhone?.current?.setValue(resultData?.salesrepPhone);
      //   entitySalesrepEmail?.current?.setValue(resultData?.salesrepEmail);
      // }, 2000);

      if (resultData?.billToCode) {
        openBillToPopup();
      }

      let arr = new Array();
      let otherId = null;
      let txtOther = null;
      for (let i = 0; i < checkList.length; i++) {
        if (checkList[i].inputType === "checkbox" && checkList[i].inputValue === "Y") {
          arr.push(checkList[i].inputText);
          if (checkList[i].inputText === "Other Desc") {
            otherId = checkList[0].inputId;
            txtOther = checkList[0].inputValue;
          }
        }
      }

      entityCheckGroup.current?.setList(arr, otherId, txtOther);

      let fileArr = new Array();
      let fileObj = null;
      uploadFileList.map(file => {
        fileObj = new Object();
        fileObj.originalFileName = file.fileName;
        fileObj.fileName = file.saveFileName;
        fileObj.filePath = file.filePath;
        fileObj.userId = file.createId;
        fileObj.isOld = true

        fileArr.push(fileObj);
      })

      setFileList(fileArr);
      entityFileList.current = fileArr;

      setDivValue(entityDivision?.current?.getValue());
      setTypeValue(entityClaimType?.current?.getValue());

      salepersonListRef.current = salePersonList.list;

      setGridData(
        new CollectionView(approvalGridData.list, {
          trackChanges: true,
          refreshOnEdit: false,
        })
      );

      loadingRef.current = true;

      if (epLoginFlag) {
        setTimeout(() => {
          //eplogin session remove
          ComUtils.removeEpLoginSessionItem();
        }, 200);
      }

      initGridFormat();
    }
  }

  const renderHeaderButtons = () => {
    return (
      <>
        <div className="btn-group">
          <Button variant="outlined" className="btn-default" onClick={e => { closeFn(true, null); }} style={{ display: epLoginFlag ? 'none' : '' }}>
            {"List"}
          </Button>
          <Button variant="contained" className="btn-default" onClick={(e) => { fncApprove(e); }}>
            {"Approve"}
          </Button>
          <Button variant="contained" className="btn-default" onClick={(e) => { fncReject(e); }}>
            {"Reject"}
          </Button>
        </div>
      </>
    );
  }

  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    getPageInfo();

    filterObject?.current?.clear();
    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [modRandomKey, initGridFormat]);

  return (
    <div className="page-wrap">

      {!epLoginFlag ?
        <div className="breadcrumb-header">
          <Breadcrumb items={pageInfo} />
          <div className=" btn-group-end" >
            {renderHeaderButtons()}
          </div>
        </div>
        :
        <div className=" btn-group-end mb-10" >
          {renderHeaderButtons()}
        </div>
      }

      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="search-wrapper">
            <div
              className="d-flex-between mt-10"
              style={{ marginRight: "20px" }}
            >
              <div className="bx-item">
                <span className="field-required fz-12 ml-5">
                  * is required field
                </span>
              </div>
            </div>

            <div className="bx-search2 fixed-column1 mt-10">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">DIVISION:</label>
                </div>
                <div className="bx-inp">
                  <ComRadioBox
                    ref={entityDivision}
                    radioList={listDivision}
                    callRelationFuntion={(e, value) => onChangeDiv(value)}
                    direction={1}
                    radioGroupName={"CarrierClaimTypeDIV"}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">TYPE:</label>
                </div>
                <div className="bx-inp">
                  <ComRadioBox
                    ref={entityClaimType}
                    radioList={listClaimType}
                    radioGroupName={"CarrierClaimType"}
                    callRelationFuntion={(e, value) => onChangeType(value)}
                    direction={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-10 bx-card-group" style={{ alignItems: "start" }}>
          <div className="w-50 ml-5" style={{ whiteSpace: "break-spaces" }}>{showDesc()}</div>
          <div className="w-50">
            <div className="search-wrapper">
              <div className="bx-search2 fixed-column1">
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">Entry No</label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityEntryNo}
                      elementId={"entityEntryNo"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">Requestor</label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityRequestor}
                      elementId={"entityRequestor"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">Email</label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityRequestorEmail}
                      elementId={"entityRequestorEmail"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">Submit Date</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComDate
                      ref={entitySubmitDate}
                      controlDateFormat={"MM/dd/yyyy"}
                      controlDateMask={"##/##/####"}
                      controlDateValidFormat={"__/__/____"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bx-card-group">
          <div className="filter-title">
            <span className="txt-title">Customer(Account) Information</span>
          </div>
          <div className="search-wrapper ">
            <div className="bx-search2 fixed-column3">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Cust. No(Bill To)
                  </label>
                </div>
                <div className="bx-inp combo">
                  {userType === "B" ?
                    <ComTextBox
                      ref={entityBillToCode}
                      elementId={"entityBillToCode"}
                      defaultReadOnly
                    /> :
                    <ComTextBox
                      ref={entityBillToCode}
                      elementId={"entityBillToCode"}
                      callRelationFuntion={callBillToPopup}
                      callOpenPopupFunction={openBillToPopup}
                      callRelationBlur={openBillToPopup}
                      callKeyUpFuction={(id, e) => {
                        e.preventDefault();
                        if (e.key === "Enter") {
                          openBillToPopup();
                        }
                      }}
                      isSearch
                      defaultSearch={defaultBillToSearchParam}
                    />
                  }
                </div>
              </div>
              <div className="bx-item column-2column">
                <div className="div-label">
                  <label className="txt-label">Customer Name</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityBillToName}
                    elementId={"entityBillToName"}
                    defaultReadOnly={userType === "B"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required" style={userType === "E" ? {} : { display: "none" }}>*</span>
                    LG Program#
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityLgPgmNo}
                    elementId={"entityLgPgmNo"}
                    callRelationFuntion={callLgPgmPopup}
                    callOpenPopupFunction={openLgPgmPopup}
                    callRelationBlur={(id, value) => {
                      if (value !== null && value !== "") {
                        fncProgramNoChange();
                      }
                    }
                    }
                    callKeyUpFuction={(id, e) => {
                      e.preventDefault();
                      if (e.key === "Enter") {
                        openLgPgmPopup();
                      }
                    }}
                    isSearch
                    defaultSearch={defaultLgPgmSearchParam}
                  />
                </div>
              </div>
              <div className="bx-item ">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required" style={userType === "E" ? {} : { display: "none" }}>*</span>LG Program Name
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityLgPgmName}
                    elementId={"entityLgPgmName"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">PGM. Balance($)</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityPgmBalance}
                    elementId={"entityPgmBalance"}
                    defaultReadOnly
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Address 1</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityAddr1}
                    elementId={"entityAddr1"}
                    defaultReadOnly={userType === "B"}
                  />
                </div>
              </div>
              <div className="bx-item item-only-bg txt-center d-flex-center">
                Dealer Contact Information
              </div>
              <div className="bx-item item-only-bg txt-center d-flex-center">
                LG Sales Person Contact Information
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Address 2</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityAddr2}
                    elementId={"entityAddr2"}
                    defaultReadOnly={userType === "B"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Name
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityName}
                    elementId={"entityName"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Salesperson
                  </label>
                </div>
                <div className="bx-inp">
                  <ComComboHeaderTable
                    ref={entitySalesPerson}
                    elementId={"entitySalesPerson"}
                    callRelationFuntion={fncSalesRepOnSelect}
                    displayKeys={["salesmanCode", "salesmanName", "emailId"]} // 헤더 아래로 표시되는 데이터 key값
                    hasHeader={["Salesman Code", "Salesman Name", "Email"]} // 헤더로 사용할 데이터
                    displayValue={"salesmanName"} // 선택시 표시되는 값
                    settingValue={"salesmanCode"} // 선택시 세팅되는 값
                    initSelect={true}
                    styleHeader={["first-column", "after-column", "after-column"]}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">City</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityCity}
                    elementId={"entityCity"}
                    defaultReadOnly={userType === "B"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Email
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityContactEmail}
                    elementId={"entityContactEmail"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Email
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entitySalesrepEmail}
                    elementId={"entitySalesrepEmail"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">State / Zip</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityState}
                    elementId={"entityState"}
                    defaultReadOnly={userType === "B"}
                  />
                  {' / '}
                  <ComTextBox
                    ref={entityZip}
                    elementId={"entityZip"}
                    defaultReadOnly={userType === "B"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Tel
                  </label>
                </div>
                <div className="bx-inp">
                  <ComMaskInput
                    ref={entityContactPhone}
                    elementId={"entityContactPhone"}
                    mask="000-000-0000"
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Tel</label>
                </div>
                <div className="bx-inp">
                  <ComMaskInput
                    ref={entitySalesrepPhone}
                    elementId={"entitySalesrepPhone"}
                    mask="000-000-0000"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bx-card-group">
          <div className="filter-title">
            <span className="txt-title">
              Type Of Activity & Expense Details
              <span style={{ color: "#d92d20", fontSize: "15px" }}>*</span>
            </span>
            <div className="search-wrapper ">
              <div className="bx-search2">
                <div className="bx-item" style={{ minWidth: "400px" }}>
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="field-required">*</span>Activity Date
                    </label>
                  </div>
                  <div className="bx-inp combo">
                    <ComDateNotRequired
                      ref={entityOccurDateFrom}
                      elementId={"entityOccurDateFrom"}
                      controlDateFormat={"MM/dd/yyyy"}
                      controlDateMask={"##/##/####"}
                      controlDateValidFormat={"__/__/____"}
                      callRelationFuntion={onChangeOccurDate}
                    />
                    {' - '}
                    <ComDateNotRequired
                      ref={entityOccurDateTo}
                      elementId={"entityOccurDateTo"}
                      controlDateFormat={"MM/dd/yyyy"}
                      controlDateMask={"##/##/####"}
                      controlDateValidFormat={"__/__/____"}
                      callRelationFuntion={onChangeOccurDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-wrapper list-checkbox-agrement">
            <div className="bx-search2" style={{ gap: "0px 200px" }}>
              <ComTypeOfChecks
                ref={entityCheckGroup}
                divValue={divValue}
                typeValue={typeValue}
              />
            </div>
          </div>
          <div className="search-wrapper ">
            <div className="bx-search2 fixed-column1">
              <div className="bx-item ">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>{descTitle}
                  </label>
                </div>
                <div className="bx-inp " style={{ paddingRight: "0px" }}>
                  <ComTextArea
                    ref={entityDesc}
                    elementId={"entityDesc"}
                    defaultStyle={{
                      resize: "vertical",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bx-card-group">
          <div className="filter-title">
            <span className="txt-title">Expense</span>
          </div>
          <div className="search-wrapper ">
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>To Be Claim
                    Reference No(DN#)
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReferenceNo}
                    elementId={"entityReferenceNo"}
                    callKeyUpFuction={(id, e) => {
                      e.preventDefault();
                      if (e.key === "Enter") {
                        fncDebitMemoSearch(id, e.target.value);
                      }
                    }
                    }
                    callRelationBlur={(id, e) => { fncDebitMemoSearch(id, e) }}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Customer Contribution</label>
                </div>
                <div className="bx-inp">
                  $
                  <ComNumberInput
                    ref={entityAccountCost}
                    elementId={"entityAccountCost"}
                    defaultDisabled={true}
                    callRelationFuntion={(e, value) => fncCalculator(e, value)}
                    formatValue={"n2"}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>Total Cost
                  </label>
                </div>
                <div className="bx-inp">
                  <ComNumberInput
                    ref={entityExpenseTotal}
                    elementId={"entityExpenseTotal"}
                    formatValue={"n2"}
                    callRelationFuntion={(e, value) => onChangeTotalCost(e, value)}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="field-required">*</span>LG Contribution
                  </label>
                </div>
                <div className="bx-inp">
                  $
                  <ComNumberInput
                    ref={entityLgCost}
                    elementId={"entityLgCost"}
                    defaultDisabled={contDisabled}
                    callRelationFuntion={(e, value) => fncCalculator(e, value)}
                    formatValue={"n2"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bx-card-group">
          <div className="filter-title">
            <span className="txt-title">Upload Documentation</span>
          </div>
          <div className="search-wrapper ">
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="bx-inp combo inp-mix" style={{ minHeight: "100px" }}>
                  <div className="layout-file">
                    <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={onFileUpload}>
                      {"Find File"}
                    </Button>
                    <input type="file" onChange={handleChange} ref={fileElement} style={{ display: 'none' }} multiple />
                    <div className="file-group">
                      <>
                        {fileList.map((f, index) => (
                          <div className="ls-file-upload" key={f.fileName}>
                            {/* <div className="file-stt">{index}</div> */}
                            <div className="file-name">
                              {f.isOld ?
                                <>
                                  <p className={FileUtils.fileExtMatch(f.originalFileName)} style={{ cursor: "pointer", color: "#1A1A3A" }} onClick={(e) => { fncDownloadAttach(f, e) }} >
                                    {f.originalFileName}
                                  </p>
                                  <img src={close} width="12px" onClick={(e) => { removeFile(f, e) }} />
                                </>
                                :
                                <>
                                  <p className={FileUtils.fileExtMatch(f.originalFileName)} style={{ cursor: "auto" }}>
                                    {f.originalFileName}
                                  </p>
                                  <img src={close} width="12px" onClick={(e) => { removeFile(f, e) }} />
                                </>
                              }
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"Approval Line"}</h3>
                </div>
              </div>
            </div>
            <div className="bx-table">
              <FlexGrid
                columnGroups={gridColGroup} // 그리드 포멧
                itemsSource={gridData} // 바인딩할 데이터
                initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
                style={{minHeight: "105%"}}
              >
                <FlexGridFilter initialized={initFilter} />
              </FlexGrid>
            </div>
          </div>
        </div>
        <div className="search-wrapper " style={{ marginTop: "10px" }}>
          <div className="bx-search2 fixed-column1">
            <div className="bx-item ">
              <div className="div-label">
                <label className="txt-label">
                  Comment
                </label>
              </div>
              <div className="bx-inp " style={{ paddingRight: "0px" }}>
                <ComTextArea
                  ref={entityComment}
                  elementId={"entityComment"}
                  defaultStyle={{
                    resize: "vertical",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalBillTo ? <BillToSchPop open={openModalBillTo} closeFn={closeBillToPopup} randomKey={randomKey.current} reqBillToCode={entityBillToCode?.current?.getValue()} reqBillToName={entityBillToName?.current?.getValue()} userLevel={userLevel} billToResData={billToResData.current} pageInfo={pageInfo} /> : null}
      {openLgPgmSchPop ? <LgProgramSchPop open={openLgPgmSchPop} closeFn={closeLgPgmSchPop} randomKey={randomKey.current} pageInfo={pageInfo} reqType="AG" reqLgPgmNo={entityLgPgmNo.current?.getValue()} reqBillTo={entityBillToCode?.current?.getValue()} reqClaimType="1" reqModelCode={""} reqRequestAmt={0} resData={lgPgmResData.current} /> : null}
      {openUserSchPop ? <UserSchPop open={openUserSchPop} closeFn={closeUserSchPop} randomKey={randomKey.current} pageInfo={pageInfo} reqEmpNo={""} reqUserName={entityUserName.current} userResData={userResData.current} /> : null}
    </div>
  );
}

export default ClaimAgreementApprovalListMod;
