import React from "react";
import { Navigate } from "react-router-dom";
import cookie from "react-cookies";

const Authmiddleware = (props) => {
    try {
        const epLogin = cookie.load("EP_LOGIN");
        if (epLogin === "Y") {
            return (
                <Navigate to={{ pathname: "/ep_login", state: { from: props.location } }} />
            );
        } else {
            if (!sessionStorage?.getItem("userId")) {
                return (
                    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
                );
            }
        }
        return (<React.Fragment>
            {props.children}
        </React.Fragment>);
    } catch (error) {
        console.error("Error getting EP_LOGIN cookie:", error);
    }


};

export default Authmiddleware;
