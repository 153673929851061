import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ComTextBox from 'common/components/ComTextBox';
import ComRadioBox from 'common/components/ComRadioBox';
import PanelGroup from "react-panelgroup";

import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import Divider from "@mui/material/Divider";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';

function OutstandingListPop({ open, closeFn, randomKey, userId, pageInfo }) {

  //url
  const retrieveOutstandingList = "/com/splComPop/retrieveOutstandingList";

  const filterSearch = useRef(null);

  const gridRef = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');
  const [showNodata, setShowNodata] = useState(false);

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();

  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {

    setGridObject(sender);
    gridRef.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, { selectionMode: 6, canAreaInfo: false, hasShowNumbering: true, hasCheckBox: false, hasDisableCheck: false})

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

  }

  const onSelectionChanged = (sender) => {
  }

  const onSourceChanged = () => {
    if (gridObject) {
      gridObject.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory 
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: 'headerId', header: "Sample Request No.", width: 160, allowDragging: true, isReadOnly: true, align: "center"}
      , { binding: 'collectionDate', header: "To be returned date", width: 160, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'modelSuffix', header: "Model", width: 160, allowDragging: true, isReadOnly: true }
      , { binding: 'reqQty', header: "Qty", width: 60, allowDragging: true, isReadOnly: true, align: "right" }
      , { binding: 'reqAmt', header: "Amount", width: 100, allowDragging: true, isReadOnly: true, align: "right" }
      , { binding: 'aging', header: "Aging", width: 100, allowDragging: true, isReadOnly: true, align: "right" }
      , { binding: 'requestDate', header: "Request Date", width: 120, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'receiverName', header: "Delivery Customer", width: 200, allowDragging: true, isReadOnly: true }
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();
    SearchGridFormat();

    const getPageInfo = async () => {

      let sendParam = {
        userId: userId
        , pageSize: '1'
        , rowSize: pageInfo.pageRowSize
        , pageNo: '1'
      }

      // send
      let resultData = await ApiManager.post(retrieveOutstandingList, sendParam);

      if (resultData) {        
        setGridData(new CollectionView(resultData.list, { trackChanges: true, refreshOnEdit: false }));

        searchDataRef.current = sendParam;
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultData.list.length);

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current);
        } else {
          setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
        }

        setShowNodata(totCntRef.current == 0);
      }
    }

    if (open) {
      filterSearch?.current?.clear();
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  // 조회
  const onSearch = (param, e) => {

    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {      
      userId: userId
        , pageSize: '1'
        , rowSize: pageInfo.pageRowSize
        , pageNo: pageNoVal.toString()
    }
    searchDataRef.current = sendParam
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    let row_index = gridRef.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveOutstandingList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveOutstandingList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
        setGridDataCount(0);
      } else {
        let resultCnt = 0;
        if (param.searchFlag) {

          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current);
        } else {
          setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
        }
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const closePopup = () => {
    popComponent.current.hide();
    closeFn && closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> 
          Outstanding List
        </h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />        
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px", marginRight: "5px"}}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>     
    </wjInput.Popup>
  )
}

export default OutstandingListPop;