import React, { Suspense, lazy, useRef, useEffect } from "react";

import { useSelector } from "react-redux";

import _ from "lodash";
const OpenContents = React.memo(
  function OpenContents({ menu, errorEvent }) {
    const LoadComponent = lazy(
      () =>
        new Promise(async (resolve) => {
          const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            localStorage.getItem("page-has-been-force-refreshed") || "false"
          );
          try {
            localStorage.setItem("page-has-been-force-refreshed", "false");
            console.log("menu url", menu.menuUrl, "menu id", menu.menuId);
            const module = await import(`Pages/${menu.menuUrl}`);
            //const module = await import("Pages/aaaaaa");
            setTimeout(() => resolve(module), 30);
          } catch (ex) {
            console.log(ex);
            if (!pageHasAlreadyBeenForceRefreshed) {
              if ("caches" in window) {
                caches.keys().then((names) => {
                  // Delete all the cache files
                  names.forEach((name) => {
                    caches.delete(name);
                  });
                });
              }
              localStorage.setItem('page-has-been-force-refreshed', 'true');
              window.location.reload(true);
            }
            errorEvent();
          }
        })
    );

    return (
      <Suspense fallback={<div></div>}>
        <LoadComponent pageInfo={menu} />
      </Suspense>
    );
  },
  (prevProps, nextProps) =>
    prevProps.menu.tabUniqueKey === nextProps.menu.tabUniqueKey &&
    (prevProps?.menu?.timeKey ?? "") === (nextProps?.menu?.timeKey ?? "")
);

function ContentsLayer() {
  const menu = useSelector((state) => state.openMenus);
  const openMenu = menu;
  if (!openMenu) return <div></div>;

  return (
    <>
      {openMenu.map((menu) => (
        <div
          className={menu.Active ? "display" : "display none"}
          key={menu.tabUniqueKey}
          id={menu.tabUniqueKey}
        >
          <OpenContents menu={menu} key={menu.tabUniqueKey} />
        </div>
      ))}
    </>
  );
}
export default React.memo(
  ContentsLayer,
  (prevProps, nextProps) =>
    prevProps.menu === nextProps.menu &&
    (prevProps?.menu?.tabUniqueKey ?? "") ===
      (nextProps?.menu?.tabUniqueKey ?? "") &&
    (prevProps?.menu?.timeKey ?? "") === (nextProps?.menu?.timeKey ?? "")
);
