import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import 'assets/styles/pages/PagesCommon.scss';
import search from "assets/icons/search-normal.svg";

import * as wijmo from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView, Tooltip, PopupPosition } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { createElement, format } from "@grapecity/wijmo";
import { MessageContext } from "common/lib/MessageProvider";

import _ from "lodash";
import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';
import ComTextArea from "common/components/ComTextArea";
import ComTextBoxFind from "common/components/ComTextBoxFind";

import { SampleRequestUtils } from "Pages/Spl/Common/SampleRequestUtils";
import PersonSchPop from "Pages/Pop/Spl/PersonSchPop";

function ApprovalLine({props, pageInfo, isEpLogin}) {
  const { messageObject } = useContext(MessageContext);
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const loadApprExecLineInfo = "/spl/apprExec/loadApprExecLineInfo";
  const validateApprExecDup = "/spl/apprExec/validateApprExecDup";
  const saveApprExecLine = "/spl/apprExec/saveApprExecLine";
  const modifyApprExecLineStatus = "/spl/apprExec/modifyApprExecLineStatus";
  const saveARApproval = "/spl/apprExec/saveARApproval";

  // popup
  const defaultPersonSearchParam = useRef({
    url: "/com/splComPop/retrievePersonList"
    , comCode: comCode
    , searchText: ''
    , pageSize: '1'
    , rowSize: '100'
    , pageNo: '1'
  });
  const personResData = useRef(null);
  const personSearchText = useRef('');
  const personCcsSearchText = useRef('');
  const [randomKey, setRandomKey] = useState(null);
  const [openModalPersonList, setOpenModalPersonList] = useState(false);
  const [openModalCcsPersonList, setOpenModalCcsPersonList] = useState(false);

  const gridObject1 = useRef();
  const [gridColGroup1, setGridColGroup1] = useState(null);
  const [gridData1, setGridData1] = useState(null);
  const [gridDataCount1, setGridDataCount1] = useState(0);

  const apprExecLine = useRef(null);

  const entityApprCcs = useRef(null);
  const entityCcsPerson = useRef(null);
  const entityApprComment = useRef(null);

  const [apprType, setApprType] = useState('');
  const [showApprovalLine, setShowApprovalLine] = useState(true);
  const [showCcs, setShowCcs] = useState(true);
  const [showApprComment, setShowApprComment] = useState(false);
  const [showApprRejBtns, setShowApprRejBtns] = useState(false);
  const [showSetArAppr, setShowSetArAppr] = useState(false);
  const [showSetApprRequest, setShowSetApprRequest] = useState(false);
  const [ccsList, setCcsList] = useState([]);


  const openPersonListPopup = async (row, e) => {
    // 서버에 전송
    defaultPersonSearchParam.current.searchText = row.value;
    let resultData = await ApiManager.post(defaultPersonSearchParam.current.url, defaultPersonSearchParam.current);

    if (resultData.list.length == 1) {
      // 갯수 확인 후 1개면 닫기
      row.dataItem.empNo = resultData.list[0].empNo;
      row.dataItem.personId = resultData.list[0].userId;
      row.dataItem.personName = resultData.list[0].userName;
      row.dataItem.email = resultData.list[0].emailId;
      gridObject1?.current?.refresh();  
    } else {
      // 여러개면 리스트 넘기기
      personSearchText.current = row.value;
      personResData.current = resultData;
      ComUtils.setComRandomKey(setRandomKey);
      setOpenModalPersonList(true);
    }
  };

  const closePersonListPopup = (selectedItems) => {
    // selectedItems 으로 name, email 설정
    if (selectedItems) {
      let idx = gridObject1?.current?.selection.row;
      let row = gridObject1?.current?.rows[idx].dataItem;

      row.empNo = selectedItems.empNo;
      row.personId = selectedItems.userId;
      row.personName = selectedItems.userName;
      row.email = selectedItems.emailId;
      gridObject1?.current?.refresh();  
    }
    
    personResData.current = null;
    setOpenModalPersonList(false);
  };

  const callCcsPersonInfoListPopup = (elementId, value) => {
    defaultPersonSearchParam.current.searchText = value;
    entityCcsPerson.current.setValue(value);
  };

  const openCcsPersonListPopup = async (elementId, userCode, resultData) => {
    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        setCcsList(ccsList => [...ccsList, {emailId: resultData.list[0].emailId, userName: resultData.list[0].userName}]);
      } else {
        //여러건이면 팝업으로 전달
        personResData.current = resultData;
        ComUtils.setComRandomKey(setRandomKey);
        setOpenModalCcsPersonList(true);
      }
    } else {
      setOpenModalCcsPersonList(true);
    }    
  };

  const closeCcsPersonListPopup = (selectedItems) => {
    if (selectedItems) {      
      setCcsList(ccsList => [...ccsList, {emailId: selectedItems.emailId, userName: selectedItems.userName}]);
    }
    
    personResData.current = null;
    setOpenModalCcsPersonList(false);
  };

  const onClickAppr = (type) => {
    if (type === 'reject') {
      if (!entityApprComment?.current?.getValue()) {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, "Please input comment",
          () => { 
            entityApprComment.current.setFocus();
          }
        );

        return;
      }
    }

    ComNoticfication.ShowConfirmUI("Info", "Are you sure " + type + "?", async (r) => {
      if (r) {
        let lines = gridObject1.current.collectionView.items;

        let sendParam = {
          headerId: apprExecLine?.current?.query?.headerId,
          actionType: apprExecLine?.current?.query?.actionType ?? '',
          targetEmpNo: apprExecLine?.current?.query?.targetEmpNo ?? '',
          type: type,
          reqId: apprExecLine?.current?.execMaster?.reqId ?? '',
          feId: apprExecLine?.current?.execMaster?.feId ?? '',
          dealType: apprExecLine?.current?.query?.dealType ?? '',
          cc: SampleRequestUtils.getCcs(ccsList) ?? '',
          arApprovalYn: apprExecLine?.current?.query?.arApprovalYn ?? '',
          // 첫번째 line 정보 입력 (그리드의 0번째)
          personId: lines[0].personId ?? '',
          empNo: lines[0].empNo ?? '',
          email: lines[0].email ?? '',
          personName: lines[0].personName ?? '',
          apprRoleType: lines[0].apprRoleType ?? '',
          comments: entityApprComment?.current?.getValue() ?? "",
          lines: lines,
        };
        
        let resultData = await ApiManager.post(modifyApprExecLineStatus, sendParam);

        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
          return;
        }
        
        if (resultData?.resultCode) {
          if (resultData?.resultCode == "-100") {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, " An error occurred while processing sample data.");
          } else if (resultData?.resultCode == "-101") {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred while sending the processing results by email.");
          } else {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
          }
          return;
        }
        

        if (isEpLogin) {
          // EPLogin은 페이지 닫기
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, type + " successfully", () => window.close());
        } else {
          // 다시 불러오기 resultData.query 기반으로 (loadApprExecLineInfo)
          getPageInfo(resultData?.query);
        }        
      }
    });
  };

  const onClickArApproval = async () => {
    ComNoticfication.ShowConfirmUI("Info", "Are you sure you want to process AR Approval?", async (r) => {
      if (r) {
        let resultData = await ApiManager.post(saveARApproval, {headerId: props.headerId});

        if (resultData == "ok") {
          // appr 다시 불러오기
          let param = _.cloneDeep(props);
          param.arApprovalYn = 'N';
          param.isOutstanding = '';
          getPageInfo(param)
        }
      }
    });
  }

  const onClickApprovalRequest = async () => {
    if (!validate()) {
      ComNoticfication.ShowErrorUI("error", "Please Select Person for approval in the Approval Line");
      return;
    } 

    ComNoticfication.ShowConfirmUI("Info", "Are you sure approval request?", async (r) => {
      if (r) {
        // send - /apprexec/validateApprExecDup.ajax
        let resultData = await ApiManager.post(validateApprExecDup, {headerId: props.headerId});
        if (resultData == "ok") {
          let lines = gridObject1.current.collectionView.items;

          let sendParam = {
            headerId: props.headerId,
            actionType: 'view',
            targetEmpNo: apprExecLine?.current?.query?.targetEmpNo ?? '',
            ariEmpNo: apprExecLine?.current?.query?.ariEmpNo ?? '',
            dealType: apprExecLine?.current?.query?.dealType ?? '',
            cc: SampleRequestUtils.getCcs(ccsList) ?? '',
            arApprovalYn: apprExecLine?.current?.query?.arApprovalYn ?? '',
            // 첫번째 line 정보 입력 (그리드의 0번째)
            personId: lines[0].personId ?? '',
            empNo: lines[0].empNo ?? '',
            email: lines[0].email ?? '',
            personName: lines[0].personName ?? '',
            apprRoleType: lines[0].apprRoleType ?? '',
            lines: lines,
          };
          
          let resultData = await ApiManager.post(saveApprExecLine, sendParam);

          // 다시 불러오기 resultData.query 기반으로 (loadApprExecLineInfo)
          getPageInfo(resultData?.query);
        } else {
          ComNoticfication.ShowErrorUI("error", "the Approval Line already exists and not finish~!");
        }
      }
    });
  }

  const validate = () => {
    // validation - 미입력 확인
    for (let el of gridObject1.current.rows) {
      if(!el.dataItem?.email) {
        return false
      }
    }

    return true;
  }

  const removeCcs = async (ccsInfo, event) => {
    let newCcsList = ccsList.filter(f => f.emailId !== ccsInfo.emailId);
    setCcsList(newCcsList);
  }

  const inputCcs = (data) => {
    if (data) {
      let cc = data?.split(';');
      let newCcs = [];
      cc?.forEach(ccs => {
        if (ccs) {
          newCcs.push({emailId: ccs, userName:''});
        }
      });
  
      setCcsList(newCcs);
    }
  }

  const inputGridData1 = (arr) => {
    arr = arr.map(item => ({
      ...item,
      emptyPerson: !item.personId ? true : false
    }));

    setGridData1(new CollectionView(arr, { trackChanges: true, refreshOnEdit: false }));
  }

  /*******Grid Format *********************************************************************************/

  const initGrid1 = (sender) => {
    gridObject1.current = sender;

    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
      canAreaInfo: false,
      keyActionTab: "None" ,
      keyActionEnter: "None"
    });
    sender.allowSorting = false;

    sender.beginningEdit.addHandler(function (s, e) {
      let item = e.getRow()?.dataItem;
      let binding = e.getColumn()?.binding;

      // apprType이 set, copy이고 e.col이 1이고 emptyPerson이 false면 읽기전용으로 만듬
      if (binding == "personName") {
        if (apprExecLine.current.apprExecLineType == "set" || apprExecLine.current.apprExecLineType == "copy") {
          if (!item.emptyPerson) {
            e.cancel = true;
          }
        } else if (apprExecLine.current.apprExecLineType == "appr") {
          e.cancel = true;
        }
      }
    });

    sender.cellEditEnded.addHandler((s, e) => {
      let binding = e.getColumn().binding;

      if (binding === "personName") {
        const value = s.getCellData(e.row, e.col);
        openPersonListPopup({rowIndex: e.row, dataItem: s.rows[e.row].dataItem, value: value, ctx: null}, e);
      }
    });

  };

  const initGridFormat1 = useCallback(
    (
      findPerson
    ) => {
    let gridFormat = [
      {
        binding: "personName",
        header: "Name",
        width: 300,
        allowDragging: true,
        isRequired: false,
        allowSorting: false,
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = ctx.value ?? ''; //this._formatChange(ctx.value);
          let rowIndex = ctx.row.index;

          if (apprExecLine.current.apprExecLineType == "set" || apprExecLine.current.apprExecLineType == "copy") {
            if (dataItem.emptyPerson) {
              let element = createElement(
                format(
                  `<div class="wj-cell-button"><span>${value}</span> <img class="grid_btn_find" src='${search}' /></div>`,
                  {}
                ),
                cell
              );
              let buttonElement = element.querySelector("img.grid_btn_find");
              buttonElement.onclick = function (e) {
                e.preventDefault();
                findPerson.apply(e, [{ rowIndex, dataItem, ctx, value }, e]);
              }; //.bind({ctx});
            } else {
              return value;
            }
          } else if (apprExecLine.current.apprExecLineType == "appr") {
            if (value.indexOf('[Delegation]') > 0) {
              value = value.substring(0, value.indexOf('[Delegation]')) + "<br>"
                + value.substring(value.indexOf('[Delegation]'));
            }
            return value;
          } else {
            return value;
          }
        },
      },
      {
        binding: "email",
        header: "Email",
        width: "*",
        minWidth: 260,
        allowDragging: true,
        isReadOnly: true,
        allowSorting: false,
        cellTemplate: (ctx, cell) => ctx.value || '',
      },
      {
        binding: "status",
        header: "Status",
        align: "center",
        width: 140,
        visible: apprExecLine.current.apprExecLineType == "appr",
        allowDragging: true,
        isReadOnly: true,
        allowSorting: false,
        cellTemplate: (ctx, cell) => ctx.value == "approval" ? 'approved' : ctx.value,
      },
      {
        binding: "apprDate",
        header: "Appr Date",
        width: 180,
        visible: apprExecLine.current.apprExecLineType == "appr",
        align: "center",
        allowDragging: true,
        isReadOnly: true,
        allowSorting: false,
      },
      {
        binding: "comments",
        header: "Comments",
        width: 280,
        visible: apprExecLine.current.apprExecLineType == "appr",
        allowDragging: true,
        isReadOnly: true,
        allowSorting: false,
        cellTemplate: (ctx, cell) => {
          let value = ctx.value;
          if (value != null && value != "") {
            let tooltip = new Tooltip({
              showAtMouse: true,
              showDelay: 500,
              position: PopupPosition.RightTop
            });
            tooltip.setTooltip(cell, `<div>${ctx.value}</div>`)
            return ctx.value;
          }
        }
      },
      {
        binding: "apprRoleNm",
        header: "R&R",
        width: 180,
        allowDragging: true,
        isReadOnly: true,
        allowSorting: false,
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value ?? '';
          let element = createElement(format(`<div style="font-weight:bold">${value}</div>`,{}), cell);
        },
      },
    ];
    setGridColGroup1([...gridFormat]);
  }, []);


  const _isMounted = useRef(true);

  const getPageInfo = async (params) => {
    if (!params)
      return;

    // 초기화
    setShowApprovalLine(true);
    setShowCcs(true);
    setShowApprComment(false);
    setShowApprRejBtns(false);
    setShowSetArAppr(false);
    setShowSetApprRequest(false);
    inputGridData1([]);

    // send 
    let resApprExecLine = await ApiManager.post(loadApprExecLineInfo, params, { loadbarOpen: false });
    if (resApprExecLine?.statusCode) {
      ComNoticfication.ShowErrorUI("error", "Can not get approval line info.");
      return;
    }

    apprExecLine.current = resApprExecLine;
    initGridFormat1(openPersonListPopup);
    setApprType(resApprExecLine.apprExecLineType);

    if (resApprExecLine.apprExecLineType == 'appr') {
      if (resApprExecLine?.execDetail.length == 0 && resApprExecLine?.execFinish?.length == 0) {
        // 전체 숨김처리
        setShowApprovalLine(false);
        return;        
      }

      // set approval line
      if (resApprExecLine?.execDetail.length > 0) { // approval 진행중
        inputGridData1(resApprExecLine?.execDetail);
      }
      if (resApprExecLine?.execFinish?.length > 0) { // approval 완료 - 위하고 둘중 하나만 분기타는듯
        inputGridData1(resApprExecLine?.execFinish[0]);
        setShowCcs(false);
      }

      // show/hide elements
      setShowApprComment(
        (resApprExecLine?.execMaster?.status == 'request' || resApprExecLine?.execMaster?.status == 'approving')
        && (resApprExecLine?.isCurrentApprover == 'Y' || resApprExecLine?.isCurrentApprover == 'D')
      );

      let strApprType = '';
      resApprExecLine?.execDetail?.forEach((item) => {
        if ("stand" == item.status)
          strApprType = item.apprRoleType;
      });

      if ("PLAN" != strApprType && "MKT" != strApprType) {
        setShowApprRejBtns((resApprExecLine?.execMaster?.status == 'request' || resApprExecLine?.execMaster?.status == 'approving')
          && resApprExecLine?.isCurrentApprover == 'Y');
      }
    } else if (resApprExecLine.apprExecLineType == 'set') {
      apprExecLine.current.arApprovalYn = resApprExecLine.query.arApprovalYn || "N";

      if (resApprExecLine?.execDetail.length > 0) { // approval 진행중
        inputGridData1(resApprExecLine?.execDetail);
      }
      if (resApprExecLine?.execFinish?.length > 0) { // approval 완료 - 위하고 둘중 하나만 분기타는듯
        inputGridData1(resApprExecLine?.execFinish[0]);
        setShowCcs(false);
      }
      if (resApprExecLine?.pmList2?.length > 0) {
        inputGridData1(resApprExecLine?.pmList2);
      }

      setShowSetArAppr(resApprExecLine.query.arApprovalYn == 'Y' && resApprExecLine.query.isOutstanding == 'Y');
      setShowSetApprRequest(resApprExecLine.query.isOutstanding != 'Y');
    } else if (resApprExecLine.apprExecLineType == 'copy') {
      if (resApprExecLine?.execDetail.length > 0) { // approval 진행중
        inputGridData1(resApprExecLine?.execDetail);
      }

      setShowSetApprRequest(true);      
    }

    inputCcs(resApprExecLine?.execMaster?.cc);
  }

  useEffect(() => {
    let abortController = new AbortController();    

    getPageInfo(props);

    return () => { _isMounted.current = false; abortController.abort(); }
  }, [props]);


  return (
    <>
    { showApprovalLine &&
      <div className="table-area-wrapper mt-10">
        <div className="tb-content">
          <div className="bx-top">
            <div>
              <div className="txt-title">
                <h3>{"Approval Line "}</h3>
              </div>
            </div>
            <div className="link-action d-flex">
            {showApprRejBtns &&
              <Button variant="outlined" className="btn-default" onClick={() => onClickAppr('approval')}>
                Approval
              </Button>
              }
            {showApprRejBtns &&
              <Button variant="outlined" className="btn-default" onClick={() => onClickAppr('reject')}>
                Reject
              </Button>
            }
            {showSetArAppr &&
              <Button variant="outlined" className="btn-default" onClick={() => onClickArApproval()}>
                AR Approval
              </Button>
            }
            {showSetApprRequest &&
              <Button variant="outlined" className="btn-default" onClick={() => onClickApprovalRequest()}>
                Approval Request
              </Button>
            }
            </div>
          </div>
          <div className="bx-table">
            <FlexGrid
              columnGroups={gridColGroup1} // 그리드 포멧
              itemsSource={gridData1} // 바인딩할 데이터
              initialized={initGrid1} // 그리드 초기 이벤트 및 등록 처리
              autoRowHeights={true}
              style={{ minheight: "200px" }}>
            </FlexGrid>
          </div>
        </div>

        { showCcs &&
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>{"CCs"}</h3>
                </div>
              </div>
            </div>
            <div className="bx-table">
              { apprType == 'set' ?
                <div>
                  <ComTextBoxFind 
                    ref={entityCcsPerson} 
                    callOpenPopupFunction={openCcsPersonListPopup}
                    callRelationFuntion={callCcsPersonInfoListPopup}
                    defaultRaiseClick={false}
                    defaultReadOnly={false}
                    defaultSearch={defaultPersonSearchParam}
                  />
                  <div className="inp-terabox">
                    <div className="layout-file">
                      <div className="file-group">
                        {
                          ccsList.map((ccs, index) => (
                            <div className="file-list" key={ccs.emailId}>
                              <div className="file-name">
                                <p>{ccs.userName}/{ccs.emailId}</p>
                                <CloseOutlinedIcon onClick={(e) => removeCcs(ccs, e)} />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                  :
                <div className="layout-file">
                  <div className="file-group">
                    {
                      ccsList.map((ccs, index) => (
                        <div className="file-list" key={ccs.emailId}>
                          <div className="file-name">
                            <p>{ccs.emailId}</p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        }        

        {showApprComment &&
        <div className="tb-content">
          <div className="bx-top">
            <div>
              <div className="txt-title">
                <h3>{"Comment"}</h3>
              </div>
            </div>
          </div>
          <div className="bx-table">
            <ComTextArea 
              ref={entityApprComment} 
              elementId={"entityApprComment"}
              defaultStyle={{
                resize: "vertical",
                minHeight: "40px",
              }}
            />
          </div>
        </div>
        }


        {openModalPersonList && <PersonSchPop open={openModalPersonList} closeFn={closePersonListPopup} randomKey={randomKey} reqSearchText={personSearchText?.current} resultData={personResData?.current} pageInfo={pageInfo}/>}
        {openModalCcsPersonList && <PersonSchPop open={openModalCcsPersonList} closeFn={closeCcsPersonListPopup} randomKey={randomKey} reqSearchText={entityCcsPerson?.current?.getValue()} resultData={personResData?.current} pageInfo={pageInfo}/>}
      </div>
    }  
    </>    
  );
}

export default ApprovalLine;