//import React, { useEffect, useRef } from 'react';
import React from 'react';
import { confirmAlert } from "react-confirm-alert";
import { ComUtils } from 'common/lib/ComUtils';
//import "react-confirm-alert/src/react-confirm-alert.css";
import 'assets/css/modal.css'
// 기본 alert / confirm 
//ComNoticfication.showAlert("onAlert Title", "onAlert Content" )
//ComNoticfication.showConfirm("onConfirm Title", "onConfirm Content",confrimCallback )

// 사용자 정의 템플릿을 이용한 화면 처리
//ComNoticfication.ShowAlertUI("onAlertUI Title", "onAlertUI Content" )
//ComNoticfication.ShowConfrirmUI("onConfirmUI Title", "onConfirmUI Content", confrimCallback )


export const ComNoticfication = () => { };

ComNoticfication.showAlert = (msgTitle, msgContent) => {

    confirmAlert({
        title: msgTitle,
        message: msgContent,
        buttons: [
            {
                label: "OK"
            }
        ]
    });
};

ComNoticfication.showConfirm = (msgTitle, msgContent, cakkFn) => {

    confirmAlert({
        title: msgTitle,
        message: msgContent,
        buttons: [
            {
                label: "OK",
                onClick: () => cakkFn(true)
            }
            , {
                label: "Cancel"
                , onClick: () => cakkFn(false)
            }

        ]
    });
};


ComNoticfication.ShowErrorUI = (msgTitle, msgContent) => {
    const options = {};//ReactConfirmAlertProps &{};
    options.title = msgTitle;
    options.message = msgContent?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />');


    options.customUI = ({ onClose }) => {


        return (
            <div className={"openModal modal"}>
                <section>
                    <header>
                        {options.title}
                        <button className="close" onClick={onClose}> &times; </button>
                    </header>
                    <div className="notice_content">
                        <div className={"notice_modal-body"}>
                            <div className={"notice_alert notice_alert-danger"} dangerouslySetInnerHTML={{ __html: ComUtils.UnEscapeHtml(options.message) }} />

                        </div>
                    </div>
                    <footer>
                        <button className="btn-box-gray50" onClick={onClose} autoFocus> Close </button>
                    </footer>
                </section>
            </div>
        )
    };
    confirmAlert(options)
}


ComNoticfication.ShowAlertUI = (msgTitle, msgContent) => {
    const options = {};//ReactConfirmAlertProps &{};
    options.title = msgTitle;
    options.message = msgContent?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />');

    options.customUI = ({ onClose }) => {

        return (
            <div className={"openModal modal"}>
                <section>
                    <header>
                        {options.title}
                        <button className="close" onClick={onClose}> &times; </button>
                    </header>
                    <div className="notice_content">
                        <div className={"notice_modal-body"} dangerouslySetInnerHTML={{ __html: ComUtils.UnEscapeHtml(options.message) }} />
                    </div>
                    <footer>
                        <button className="btn-box-gray50" onClick={onClose} autoFocus> Close </button>
                    </footer>
                </section>
            </div>
        )
    };
    confirmAlert(options)
}


ComNoticfication.ShowAlertUICallback = (msgTitle, msgContent, callbackFunction, returnParam) => {
    setTimeout(() => {
        const options = {};//ReactConfirmAlertProps &{};
        options.title = msgTitle;
        options.message = msgContent?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />');
        options.callbackFn = callbackFunction;
        options.returnParam = returnParam;
        options.customUI = ({ onClose }) => {

            return (
                <div className={"openModal modal"}>
                    <section>
                        <header>
                            {options.title}
                            <button className="close" onClick={onClose}> &times; </button>
                        </header>
                        <div className="notice_content">
                            <div className={"notice_modal-body"} dangerouslySetInnerHTML={{ __html: ComUtils.UnEscapeHtml(options.message) }} />
                        </div>
                        <footer>
                            <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(true, options.returnParam); }} autoFocus> OK </button>
                        </footer>
                    </section>
                </div>
            )
        };
        confirmAlert(options)
    }, 200);
}

ComNoticfication.ShowConfirmUI = (msgTitle, msgContent, callbackFunction, returnParam) => {
    const options = {};//ReactConfirmAlertProps &{};
    options.title = msgTitle;
    options.message = msgContent?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />');
    options.callbackFn = callbackFunction;
    options.returnParam = returnParam;

    options.customUI = ({ onClose }) => {

        return (

            <div className={"openModal modal"}>
                <section>
                    <header>
                        {options.title}
                        <button className="close" onClick={onClose}> X </button>
                    </header>
                    <div className="notice_content">
                        <div className={"notice_modal-body"} dangerouslySetInnerHTML={{ __html: ComUtils.UnEscapeHtml(options.message) }} />
                    </div>
                    <footer>
                        <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(true, options.returnParam); }} autoFocus> OK </button>
                        &nbsp;
                        <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(false, options.returnParam); }}>Cancel</button>
                    </footer>
                </section>
            </div>

        )
    };
    confirmAlert(options)
}



ComNoticfication.ShowDeleteConfirmUI = (msgTitle, msgContent, callbackFunction, returnParam) => {
    const options = {};//ReactConfirmAlertProps &{};
    options.title = msgTitle;
    options.message = msgContent?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />');
    options.callbackFn = callbackFunction;
    options.returnParam = returnParam;

    options.customUI = ({ onClose }) => {

        return (

            <div className={"openModal modal"}>
                <section>
                    <header>
                        {options.title}
                        <button className="close" onClick={onClose}> X </button>
                    </header>
                    <div className="notice_content">
                        <div className={"notice_modal-body"} dangerouslySetInnerHTML={{ __html: ComUtils.UnEscapeHtml(options.message) }} />
                    </div>
                    <footer>
                        <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(true, options.returnParam); }}> OK </button>
                        &nbsp;
                        <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(false, options.returnParam); }} autoFocus>Cancel </button>
                    </footer>
                </section>
            </div>

        )
    };
    confirmAlert(options)
}


ComNoticfication.ShowConfirmYnUI = (msgTitle, msgContent, callbackFunction, returnParam) => {
    const options = {};//ReactConfirmAlertProps &{};
    options.title = msgTitle;
    options.message = msgContent?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />');
    options.callbackFn = callbackFunction;
    options.returnParam = returnParam;

    options.customUI = ({ onClose }) => {

        return (

            <div className={"openModal modal"}>
                <section>
                    <header>
                        {options.title}
                        <button className="close" onClick={onClose}> X </button>
                    </header>
                    <div className="notice_content">
                        <div className={"notice_modal-body"} dangerouslySetInnerHTML={{ __html: ComUtils.UnEscapeHtml(options.message) }} />
                    </div>
                    <footer>
                        <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(true, options.returnParam); }} autoFocus> Yes </button>
                        &nbsp;
                        <button className="btn-box-gray50" onClick={() => { onClose(); options.callbackFn(false, options.returnParam); }}>No</button>
                    </footer>
                </section>
            </div>

        )
    };
    confirmAlert(options)
}
