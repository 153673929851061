import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { ApiManager } from 'common/lib/ApiUtil';


import { MessageContext } from 'common/lib/MessageProvider';
import { ComUtils } from 'common/lib/ComUtils';
import * as wjInput from '@grapecity/wijmo.react.input';

const ComComboMultiAll = React.forwardRef(({
    isShowAllCheck        // 전체 체크 사용 여부
    , isShowFilter          // 필터 사용여부
    , isAutoCheckFilter     // 필터 입력시 자동선택
    , controlTitle          // 컨트롤 표시명
    , displayFormat         // 선택 결과 유형  1: ~ 외 ~건 , 2 : 타이틀 + 건수 , 3 : 건수만, 4 : 목록 나열
    , countTitle            // 건 표시 명
    , otherTitle            // 외 표시명
    , defaultValue          // 기본 값

    , callRelationFuntion   // 페이지에서 전달 받을 함수
    , elementId             // 객체 아이디
    // 스타일 관련
    , defaultDisabled           // 활성화여부
    , defaultStyle              // 스타일
    , defaultClassName          // CSS 클래스명

    , dropdownClass

}, ref) => {

    //const {messageObject} = useContext(MessageContext);
    const lblOther = "";//ComUtils.getDictionary(messageObject, "TITLE.OTHER")
    const lblCount = "";//ComUtils.getDictionary(messageObject, "TITLE.COUNT")

    // 부모 Ref 를 내부 Ref로 변경 처리
    const cboMultiRef = useRef(null);
    const cboInitValue = useRef(null);
    const mulSelRef = useRef(null);

    const cboCode = useRef();
    const cboName = useRef();

    // Combo 객체
    const [comboObject, setComboObject] = useState();
    // Combo Data
    const [comboData, setComboData] = useState([]);

    const [checkedList, setCheckedList] = useState([]);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    const [userHeight, setUserHeight] = useState(null);
    const [hasMaxHeightStyle, setHasMaxHeightStyle] = useState(false);

    React.useImperativeHandle(ref, () => (
        {
            getValue: (codeField) => {
                let checkedItem = cboMultiRef.current;
                let tempCode = codeField || cboCode.current || "cd";
                let returnValue = [];
                if (checkedItem) {
                    checkedItem.map(el => returnValue.push(el[tempCode]))
                }
                return returnValue.join(",");
            },

            isAll: () => {
                let selectedCount = 0;
                let itemsCount = 0;
                if (cboMultiRef?.current) {
                    selectedCount = cboMultiRef.current.length;
                }

                if (comboData) {
                    itemsCount = comboData.length;
                }
                return selectedCount === itemsCount;
            }
            , getCheckedValues: () => cboMultiRef.current

            , setValue: (v) => { cboInitValue.current = v; }
            , setStyle: (v) => { setHasStyle(true); setUserStyle(v); }
            , setClassName: (v) => { setHasClassName(true); setUserClassName(v); }
            , setDisabled: (v) => { setUserDisable(v); }

            , setList: (v) => {
                if (v["initValue"] && v.initValue !== undefined && v.initValue !== null) {
                    cboInitValue.current = v.initValue;
                }

                if (v["code"]) { cboCode.current = v.code }
                if (v["name"]) { cboName.current = v.name }
                if (v["data"]) { setComboData(v.data); }
            }
            , setBindColumInfo: (v) => {
                if (v["code"]) { cboCode.current = v.code }
                if (v["name"]) { cboName.current = v.name }
            }
            , setLoadData: (v) => {
                if (v["initValue"] && v.initValue !== undefined && v.initValue !== null) {
                    cboInitValue.current = v.initValue;
                }

                let url = null;
                let param = null;


                if (v["url"]) { url = v.url; }
                if (v["param"]) { param = v.param; }

                if (v["code"]) { cboCode.current = v.code }
                if (v["name"]) { cboName.current = v.name }
                loadCobmoData(url, param);
            }

        }
    ));

    const loadCobmoData = useCallback(async (url, param) => {

        try {
            let retrieveComboData = null;

            if (param) {
                retrieveComboData = await ApiManager.post(url, param);
            }
            else {
                retrieveComboData = await ApiManager.post(url);
            }
            setComboData(retrieveComboData);

        }
        catch (ex) {
            console.log(ex.message)
        }
    }, []);

    const setComponentDefault = useCallback((sender, e) => {
        let values = cboInitValue.current;
        if (values) {

            let tempCode = cboCode.current || "cd";
            let defultItem = null;

            if (comboObject) {
                defultItem = comboObject.itemsSource.filter(el => values.indexOf(el[tempCode]) !== -1)
                comboObject.checkedItems = defultItem;
                cboMultiRef.current = defultItem;
            }
            else {
                setComboObject(sender);
                defultItem = sender.itemsSource.filter(el => values.indexOf(el[tempCode]) !== -1)
                sender.checkedItems = defultItem;
                cboMultiRef.current = defultItem;
            }
        }

    }, [comboObject]);

    useEffect(() => {


        if (defaultStyle) {
            setUserStyle(defaultStyle);
            setHasStyle(true);
        }

        if (defaultClassName) {
            setUserClassName(defaultClassName)
            setHasClassName(true)
        }
        if (defaultDisabled === undefined || defaultDisabled === null) {
            setUserDisable(false);
        }
        else {
            setUserDisable(defaultDisabled);
        }

        if (defaultValue !== undefined && defaultValue !== null && comboObject) {
            cboInitValue.current = defaultValue;
            //setComponentDefault()
        }

        if (dropdownClass) {
            setUserHeight(dropdownClass);
            setHasMaxHeightStyle(true);

        }
        else {
            setHasMaxHeightStyle(false);
        }


    }, [defaultValue, comboObject, defaultDisabled, defaultStyle, defaultClassName, dropdownClass])



    const comboInit = (sender) => {
        setComboObject(sender);
    }

    const onCheckedItems = (sender) => {
        cboMultiRef.current = sender.checkedItems;
        if (callRelationFuntion) {
            callRelationFuntion(elementId, sender.checkedItems);
        }
    }

    const onCheckedItemsChanged = (sender) => {
        setCheckedList(sender.checkedItems);
    }

    const getCheckedTitle = (sender) => {

        let displayName = cboName.current || "nm";
        //선택된 개수가 0일 경우 ALL 넣어주기
        if (sender.checkedItems.length === 0) {
            sender.checkedItems.push({ cd: "ALL", nm: "ALL" })
            return sender.checkedItems[0][displayName]
        }
        if (sender.checkedItems.length === 1) {
            return sender.checkedItems[0][displayName]
        }
        else if (sender.checkedItems.length > 1) {
            let checkDisplayType = (displayFormat || 4)
            //다중 선택일 경우 ALL 안보이게 -- 원복 시 case의 items를 sender.checkedItems로 바꿔주면됨
            let items = sender.checkedItems.filter(el => el.cd !== "ALL");

            switch (checkDisplayType) {
                case 1: //  ~ 외 ~건
                    return items[0][displayName] + " " + (otherTitle || lblOther) + " " + (items.length - 1) + (countTitle || lblCount);
                case 2: // 타이틀 + 건수
                    return (controlTitle || "") + " " + (items.length) + (countTitle || lblCount);
                case 3: // 건수만
                    return (items.length) + (countTitle || lblCount);
                case 4: // 목록 나열
                    let returnValue = [];
                    for (let i = 0; i < items.length; i++) {
                        returnValue.push(items[i][displayName])
                    }
                    return returnValue.join(", ");
                default:
                    return "";
            }
        }
        else {
            return controlTitle || "";
        }
    }

    const onClickDiv = (context) => {


        if (checkedList.includes(context.item)) {
            let newList = checkedList.filter(item => item.cd !== context.item.cd);
            setCheckedList(newList);
        } else {
            setCheckedList([...checkedList, context.item]);
        }

    }

    useEffect(() => {
        mulSelRef.current.control.checkedItems = checkedList;
    }, [checkedList])



    return (
        <wjInput.MultiSelect
            name="comComboMultiAll"
            dropDownCssClass={
                hasMaxHeightStyle ? userHeight : "combo-table"
            }

            ref={mulSelRef}
            headerFormatter={getCheckedTitle}
            itemsSourceChanged={setComponentDefault}
            initialized={comboInit}
            lostFocus={onCheckedItems}

            itemsSource={comboData}
            displayMemberPath={cboName.current || "nm"}
            placeholder={controlTitle || ""}
            showSelectAllCheckbox={isShowAllCheck ? true : false}
            showFilterInput={isShowFilter ? true : false}
            checkOnFilter={isAutoCheckFilter ? true : false}
            checkedItemsChanged={onCheckedItemsChanged}

            isDisabled={userDisable}
            className={hasClassName ? userClassName : "wj_with10"}
            style={hasStyle ? userStyle : {}}
            wjItemTemplate={(context) => (
                <div className="item-wrapper-cd" style={context.item.cd !== "ALL" ? { "alignItems": "center", "marginLeft": "10px" } : { "display": "none" }}
                    onClick={() => onClickDiv(context)}
                >
                    <label><input type="checkbox" /></label>
                    <span className="item-name">{context.item.nm}</span>
                </div>
            )}
        />
    )

});
export default React.memo(ComComboMultiAll);