import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
//import { createStore } from 'redux';

// import { legacy_createStore as createStore } from 'redux';
// import rootReducer from './reducer';
// import { composeWithDevTools } from 'redux-devtools-extension'; //리덕스 개발자 도구

// 스토어를 만듭니다.
import store from "./store";
// const store = createStore(rootReducer, composeWithDevTools()); 

import {setLicenseKey} from '@grapecity/wijmo';
if (window.location.hostname.toLowerCase() ==='us.lgsalesportal.com') {
  setLicenseKey("us.lgsalesportal.com,922679262222981#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPB9kah3yUIh6QOd6a62yaU3WTZpVeNZEaIhTMvtGcIFkWCdXY8YGS4siT7ljT9QUOSFGa4QTVOllUKNkUElUWqhkV9UmRLNEU6AXavFXTyJlVHJUZWhWZ8V4UMhUOYxmahd4SlNTVaV7bDplTsxWZrwGNl54LzN5L7N5ZWFkcC34duZEc534bjVWdOpFW9VXQ6llZrd4b5gkS5cGOGpHRTNnT4N4KL3GcMd4a8IEOCtENQ5kSN3CS7IXa84mdu3kYpRlUXZmQCRUZNdkNhVUakxWYxgVQ6ZFZUtSdjl5UEhUR7M7KrEGaQlEUWJXQNt6d0JnbF34URJEb5MFNZRWWpxUbthlWvgzNBtERmtyK9R6KwYDZPl7U8l6MvU5UTFkW9M6Z4omNuJVax3kTxY4URd6N4QEMMV6L8VTTZFGSmRlS8g4Rlp4a8ZUS7AXOtdTZoR7bql4UiojITJCLigTM4UzQyITMiojIIJCL5AjNxUTOxkTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUjM6QTOwACMzUDM4IDMyIiOiQncDJCLi46bj9CbhRncvB7clxWYzdGbuMXdiojIz5GRiwiIpgritTIltzohsDbtqj0irjCkeyOhgy+RMJiOiEmTDJCLiEDO9IjMyIjNykzN6IjM9IiOiQWSiwSfdtlOicGbmJCLiIjdzIDMyIiOiI3ZxJ")
} 
else{ 
  setLicenseKey('WE12200-HS-649497-06-6XBHFBQ');
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
    <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
