import "assets/styles/layout/FooterLogin.scss";
import { Link } from "react-router-dom";
function FooterLogin() {
  return (
    <div className="footer-login-container">
      <div className="in-wrap">
        <ul className="lk-nav">
          <li>
            <Link to="https://privacy.us.lg.com/policies" target="_blank">
              Privacy
            </Link>
          </li>
          <div className="divider-legal"></div>
          <li>
            <Link to="https://www.lg.com/us/legal" target="_blank">
              Legal
            </Link>
          </li>
        </ul>
        <p className="txt-copy">
          Copyright © 2009-2024 LG Electronics. All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default FooterLogin;
