//import ReactDOM from 'react-dom';
//import { fabClasses } from "@mui/material";

import _ from "lodash";
const OPEN_MENU = "menu/openMenu";

const CLOSE_MENU = "menu/closeMenu";
const CHANGE_VIEW = "menu/menuView";
const CHANGE_HOME = "menu/menuHome";
const CLOSE_ALL = "menu/closeAll";
const CLOSE_MOVE = "menu/closeMove";
const MOVE_LEFT = "menu/moveLeft";
const MOVE_RIGHT = "menu/moveRight";

//추가
const CHANGE_PROFILE = "menu/menuHome";

export const openMenu = (menuInfo) => ({ type: OPEN_MENU, menuInfo });

export const closeMenu = (menuInfo) => ({ type: CLOSE_MENU, menuInfo });
export const viewMenu = (menuInfo) => ({ type: CHANGE_VIEW, menuInfo });
export const viewHome = (menuInfo) => ({ type: CHANGE_HOME, menuInfo });
export const closeMove = (menuInfo) => ({ type: CLOSE_MOVE, menuInfo });
export const closeAll = () => ({ type: CLOSE_ALL });
export const moveLeft = () => ({ type: MOVE_LEFT });
export const moveRight = () => ({ type: MOVE_RIGHT });

export const viewProfile = (menuInfo) => ({ type: CHANGE_PROFILE, menuInfo });

const initialState = [
  {
    menuId: "0",
    menuName: "Home",
    menuUrl: "Landing/RSALanding",
    tabUniqueKey: "0",
    Active: false,
    timeKey: "0",
  },
];

export default function OpenMenus(state = initialState, action) {
  document.getElementById("areaInfoObject").style.display = "none";

  switch (action.type) {
    case OPEN_MENU:
      sessionStorage.setItem(
        "currentOpenMenu",
        JSON.stringify(action.menuInfo)
      );

      let current = state.filter(
        (state) => state.tabUniqueKey === action.menuInfo.tabUniqueKey
      );
      if (current.length === 0) {
        return state
          .concat(action.menuInfo)
          .map((state) =>
            state.tabUniqueKey === action.menuInfo.tabUniqueKey
              ? { ...state, Active: true }
              : { ...state, Active: false }
          );
      } else {
        if ((current[0]?.fromMenuCode ?? "") === "ORD005") {
          return state.map((state) =>
            state.tabUniqueKey === action.menuInfo.tabUniqueKey
              ? {
                ...state,
                Active: true,
                timeKey: action.menuInfo.timeKey,
                origSysDocumentRef:
                  action?.menuInfo?.origSysDocumentRef ??
                  action.menuInfo.tabUniqueKey,
                billToCode:
                  action?.menuInfo?.billToCode ??
                  action.menuInfo.tabUniqueKey,
                shipToCode:
                  action?.menuInfo?.shipToCode ??
                  action.menuInfo.tabUniqueKey,
                lgOrderNo:
                  action?.menuInfo?.lgOrderNo ?? action.menuInfo.tabUniqueKey,
              }
              : { ...state, Active: false }
          );
        } else {
          return state.map((state) =>
            state.tabUniqueKey === action.menuInfo.tabUniqueKey
              ? {
                ...state,
                Active: true,
                timeKey: action.menuInfo.timeKey,
                origSysDocumentRef:
                  action?.menuInfo?.origSysDocumentRef ??
                  action.menuInfo.tabUniqueKey,
                linkParam: action?.menuInfo?.linkParam,
              }
              : { ...state, Active: false }
          );
        }
      }

    case CLOSE_MENU:
      return state.filter(
        (state) => state.tabUniqueKey !== action.menuInfo.tabUniqueKey
      );

    case CHANGE_VIEW:
      if (state && state.length > 0) {
        //sessionStorage.setItem('currentOpenMenu', JSON.stringify( action.menuInfo));
        if ((action?.menuInfo?.tabUniqueKey ?? "0") !== "0") {
          let current = state.filter(
            (state) => state.tabUniqueKey === action.menuInfo.tabUniqueKey
          );
          if (current.length > 0) {
            sessionStorage.setItem(
              "currentOpenMenu",
              JSON.stringify(current[0])
            );
          }
        }
        return state.map((state) =>
          state.tabUniqueKey === action.menuInfo.tabUniqueKey
            ? { ...state, Active: true }
            : { ...state, Active: false }
        );
      }
      return state;

    case CHANGE_HOME:
      sessionStorage.setItem("currentOpenMenu", null);
      return state.map((state) =>
        state.tabUniqueKey === "0"
          ? { ...state, Active: true }
          : { ...state, Active: false }
      );

    case CLOSE_MOVE:
      let cloneMenuInfo = _.cloneDeep(action.menuInfo);
      if (cloneMenuInfo?.closeTabUniqueKey) {
        delete cloneMenuInfo.closeTabUniqueKey;
      }
      sessionStorage.setItem("currentOpenMenu", JSON.stringify(cloneMenuInfo));
      let neOpenMenu = state.filter(
        (state) => state.tabUniqueKey === cloneMenuInfo.tabUniqueKey
      );

      if (neOpenMenu.length === 0) {
        return state
          .filter(
            (state) => state.tabUniqueKey !== action.menuInfo.closeTabUniqueKey
          )
          .concat(cloneMenuInfo)
          .map((state) =>
            state.tabUniqueKey === cloneMenuInfo.tabUniqueKey
              ? { ...state, Active: true }
              : { ...state, Active: false }
          );
      } else {
        return state
          .filter(
            (state) => state.tabUniqueKey !== action.menuInfo.closeTabUniqueKey
          )
          .map((state) =>
            state.tabUniqueKey === action.menuInfo.tabUniqueKey
              ? {
                ...state,
                Active: true,
                timeKey: action.menuInfo.timeKey,
                origSysDocumentRef:
                  action?.menuInfo?.origSysDocumentRef ??
                  action.menuInfo.tabUniqueKey,
              }
              : { ...state, Active: false }
          );
      }
    case CLOSE_ALL:
      sessionStorage.setItem("currentOpenMenu", null);
      return state
        .map((state) =>
          state.tabUniqueKey === "0"
            ? { ...state, Active: true }
            : { ...state, Active: false }
        )
        .filter((state) => state.tabUniqueKey === "0");

    case MOVE_LEFT:
      if (state && state.length > 0) {
        let curIdx = state.findIndex((item) => item.Active === true);

        console.log("openMenu moveLeft curIdx=>" + curIdx);

        if (curIdx > 0) {
          curIdx = curIdx - 1;
        }
        let current = state[curIdx];

        console.log(current);
        if (current) {
          sessionStorage.setItem(
            "currentOpenMenu",
            JSON.stringify(current)
          ); 
        }
        return state.map((state) =>
          state.tabUniqueKey === current.tabUniqueKey
            ? { ...state, Active: true }
            : { ...state, Active: false }
        );
      }
    case MOVE_RIGHT:
      if (state && state.length > 0) {
        let curIdx = state.findIndex((item) => item.Active === true);

        console.log("openMenu moveRight curIdx=>" + curIdx);

        if (curIdx >= 0 && curIdx < state.length - 1) {
          curIdx = curIdx + 1;
        }
        let current = state[curIdx];
        if (current) {
          sessionStorage.setItem(
            "currentOpenMenu",
            JSON.stringify(current)
          );
        }
        return state.map((state) =>
          state.tabUniqueKey === current.tabUniqueKey
            ? { ...state, Active: true }
            : { ...state, Active: false }
        );
      }
    default:
      return state; //throw new Error('Matching action type is not exist.');
  }
}

export const getCurrentMenuState = (state) => {
  try {
    return state;
  } catch (e) {
    return null;
  }
};
