import { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";

import Divider from "@mui/material/Divider";

import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";
import { ComUtils } from "common/lib/ComUtils";
import { CollectionView } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

function ShowModelBillToSearch({ callbackFuntion, isOpen, randomKey }) {
  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  const filterObject = useRef();
  const gridObject = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGidData] = useState(null);

  const initFilter = (f) => {
    filterObject.current = f;
  };
  const initGridFormat = () => {
    let gridFormat = [
      {
        binding: "lineNo",
        header: " ",
        width: 50,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "billToCode",
        header: "Bill To Code",
        width: 300,
        align: "center",
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "billToName",
        header: "Bill To Name",
        width: 250,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      },
      {
        binding: "address",
        header: "Address",
        width: "*",
        minWidth: 150,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
      },
    ];
    setGridColGroup([...gridFormat]);
  };

  const initGridBindData = () => {
    let resultData = [
      {
        lineNo: 1,
        billToCode: "AE000001067B",
        billToName: "LG Electronics GULF FZE",
        address: "B34BS12 JEBIL ALI",
      },
      {
        lineNo: 2,
        billToCode: "AU000001040B",
        billToName: "LG ELECTRONICS AUSTRALIA PTY LTD",
        address: "2 WONDERLAND DRIVE",
      },
      {
        lineNo: 3,
        billToCode: "BM000368001B",
        billToName: "AIRSERV LTD",
        address: "LG Electronics Do Brasil Ltda",
      },
      {
        lineNo: 4,
        billToCode: "BR000002011B",
        billToName: "LG Electronics Do Brasil Ltda",
        address: "DBA WARWICK PARADISE ISLAND BAHAMAS",
      },
    ];
    setGidData(
      new CollectionView(resultData, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
  };

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });
  };

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();
    if (isOpen) {
      popComponent.current.show(true);

      positionTopValue.current = popComponent.current.hostElement.style.top;
    }
    setPopuView(isOpen);
    initGridFormat();
    initGridBindData();

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [randomKey]);

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    callbackFuntion(false, -1, null);
  };

  const findAction = (elementId, userValue) => {};

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"BillTo Search"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol">*</span>
                Bill To Code
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol">*</span>
                Bill To Name
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox />
            </div>
          </div>
        </div>
        <div className="btn-group-end mt-10">
          <Button
            variant="contained"
            className="btn-default btn-search-in-title"
          >
            {"Search"}
          </Button>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ShowModelBillToSearch;
