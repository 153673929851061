import React, { useState, useEffect, useRef } from "react";

const ComCheckBox = React.forwardRef(
  (
    {
      callRelationFuntion, // 부모에서 호출 할 함수
      defaultValue, // 기본 값
      elementId, // 객체 아이디
      // 스타일 관련
      defaultDisabled, // 활성화여부
      defaultStyle,
      defaultClassName,
      idCheckbox,
    },
    ref
  ) => {
    const chkRef = useRef(null);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userValue, setUserValue] = useState(false);
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    React.useImperativeHandle(ref, () => ({
      getValue: (trueValue, falseValue) => {
        if (chkRef.current) {
          if (trueValue) {
            return trueValue;
          }
          return chkRef.current;
        } else {
          if (falseValue) {
            return falseValue;
          }
          return chkRef.current;
        }
      },
      getCheckedValues: () => chkRef.current,
      setStyle: (v) => {
        setHasStyle(true);
        setUserStyle(v);
      },
      setClassName: (v) => {
        setHasClassName(true);
        setUserClassName(v);
      },
      setDisabled: (v) => {
        setUserDisable(v);
      },
      setValue: (v) => {
        setUserValue(v);
        chkRef.current = v;
      },
    }));

    // 전달 파라미터가 변경될때
    useEffect(() => {
      if (defaultValue) {
        chkRef.current = defaultValue;
        setUserValue(defaultValue);
      } else {
        chkRef.current = false;
        setUserValue(false);
      }
      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }
    }, [defaultValue, defaultStyle, defaultClassName, defaultDisabled]);

    const checkChanged = (e) => {
      chkRef.current = e.target.checked;
      setUserValue(e.target.checked);
      if (callRelationFuntion) {
        callRelationFuntion(elementId, e.target.checked);
      }
    };

    return (
      <input
        name="comCheckBox"
        type="checkbox"
        onChange={checkChanged}
        checked={userValue}
        disabled={userDisable}
        className={hasClassName ? userClassName : ""}
        style={hasStyle ? userStyle : {}}
        id={idCheckbox || null}
      />
    );
  }
);
export default React.memo(ComCheckBox);
