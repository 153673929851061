import axios from "./AxiosUtils";
import FileSaver from "file-saver";
import _ from "lodash";
import { ComNoticfication } from "../components/ComNoticfication";
//import {  isNumber } from '@grapecity/wijmo';
export const ApiManager = () => { };

ApiManager.bigDataFetch = async (url, param, callbackFn) => {
  function onChunkedResponseComplete(resultData) {
    let splitData = resultData.split("{");
    let parseRd = "{ \"listData\":" + resultData + "}"
    return JSON.parse(parseRd);
    /*
        if (splitData[0].length === 0) {
          return JSON.parse("listdata: [{" + splitData.slice(1).join(",{") + "]");
        } else {
          return JSON.parse("listdata: [" + splitData.join(",{") + "]");
        }
        */
  }

  function onChunkedResponseError(err) {
    console.log("Error responseErrror",err);
  }

  function processChunkedResponse(response) {
    let resultData = "";
    let reader = response.body.getReader();
    let decoder = new TextDecoder();
    return readChunk();

    function readChunk() {
      return reader.read().then(appendChunks);
    }

    function appendChunks(result) {
      let chunk = decoder.decode(result.value || new Uint8Array(), {
        stream: !result.done,
      });
      resultData += chunk;

      if (result.done) {
        return resultData;
      } else {
        return readChunk();
      }
    }
  }

  let options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'incom-checker' : sessionStorage.getItem("scmchecker") || "",
      'x-charect-set' : sessionStorage.getItem("scmcharset") || ""
    },
    body: JSON.stringify(param|| '')
  };

  let resultData = await fetch("/lgsp"+url, options)
                        .then(processChunkedResponse)
                        .then(onChunkedResponseComplete)
                        .catch(onChunkedResponseError);

  if (resultData) {

    callbackFn(resultData)
    //return ;
  }
  return null;
};

ApiManager.get = async (url, params) => {
  const response = await ApiManager.request("GET", url, params);

  if (response && response.code === "0") {
    return response.data;
  }
  return null;
};

ApiManager.post = async (url, params, addConfig) => {
  const response = await ApiManager.request("POST", url, params, addConfig);
  if (response && response.code === "0") {
    return response.data;
  } else {
    return null;
  }
};

ApiManager.multiPost = async (url, params) => {
  const response = await ApiManager.request("POST", url, params, {
    loadbarClose: false,
  });
  if (response && response.code === "0") {
    return response.data;
  } else {
    return null;
  }
};

ApiManager.execute = async (url, params) => {
  const response = await ApiManager.request("POST", url, params);
  if (response) {
    return response.code === "0";
  } else {
    return false;
  }
};

ApiManager.request = (method, url, params, config) => {
  let response;
  switch (method) {
    case "POST":
      response = axios.post(url, params, config);
      break;
    case "PUT":
      response = axios.put(url, params);
      break;
    case "DELETE":
      response = axios.delete(url, { data: params });
      break;
    default:
      response = axios.get(url, { params });
      break;
  }
  return response;
};

/*****************************파일 다운로드***************************************************************************************************** */
ApiManager.downloadFileById = async (downloadUrl, params) => {
  return await axios.post(downloadUrl, params, {
    responseType: "arraybuffer",
  });
};

ApiManager.downloadFile = async (params) => {
  return await axios.post("/Common/File/FileDownload", params, {
    responseType: "arraybuffer",
  });
};

ApiManager.downloadZipFile = async (params) => {
  return await axios.post("/common/file/downloadZipFileByFileId", params, {
    responseType: "arraybuffer",
  });
};

ApiManager.downloadFileByUrl = async (url, params) => {
  return await axios.post(url, params, {
    responseType: "arraybuffer",
  });
};
/***************************** 엑셀 다운로드***************************************************************************************************** */
ApiManager.callExcelDowonload = async (downloadUrl, params) => {
  return await axios.post(downloadUrl, params, {
    responseType: "arraybuffer",
  });
};
/***************************** 탭별 엑셀 다운로드***************************************************************************************************** */
ApiManager.callExcelDowonloadPartialParsing = async (
  downloadUrl,
  params,
) => {
  return await axios.post(downloadUrl, params, {
    loadbarOpen: false,
    responseType: "arraybuffer",
  });
};
/***************************** 배열 분할***************************************************************************************************** */
ApiManager.splitArrayData = (arr, size) => {
  let i,
    j,
    temparray = [],
    chunk = size;
  for (i = 0, j = arr.length; i < j; i += chunk) {
    temparray.push(arr.slice(i, i + chunk));
  }
  return temparray;
};

/********************************************************************************************************************************** */
// 그리드 데이터를 파일로 나눠 업로드 후 엑셀 다운로드 처리 하여 엑셀 다운로드
ApiManager.ExcelDownloadSplitFile = async (
  gridObject,
  sheetName,
  splitRows,
  callbackFn,
  headerHeight

) => {
  if ((gridObject?.itemsSource?.items?.length ?? 0) > 0) {
    document.getElementById("loadingArea").style.display = "flex";
    setTimeout(() => {
      ApiManager.ExcelDownloadSplitFileExecute(
        gridObject,
        sheetName,
        splitRows,
        callbackFn,
        headerHeight
      );
    }, 500);
  } else {
    ComNoticfication.ShowErrorUI("Error", "There is no Data");
    return;
  }
};

ApiManager.ExcelDownloadSplitFileExecute = async (
  gridObject,
  sheetName,
  splitRows,
  callbackFn,
  headerHeight

) => {
  let originalSource = gridObject.itemsSource;
  let originalScrollPoint = gridObject.scrollPosition;
  let dataFileList = [];
  let widthData = [];
  let sendHeaderData = [];
  let headerMerge = {};

  let maxCols = gridObject.columns.length;
  let _cols = gridObject.cells._cols;
  let _columnHeaderRows = gridObject.columnHeaders.rows;
  let headerMaxRow = _columnHeaderRows.length;
  let htmlElement = document.getElementById("gridCellStyleElement");

  let getStyleFn = window.getComputedStyle;
  let newObj = _.cloneDeep(gridObject);
  let oldeSelect = newObj.selection;
  let updateCellFn = newObj.cellFactory._baseCf.updateCell;

  let newBindData = null;
  let startIndex = 0;
  let sendBodyData = [];
  let newMax = 0;
  let _cells = null;
  let objectStylte = null;
  let rowsData = [];
  let formRowData = null;
  let retrieveFileData = null;

  let newData = null;

  try {
    function blankSelectionChange() { }
    newObj.onSelectionChanged = blankSelectionChange;
    //newData         = _.cloneDeep(newObj.itemsSource);
    newData = _.cloneDeep(newObj.collectionView._view);
    function getMergeInfo(colInfo, sendHeaderData) {
      if (
        colInfo._rng._col !== colInfo._rng._col2 ||
        colInfo._rng._row !== colInfo._rng._row2
      ) {
        let keyValue = colInfo._rng._row + "_" + colInfo._rng._col;
        headerMerge[keyValue] = {
          row: colInfo._rng._row,
          row2: colInfo._rng.row2,
          col: colInfo._rng._col,
          col2: colInfo._rng._col2,
        };
      }
      if (colInfo.parentGroup) {
        getMergeInfo(colInfo.parentGroup, sendHeaderData);
      }

      let tmpRow = sendHeaderData[colInfo._rng._row];
      tmpRow[colInfo._rng._col] = colInfo._curr_header;
    }

    for (let hCount = 0; hCount < headerMaxRow; hCount++) {
      let columnHeaderTitle;
      (columnHeaderTitle = []).length = maxCols;
      columnHeaderTitle.fill("");

      sendHeaderData.push(columnHeaderTitle);
    }

    if (
      splitRows === undefined ||
      splitRows === null ||
      isNaN(splitRows) ||
      splitRows < 800
    ) {
      splitRows = 800;
    }

    newBindData = ApiManager.splitArrayData(newData, splitRows);

    for (let c = 0; c < maxCols; c++) {
      let currentCols = _cols[c];

      if (
        currentCols._rng._col !== currentCols._rng._col2 ||
        currentCols._rng._row !== currentCols._rng._row2
      ) {
        let keyValue = currentCols._rng._row + "_" + currentCols._rng._col;
        headerMerge[keyValue] = {
          row: currentCols._rng._row,
          row2: currentCols._rng.row2,
          col: currentCols._rng._col,
          col2: currentCols._rng._col2,
        };
      }
      if (currentCols.parentGroup) {
        getMergeInfo(currentCols.parentGroup, sendHeaderData);
      }

      let tmpRow = sendHeaderData[currentCols._rng._row];
      tmpRow[currentCols._rng._col] = currentCols._curr_header;
      //sendHeaderData[currentCols._rng._col, ] = currentCols._curr_header
    }

    while (newBindData.length > 0) {
      console.log("Split Count : " + startIndex);

      newObj.itemsSource = newBindData[0];
      newObj.select(-1, -1);
      newMax = newBindData[0].length;
      _cells = newObj.cells;

      //console.time("RowTime")
      for (let r = 0; r < newMax; r++) {
        rowsData = [];
        for (let c = 0; c < maxCols; c++) {
          updateCellFn(_cells, r, c, htmlElement, null, true);

          htmlElement.style.display = "none";
          htmlElement.style.left = "0px";
          htmlElement.style.top = "0px";

          objectStylte = getStyleFn(htmlElement);
          if (startIndex === 0 && r === 0) {
            widthData.push(objectStylte.width);
          }

          if ((newObj?.itemsSource[r]?.sortType ?? "") === "SUBTOTAL") {
            rowsData.push({
              value: htmlElement.innerHTML,
              backgroundColor: "rgb(165,211,249)",
              color: objectStylte.color || "rgb(255,255,255)",
              align: objectStylte.textAlign || "",
              format: _cells.columns[c].format || "normal",
            });
          } else {
            rowsData.push({
              value: htmlElement.innerHTML,
              backgroundColor:
                objectStylte.backgroundColor || "rgb(255,255,255)",
              color: objectStylte.color || "rgb(255,255,255)",
              align: objectStylte.textAlign || "",
              format: _cells.columns[c].format || "normal",
            });
          }
        }

        sendBodyData.push(JSON.stringify(rowsData));
      }

      formRowData = new FormData();
      formRowData.append(
        "bodyData",
        new Blob([sendBodyData.join("\n")], { type: "application/json" })
      );

      retrieveFileData = await ApiManager.post(
        "/Common/ExcelUtils/DataToFile", formRowData
      );
      if (retrieveFileData?.length ?? 0 > 0) {
        dataFileList.push({ filePath: retrieveFileData[0].SavedDataName });
      }
      //Loading.showLoading();
      document.getElementById("loadingArea").style.display = "flex";

      sendBodyData = [];
      newBindData = newBindData.slice(1);
      startIndex++;
      retrieveFileData = null;
      formRowData = null;
      objectStylte = null;
      //console.timeEnd("RowTime")
    }

    //console.log(ccc);

    console.log("filelist : " + dataFileList.length);

    formRowData = new FormData();

    formRowData.append(
      "bodyData",
      new Blob([JSON.stringify(dataFileList)], { type: "application/json" })
    );
    formRowData.append(
      "headerData",
      new Blob([JSON.stringify(sendHeaderData)], { type: "application/json" })
    );
    formRowData.append(
      "widthData",
      new Blob([JSON.stringify(widthData)], { type: "application/json" })
    );
    formRowData.append(
      "headerMergeData",
      new Blob([JSON.stringify(headerMerge)], { type: "application/json" })
    );
    formRowData.append("sheetName", sheetName);
    formRowData.append("headerHeight", headerHeight == undefined ? '' : headerHeight);

    let fileinfo = await ApiManager.callExcelDowonload(
      "/Common/ExcelUtils/ExcelDownloadSplitFile",
      formRowData
    );

    let disposition = fileinfo.headers["content-disposition"];

    let filename = null;
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    if (filename) {
      try {
        FileSaver.saveAs(
          new Blob([fileinfo.data], { type: fileinfo.headers["content-type"] }),
          decodeURI(filename)
        );
      } catch (ex) {
        console.log(ex);
      }
    }
  } finally {
    htmlElement.style.display = "none";
    htmlElement.style.left = "0px";
    htmlElement.style.top = "0px";
    sendBodyData = null;
    updateCellFn = null;
    newObj = null;
    newBindData = null;
    sendHeaderData = null;
    widthData = null;
    headerMerge = null;
    dataFileList = null;
    newData = null;

    gridObject.itemsSource = originalSource;

    if (oldeSelect) {
      //setTimeout(() => {gridObject.refresh(); gridObject.select (oldeSelect, true); gridObject.scrollPosition = originalScrollPoint; gridObject.scrollIntoView(oldeSelect.row, oldeSelect.col, true); document.getElementById("loadingArea").style.display = "none";}, 500);
      //setTimeout(() => {gridObject.refresh(); gridObject.select (oldeSelect, true); gridObject.scrollPosition = originalScrollPoint;  Loading.hideLoading();}, 500);
      setTimeout(() => {
        gridObject.refresh();
        gridObject.select(oldeSelect, true);
        gridObject.scrollPosition = originalScrollPoint;
        document.getElementById("loadingArea").style.display = "none";
      }, 500);
    } else {
      //setTimeout(() => {gridObject.refresh(); gridObject.select (-1, -1); Loading.hideLoading();}, 500);
      setTimeout(() => {
        gridObject.refresh();
        gridObject.select(-1, -1);
        document.getElementById("loadingArea").style.display = "none";
      }, 500);
    }

    if (callbackFn) {
      callbackFn();
    }
    //gridObject.refresh();
  }
};

/********************************************************************************************************************************** */
// 그리드 데이터를 파일로 나눠 업로드 후 엑셀 다운로드 처리 하여 엑셀 다운로드
ApiManager.ExcelDownloadSplitFileNoStyle = async (
  gridObject,
  sheetName,
  splitRows,
  callbackFn
) => {
  if ((gridObject?.itemsSource?.items?.length ?? 0) > 0) {
    document.getElementById("loadingArea").style.display = "flex";
    setTimeout(() => {
      ApiManager.ExcelDownloadSplitNoStyleFileExecute(
        gridObject,
        sheetName,
        splitRows,
        callbackFn
      );
    }, 500);
  } else {
    ComNoticfication.ShowErrorUI("Error", "There is no Data");
    return;
  }
};

ApiManager.ExcelDownloadSplitNoStyleFileExecute = async (
  gridObject,
  sheetName,
  splitRows,
  callbackFn
) => {
  console.time("finish");
  let originalSource = gridObject.itemsSource;
  let originalScrollPoint = gridObject.scrollPosition;
  let dataFileList = [];
  let widthData = [];
  let sendHeaderData = [];
  let headerMerge = {};

  let maxCols = gridObject.columns.length;
  let _cols = gridObject.cells._cols;
  let _columnHeaderRows = gridObject.columnHeaders.rows;
  let headerMaxRow = _columnHeaderRows.length;
  let htmlElement = document.getElementById("gridCellStyleElement");

  let newObj = _.cloneDeep(gridObject);
  let oldeSelect = newObj.selection;

  let newBindData = null;
  let startIndex = 0;
  let sendBodyData = [];
  let newMax = 0;
  let _cells = null;
  let rowsData = [];
  let formRowData = null;
  let retrieveFileData = null;

  let newData = null;

  try {
    function blankSelectionChange() { }
    newObj.onSelectionChanged = blankSelectionChange;
    //newData         = _.cloneDeep(newObj.itemsSource);
    newData = _.cloneDeep(newObj.collectionView._view);
    function getMergeInfo(colInfo, sendHeaderData) {
      if (
        colInfo._rng._col !== colInfo._rng._col2 ||
        colInfo._rng._row !== colInfo._rng._row2
      ) {
        let keyValue = colInfo._rng._row + "_" + colInfo._rng._col;
        headerMerge[keyValue] = {
          row: colInfo._rng._row,
          row2: colInfo._rng.row2,
          col: colInfo._rng._col,
          col2: colInfo._rng._col2,
        };
      }
      if (colInfo.parentGroup) {
        getMergeInfo(colInfo.parentGroup, sendHeaderData);
      }

      let tmpRow = sendHeaderData[colInfo._rng._row];
      tmpRow[colInfo._rng._col] = colInfo._curr_header;
    }

    for (let hCount = 0; hCount < headerMaxRow; hCount++) {
      let columnHeaderTitle;
      (columnHeaderTitle = []).length = maxCols;
      columnHeaderTitle.fill("");

      sendHeaderData.push(columnHeaderTitle);
    }

    if (
      splitRows === undefined ||
      splitRows === null ||
      isNaN(splitRows) ||
      splitRows < 800
    ) {
      splitRows = 800;
    }

    newBindData = ApiManager.splitArrayData(newData, splitRows);

    for (let c = 0; c < maxCols; c++) {
      let currentCols = _cols[c];

      if (
        currentCols._rng._col !== currentCols._rng._col2 ||
        currentCols._rng._row !== currentCols._rng._row2
      ) {
        let keyValue = currentCols._rng._row + "_" + currentCols._rng._col;
        headerMerge[keyValue] = {
          row: currentCols._rng._row,
          row2: currentCols._rng.row2,
          col: currentCols._rng._col,
          col2: currentCols._rng._col2,
        };
      }
      if (currentCols.parentGroup) {
        getMergeInfo(currentCols.parentGroup, sendHeaderData);
      }

      let tmpRow = sendHeaderData[currentCols._rng._row];
      tmpRow[currentCols._rng._col] = currentCols._curr_header;
    }

    while (newBindData.length > 0) {
      console.log("Split Count : " + startIndex);

      newObj.itemsSource = newBindData[0];
      newObj.select(-1, -1);
      newMax = newBindData[0].length;
      _cells = newObj.cells;

      console.time("RowTime");
      for (let r = 0; r < newMax; r++) {
        rowsData = [];
        for (let c = 0; c < maxCols; c++) {
          //updateCellFn(_cells, r, c, htmlElement, null, true);

          htmlElement.style.display = "none";
          htmlElement.style.left = "0px";
          htmlElement.style.top = "0px";

          if (startIndex === 0 && r === 0) {
            widthData.push("120px");
          }

          rowsData.push({
            value: _cells.getCellData(r, c), // htmlElement.innerHTML
            format: _cells.columns[c].format || "normal",
          });
        }

        sendBodyData.push(JSON.stringify(rowsData));
      }

      formRowData = new FormData();
      formRowData.append(
        "bodyData",
        new Blob([sendBodyData.join("\n")], { type: "application/json" })
      );

      retrieveFileData = await ApiManager.post("/Common/ExcelUtils/DataToFile", formRowData);
      if (retrieveFileData?.length ?? 0 > 0) {
        dataFileList.push({ filePath: retrieveFileData[0].SavedDataName });
      }
      //Loading.showLoading();
      document.getElementById("loadingArea").style.display = "flex";

      sendBodyData = [];
      newBindData = newBindData.slice(1);
      startIndex++;
      retrieveFileData = null;
      formRowData = null;

      console.timeEnd("RowTime");
    }

    console.time("server");

    formRowData = new FormData();

    //formData.append("bodyData" , new Blob([sendBodyData.join("\n")], {type: 'application/json'}));
    formRowData.append(
      "bodyData",
      new Blob([JSON.stringify(dataFileList)], { type: "application/json" })
    );
    formRowData.append(
      "headerData",
      new Blob([JSON.stringify(sendHeaderData)], { type: "application/json" })
    );
    formRowData.append(
      "widthData",
      new Blob([JSON.stringify(widthData)], { type: "application/json" })
    );
    formRowData.append(
      "headerMergeData",
      new Blob([JSON.stringify(headerMerge)], { type: "application/json" })
    );
    formRowData.append("sheetName", sheetName);

    //sendHeaderData = null;
    //widthData = null;
    //headerMerge = null;
    //dataFileList = null;

    let fileinfo = await ApiManager.callExcelDowonload(
      "/Common/ExcelUtils/ExcelDownloadSplitFileNoStyle",
      formRowData
    );
    //let fileinfo =  await ApiManager.callExcelDowonload("/Common/ExcelUtils/ExcelDownloadSplitFile", formRowData);

    let disposition = fileinfo.headers["content-disposition"];

    let filename = null;
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    if (filename) {
      try {
        FileSaver.saveAs(
          new Blob([fileinfo.data], { type: fileinfo.headers["content-type"] }),
          decodeURI(filename)
        );
      } catch (ex) {
        console.log(ex);
      }
    }
    console.timeEnd("server");
    console.timeEnd("finish");
  } finally {
    htmlElement.style.display = "none";
    htmlElement.style.left = "0px";
    htmlElement.style.top = "0px";
    sendBodyData = null;

    newObj = null;
    newBindData = null;
    sendHeaderData = null;
    widthData = null;
    headerMerge = null;
    dataFileList = null;
    newData = null;

    gridObject.itemsSource = originalSource;

    if (oldeSelect) {
      //setTimeout(() => {gridObject.refresh(); gridObject.select (oldeSelect, true); gridObject.scrollPosition = originalScrollPoint; gridObject.scrollIntoView(oldeSelect.row, oldeSelect.col, true); document.getElementById("loadingArea").style.display = "none";}, 500);
      //setTimeout(() => {gridObject.refresh(); gridObject.select (oldeSelect, true); gridObject.scrollPosition = originalScrollPoint;  Loading.hideLoading();}, 500);
      setTimeout(() => {
        gridObject.refresh();
        gridObject.select(oldeSelect, true);
        gridObject.scrollPosition = originalScrollPoint;
        document.getElementById("loadingArea").style.display = "none";
      }, 500);
    } else {
      //setTimeout(() => {gridObject.refresh(); gridObject.select (-1, -1); Loading.hideLoading();}, 500);
      setTimeout(() => {
        gridObject.refresh();
        gridObject.select(-1, -1);
        document.getElementById("loadingArea").style.display = "none";
      }, 500);
    }

    //gridObject.refresh();

    if (callbackFn) {
      callbackFn();
    }
  }
};

ApiManager.getServerDate = async (format, addDt, addMonth) => {
  let resultData = await ApiManager.post("/com/comUtil/retrieveServerDateInfo", { "dtFormat": (format ?? "YYYYMMDD"), "addDt": (addDt ?? "0"), "addMonth": (addMonth ?? "0") })
  let serverDate = "";
  if (resultData?.statusCode) {
    ComNoticfication.ShowErrorUI("Error", "An error occurred during processing");
  } else {
    serverDate = resultData.serverDate;
  }
  return serverDate;
}