import React, { useState, useEffect, useRef, useCallback } from "react";
import { ApiManager } from "../lib/ApiUtil";
import * as wjInput from "@grapecity/wijmo.react.input";

const ComComboTable = React.forwardRef(
  (
    {
      elementId, // 객체 아이디
      callRelationFuntion, // 부모에서 호출 할 함수
      loadCompleteCallBack, // 데이터가 로드됐을때 부모에서 호출할 함수
      defaultValue, // 기본 값
      defaultIndex, // 기본 인덱스
      defalultList,
      defaultDisabled, // 활성화여부
      defaultStyle, // 스타일
      defaultClassName, // CSS 클래스명
      dropdownClass,
      colTitles, // 컬럼타이틀

      isDisplayCode,         // 콤보박스 Code or Name 우선 표시(default false(name))
      isSetValCode,          // select시 Value에 Code or Name 세팅(default true(code))

      isSmallCode,           // 콤보박스 Code 작게 표시
      isBigCode,             // 콤보박스 Code 크게 표시

      cdnmFormat, //header display format 지정 (true => cd-nm or nm-cd /// false => cd or nm)
    },
    ref
  ) => {
    // 부모 Ref 를 내부 Ref로 변경 처리

    const cboRef = useRef();
    const cboIndexRef = useRef(null);
    const focusRef = useRef(null);

    const cboInitValue = useRef(null);
    const cboInitIndex = useRef(null);

    const cboCode = useRef();
    const cboName = useRef();

    // Combo 객체
    const [comboObject, setComboObject] = useState();
    // Combo Data
    const [comboData, setComboData] = useState([]);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    const [userHeight, setUserHeight] = useState(null);
    const [hasMaxHeightStyle, setHasMaxHeightStyle] = useState(false);

    // Code Or Name 관련
    const [displayCode, setDisplayCode] = useState(false);
    const [setValCode, setSetValCode] = useState(true);
    const [displayCdnm, setDisplayCdnm] = useState(false);

    const [shouldFocus, setShouldFocus] = useState(false);

    useEffect(() => {
      if(shouldFocus && focusRef.current){
        focusRef.current.control.hostElement.focus();
        setShouldFocus(false);
      }
    }, [shouldFocus])

    React.useImperativeHandle(ref, () => ({
      getValue: (codeField) => {
        if (cboRef.current) {
          let checkedItem = cboRef.current;
          let tempCode = setValCode ? codeField || cboCode.current || "cd" : cboName.current || "nm";
          if (checkedItem) {
            return checkedItem[tempCode];
          }
          return "";
        }
      },
      getValueItem: () => cboRef.current,
      getSelectedIndex: () => cboIndexRef.current,

      setValue: (v) => {
        if (comboObject) {
          if (v) {
            comboObject.selectedValue = v;
            cboInitValue.current = v;
          } else {
            comboObject.selectedValue = "";
            cboInitValue.current = "";
          }
        }
      },
      setIndex: (v) => {
        if (comboObject) {
          comboObject.selectedIndex = v;
        }
        cboInitIndex.current = v;
      },
      setStyle: (v) => {
        setHasStyle(true);
        setUserStyle(v);
      },
      setClassName: (v) => {
        setHasClassName(true);
        setUserClassName(v);
      },
      setDisabled: (v) => {
        setUserDisable(v);
      },

      setList: (v) => {
        if (
          v["initValue"] &&
          v.initValue !== undefined &&
          v.initValue !== null
        ) {
          cboInitIndex.current = null;
          cboInitValue.current = v.initValue;
        } else if (
          v["initIndex"] &&
          v.initIndex !== undefined &&
          v.initIndex !== null
        ) {
          cboInitValue.current = null;
          cboInitIndex.current = v.initIndex;
        }
        if (v["code"]) {
          cboCode.current = v.code;
        }
        if (v["name"]) {
          cboName.current = v.name;
        }
        if (v["data"]) {
          setComboData(v.data);
        }
      },
      setBindColumInfo: (v) => {
        if (v["code"]) {
          cboCode.current = v.code;
        }
        if (v["name"]) {
          cboName.current = v.name;
        }
      },
      setLoadData: (v) => {
        if (
          v["initValue"] &&
          v.initValue !== undefined &&
          v.initValue !== null
        ) {
          cboInitIndex.current = null;
          cboInitValue.current = v.initValue;
        } else if (
          v["initIndex"] &&
          v.initIndex !== undefined &&
          v.initIndex !== null
        ) {
          cboInitValue.current = null;
          cboInitIndex.current = v.initIndex;
        }
        let url = null;
        let param = null;
        let label = null;
        let labelDesciption = "All";
        let cd = null;
        let nm = null;
        if (v["url"]) {
          url = v.url;
        }
        if (v["param"]) {
          param = v.param;
        }
        if (v["label"]) {
          label = v.label;
        }
        if (v["labelDesciption"]) {
          labelDesciption = v.labelDesciption;
        }
        if (v["code"]) {
          cboCode.current = v.code;
          cd = v.code;
        }
        if (v["name"]) {
          cboName.current = v.name;
          nm = v.name;
        }

        loadCobmoData(url, param, label, labelDesciption, cd, nm);
      },
      setFocusCode: (v) => {
        setShouldFocus(true);
      },
    }));

    const loadCobmoData = useCallback(
      async (url, param, label, labelDesciption, cdColumn, nmCloumn) => {
        try {
          let retrieveComboData = null;
          if (param) {
            retrieveComboData = await ApiManager.post(url, param);
          } else {
            retrieveComboData = await ApiManager.post(url);
          }

          if (label) {
            let tempCode = cdColumn || "cd";
            let tempName = nmCloumn || "nm";

            let tempAll = {};
            tempAll[tempName] = labelDesciption || "ALL";
            tempAll[tempCode] = "";

            let allArray = [];
            allArray.push(tempAll);

            setComboData(allArray.concat(retrieveComboData));
          } else {
            setComboData(retrieveComboData);
          }

          if (comboObject) {
            if (defaultValue !== undefined && defaultValue !== null) {
              comboObject.selectedValue = defaultValue;
            } else if (defaultIndex !== undefined && defaultIndex !== null) {
              comboObject.selectedIndex = defaultIndex;
            }
          }
        } catch (ex) {
          console.log(ex.message);
        }
      },
      [comboObject, defaultValue, defaultIndex]
    );

    useEffect(() => {
      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }
      if (dropdownClass) {
        setUserHeight(dropdownClass);
        setHasMaxHeightStyle(true);
      } else {
        setHasMaxHeightStyle(false);
      }

      if (isDisplayCode !== undefined) {
        isDisplayCode ? setDisplayCode(true) : setDisplayCode(false);
      }

      if (isSetValCode !== undefined) {
        isSetValCode ? setSetValCode(true) : setSetValCode(false);
      }

      if (cdnmFormat !== undefined) {
        cdnmFormat ? setDisplayCdnm(true) : setDisplayCdnm(false);
      }

      if (defalultList) {
        setComboData(defalultList);
      }

      if (comboObject) {
        if (defaultValue !== undefined && defaultValue !== null) {
          comboObject.selectedValue = defaultValue;
          //setUserValue(defaultValue);
        } else if (defaultIndex !== undefined && defaultIndex !== null) {
          comboObject.selectedIndex = defaultIndex;
          //setUserSelectedIndex(defaultIndex);
        }
      }
    }, [
      defaultValue,
      defaultIndex,
      comboObject,
      defaultStyle,
      defaultClassName,
      defaultDisabled,
      dropdownClass,
      isDisplayCode,
      isSetValCode,
      defalultList
    ]);

    const comboInit = (sender) => {
      setComboObject(sender);
      sender.inputElement.addEventListener("click", function () {
        if(sender.isDroppedDown) sender.isDroppedDown = false;
        else sender.isDroppedDown = true;
      });	
    };

    const onComboSelectedIndexChanged = (e) => {
      cboIndexRef.current = e.selectedIndex;
      cboRef.current = e.selectedItem;
      if (callRelationFuntion) {
        callRelationFuntion(
          elementId,
          e.selectedValue,
          e.selectedIndex,
          e.selectedItem
        );
      }
    };

    const onItemSourceChanged = (cbo) => {
      if (cboInitValue.current) {
        cbo.selectedValue = cboInitValue.current || "";
      } else if (
        cboInitIndex.current !== undefined &&
        cboInitIndex.current !== null
      ) {
        cbo.selectedIndex = cboInitIndex.current;
      }

      if (loadCompleteCallBack) {
        loadCompleteCallBack(elementId, cbo.selectedValue, cbo.selectedIndex);
      }
    };

    return (
      <wjInput.ComboBox
        dropDownCssClass={`${hasMaxHeightStyle ? userHeight : "comobo-default-max-height"
          } combo-table`}
        name="ComComboTable"
        displayMemberPath={setValCode ? cboName.current || "nm" : cboCode.current || "cd"}
        selectedValuePath={setValCode ? cboCode.current || "cd" : cboName.current || "nm"}
        initialized={comboInit}
        itemsSource={comboData}
        itemsSourceChanged={onItemSourceChanged}
        selectedIndexChanged={onComboSelectedIndexChanged}
        isEditable
        headerPath={displayCode ? displayCdnm ? "cdnm" : "cd" : displayCdnm ? "nmcd" : "nm"}
        ref={focusRef}
        wjItemTemplate={(context) => (
          <div className={displayCode ? "item-wrapper-cd" : "item-wrapper-nm"} style={{ "alignItems": "center", "marginLeft": "10px" }}>

            {context.item.cd === '----- CHOICE-----' ? 
              <span style={{padding: "6px"}}>{context.item.cd}</span>
              :
              <>
              {displayCode ?
                context.item.cd !== "all" && <span className={isSmallCode ? "item-code-small" : isBigCode ? "item-code-big" : "item-code"}>{context.item.cd}</span>
                : <span className="item-name">{context.item.nm}</span>
                }
              {displayCode ?
                <span className="item-name">{context.item.nm}</span>
                : context.item.cd !== "all" && <span className="item-code">{context.item.cd}</span>
                }
              </>
            }
          </div>
        )}
        isDisabled={userDisable}
        className={hasClassName ? userClassName : "wj_with10"}
        style={hasStyle ? userStyle : {}}
      />
    );
  }
);
//export default ComCombo;
export default React.memo(ComComboTable);
//export default React.memo(ComCombo,(prevProps, nextProps) => prevProps === nextProps);
