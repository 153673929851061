import React, { useState, useEffect, useRef, useCallback } from "react";
import { ApiManager } from "../lib/ApiUtil";
import * as wjInput from "@grapecity/wijmo.react.input";

const ComComboMultiColumn = React.forwardRef(
  (
    {
      elementId, // 객체 아이디
      callRelationFuntion, // 부모에서 호출 할 함수
      loadCompleteCallBack, // 데이터가 로드됐을때 부모에서 호출할 함수
      defaultValue, // 기본 값
      defaultIndex, // 기본 인덱스
      defalultList,
      defaultDisabled, // 활성화여부
      defaultStyle, // 스타일
      defaultClassName, // CSS 클래스명
      dropdownClass,
      columnConfig, // 컬럼 설정
      defaultDisplayColumn,// 선택시 콤보에 표시할 컬럼명
    },
    ref
  ) => {
    // 부모 Ref 를 내부 Ref로 변경 처리

    const cboRef = useRef();
    const cboIndexRef = useRef(null);

    const cboInitValue = useRef(null);
    const cboInitIndex = useRef(null);

    const cboCode = useRef("cd");
    const cboName = useRef("nm");
    const cboDisplay = useRef("nm");

    const [config, setConfig] = useState([]);

    // Combo 객체
    const [comboObject, setComboObject] = useState();
    // Combo Data
    const [comboData, setComboData] = useState([]);

    // 스타일 관련
    const [userStyle, setUserStyle] = useState({});
    const [userClassName, setUserClassName] = useState(false);
    const [userDisable, setUserDisable] = useState(false);

    const [hasClassName, setHasClassName] = useState(false);
    const [hasStyle, setHasStyle] = useState(false);

    const [userHeight, setUserHeight] = useState(null);
    const [hasMaxHeightStyle, setHasMaxHeightStyle] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);

    React.useImperativeHandle(ref, () => ({
      getValue: (codeField) => {
        if (cboRef.current) {
          let checkedItem = cboRef.current;
          let tempCode = codeField || cboCode.current || "cd";
          if (checkedItem) {
            return checkedItem[tempCode];
          }
          return "";
        }
      },
      getValueItem: () => cboRef.current,
      getSelectedIndex: () => cboIndexRef.current,

      setValue: (v) => {
        if (comboObject) {
          if (v) {
            comboObject.selectedValue = v;
            cboInitValue.current = v;
          } else {
            comboObject.selectedValue = "";
            cboInitValue.current = "";
          }
        }
      },
      setIndex: (v) => {
        if (comboObject) {
          comboObject.selectedIndex = v;
        }
        cboInitIndex.current = v;
      },
      setStyle: (v) => {
        setHasStyle(true);
        setUserStyle(v);
      },
      setClassName: (v) => {
        setHasClassName(true);
        setUserClassName(v);
      },
      setDisabled: (v) => {
        setUserDisable(v);
      },
      setColumnConfig: (v) => {
        setConfig(v);
      },      
      setDisplayColumn: (v) => {
        cboDisplay.current = v;
      },

      setList: (v) => {
        if (
          v["initValue"] &&
          v.initValue !== undefined &&
          v.initValue !== null
        ) {
          cboInitIndex.current = null;
          cboInitValue.current = v.initValue;
        } else if (
          v["initIndex"] &&
          v.initIndex !== undefined &&
          v.initIndex !== null
        ) {
          cboInitValue.current = null;
          cboInitIndex.current = v.initIndex;
        }
        if (v["code"]) {
          cboCode.current = v.code;
        }
        if (v["name"]) {
          cboName.current = v.name;
        }
        if (v["data"]) {
          setComboData(v.data);
        }
      },
      setBindColumInfo: (v) => {
        if (v["code"]) {
          cboCode.current = v.code;
        }
        if (v["name"]) {
          cboName.current = v.name;
        }
      },
      setLoadData: (v) => {
        if (
          v["initValue"] &&
          v.initValue !== undefined &&
          v.initValue !== null
        ) {
          cboInitIndex.current = null;
          cboInitValue.current = v.initValue;
        } else if (
          v["initIndex"] &&
          v.initIndex !== undefined &&
          v.initIndex !== null
        ) {
          cboInitValue.current = null;
          cboInitIndex.current = v.initIndex;
        }
        let url = null;
        let param = null;
        let label = null;
        let labelDesciption = "All";
        let cd = null;
        let nm = null;
        if (v["url"]) {
          url = v.url;
        }
        if (v["param"]) {
          param = v.param;
        }
        if (v["label"]) {
          label = v.label;
        }
        if (v["labelDesciption"]) {
          labelDesciption = v.labelDesciption;
        }
        if (v["code"]) {
          cboCode.current = v.code;
          cd = v.code;
        }
        if (v["name"]) {
          cboName.current = v.name;
          nm = v.name;
        }

        loadCobmoData(url, param, label, labelDesciption, cd, nm);
      },
    }));

    const loadCobmoData = useCallback(
      async (url, param, label, labelDesciption, cdColumn, nmCloumn) => {
        try {
          let retrieveComboData = null;
          if (param) {
            retrieveComboData = await ApiManager.post(url, param);
          } else {
            retrieveComboData = await ApiManager.post(url);
          }

          if (label) {
            let tempCode = cdColumn || "cd";
            let tempName = nmCloumn || "nm";

            let tempAll = {};
            tempAll[tempName] = labelDesciption || "ALL";
            tempAll[tempCode] = "";

            let allArray = [];
            allArray.push(tempAll);

            setComboData(allArray.concat(retrieveComboData));
          } else {
            setComboData(retrieveComboData);
          }

          if (comboObject) {
            if (defaultValue !== undefined && defaultValue !== null) {
              comboObject.selectedValue = defaultValue;
            } else if (defaultIndex !== undefined && defaultIndex !== null) {
              comboObject.selectedIndex = defaultIndex;
            }
          }
        } catch (ex) {
          console.log(ex.message);
        }
      },
      [comboObject, defaultValue, defaultIndex]
    );

    useEffect(() => {
      if (defaultStyle) {
        setUserStyle(defaultStyle);
        setHasStyle(true);
      }

      if (defaultClassName) {
        setUserClassName(defaultClassName);
        setHasClassName(true);
      }
      if (defaultDisabled === undefined || defaultDisabled === null) {
        setUserDisable(false);
      } else {
        setUserDisable(defaultDisabled);
      }
      if (dropdownClass) {
        setUserHeight(dropdownClass);
        setHasMaxHeightStyle(true);
      } else {
        setHasMaxHeightStyle(false);
      }

      if (columnConfig) {
        setConfig(columnConfig);
      }

      if (defaultDisplayColumn) {
        cboDisplay.current = defaultDisplayColumn; 
      }

      if (defalultList) {
        setComboData(defalultList);
      }

      if (comboObject) {
        if (defaultValue !== undefined && defaultValue !== null) {
          comboObject.selectedValue = defaultValue;
          //setUserValue(defaultValue);
        } else if (defaultIndex !== undefined && defaultIndex !== null) {
          comboObject.selectedIndex = defaultIndex;
          //setUserSelectedIndex(defaultIndex);
        }
      }
    }, [
      defaultValue,
      defaultIndex,
      comboObject,
      defaultStyle,
      defaultClassName,
      defaultDisabled,
      dropdownClass,
      columnConfig,
      defaultDisplayColumn,
    ]);

    const comboInit = (sender) => {
      setComboObject(sender);
      sender.inputElement.addEventListener("click", function () {
        if(sender.isDroppedDown) sender.isDroppedDown = false;
        else sender.isDroppedDown = true;
      });	
    };

    const onComboSelectedIndexChanged = (e) => {
      cboIndexRef.current = e.selectedIndex;
      cboRef.current = e.selectedItem;
      setSelectedItem(e.selectedItem);
      if (callRelationFuntion) {
        callRelationFuntion(
          elementId,
          e.selectedValue,
          e.selectedIndex,
          e.selectedItem
        );
      }
    };

    const onItemSourceChanged = (cbo) => {
      if (cboInitValue.current) {
        cbo.selectedValue = cboInitValue.current || "";
      } else if (
        cboInitIndex.current !== undefined &&
        cboInitIndex.current !== null
      ) {
        cbo.selectedIndex = cboInitIndex.current;
      }

      if (loadCompleteCallBack) {
        loadCompleteCallBack(elementId, cbo.selectedValue, cbo.selectedIndex);
      }
    };

    const renderItem = (value, cfg) => {
      // if (!value)
      //   return null;

      if (!cfg?.width)
        return null;

      return <span 
              className={"item-name"} 
              style={{width: cfg?.width === "*" ? "100%" : cfg?.width, 
                    textAlign: cfg?.textAlign}
              }>
                {value}
              </span>         
    }

    const renderHeader = (value, cfg) => {
      if (!value)
        return null;

      if (!cfg?.width)
        return null;

      return  <span 
                className={"item-name"} 
                style={{width: cfg?.width === "*" ? "100%" : cfg?.width, 
                  textAlign: "center", 
                  fontWeight: 'bold'}
                }>
                  {value}
              </span>
    }

    const getHeaderPath = () => {
      // 선택된 cd가 ALL이면 cd로 아니면 cboDisplay.current으로
      let tempCode = cboCode.current || "cd";
      if (selectedItem) {
        if (selectedItem[tempCode].toUpperCase() === "ALL" || selectedItem[tempCode].toUpperCase() === "SELECT") {
          return tempCode;
        } 
      }
      
      return cboDisplay.current;
    }

    return (
      <wjInput.ComboBox
        dropDownCssClass={`${hasMaxHeightStyle ? userHeight : "comobo-default-max-height"
        } combo-table`}
        name="ComComboMultiColumn"
        displayMemberPath={cboName.current || "nm"}
        selectedValuePath={cboCode.current || "cd"}
        initialized={comboInit}
        itemsSource={comboData}
        isEditable
        itemsSourceChanged={onItemSourceChanged}
        selectedIndexChanged={onComboSelectedIndexChanged}
        selectedItem={selectedItem}
        isDisabled={userDisable}
        className={hasClassName ? userClassName : "wj_with10"}
        style={hasStyle ? userStyle : {}}
        headerPath={getHeaderPath()}
        wjItemTemplate={(context) => { 
          const item = context.item;
          const keys = Object.keys(item);

          return (
            <>  
            {/* Render Header */}
            { context.itemIndex === 0 &&
              <div className={"item-wrapper-cd"} style={{ "alignItems": "center", backgroundColor: "#ededed"}}>
                { config.map(c => renderHeader(c?.header, c)) }
              </div>
            } 

            {/* Render Item */}
            { (item[cboCode.current].toUpperCase() === "ALL" || item[cboCode.current].toUpperCase() === "SELECT") ?
              <div className={"item-wrapper-cd"} style={{ "alignItems": "center"}}>    
                <span className={"item-name"} style={{width: "100%",  textAlign: "center"}}>
                    {item[cboCode.current]}
                </span>   
              </div>
              :
              <div className={"item-wrapper-cd"} style={{ "alignItems": "center"}}>    
                {config.map(c => renderItem(item[c.binding], c)) }
              </div>
            }             
          </>
        )}}
        >
        </wjInput.ComboBox>
    );
  }
);
//export default ComCombo;
export default React.memo(ComComboMultiColumn);
//export default React.memo(ComCombo,(prevProps, nextProps) => prevProps === nextProps);
