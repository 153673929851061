import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
// import PanelGroup from "react-panelgroup";

import * as wjInput from "@grapecity/wijmo.react.input";
import '@grapecity/wijmo.touch';
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { CollectionView } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Divider from "@mui/material/Divider";

import "assets/styles/pages/ModalCommon.scss";

import ComTextBox from "common/components/ComTextBox";
import ComCombo from "common/components/ComCombo";

import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";
import { ComNoticfication } from "common/components/ComNoticfication";

function CityZipSchPopWithRegister({
  closeFn,
  randomKey,
  reqSearchText,
  resultData,
  reqComCode,
}) {
  //url
  const retrieveCityZipList = "/init/b2buser/retrieveCityZipList";

  // 검색
  const entitySearchText = useRef(null);
  const entitySearchType = useRef(null);

  const filterSearch = useRef(null);

  const gridRef = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState("0");

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);
  const popComponent = useRef();

  const comCode = reqComCode;

  /***** Grid init *************************************************************************************************/
  const initFilter = (f) => {
    filterSearch.current = f;
  };

  const initGrid = (sender) => {
    setGridObject(sender);
    gridRef.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      canAreaInfo: false,
      hasCheckBox: false,
      hasDisableCheck: true,
      hasReadOnlyCombo: true,
      readOnlyComboList: ["langCode", "effectiveStartDate"],
    });

    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (
        ht._row !== -1 &&
        ht._p.cellType === 1 &&
        sender.selectedItems &&
        Array.isArray(sender.selectedItems) &&
        sender.selectedItems.length > 0
      ) {
        popComponent.current.hide();
        closeFn(sender.selectedItems[0]);
      }
    });

    //모바일 화면에서 더블클릭 처리.
    let doubleClicked = false;
    sender.addEventListener(sender.hostElement, "click", (e) => {
      if (doubleClicked) {
        let ht = sender.hitTest(e);
        if (
          ht._row !== -1 &&
          ht._p.cellType === 1 &&
          sender.selectedItems &&
          Array.isArray(sender.selectedItems) &&
          sender.selectedItems.length > 0
        ) {
          popComponent.current.hide();
          closeFn(sender.selectedItems[0]);
        }
      }
      doubleClicked = true;
      setTimeout(() => {
        doubleClicked = false;
      }, 300);
    }, true);

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view });
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });
  };

  /*******Grid Format *********************************************************************************/
  //Memory
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {
    let gridFormat = [
      {
        binding: "cityName",
        header: "City",
        minWidth: 200,
        width: "*",
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "stateCode",
        header: "State",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      },
      {
        binding: "postalCode",
        header: "Zip code",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      },
      {
        binding: "countyName",
        header: "County",
        minWidth: 180,
        width: "*",
        allowDragging: true,
        isReadOnly: true,
      },
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();
    SearchGridFormat();

    const getPageInfo = async () => {
      let sendParam = {
        comCode: comCode,
        searchBizType: "",
        searchText: reqSearchText,
        searchType: "zip",
        pageSize: "1",
        rowSize: "100",
        pageNo: "1",
      };

      if (resultData) {
        setGridData(
          new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })
        );

        searchDataRef.current = sendParam;
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultData.list.length);

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current);
        } else {
          setGridDataCount(
            pageCntRef.current.toLocaleString() +
            " / " +
            totCntRef.current.toLocaleString()
          );
        }
      }
      filterSearch?.current?.clear();

      setTimeout(() => {
        entitySearchText.current?.setFocus();
      }, 300);
    };

    getPageInfo();

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [randomKey]);

  // 조회
  const onSearch = (param, e) => {
    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {
      comCode: comCode, //TODO
      searchBizType: "",
      searchText: entitySearchText.current.getValue()
        ? entitySearchText.current.getValue().toUpperCase()
        : "",
      searchType: entitySearchType.current.getValue(),
      pageSize: "1",
      rowSize: "100",
      pageNo: pageNoVal.toString(),
    };
    searchDataRef.current = sendParam;
    onSearchExecute(sendParam, param);
  };

  const onSearchExecute = async (sendParam, param) => {
    // let row_index = gridRef.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveCityZipList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(
          retrieveCityZipList,
          searchDataRef.current
        );
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(
          "Error",
          "An error occurred while looking up the code."
        );
        setGridDataCount(0);
      } else {
        let resultCnt = 0;
        if (param.searchFlag) {
          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;
        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          });

          let totResultData = [
            ...param.view._pgView,
            ...collectionView._pgView,
          ];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current);
        } else {
          setGridDataCount(
            pageCntRef.current.toLocaleString() +
            " / " +
            totCntRef.current.toLocaleString()
          );
        }
      }
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  };

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top;
  };

  const [customWidths, setCustomWidths] = useState([
    { size: 200, minSize: 100, resize: "dynamic" },
    { minSize: 100, resize: "dynamic" },
  ]);
  const handlePanelUpdate = (widths) => {
    setCustomWidths(widths);
  };

  return (
    <wjInput.Popup
      className="pop-resizable pop-small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`City Search`}</h2>
        <Button
          color="secondary"
          className="btn-close"
          tabIndex={-1}
          onClick={closePopup}
        >
          <CloseIcon />
        </Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Search by</label>
            </div>
            <div className="bx-inp combo">
              <ComCombo
                ref={entitySearchType}
                elementId={"entitySearchType"}
                defalultList={[
                  { cd: "zip", nm: "Zip Code" },
                  { cd: "city", nm: "City" },
                  { cd: "state", nm: "State" },
                  { cd: "county", nm: "County" },
                ]}
                defaultValue={"zip"}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Search Text</label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entitySearchText}
                elementId={"entitySearchText"}
                defaultValue={reqSearchText}
                defaultStyle={{ textTransform: "uppercase" }}
              />

              <Button
                variant="contained"
                onClick={(e) => {
                  onSearch({ searchFlag: true, view: null }, e);
                }}
              >
                {"Search"}
              </Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default CityZipSchPopWithRegister;
