import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUserProceeding, registerUserIsIRSWqStateInfoFinish } from 'store/auth/register/reducer';

import "assets/styles/pages/SignUpPage.scss";
import Grid from '@mui/material/Grid';

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";

import ComTextBox from "common/components/ComTextBoxWithForm";

import { ComUtils } from "common/lib/ComUtils";

const IRSWqStatementSpiffPage = React.forwardRef(
    ({ }
        , ref
    ) => {
        const alphaNumericRegex = /^[a-zA-Z\s-]*$/;

        // 메세지 추가
        // const { messageObject } = useContext(MessageContext);
        // const savedMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_004");
        // const errorMessage = ComUtils.getMessage(messageObject, "JMSG_COM_ERR_001");
        const entitySignature = useRef(null);

        const dispatch = useDispatch();
        const AccountProperties = createSelector(
            (state) => state.account,
            (account) => ({
                user: account.user
            })
        );
        const { user } = useSelector(AccountProperties, shallowEqual);

        React.useImperativeHandle(ref, () => ({
            isEmptyRequired: () => {
                if (!user.esignature) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn, "Please enter your E-Signature (Last Name)."
                        , () => { entitySignature.current.setFocus(); });
                }
                else { }
            }
            , isDiffSignature: () => {
                ComNoticfication.ShowAlertUICallback(
                    ComUtils._altTitleWarn, "Invalid Signature. Please type in your last name for the signature."
                    , () => {
                        entitySignature.current?.setValue(null);
                        entitySignature.current.setFocus();
                    });
            }
        }));

        const onBlurSignature = (id, value) => {
            if (alphaNumericRegex.test(value)) {
                user.esignature = value.toUpperCase();
                dispatch(registerUserProceeding(user));
                dispatch(registerUserIsIRSWqStateInfoFinish(true))
            } else {
                if (value) {
                    ComNoticfication.ShowAlertUICallback(
                        ComUtils._altTitleWarn,
                        "Special characters and numbers are not allowed in the E-Signature (Last Name).",
                        () => {
                            entitySignature.current?.setValue(null);
                            entitySignature.current?.setFocus();
                        }
                    );
                }

            }
        };

        const _isMounted = useRef(true);
        useEffect(() => {
            let abortController = new AbortController();

            const getPageInfo = async () => {
                entitySignature.current.setValue(user.esignature);
            }

            getPageInfo();

            // ComponentWillUnmount in Class Component
            return () => {
                _isMounted.current = false;
                abortController.abort();
            };
        }, [user]);

        return (
            <div className="content-signup">
                <div className="content-text-statement">
                    <div>
                        <p>Under Penalties of perjury, I certify that: </p>
                    </div>
                    <div>
                        <p>1. My Social Security Number input in this form is correct. </p>
                        <p>2. I am not subject to backup withholding because </p>
                        <p className="pl-8">(a) I am exempt from backup withholding, or </p>
                        <p className="pl-8">
                            (b) IRS has notified me that I am no longer subject to backup
                            withholding, or
                        </p>
                        <p className="pl-8">
                            (c) IRS has not notified me that I am subject to backup withholding
                            as a result of a failure to report all interests and dividends.{" "}
                        </p>
                    </div>
                    <p>
                        To certify this statement you must electronically sign this statement
                        by entering your last name.
                    </p>
                </div>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid className="ip-number">
                        <p>
                            E-Signature (Last Name) <span className="required">*</span>
                        </p>
                        <ComTextBox ref={entitySignature}
                            elementId={"entitySignature"}
                            callRelationBlur={onBlurSignature}
                            defaultMaxLength={100}
                            defaultStyle={{ "textTransform": "uppercase" }} />
                    </Grid>
                </Grid>
            </div>
        )
    }
);
export default IRSWqStatementSpiffPage;