import { useState, useEffect, useContext, useRef, useCallback } from "react";
import "assets/styles/pages/PagesCommon.scss";
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Divider } from "@mui/material";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";

import OtherClaimRequestDtl from "./OtherClaimRequestDtl";
import OtherClaimRequestMod from "./OtherClaimRequestMod";

function OtherClaimViewPop({ open, pageInfo, randomKey, closeFn, initData }) {
  const popComponent = useRef();

  const filterSearch = useRef(null);
  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    if (open) {
      filterSearch?.current?.clear();
    }

    return () => { _isMounted.current = false; abortController.abort(); }
  }, [open, initData]);

  const onCancleClick = () => {
    popComponent.current.hide();
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  return (
    <wjInput.Popup
      className="pop-resizable"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{
        height: "calc(90vh)",
        overflowY: "auto",
        width: "1400px"
      }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Other Claim View"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        {initData?.viewMode === 'UM' ?
          <OtherClaimRequestMod pageInfo={pageInfo} initData={initData}/>
          :
          <OtherClaimRequestDtl pageInfo={pageInfo} initData={initData}/> 
        }
      </div>      
    </wjInput.Popup>
         
  );
}

export default OtherClaimViewPop;
