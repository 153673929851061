import React, { useState, useEffect } from "react";
import { RecoilRoot } from "recoil";
import cookie from "react-cookies";
// sharepoint_cns 추가
import { Routes, Route } from "react-router-dom";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import "assets/wijmo_scss/wijmo.scss";
import "App_modify.scss";

import packageJson from "../package.json";
import Authmiddleware from "./routes/route";
import { protectedRoutes, publicRoutes } from "./routes";

import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";
// import LayoutContainer from "Layout/LayoutContainer";
// import LoginPage from "Pages/Login/LoginPage";
// import SignUpPage from "Pages/Login/SignUpPage";


function App() {
  const setScreenSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  // const [isLogin, setIsLogin] = useState(false);
  // const [isRegister, setIsRegister] = useState(false);

  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          default: {
            main: "#8a8d93",
            contrastText: "#fff",
          },
          primary: {
            main: "#a50034",
          },
          secondary: {
            main: "#000",
          },
        },
      },
    },
  });

  const checkLogin = async () => {

    if (!sessionStorage.getItem("userId")) {
      let resultData = await ApiManager.post("/init/loginCheck", { "isClose": "false" });
      if (resultData && resultData.result === "Success") {

        sessionStorage.setItem("userId", resultData?.userId ?? '');
        sessionStorage.setItem("userName", resultData?.userName ?? '');
        sessionStorage.setItem("empNo", resultData?.empNo ?? '');
        sessionStorage.setItem("emailId", resultData?.emailId ?? '');
        sessionStorage.setItem("comCode", resultData?.comCode ?? '');
        sessionStorage.setItem("language", resultData?.language ?? '');
        sessionStorage.setItem("userType", resultData?.userType ?? '');
        sessionStorage.setItem("userTypeDtl", resultData?.userTypeDtl ?? '');
        sessionStorage.setItem("userGroup", resultData?.userGroup ?? '');
        sessionStorage.setItem("billTo", resultData?.billTo ?? '');
        sessionStorage.setItem("billTo2", resultData?.billTo2 ?? '');
        sessionStorage.setItem("billTo3", resultData?.billTo3 ?? '');
        sessionStorage.setItem("userMainClaimLevel", resultData?.userMainClaimLevel ?? '');
        sessionStorage.setItem("pageRowSize", resultData?.pageRowSize ?? '');
        sessionStorage.setItem("profile", resultData?.profile ?? '');
        sessionStorage.setItem("csrfToken", resultData.csrfToken ?? '');
        sessionStorage.setItem("targetGroup", resultData.targetGroup ?? '');
        if (resultData?.epLogin === "Y") {
          ComUtils.setEpLoginSessionItem(resultData);
          document.location.href = "/ep_login";
        } else {
          document.location.href = "/";
        }

      }
      //userId true
    }
    else if (cookie.load("EP_LOGIN") === "Y") {
      let resultData = await ApiManager.post("/init/loginCheck", { "isClose": "false" });
      if (resultData && resultData.result === "Success") {
        if ((sessionStorage.getItem("reqId") != resultData?.reqId)) {

          sessionStorage.setItem("userId", resultData?.userId ?? '');
          sessionStorage.setItem("userName", resultData?.userName ?? '');
          sessionStorage.setItem("empNo", resultData?.empNo ?? '');
          sessionStorage.setItem("emailId", resultData?.emailId ?? '');
          sessionStorage.setItem("comCode", resultData?.comCode ?? '');
          sessionStorage.setItem("language", resultData?.language ?? '');
          sessionStorage.setItem("userType", resultData?.userType ?? '');
          sessionStorage.setItem("userTypeDtl", resultData?.userTypeDtl ?? '');
          sessionStorage.setItem("userGroup", resultData?.userGroup ?? '');
          sessionStorage.setItem("billTo", resultData?.billTo ?? '');
          sessionStorage.setItem("billTo2", resultData?.billTo2 ?? '');
          sessionStorage.setItem("billTo3", resultData?.billTo3 ?? '');
          sessionStorage.setItem("userMainClaimLevel", resultData?.userMainClaimLevel ?? '');
          sessionStorage.setItem("pageRowSize", resultData?.pageRowSize ?? '');
          sessionStorage.setItem("profile", resultData?.profile ?? '');
          sessionStorage.setItem("csrfToken", resultData.csrfToken ?? '');
          sessionStorage.setItem("targetGroup", resultData.targetGroup ?? '');
          if (resultData?.epLogin === "Y") {
            ComUtils.setEpLoginSessionItem(resultData);
            document.location.href = "/ep_login";
          } else {
            document.location.href = "/";
          }
        }
      }
    }



    // if (sessionStorage?.getItem("userId") !== null) {
    //   let resultData = await ApiManager.post("/init/loginCheck", {isClose: "false"});
    //   if (resultData) {
    //     setIsLogin(() => true);
    //   } else {
    //     setIsLogin(() => false);
    //     sessionStorage.clear();
    //   }
    // } else {
    //   setIsLogin(() => false);
    //   sessionStorage.clear();
    // }

    /* else {
      let ssoLogIn = sessionStorage.getItem("ssoLogIn");
      if ((ssoLogIn ?? "") === "Y") {
        document.location.href = "https://sso.lge.com";
      }
      setIsLogin(() => false);
      //setIsLogin(false);
    }*/
  };

  const caching = () => {
    let version = localStorage.getItem("sourceVersion");

    console.log("version : " + version);
    console.log("packageJson.version : " + packageJson.version);

    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      localStorage.removeItem("sourceVersion");
      localStorage.setItem("sourceVersion", packageJson.version);
      window.location.reload(true);
    }
  };

  /*   const preventClose = (e) => {
      try {
        e.preventDefault();
        e.returnValue = ""; //Chrome에서 동작하도록; deprecated
      } catch (ex) {}
  
      ApiManager.post("/init/clearSession", {
        isClose: "true",
        actorId: sessionStorage.getItem("userId"),
      });
    }; */

  useEffect(() => {
    const clearCache = async () => {
      caching();
      checkLogin();
    };
    clearCache();

    setScreenSize();
    /*
        if ("onpagehide" in window) {
            
            window.addEventListener("pagehide", preventClose, false);
        } 
        else {
            window.addEventListener("beforeunload",  preventClose, false);
        }
        */
    return () => {
      /*
            if ("onpagehide" in window) {
                window.removeEventListener("pagehide", preventClose);
            }
            else{
                window.removeEventListener("beforeunload", preventClose);
            }
            */
    };
  }, []);
  // const redirectLoginPage = () => {
  //   setIsRegister(false);
  // };

  return (
    <RecoilRoot>
      <CssVarsProvider theme={theme}>

        <React.Fragment>
          <Routes >
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={route.component}
                key={idx}
                exact={true}
              />
            ))}

            {protectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    {route.component}
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            ))}
          </Routes >
        </React.Fragment>

        {/* <LayoutContainer /> */}
        {/* {isLogin ? (
          <LayoutContainer isLogin={isLogin} />
        ) : isRegister ? (
          <SignUpPage backToLogin={redirectLoginPage} />
        ) : (
          <LoginPage redirectRegister={() => setIsRegister(true)} />
        )} */}

      </CssVarsProvider>
    </RecoilRoot>
  );
}

export default App;
