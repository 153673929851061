import * as wjGrid from '@grapecity/wijmo.grid';

class GridUserMergeByKey extends wjGrid.MergeManager {
    getMergedRange(panel, currentRow, currentCol, clip = false) {

        // BottomLeft: = 6
        // Cell: = 1
        // ColumnFooter: = 5
        // ColumnHeader: = 2
        // None: = 0
        // RowHeader: = 3
        // TopLeft: = 4

        // Header 
        // 체크 박스 병합
        if (panel.cellType === 4 ){

            
            let  rng = new wjGrid.CellRange(currentRow, currentCol);

            // expand left/right
            for (let i = rng.col; i < panel.columns.length - 1; i++) {
                if (
                panel.getCellData(rng.row, i, true) !==
                panel.getCellData(rng.row, i + 1, true)
                )
                break;
                rng.col2 = i + 1;
            }
            for (let i = rng.col; i > 0; i--) {
                if (
                panel.getCellData(rng.row, i, true) !==
                panel.getCellData(rng.row, i - 1, true)
                )
                break;
                rng.col = i - 1;
            }
            //
            // expand up/down
            for (let i = rng.row; i < panel.rows.length - 1; i++) {
                if (
                panel.getCellData(i, rng.col, true) !==
                panel.getCellData(i + 1, rng.col, true)
                )
                break;
                rng.row2 = i + 1;
            }
            for (let i = rng.row; i > 0; i--) {
                if (
                panel.getCellData(i, rng.col, true) !==
                panel.getCellData(i - 1, rng.col, true)
                )
                break;
                rng.row = i - 1;
            }
            //
            // done
            return rng;
            
        }
        // Heaer 처리
        else if (panel.cellType === 2){
            
            // create basic cell range
            let rng = null;
            
            // start with single cell
            rng = new wjGrid.CellRange(currentRow, currentCol);

            
            // get reference values to use for merging
            let val = panel.getCellData(currentRow, currentCol, false);
            
            // Row Expand Up
            while (rng.row > 0 &&
                panel.getCellData(rng.row - 1, currentCol, false) === val ){
                rng.row--;
            }
            // Row Expand down
            while (rng.row2 < panel.rows.length - 1 &&
                panel.getCellData(rng.row2 + 1, currentCol, false) === val){
                rng.row2++;
            }
            if ((panel.rows.length -1) !== currentRow){
                // Col Expand Left
                while (rng.col > 0 &&
                    panel.getCellData(rng.row, rng.col -1 , false) === val ){
                    rng.col--;
                }
                //
                // Col Expand Right
                while (rng.col2 < panel.columns.length - 1 &&
                    panel.getCellData(rng.row2, rng.col2+1, false) === val){
                    rng.col2++;
                }
            }
                
            // No Merge
            if (rng.isSingleCell) {
                rng = null;
            }
            //
            // done
            return rng;
            
        }
        // Data Cell 
        else if (panel.cellType === 1){

            // 데이터 가져오는 방법
            //panel.grid.collectionView._pgView[currentRow].uiFlag

            if (!panel.columns[currentCol].allowMerging){
                return null;
            }
            //
            // create basic cell range
            let rng = null;
            //
            // start with single cell
            rng = new wjGrid.CellRange(currentRow, currentCol);
            
            let minRow = panel.viewRange.row;
            let maxRow = panel.viewRange.row2;
            let staticHeight = panel.grid.rows.defaultSize;


            //let spanCount = panel.viewRange.rowSpan;
            //let viewCnt = parseInt(panel.grid.clientSize.height / 26);
            //let div     = (panel.grid.clientSize.height / 26) - viewCnt;

            //let viewCnt = panel.grid.clientSize.height / staticHeight;
            //let div     = (panel.grid.clientSize.height / staticHeight) - viewCnt;
            //let displayCount = maxRow - minRow;
            
            // console.log("panel.grid.clientSize.height : " + panel.grid.clientSize.height)
            // console.log("panel.grid.scrollPosition.y : "+ panel.grid.scrollPosition.y);
            // console.log("panel.grid.clientSize.height / staticHeight : "+ panel.grid.clientSize.height / staticHeight) ;
            // console.log("(panel.grid.clientSize.height / staticHeight) - viewCnt : "+ ((panel.grid.clientSize.height / staticHeight) - parseInt(viewCnt))) ;
            // console.log("maxRow "+ maxRow) ;
            // console.log("minRow "+ minRow) ;
            // console.log("displayCount : " + displayCount)

            let currentValueArray = [];

            let customMergeKey  = panel.grid.customMergeKey
            let items           = panel.grid.collectionView.items
            let bindName        = panel.grid.cells._cols[currentCol].binding
            let findIndex       = customMergeKey.indexOf(bindName)

            if (findIndex === -1){
                findIndex = customMergeKey.length-1;
            }
            // get reference values to use for merging
            //for (let i=currentCol; i>=0; i--){
            for (let i=0; i<= findIndex; i++){

                let itemValue = items[currentRow][customMergeKey[i]];

                if (itemValue){
                    currentValueArray.push(itemValue)
                }
                else{
                    currentValueArray.push("_")
                }
            }

            // current Values Array to String
            let currentValue = currentValueArray.join(",");
            
            // expand up
            let checkCount = (((minRow * staticHeight) +panel.grid.scrollPosition.y )+ staticHeight)
            //if ( 1<= checkCount && checkCount <= 17){
            //console.log("befaore minRow : "+ minRow)
            if ( 0<= checkCount && checkCount <= 15){

                if (minRow === 0 && checkCount<=12){
                    minRow =minRow +1;
                }
                else if (minRow > 0){
                    minRow =minRow +1;
                }
            }
            //console.log("after minRow : "+ minRow)
            //console.log("checkCount :"+checkCount)

            while (rng.row > minRow){
                
                let targetValueArray = [];
                for (let i=0; i<= findIndex; i++){

                    let itemValue = items[rng.row - 1][customMergeKey[i]];

                    if (itemValue){
                        targetValueArray.push(itemValue)
                    }
                    else{
                        targetValueArray.push("_")
                    }
                }
                if (targetValueArray.join(",") !== currentValue){
                    break;
                }
                
                if (rng.row  > 0){
                    rng.row--;
                }
                else{
                    break;
                }
            }
            
            
            // expand down
            while (rng.row2 <= maxRow+ 2){ //panel.rows.length - 1){
            
                let targetValueArray = [];
                //for (let i=currentCol; i>=0; i--){
                    for (let i=0; i<= findIndex; i++){

                    if ((rng.row2 + 1) < items.length){
                        let itemValue = items[rng.row2 + 1][customMergeKey[i]];

                        if (itemValue){
                            targetValueArray.push(itemValue)
                        }
                        else{
                            targetValueArray.push("_")
                        }
                    }
                }

                if (targetValueArray.join(",") !== currentValue){
                    break;
                }
                rng.row2++;
            }
            
            
            // No Merge
            if (rng.isSingleCell) {
                rng = null;
            }
            //
            // done
            // if (rng){
            //     if (rng._row !== rng.row2){
            //         panel.hostElement.className +="mergeCell"
            //     }
            // }
            
            return rng;
        }
    }
}

export default GridUserMergeByKey