import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { createSelector } from "reselect";
import { registerUserInit } from 'store/auth/register/reducer';

import cookie from "react-cookies";

import { JSEncrypt } from "jsencrypt";

import { Button, Divider, Link } from "@mui/material";
import "assets/styles/pages/LoginPage.scss";
import "assets/styles_mobile/components/layout/pages/ResponsiveLogin.scss";

import { ComNoticfication } from "common/components/ComNoticfication";
import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";

import ComCheckBox from "common/components/ComCheckBox";
import ComTextBox from "common/components/ComTextBox";
import ComPassword from "common/components/ComPassword";

import HeaderSignUp from "./HeaderSignUp";
import FooterLogin from "Layout/FooterLogin";

import ShowModalID from "./ShowModalID";
import ShowModalPW from "./ShowModalPW";
import ShowModalSusses from "./ShowModalSusses";

import ShowModalAccUnlockPopup from "./ShowModalAccUnlockPopup";
import ShowModalAccUnlockWaiting from "./ShowModalWaitingForApproval";
import ShowModalAccUnlockAproval from "./ShowModalAccUnlockAproval";
import ShowModalAccUnlockReject from "./ShowModalAccUnlockReject";
import ShowModalRejected from "./ShowModalRejected";
import ShowModalPasswordChange from "./ShowModalPasswordChange";

function LoginPage() {

  const navigate = useNavigate();

  const logInIdRef = useRef(null);
  const loginPwdRef = useRef(null);
  const randomKey = useRef(null);

  const [modalID, setModalID] = useState(false);
  const [modalPW, setModalPW] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);

  const [modalUnlockPop, setModalUnlockPop] = useState(false);
  const [modalUnlockRequest, setModalUnlockRequest] = useState(false);
  const [modalUnlockWaiting, setModalUnlockWaiting] = useState(false);
  const [modalUnlockApproval, setModalUnlockApproval] = useState(false);
  const [modalRejected, setModalRejected] = useState(false);
  const [modalPasswordChange, setModalPasswordChange] = useState(false);

  const [isRemember, setIsRemember] = useState(true); //아이디 저장 체크박스 체크 유무
  const [isCheckCLSD, setIsCheckCLSD] = useState(false);
  const [userSaveInfo, setUserSaveInfo] = useState(null);

  const [receiveEmail, setReceiveEmail] = useState(null);
  const [actionType, setActionType] = useState(null);

  const dispatch = useDispatch();
  const AccountProperties = createSelector(
    (state) => state.account,
    (account) => ({
      proceeding: account.proceeding
      , success: account.success
    })
  );
  let { proceeding, success } = useSelector(AccountProperties, shallowEqual);

  const onLoginProcess = async () => {
    try {
      let cryptInfo = await ApiManager.post("/init/PageLoad", {});

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(cryptInfo.resultMsg);

      let encryptedLoginData = encrypt.encrypt(loginPwdRef.current.getValue());

      let resultData = await ApiManager.post("/init/Login", {
        tkdydwkdkagh: encryptedLoginData,
        tkdydwkdkdlel: logInIdRef.current.getValue(),
      });
      /*
      if (resultData.result === "H") {
          ComNoticfication.ShowAlertUI("Info", 'ID '+logInIdRef.current.value+' is already logging in. You must use your own ID.');
      }
      //[RITM1256401]
      else if (resultData.result === "E") {
          if (resultData.loginFailCnt !== "X"){
              if (ComUtils.tryToNumber(resultData.loginFailCnt) < 5 ) {
                  ComNoticfication.ShowAlertUI("Info", 'Login failed '+ resultData.loginFailCnt +' times. Your ID will be locked if you fail 5 times.<br/><br/>If you have forgot your password,<br/> click on <span style="font-weight: bold;color: #a92222;">"Change password?"</span>  to initialize new password.');
              }
              else if(ComUtils.tryToNumber(resultData.loginFailCnt) >= 5 ) {
                  ComNoticfication.ShowAlertUI("Info", "Login failed 5 times, so you can't access it.<br/>Please contact your account manager.");
              }
          }
          else {
              //ComNoticfication.ShowAlertUI("Info", 'If you are logging in <span style="font-weight: bold;color: #a92222;">first time into LG Customer Portal</span> or <br/>have forgot your password,<br/><br/> click on <span style="font-weight: bold;color: #a92222;">"Change password?"</span>  to initialize new password.');
              ComNoticfication.ShowAlertUI("Info", 'If you are logging in <span style="font-weight: bold;color: #a92222;">first time into LG Customer Portal</span>,<br/>click on <span style="font-weight: bold;color: #a92222;">"SIGN UP NOW"</span>  to register a new ID.');
          }
      }
      else 
      */
      if (resultData?.result === "Success") {

        if (resultData.useFlag === "N") {
          // 잠금 유저 상태 useFlag 
          // setUserSaveInfo(resultData);
          // randomKey.current = ComUtils.setRefRandomKey();
          // setModalUnlockPop(true);
        } else if (resultData.acctUnlockRqst === "R") {
          randomKey.current = ComUtils.setRefRandomKey();
          // 잠금 해제 승인 요청 상태 acctUnlockRqst (현재 R 상태)
          setModalUnlockWaiting(true);
        }
        else if (resultData.acctUnlockRqst === "A") {
          // 잠금 해제 승인 상태 acctUnlockRqst (현재 A 상태)
          setUserSaveInfo(resultData);
          randomKey.current = ComUtils.setRefRandomKey();
          setModalUnlockApproval(true);
        } else if (resultData.acctUnlockRqst === "U") {
          // 잠금 해제 거부 상태 acctUnlockRqst (현재 U 상태)
          // randomKey.current = ComUtils.setRefRandomKey();
          // setModalRejected(true);
        } else if ((resultData.pwConverted === "N") || (resultData.pwdResetYn === "N")) { // 패스워드 변경 필요
            setUserSaveInfo(resultData);
            randomKey.current = ComUtils.setRefRandomKey();
            setModalPasswordChange(true);
            
            sessionStorage.setItem("csrfToken", resultData.csrfToken);
        } else {

          if (resultData?.infStatus === "CLSD") { //계정 담힘
            setIsCheckCLSD(true);
            ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "This account has been closed permanently.<br/>Please register again or contact spiff.claim@lge.com if you need additional assistance.");
            // } else if(resultData?.infStatus === "DEAC"){
            //   ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "RSA can request reactivation by filling out online form instead of creating email to spiff.claim@lge.com");
            // } else if(resultData?.infStatus === "REAC"){
            //   ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "");
            // }else if(resultData?.infStatus === "LOCK"){
            //   ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "");
            // }else if(resultData?.infStatus === "PEND"){
            //   ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, "");
          } else {
            sessionStorage.setItem("csrfToken", resultData.csrfToken);
            sessionStorage.setItem("userId", resultData.userId);

            if (resultData?.authorityId) { sessionStorage.setItem("authorityId", resultData.authorityId); }
            else { sessionStorage.setItem("authorityId", ""); }

            if (resultData?.empNo) { sessionStorage.setItem("empNo", resultData.empNo); }
            else { sessionStorage.setItem("empNo", ""); }

            if (resultData?.language) { sessionStorage.setItem("language", resultData.language); }
            else { sessionStorage.setItem("language", ""); }

            if (resultData?.userName) { sessionStorage.setItem("userName", resultData.userName); }
            else { sessionStorage.setItem("userName", ""); }

            if (resultData?.billTo) { sessionStorage.setItem("billTo", resultData.billTo); }
            else { sessionStorage.setItem("billTo", ""); }

            if (resultData?.billTo2) { sessionStorage.setItem("billTo2", resultData.billTo2); }
            else { sessionStorage.setItem("billTo2", ""); }

            if (resultData?.billTo3) { sessionStorage.setItem("billTo3", resultData.billTo3); }
            else { sessionStorage.setItem("billTo3", ""); }

            if (resultData?.bizUnit) { sessionStorage.setItem("bizUnit", resultData.bizUnit); }
            else { sessionStorage.setItem("bizUnit", ""); }

            if (resultData?.citiFlag) { sessionStorage.setItem("citiFlag", resultData.citiFlag); }
            else { sessionStorage.setItem("citiFlag", ""); }

            if (resultData?.comCode) { sessionStorage.setItem("comCode", resultData.comCode); }
            else { sessionStorage.setItem("comCode", ""); }

            if (resultData?.emailId) { sessionStorage.setItem("emailId", resultData.emailId); }
            else { sessionStorage.setItem("emailId", ""); }

            if (resultData?.storeName) { sessionStorage.setItem("storeName", resultData.storeName); }
            else { sessionStorage.setItem("storeName", ""); }

            if (resultData?.targetGroup) { sessionStorage.setItem("targetGroup", resultData.targetGroup); }
            else { sessionStorage.setItem("targetGroup", ""); }

            if (resultData?.today) { sessionStorage.setItem("today", resultData.today); }
            else { sessionStorage.setItem("today", ""); }

            if (resultData?.userGroup) { sessionStorage.setItem("userGroup", resultData.userGroup); }
            else { sessionStorage.setItem("userGroup", ""); }

            if (resultData?.userType) { sessionStorage.setItem("userType", resultData.userType); }
            else { sessionStorage.setItem("userType", ""); }

            if (resultData?.userTypeDtl) { sessionStorage.setItem("userTypeDtl", resultData.userTypeDtl); }
            else { sessionStorage.setItem("userTypeDtl", ""); }

            if (resultData?.pwdExpired) { sessionStorage.setItem("pwdExpired", resultData.pwdExpired); }
            else { sessionStorage.setItem("pwdExpired", ""); }

            if (resultData?.pwConverted) { sessionStorage.setItem("pwConverted", resultData.pwConverted); }
            else { sessionStorage.setItem("pwConverted", ""); }

            if (resultData?.userMainClaimLevel) { sessionStorage.setItem("userMainClaimLevel", resultData.userMainClaimLevel); }
            else { sessionStorage.setItem("userMainClaimLevel", ""); }

            if (resultData?.pageRowSize) { sessionStorage.setItem("pageRowSize", resultData.pageRowSize); }
            else { sessionStorage.setItem("pageRowSize", "100"); }

            if (resultData?.profile) { sessionStorage.setItem("profile", resultData.profile); }

            // if ((resultData?.pwdChangedDays??0)>0){
            //     sessionStorage.setItem('pwdChangedDays', resultData.pwdChangedDays);
            //     if (resultData.pwdChangedDays >= 90 && (resultData?.userTypeCode??"") ==="C"){
            //         sessionStorage.setItem('currentOpenMenu', '{"highSortOrder":40,"linkLangUseFlag":"","sslFlag":"","menuLvl":2,"highMenuCode":"BAS","menuName":"My Profile","menuDesc":"","linkCountryUseFlag":"","useFlag":"Y","menuUrl":"Profile/MyProfile","adminGrCode":"","menuCode":"BAS002","sortOrder":1,"menuType":"M","linkType":"","parentNamePath":"Basic > My Profile","parentPath":"BAS","currentLevel":2,"newDisplaySeq":1,"newMaxSeq":1,"origSysDocumentRef":"","copyFlag":false,"editFlag":false,"timeKey":"BAS002","tabUniqueKey":"BAS002","Active":true}');
            //     }
            // }

            if (isRemember) {
              let expires = new Date();
              expires.setDate(expires.getDate() + 90);
              cookie.save("userId", logInIdRef.current.getValue(), {
                path: "/",
                expires,
              });
            } else {
              cookie.remove("userId", { path: "/" });
            }
            document.location.href = "/";
          }
        }

      } else {

        if (resultData.result) {
          ComNoticfication.ShowAlertUICallback(
            ComUtils._altTitleError,
            resultData.result,
            () => {
              if (resultData.userId !== "") {
                loginPwdRef.current.setFocus();
              } else {
                logInIdRef.current.setFocus();
              }
            });
        }
        //if ((resultData?.pwdChangedDays??0) > 90){
        //    ComNoticfication.ShowAlertUICallback("Info", 'Previous password cannot be used. Password should be changed every 90 Day. Please change your password.');
        //}
        //else{<span style="font-weight: bold;"> "Change password?"</span>
        //failCount.current = resultData?.loginFailCnt ?? 1
        // ComNoticfication.ShowAlertUI("Info", 'If you are logging in <span style="font-weight: bold;color: #a92222;">first time into LG Customer Portal</span> or <br/>have forgot your password,.<br/><br/> click on <span style="font-weight: bold;color: #a92222;">"Change password?"</span>  to initialize new password.');
        // <br/> LGE Employees should log in via EP Portal.}
        /*
        if ((resultData?.statCode??"N")==="X"){
          
        }
        else if(failCount.current>=5){
          ComNoticfication.ShowAlertUICallback("Info", "Login failed 5 times, so you can't access it. Please contact your account manager.");
        }
        */
      }
    } catch (ex) {
      console.log(ex.message);
    }
  };
  const _isMounted = useRef(true);

  useEffect(() => {
    let abortController = new AbortController();

    const onPageLoaded = async () => { };
    onPageLoaded();

    if (cookie.load("userId")) {
      logInIdRef.current.setValue(cookie.load("userId"));
    } else {
      logInIdRef.current.setValue("");
    }

    if (proceeding || success) { //리덕스 개인 정보 초기화
      dispatch(registerUserInit());
    }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);

  const saveId = (elId, bChecked) => {
    console.log("event.target.checked saveId");
    setIsRemember(bChecked);
  };

  const onKeyPress = (e) => {
    // Enter를 눌렀을 때 이벤트
    if (e.key == "Enter") {
      onLoginProcess();
    }
  };

  /* popup control  */
  const handleForgotUserID = () => {
    setActionType("id");
    randomKey.current = ComUtils.setRefRandomKey();
    setModalID(true);
  };
  const handleForgotPassWord = () => {
    setActionType("pw");
    randomKey.current = ComUtils.setRefRandomKey();
    setModalPW(true);
  };

  const handleForgotSubmit = (receiveEmail) => {
    setReceiveEmail(receiveEmail);
    randomKey.current = ComUtils.setRefRandomKey();
    setModalSubmit(true);
  };

  const closePopUpShowModalID = () => {
    setModalID(false);
  };
  const closePopUpShowModalPW = () => {
    setModalPW(false);
  };
  const closePopUpShowModaSubmit = () => {
    setModalSubmit(false);
  };

  const closePopUpShowModalAccUnlockPopup = (resultValue) => {
    setModalUnlockPop(false);
    if (resultValue) {
      randomKey.current = ComUtils.setRefRandomKey();
      setModalUnlockRequest(true);
    } else {
      logOut();
    }
  };
  const closePopUpShowModalAccUnlockRequest = () => {
    setModalUnlockRequest(false);
    logOut();
  };
  const closePopUpShowModalAccUnlockWaiting = () => {
    setModalUnlockWaiting(false);
    logOut();
  };
  const closePopUpShowModalAccUnlockApproval = (resultValue) => {
    setModalUnlockApproval(false);
    if (resultValue) {
      sessionStorage.setItem("userId", userSaveInfo.userId);

      if (userSaveInfo?.authorityId) { sessionStorage.setItem("authorityId", userSaveInfo.authorityId); }
      else { sessionStorage.setItem("authorityId", ""); }

      if (userSaveInfo?.empNo) { sessionStorage.setItem("empNo", userSaveInfo.empNo); }
      else { sessionStorage.setItem("empNo", ""); }

      if (userSaveInfo?.language) { sessionStorage.setItem("language", userSaveInfo.language); }
      else { sessionStorage.setItem("language", ""); }

      if (userSaveInfo?.userName) { sessionStorage.setItem("userName", userSaveInfo.userName); }
      else { sessionStorage.setItem("userName", ""); }

      if (userSaveInfo?.billTo) { sessionStorage.setItem("billTo", userSaveInfo.billTo); }
      else { sessionStorage.setItem("billTo", ""); }

      if (userSaveInfo?.billTo2) { sessionStorage.setItem("billTo2", userSaveInfo.billTo2); }
      else { sessionStorage.setItem("billTo2", ""); }

      if (userSaveInfo?.billTo3) { sessionStorage.setItem("billTo3", userSaveInfo.billTo3); }
      else { sessionStorage.setItem("billTo3", ""); }

      if (userSaveInfo?.bizUnit) { sessionStorage.setItem("bizUnit", userSaveInfo.bizUnit); }
      else { sessionStorage.setItem("bizUnit", ""); }

      if (userSaveInfo?.citiFlag) { sessionStorage.setItem("citiFlag", userSaveInfo.citiFlag); }
      else { sessionStorage.setItem("citiFlag", ""); }

      if (userSaveInfo?.comCode) { sessionStorage.setItem("comCode", userSaveInfo.comCode); }
      else { sessionStorage.setItem("comCode", ""); }

      if (userSaveInfo?.emailId) { sessionStorage.setItem("emailId", userSaveInfo.emailId); }
      else { sessionStorage.setItem("emailId", ""); }

      if (userSaveInfo?.storeName) { sessionStorage.setItem("storeName", userSaveInfo.storeName); }
      else { sessionStorage.setItem("storeName", ""); }

      if (userSaveInfo?.targetGroup) { sessionStorage.setItem("targetGroup", userSaveInfo.targetGroup); }
      else { sessionStorage.setItem("targetGroup", ""); }

      if (userSaveInfo?.today) { sessionStorage.setItem("today", userSaveInfo.today); }
      else { sessionStorage.setItem("today", ""); }

      if (userSaveInfo?.userGroup) { sessionStorage.setItem("userGroup", userSaveInfo.userGroup); }
      else { sessionStorage.setItem("userGroup", ""); }

      if (userSaveInfo?.userType) { sessionStorage.setItem("userType", userSaveInfo.userType); }
      else { sessionStorage.setItem("userType", ""); }

      if (userSaveInfo?.userTypeDtl) { sessionStorage.setItem("userTypeDtl", userSaveInfo.userTypeDtl); }
      else { sessionStorage.setItem("userTypeDtl", ""); }

      if (userSaveInfo?.userMainClaimLevel) { sessionStorage.setItem("userMainClaimLevel", userSaveInfo.userMainClaimLevel); }
      else { sessionStorage.setItem("userMainClaimLevel", ""); }

      if (userSaveInfo?.pageRowSize) { sessionStorage.setItem("pageRowSize", userSaveInfo.pageRowSize); }
      else { userSaveInfo.setItem("pageRowSize", "100"); }

      if (userSaveInfo?.csrfToken) { sessionStorage.setItem("csrfToken", userSaveInfo.csrfToken); }
      else { userSaveInfo.setItem("csrfToken", ""); }

      document.location.href = "/";
    }
  };
  const closePopUpShowModalRejected = () => {
    setModalRejected(false);
    logOut();
  };

  const closePopUpShowModalPasswordChange = (saved) => {
    setModalPasswordChange(false);

    if (saved) {
      loginPwdRef.current.setValue("");
      setTimeout(() => {
        loginPwdRef.current.setFocus();
      }, 200);
    } else {
      logOut();
    }
  };

  const logOut = async () => {
    await ApiManager.post("/init/LogOut", {});
    sessionStorage.clear();

    document.location.href = "/";
  };

  return (
    <div className="sign-in-page" style={{ display: cookie.load("SSO_LOGIN") === "TRUE" ? 'none' : '' }}>
      <div>
        <HeaderSignUp />
        <Divider />
      </div>
      <div className="body-main-login">
        <div className="sign">
          <div className="sign-lg-content">
            <p>Sign Into LG Sales Portal</p>
            <div className="form-input-sign-in">
              <div className="card-input">
                <ComTextBox
                  placeholder="User ID"
                  ref={logInIdRef}
                  keyPressFuction={onKeyPress}
                  defaultMaxLength={50}
                />
                <ComPassword
                  placeholder="Password"
                  keyPressFuction={onKeyPress}
                  ref={loginPwdRef}
                  defaultMaxLength={100}
                />
              </div>
              <Button variant="contained" onClick={onLoginProcess}>
                {"SIGN IN"}
              </Button>
            </div>
            <div className="forgot-link">
              <div>
                <ComCheckBox callRelationFuntion={saveId} defaultValue={true} />
                Remember Me
              </div>
              <div className="link-content">
                <Link onClick={handleForgotUserID}>Forgot User ID?</Link>
                <Link onClick={handleForgotPassWord}>Forgot Password?</Link>
              </div>
            </div>
          </div>
        </div>
        <Divider className="divider-lg" />
        <div className="sign">
          <div className="sign-up-info">
            <p>Don't have an account?</p>
            <div className="form-input-sign-up">
              <div className="sign-lable">
                <p>
                  The LG Sales Portal assists our global business partners in
                  creating new value.
                </p>
                <p>
                  We provide an effective and systematic view of all aspects of
                  information pertaining to your business operations.
                </p>
              </div>
              <Button variant="outlined" onClick={() => navigate("/sign-up", {})}>
                {"SIGN UP"}
              </Button>
            </div>
            <div className="forgot-link"></div>
          </div>
        </div>
      </div>
      <FooterLogin />

      {modalID ? (
        <ShowModalID
          callbackFuntion={closePopUpShowModalID}
          // isOpen={modalID}
          randomKey={randomKey.current}
          handleSubmit={handleForgotSubmit}
          isCheckCLSD={isCheckCLSD}
        />)
        : (null)}
      {modalPW ? (
        <ShowModalPW
          callbackFuntion={closePopUpShowModalPW}
          // isOpen={modalPW}
          randomKey={randomKey.current}
          handleForgotID={handleForgotUserID}
          handleSubmit={handleForgotSubmit}
          isCheckCLSD={isCheckCLSD}
        />)
        : (null)}
      {/* {modalSubmit ? */}
      <ShowModalSusses
        callbackFuntion={closePopUpShowModaSubmit}
        isOpen={modalSubmit}
        randomKey={randomKey.current}
        actionType={actionType}
        receiveEmail={receiveEmail}
      />
      {/* : null} */}
      {/* {modalUnlockPop ? */}
      <ShowModalAccUnlockPopup
        callbackFuntion={closePopUpShowModalAccUnlockPopup}
        userSaveInfo={userSaveInfo}
        isOpen={modalUnlockPop}
        randomKey={randomKey}
      />
      {/* : null} */}
      {/* {modalUnlockRequest ? */}
      <ShowModalAccUnlockAproval
        callbackFuntion={closePopUpShowModalAccUnlockRequest}
        isOpen={modalUnlockRequest}
        randomKey={randomKey.current}
      />
      {/* : null} */}
      {/* {modalUnlockWaiting ? */}
      <ShowModalAccUnlockWaiting
        callbackFuntion={closePopUpShowModalAccUnlockWaiting}
        isOpen={modalUnlockWaiting}
        randomKey={randomKey.current}
      />
      {/* : null} */}
      {/* {modalUnlockApproval ? */}
      <ShowModalAccUnlockReject
        callbackFuntion={closePopUpShowModalAccUnlockApproval}
        isOpen={modalUnlockApproval}
        randomKey={randomKey.current}
      />
      {/* : null} */}
      {/* {modalRejected ? */}
      <ShowModalRejected
        callbackFuntion={closePopUpShowModalRejected}
        isOpen={modalRejected}
        randomKey={randomKey.current}
      />
      {/* : null} */}

      {/* {modalPasswordChange ? */}
      <ShowModalPasswordChange
        callbackFuntion={closePopUpShowModalPasswordChange}
        isOpen={modalPasswordChange}
        userSaveInfo={userSaveInfo}
        randomKey={randomKey.current}
      />
      {/* : null} */}
    </div>
  );
}

export default LoginPage;
