import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";

import * as wjInput from '@grapecity/wijmo.react.input';

import { ApiManager } from 'common/lib/ApiUtil';
import { ComNoticfication } from 'common/components/ComNoticfication';

function AddressInfoPop({ open, closeFn, headerId, lineId, pageInfo }) {

  //url
  const retrieveAddressInfo = "/com/splComPop/retrieveAddressInfo";

  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');

  const popComponent = useRef();

  
  //Memory 
  const _isMounted = useRef(true);


  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();

    const getPageInfo = async () => {

      let sendParam = {
        comCode: 'LGEUS' //TODO 하드코딩
        , headerId: headerId
        , lineId: lineId
      }
      const [resultData] = await Promise.all([
        ApiManager.post(retrieveAddressInfo, sendParam)
      ]);      

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("error", "코드 조회 중 에러가 발생했습니다.");
      }
      else {
        setDeliveryAddress(resultData?.address1);
        setCity(resultData?.city);
        setState(resultData?.state);
        setZip(resultData?.zip);
        setContactName(resultData?.receiverName);
        setContactPhone(resultData?.receiverPhoneNo);
      }        
    }

    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`Address Information`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                Delivery Address
              </label>
            </div>
            <div className="bx-inp combo">
              {deliveryAddress}
            </div>
          </div>

          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                City
              </label>
            </div>
            <div className="bx-inp combo">
              {city}
            </div>
          </div>

          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                State
              </label>
            </div>
            <div className="bx-inp combo">
              {state}
            </div>
          </div>

          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                Zip
              </label>
            </div>
            <div className="bx-inp combo">
              {zip}
            </div>
          </div>

          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                Contact Name
              </label>
            </div>
            <div className="bx-inp combo">
              {contactName}
            </div>
          </div>

          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                Contact Phone
              </label>
            </div>
            <div className="bx-inp combo">
              {contactPhone}
            </div>
          </div>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default AddressInfoPop;