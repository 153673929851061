import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openMenu, viewHome } from "reducer/OpenMenus";

import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";
import { Button, Divider, Tooltip } from "@mui/material";
import { MessageContext } from "common/lib/MessageProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BookIcon from "assets/icons/book.svg";
import HomeMenuDefault from "assets/icons/home-menu.svg";
import HomeMenuActive from "assets/icons/home-menu-active.svg";

import _ from "lodash";
import { ComNoticfication } from "common/components/ComNoticfication";
import FileSaver from "file-saver";
import { FileUtils } from "common/lib/FileUtils";

const menuData = [
  {
    menuId: "claim",
    menuName: "Claim",
    items: [
      {
        menuId: "rsaSpiff",
        menuName: "RSA SPIFF",
        items: [
          {
            menuId: "rsa-request",
            menuName: "Request",
            menuUrl: "Pub/Claim/RSASpiff/Request/RSASpiffRequest",
          },
          {
            menuId: "rsa-extension-request",
            menuName: "Extension Request",
            menuUrl: "Pub/Claim/RSASpiff/Request/RSASpiffExtensionRequest",
          },
          {
            menuId: "rsa-tracking",
            menuName: "Tracking",
            menuUrl: "Pub/Claim/RSASpiff/Tracking/RSASpiffTracking",
          },
          {
            menuId: "rsa-tracking-detail",
            menuName: "Tracking Detail",
            menuUrl: "Pub/Claim/RSASpiff/Tracking/RSASpiffTrackingDetail",
          },
          {
            menuId: "rsa-tracking-modify",
            menuName: "Tracking Modify",
            menuUrl: "Pub/Claim/RSASpiff/Tracking/RSASpiffTrackingModify",
          },
          {
            menuId: "rsa-salesperson-management",
            menuName: "Salesperson Management",
            menuUrl: "Pub/Claim/RSASpiff/Salesperson/SalespersonManagement",
          },
          {
            menuId: "rsa-salesperson-information",
            menuName: "Salesperson Information",
            menuUrl: "Pub/Claim/RSASpiff/Salesperson/SalespersonInformation",
          },
          {
            menuId: "rsa-amount-management",
            menuName: "SPIFF Owed Amount Management",
            menuUrl:
              "Pub/Claim/RSASpiff/AmountManagement/SpiffOwedAmountManagement",
          },
          {
            menuId: "rsa-confirm",
            menuName: "RSA Confirm",
            menuUrl: "Pub/Claim/RSASpiff/RSAConfirm",
          },
          {
            menuId: "rsa-confirm-detail",
            menuName: "RSA Confirm Detail",
            menuUrl: "Pub/Claim/RSASpiff/RSAConfirmDetail",
          },
        ],
      },
      {
        menuId: "ir-spiff",
        menuName: "IR/SPIFF",
        items: [
          {
            menuId: "ir-confirm-detail",
            menuName: "Confirm Detail",
            menuUrl: "Pub/Claim/Request/IRSpiffConfirmDetail",
          },
          {
            menuId: "ir-request",
            menuName: "Request",
            menuUrl: "Pub/Claim/IRSpiff/Request/IRSpiffRequest",
          },
          {
            menuId: "ir-request-detail",
            menuName: "Request Detail",
            menuUrl: "Pub/Claim/IRSpiff/Request/IRSpiffRequestDetail",
          },
          {
            menuId: "ir-request-detail-create",
            menuName: "Request Detail Create",
            menuUrl: "Pub/Claim/IRSpiff/Request/IRSpiffRequestDetailCreate",
          },
          {
            menuId: "ir-request-detail-modify",
            menuName: "Request Detail Modify",
            menuUrl: "Pub/Claim/IRSpiff/Request/IRSpiffRequestDetailModify",
          },
          {
            menuId: "ir-ex-request",
            menuName: "Extension Request",
            menuUrl: "Pub/Claim/IRSpiff/Request/IRSpiffExtensionRequest",
          },
          {
            menuId: "ir-review-confirm",
            menuName: "Review & Confirm",
            menuUrl: "Pub/Claim/IRSpiff/Confirm/IRSpiffReviewConfirm",
          },
          {
            menuId: "ir-tracking",
            menuName: "Tracking",
            menuUrl: "Pub/Claim/IRSpiff/Tracking/IRSpiffTracking",
          },
          {
            menuId: "ir-scm-tracking",
            menuName: "IR SCM Tracking",
            menuUrl: "Pub/Claim/IRSpiff/Tracking/IRSpiffSCMTracking",
          },
          {
            menuId: "ir-scm-detail-tracking",
            menuName: "IR SCM Tracking Detail",
            menuUrl: "Pub/Claim/IRSpiff/Tracking/IRSpiffSCMTrackingDetail",
          },
          {
            menuId: "error-tracking",
            menuName: "Error Tracking",
            menuUrl: "Pub/Claim/IRSpiff/Tracking/IRSpiffErrorTracking",
          },
        ],
      },

      {
        menuId: "mail-in-rebate",
        menuName: "Mail In Rebate",
        menuUrl: "Pub/Claim/MailInRebate/Confirm/MailInRebateConfirm",
        items: [
          {
            menuId: "mail-in-rebate-confirm",
            menuName: "Confirm",
            menuUrl: "Pub/Claim/MailInRebate/Confirm/MailInRebateConfirm",
          },
          {
            menuId: "mail-in-rebate-detail",
            menuName: "Detail",
            menuUrl: "Pub/Claim/MailInRebate/Confirm/MailInRebateConfirmDetail",
          },
          {
            menuId: "mail-in-rebate-tracking",
            menuName: "Tracking",
            menuUrl: "Pub/Claim/MailInRebate/Tracking/MailInRebateTracking",
          },
          {
            menuId: "mail-in-rebate-monitoring",
            menuName: "Monitoring",
            menuUrl: "Pub/Claim/MailInRebate/Monitor/MailInRebateMonitoring",
          },
        ],
      },
      {
        menuId: "other-claim",
        menuName: "Other Claim",
        items: [
          {
            menuId: "ClaimPromoFlagAnalystMgmt",
            menuName: "Claim Promo Flag/Analyst Mgmt",
            menuUrl: "Pub/Claim/OtherClaim/Analyst/ClaimPromoFlagAnalystMgmt",
          },
          {
            menuId: "ClaimPromoAnalystRegistration",
            menuName: "Claim Promo Flag/Analyst Mgmt  – Analyst Registration",
            menuUrl:
              "Pub/Claim/OtherClaim/Analyst/ClaimPromoAnalystRegistration",
          },
          {
            menuId: "ClaimPromoAnalystModify",
            menuName: "Claim Promo Flag/Analyst Mgmt – Analyst Modify",
            menuUrl: "Pub/Claim/OtherClaim/Analyst/ClaimPromoAnalystModify",
          },
          {
            menuId: "ClaimApprovalDelegate",
            menuName: "Other Claim Approval Delegate",
            menuUrl: "Pub/Claim/OtherClaim/Delegate/OtherClaimApprovalDelegate",
          },
          {
            menuId: "VendorRegistration",
            menuName: "Vendor Registration",
            menuUrl: "Pub/Claim/OtherClaim/Vendor/VendorRegistration",
          },
          {
            menuId: "VendorModify",
            menuName: "Vendor Modify",
            menuUrl: "Pub/Claim/OtherClaim/Vendor/VendorModify",
          },
          {
            menuId: "VendorManagement",
            menuName: "Vendor Management",
            menuUrl: "Pub/Claim/OtherClaim/Vendor/VendorManagement",
          },
          {
            menuId: "LGProgramManagement",
            menuName: "LG Program Management",
            menuUrl: "Pub/Claim/OtherClaim/LGProgram/LGProgramManagement",
          },
          {
            menuId: "OtherClaimEntry",
            menuName: "Other Claim Entry",
            menuUrl: "Pub/Claim/OtherClaim/OtherClaimEntry",
          },
          {
            menuId: "OtherClaimConfirmList",
            menuName: "Other Claim Confirm List",
            menuUrl: "Pub/Claim/OtherClaim/OtherClaimConfirmList",
          },
          {
            menuId: "OtherClaimReport",
            menuName: "Other Claim Report",
            menuUrl: "Pub/Claim/OtherClaim/OtherClaimReport",
          },
        ],
      },
    ],
  },
  {
    menuId: "defect-return",
    menuName: "Defect Return",
    items: [
      {
        menuId: "ra-approval-list",
        menuName: "RA Approval List",
        menuUrl: "Pub/DefectReturn/RAApprovalList/RAApprovalList",
      },
      {
        menuId: "ra-approval-detail",
        menuName: "RA Approval Detail",
        menuUrl: "Pub/DefectReturn/RAApprovalList/RAApprovalDetail",
      },
      {
        menuId: "ra-issue",
        menuName: "RA Issue",
        menuUrl: "Pub/DefectReturn/RAISSUE/RAIssue",
      },
      {
        menuId: "ra-list",
        menuName: "RA List",
        menuUrl: "Pub/DefectReturn/RAList/RAList",
      },
      {
        menuId: "ra-status-tracking",
        menuName: "RA Status Tracking",
        menuUrl: "Pub/DefectReturn/RAStatusTracking/RAStatusTracking",
      },
      {
        menuId: "no-return-customers",
        menuName: "No Return Customers",
        menuUrl: "Pub/DefectReturn/NoReturnCustomers/NoReturnCustomers",
      },
      {
        menuId: "no-return-customers-add",
        menuName: "No Return Customers Add",
        menuUrl: "Pub/DefectReturn/NoReturnCustomers/NoReturnCustomersAdd",
      },
    ],
  },
  {
    menuId: "cust-order-mgmt",
    menuName: "Cust. Order Mgmt",
    items: [
      {
        menuId: "orderStatus",
        menuName: "Order Status",
        menuUrl: "Pub/CustOrderMgmt/OrderStatus",
      },
      {
        menuId: "purchaseSummary",
        menuName: "Purchase Summary",
        menuUrl: "Pub/CustOrderMgmt/PurchaseSummary",
      },
    ],
  },
  {
    menuId: "sample",
    menuName: "Sample",
    items: [
      {
        menuId: "sampleConfirm",
        menuName: "Sample Confirm",
        menuUrl: "Pub/Sample/SampleConfirm",
      },
      {
        menuId: "sampleRequest",
        menuName: "Sample Request",
        menuUrl: "Pub/Sample/SampleRequest",
      },
      {
        menuId: "sampleOrderList",
        menuName: "Sample Order List",
        menuUrl: "Pub/Sample/SampleOrderList",
      },
      {
        menuId: "sampleOrderTracking",
        menuName: "Sample Order Tracking",
        menuUrl: "Pub/Sample/SampleOrderTracking",
      },
      {
        menuId: "followUp",
        menuName: "Follow Up",
        menuUrl: "Pub/Sample/FollowUp",
      },
      {
        menuId: "report",
        menuName: "Daily Report",
        menuUrl: "Pub/Sample/RequestorReport",
      },
    ],
  },
  {
    menuId: "arfi",
    menuCode: "ar-fi",
    menuName: "A/R & FI",
    items: [
      {
        menuId: "statement",
        menuName: "A/R Statement",
        menuUrl: "Pub/Sample/ARStatement",
      },
    ],
  },
  {
    menuId: "admin",
    menuName: "Admin",
    items: [
      {
        menuId: "role",
        menuName: "Role",
        menuUrl: "Pub/Admin/Role",
      },
      {
        menuId: "menus",
        menuName: "Menu",
        menuUrl: "Pub/Admin/MenuMgmt",
      },
      {
        menuId: "userMaintenance",
        menuName: "User Maintenance",
        menuUrl: "Pub/Admin/UserMaintenance",
      },
      {
        menuId: "userDetail",
        menuName: "User Detail",
        menuUrl: "Pub/Admin/UserDetail",
      },
      {
        menuId: "code",
        menuName: "Code",
        menuUrl: "Pub/Admin/CodeMngmt",
      },
      {
        menuId: "authority",
        menuName: "Authority",
        menuUrl: "Pub/Admin/Authority",
      },
      {
        menuId: "adminNotice",
        menuName: "Notice",
        menuUrl: "Pub/Admin/Notice",
      },
      {
        menuId: "externalRegistrationWaitingList",
        menuName: "External Registration Waiting List",
        menuUrl: "Pub/Admin/ExternalRegistrationWaitingList",
      },
      {
        menuId: "youngSample",
        menuName: "Customer Portal Change",
        menuUrl: "Pub/Admin/YoungSample",
      },
      {
        menuId: "youngSampleFix",
        menuName: "Customer Portal Change (Fix)",
        menuUrl: "Pub/Admin/YoungSearchSample",
      },
      {
        menuId: "popupTestCheck",
        menuName: "PopupTestCheck",
        menuUrl: "Pub/Admin/PopupTestCheck", //popup test page (kyjhouse 2024.05.02)
      },
    ],
  },
  {
    menuId: "goodset-return",
    menuName: "GOODSET Return",
    items: [
      {
        menuId: "autoVMIClaimRequestApprovalTrackingAgedGR",
        menuName: "Auto VMI Claim Request / Approval /Tracking - Aged GR",
        menuUrl: "Pub/GoodsetReturn/AutoVMIClaimRequestApprovalTrackingAgedGR",
      },
      {
        menuId: "carrierClaimReversalRequest",
        menuName: "Carrier Claim Reversal Request",
        menuUrl: "Pub/GoodsetReturn/CarrierClaimReversalRequest",
      },
      {
        menuId: "reversalRequest",
        menuName: "Reversal Request",
        menuUrl: "Pub/GoodsetReturn/ReversalRequest",
      },
      {
        menuId: "carrierClaimReversalRequestModify",
        menuName: "Carrier Claim Reversal Request Modify",
        menuUrl: "Pub/GoodsetReturn/CarrierClaimReversalRequestModify",
      },
      {
        menuId: "GRRARequest",
        menuName: "GR R/A Request",
        menuUrl: "Pub/GoodsetReturn/GRRARequest",
      },
      {
        menuId: "GRRARequestModify",
        menuName: "GR R/A Request Modify",
        menuUrl: "Pub/GoodsetReturn/GRRARequestModify",
      },
    ],
  },
  {
    menuId: "master-of-promo",
    menuName: "Master of Promo",
    items: [
      {
        menuId: "dealerConfiguration",
        menuName: "Dealer Configuration",
        menuUrl:
          "Pub/MasterOfPromo/Promotion/DealerConfiguration/DealerConfiguration",
      },
      {
        menuId: "programUpload",
        menuName: "Program Upload",
        menuUrl: "Pub/MasterOfPromo/Promotion/ProgramUpload/ProgramUpload",
      },
      {
        menuId: "programManagement",
        menuName: "Program Management",
        menuUrl: "Pub/MasterOfPromo/ProgramManagement/ProgramManagement",
      },
      {
        menuId: "programManagementDetail",
        menuName: "Program Management Detail",
        menuUrl: "Pub/MasterOfPromo/ProgramManagement/ProgramManagementDetail",
      },
      {
        menuId: "programManagementException",
        menuName: "Program Management Exception",
        menuUrl:
          "Pub/MasterOfPromo/ProgramManagement/ProgramManagementException",
      },
      {
        menuId: "programExtensionManagement",
        menuName: "Program Extension Management",
        menuUrl:
          "Pub/MasterOfPromo/ProgramExtensionManagement/ProgramExtensionManagement",
      },
      {
        menuId: "programBalance",
        menuName: "Program Balance",
        menuUrl: "Pub/MasterOfPromo/ProgramBalance/ProgramBalance",
      },
    ],
  },
  {
    menuId: "agrement",
    menuName: "Agrement",
    items: [
      {
        menuId: "claim request",
        menuName: "Claim Request",
        menuUrl: "Pub/Agrement/ClaimRequest",
      },
      {
        menuId: "status tracking",
        menuName: "Status Tracking",
        menuUrl: "Pub/Agrement/StatusTracking",
      },
      {
        menuId: "status tracking detail",
        menuName: "Status Tracking Detail",
        menuUrl: "Pub/Agrement/StatusTrackingDetail",
      },
      {
        menuId: "claim request approval list",
        menuName: "Approval List",
        menuUrl: "Pub/Agrement/ApprovalList",
      },
      {
        menuId: "claim request approval detail",
        menuName: "Approval Detail",
        menuUrl: "Pub/Agrement/ApprovalDetail",
      },
    ],
  },
  {
    menuId: "popup-common",
    menuName: "Popups",
    menuUrl: "Pub/Popup/ListPopup",
  },
  {
    menuId: "claim-cover-sheet",
    menuName: "ClaimCoverSheet",
    menuUrl: "Pub/Claim/ClaimCoverSheet",
  },
  {
    menuId: "request-detail-wp",
    menuName: "RequestDetailWP",
    menuUrl: "Pub/Claim/RequestDetailWP",
  },
  {
    menuId: "vendor-modify-wp",
    menuName: "VendorModifyWP",
    menuUrl: "Pub/Claim/VendorModifyWP",
  },
  {
    menuId: "tracking-detail-wp",
    menuName: "TrackingDetailWP",
    menuUrl: "Pub/Claim/TrackingDetailWP",
  },
];
const MenuLayer = (props) => {
  const fileDownload = "/mop/programExtension/fileDownload";

  /** test code start  */
  const [dbMenuData, setDbMenuData] = useState(null);
  const [pubMenu, setPubMenu] = useState(true);
  const onSetPubMenu = () => {
    if (
      sessionStorage.getItem("profile") &&
      sessionStorage.getItem("profile") != "stg" &&
      sessionStorage.getItem("profile") != "prd"
    ) {
      setPubMenu(!pubMenu);
      if (pubMenu) {
        setMenuTree(menuData);
      } else {
        setMenuTree(dbMenuData);
      }
    }
  };
  /** test code end  */

  const { messageObject } = useContext(MessageContext);

  const currentMenu = useSelector((state) => state.openMenus);
  const dispatch = useDispatch();

  const [menuTree, setMenuTree] = useState();
  const [activeHome, setActiveHome] = useState(true);
  const [activeMenu, setActiveMenu] = useState("");

  const menuClick = (menuItem) => {
    const menuId = menuItem?.menuId;
    if (
      menuId === "M9106" || // RSA SPIFF manual
      menuId === "M9107" || // IR/Dealer SPIFF manual
      menuId === "M9108" || // Other Claims manual
      menuId === "M8022" || // Sample manual
      menuId === "M9109" // Defective Return manual
    ) {
      onManualDownload(menuItem);
      return;
    }

    if (menuItem?.menuUrl) {
      if (menuItem.menuUrl.indexOf("http") > -1) {
        window.open(menuItem.menuUrl);
      } else {
        document.getElementById("areaInfoObject").style.display = "none";
        let t = {};
        Object.assign(t, menuItem);
        t["origSysDocumentRef"] = "";
        t["copyFlag"] = false;
        t["editFlag"] = false;
        t["timeKey"] = menuItem.menuId;
        t["tabUniqueKey"] = menuItem.menuId;
        t["Active"] = true;
        dispatch(openMenu(t));
        setActiveMenu(menuItem.menuId);

        // handle click collapse menu
        if (
          !sessionStorage.getItem("pin-menu") ||
          sessionStorage.getItem("pin-menu") == "unpin"
        ) {
          props.onCollapseClick();
        }
      }
    }
  };

  const onManualDownload = async (menuItem) => {
    let fileInfo = await ApiManager.downloadFileByUrl(fileDownload, {
      savedFilePath: `/${menuItem?.menuUrl}`,
      orgFileName: menuItem?.menuUrl,
      fileName: "manual",
    });

    if (fileInfo !== -1) {
      let filename = null;
      let disposition = fileInfo?.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
          filename = ComUtils.removeSpecialCharacters(filename);
        }
      }
      if (filename) {
        const extensionRegex = /\.([^.]*)$/;
        const match = filename.match(extensionRegex);

        if (FileUtils.allowFileExt?.indexOf(match[1].toLowerCase()) > -1) {
          try {
            FileSaver.saveAs(
              new Blob([fileInfo.data], {
                type: fileInfo?.headers["content-type"],
              }),
              decodeURI(filename)
            );
          } catch (ex) {
            ComNoticfication.ShowAlertUI(
              ComUtils._altTitleError,
              "There are no files to download"
            );
          }
        } else {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            "Wrong file format."
          );
          return;
        }
      } else {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, "File Not Found");
        return;
      }
    } else {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, "File Not Found");
      return;
    }
  };

  useEffect(() => {
    if (currentMenu?.length > 0 && currentMenu[0].Active) {
      setActiveHome(true);
    } else {
      setActiveHome(false);
    }
  }, [currentMenu, dispatch]);

  useEffect(() => {
    const loadMenuData = async () => {
      let resultData = await ApiManager.post(
        "/menu/retrieveMenuWithUserNo",
        {}
      );
      let orginalMenu = _.cloneDeep(resultData);
      // 조회 데이터를 트리로 변경 처리
      let menuTreeData = ComUtils.makeMenuTree(resultData);

      // test code
      setDbMenuData(menuTreeData);

      // 데이터 바인딩
      setMenuTree(menuTreeData);
    };

    loadMenuData();
  }, [dispatch]);

  const [selected, setSelected] = useState(null);
  // const [allSelected, setAllSelected] = useState(false);
  const [subMenuOpened, setSubMenuOpened] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);

  const toggle = (i, el) => {
    if (el?.menuUrl) {
      if (el.menuUrl.indexOf("http") > -1) {
        window.open(el.menuUrl);
      } else {
        document.getElementById("areaInfoObject").style.display = "none";
        let t = {};
        Object.assign(t, el);
        t["origSysDocumentRef"] = "";
        t["copyFlag"] = false;
        t["editFlag"] = false;
        t["timeKey"] = el.menuId;
        t["tabUniqueKey"] = el.menuId;
        t["Active"] = true;
        dispatch(openMenu(t));
      }
    }
    const currentItem = expandedItems.find((item) => item === el?.menuId);
    if (currentItem) {
      setExpandedItems([
        ...expandedItems.filter((item) => item !== el?.menuId),
      ]);
    } else {
      if (selected === i) {
        return setSelected(null);
      }
      setSelected(i);
    }
    if (props.menuFixed === false) {
      props.onCollapseClick();
    }
  };

  const toggleSubMenu = (i, menuId) => {
    const currentItem = expandedItems.find((item) => item === menuId);
    if (currentItem) {
      setExpandedItems([...expandedItems.filter((item) => item !== menuId)]);
    } else {
      setSubMenuOpened(subMenuOpened === i ? null : i);
    }
  };

  useEffect(() => {
    if (props.menuFixed === false) {
      setSubMenuOpened(null);
      setExpandedItems([]);
    }
  }, [props.menuFixed]);

  useEffect(() => {
    //handle open all menu when user type is RSA SPIFF
    if (
      sessionStorage.getItem("userType") &&
      sessionStorage.getItem("userType") == "B"
    ) {
      let subItems = [];
      if (dbMenuData && dbMenuData.length > 0) {
        setExpandedItems(
          [
            ...dbMenuData.map((item) => {
              if (item.items) {
                subItems.push([...item.items.map((i) => i.menuId)]);
              }
              return [item.menuId, ...subItems].flat();
            }),
          ].flat()
        );
      }
    }
  }, [dbMenuData]);

  return (
    <>
      <div>
        <Divider textAlign="left" className={activeHome ? "isHome" : ""}>
          <span onClick={() => onSetPubMenu()}>
            {props.menuFixed ? "MENU" : ""}
          </span>
        </Divider>
        <div className={activeHome ? "menu-ctrl on-title" : "menu-ctrl"}>
          <div className="btn-collapse">
            <Button
              variant="outlined"
              onClick={() => {
                const subItems = [];
                {
                  !pubMenu
                    ? setExpandedItems(
                        [
                          ...menuData.map((item) => {
                            if (item.items) {
                              subItems.push([
                                ...item.items.map((i) => i.menuId),
                              ]);
                            }
                            return [item.menuId, ...subItems].flat();
                          }),
                        ].flat()
                      )
                    : setExpandedItems(
                        [
                          ...dbMenuData.map((item) => {
                            if (item.items) {
                              subItems.push([
                                ...item.items.map((i) => i.menuId),
                              ]);
                            }
                            return [item.menuId, ...subItems].flat();
                          }),
                        ].flat()
                      );
                }
              }}
            >
              {"+Expand All"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setExpandedItems([]);
                setSelected(null);
                setSubMenuOpened(null);
              }}
            >
              {"-Collapse All"}
            </Button>
          </div>
        </div>
      </div>
      <div className="main-ul-wrap">
        <ul className="main-ul">
          {menuTree !== undefined && menuTree !== null
            ? menuTree.map((el, i) => (
                <li key={el.menuId}>
                  <Button
                    className={selected === i ? "btn-nav active" : "btn-nav"}
                    onClick={() => {
                      toggle(i, el);
                    }}
                    endIcon={
                      el.items === undefined ? (
                        ""
                      ) : selected === i ||
                        expandedItems.includes(el.menuId) ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )
                    }
                  >
                    {!props.menuFixed ? (
                      <Tooltip title={el.menuName} placement="right-start">
                        <span className={"bx-icon " + el.menuName}></span>
                      </Tooltip>
                    ) : (
                      <span className={"bx-icon " + el.menuName}></span>
                    )}
                    <span className="btn-text">{el.menuName}</span>
                  </Button>
                  {el?.items?.length > 0 ? (
                    <ul
                      className={
                        (selected === i && props.menuFixed) ||
                        expandedItems.includes(el.menuId)
                          ? "bx-drop-menu show"
                          : "bx-drop-menu"
                      }
                      key={el.menuId + "Ul"}
                    >
                      {el.items.map((sub2, i2) => (
                        <li
                          key={sub2.menuId}
                          onClick={() => {
                            sub2?.items?.length > 0
                              ? toggleSubMenu(i2, sub2.menuId)
                              : menuClick(sub2);
                          }}
                          className={
                            sub2?.items?.length > 0
                              ? "has-child"
                              : activeMenu === sub2.menuId
                              ? "active"
                              : ""
                          }
                        >
                          <span
                            className={`sub-text ${
                              subMenuOpened === i2 ? "on" : ""
                            }`}
                          >
                            {sub2.menuName}
                            {sub2.items === undefined ? (
                              ""
                            ) : subMenuOpened === i2 ||
                              expandedItems.includes(sub2.menuId) ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </span>
                          {sub2?.items?.length > 0 ? (
                            <ul
                              className={`lvl-3 ${
                                subMenuOpened === i2 ||
                                expandedItems.includes(sub2.menuId)
                                  ? "bx-drop-menu show"
                                  : "bx-drop-menu"
                              }`}
                              key={sub2.menuId + "Ul"}
                            >
                              {sub2.items.map((sub3) => (
                                <li
                                  key={sub3.menuId}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    menuClick(sub3);
                                  }}
                                  className={
                                    activeMenu === sub3.menuId ? "active" : ""
                                  }
                                >
                                  <span className="sub-text">
                                    {sub3.menuName}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))
            : ""}
          <li></li>
        </ul>
      </div>
      <div className="btn-manual-wrapper">
        {/* <Divider /> */}
        {props.menuFixed ? (
          <>
            <div
              className="d-flex-center fz-11"
              style={{ fontFamily: "Arial" }}
            >
              LG Sales Portal
            </div>
            {/* <div className="d-flex" style={{ marginRight: "10px" }}>
              <Button
                className={activeHome ? "btn-nav btn-manual" : "btn-nav"}
                variant="outlined"
                style={{
                  width: "96%",
                  gap: "4px",
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "10px",
                }}
              >
                <img
                  src={BookIcon}
                  alt="manual"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                <span
                  className="btn-text"
                  style={{ flex: "inherit", fontSize: "12px" }}
                >
                  Manual
                </span>
              </Button>
            </div> */}
          </>
        ) : (
          <>
            {/* <Button
              className={activeHome ? "btn-nav btn-manual" : "btn-nav"}
              variant="outlined"
              style={{
                width: "50%",
                gap: "4px",
                display: "flex",
                justifyContent: "center",
                paddingLeft: "10px",
              }}
            >
              <img
                src={BookIcon}
                alt="manual"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <span className="btn-text" style={{ flex: "inherit" }}>
                Manual
              </span>
            </Button> */}
          </>
        )}
      </div>
    </>
  );
};
export default MenuLayer;
