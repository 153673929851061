import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ComTextBox from 'common/components/ComTextBox';
import ComCombo from 'common/components/ComCombo';
import PanelGroup from "react-panelgroup";
import Divider from "@mui/material/Divider";

import { CollectionView, createElement, format } from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { InputNumber, InputDate } from "@grapecity/wijmo.input";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';

function LgProgramModelSchPop({ open, closeFn, randomKey, reqBillToCode, reqModelCode, reqPriceProtectionDate, resData, pageInfo }) {

  //url
  const retrieveProgramModelList = "/com/comPop/retrieveProgramModelList";
  const retrieveSelCodeUrl = "/com/comCode/retrieveSelCodeList";

  // 검색
  const entityDivision = useRef(null);
  const entityModelCode = useRef(null);

  const filterSearch = useRef(null);

  const gridRef = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const displayCheck = useRef(false);

  const comCode = sessionStorage.getItem("comCode").toString();


  const popComponent = useRef();

  /***** Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {

    setGridObject(sender);
    gridRef.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });


    // sender.addEventListener(sender.hostElement, "dblclick", (e) => {
    //   let ht = sender.hitTest(e);

    //   if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
    //     popComponent.current.hide();
    //     closeFn(sender.selectedItems[0]);
    //   }
    // });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

    sender.cellEditEnded.addHandler((s, e) => {
      let binding = e.getColumn().binding;

      let flexGrid = gridRef.current;
      // 체크 박스 제어.
      if (binding === "isChecked2") {
        let checkNoneList = sender.collectionView.items.filter(
          (e) => e.isChecked2 === false
        );
        if (checkNoneList.length === sender.collectionView.items.length) {
          displayCheck.current = false;
        }

        let checkAllList = sender.collectionView.items.filter(
          (e) => e.isChecked2 === true
        );
        if (checkAllList.length === sender.collectionView.items.length) {
          displayCheck.current = true;
        } else {
          displayCheck.current = false;
        }
        sender.refresh();
      }
    });

    sender.formatItem.addHandler((s, e) => {
      if (
        e.panel === s.columnHeaders &&
        e.getColumn(e.col).binding === "isChecked2"
      ) {
        e.cell?.firstChild?.remove();
        let element = null;
        if (displayCheck.current) {
          element = createElement(
            format(
              `<div class="wj-align-center" tabindex="-1" aria-colindex="1" aria-selected="false" style="left: 0px; top: 0px; width: 43px; height: 15px; z-index: 0;"><label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true></label></div>`,
              {}
            ),
            e.cell
          );
        } else {
          element = createElement(
            format(
              `<div class="wj-align-center" tabindex="-1" aria-colindex="1" aria-selected="false" style="left: 0px; top: 0px; width: 43px; height: 15px; z-index: 0;"><label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1"></lable></div>`,
              {}
            ),
            e.cell
          );
        }
        element.onchange = function (e) {
          e.preventDefault();
          onAllChecked.apply(e, [e]);
        };
      }
    });



  }


  const onAllChecked = (status) => {
    let tmpChecked = status?.target?.checked;
    if (
      (gridRef?.current?.collectionView?.items?.length ?? 0) > 0
    ) {
      let checkCountList =
        gridRef.current.collectionView.items.filter(
          (e) => e.isChecked2 === true
        );
      if (
        checkCountList.length ===
        gridRef.current.collectionView.items.length
      ) {
        tmpChecked = false;
      } else {
        tmpChecked = true;
      }

      gridRef.current.collectionView.items.forEach((el) => {
        if (tmpChecked) {
          el.isChecked2 = true;
        } else {
          el.isChecked2 = false;
        }
      });

      gridRef.current.collectionView.commitEdit();
      gridRef.current.collectionView.refresh();
    } else {
      status.target.checked = false;
      tmpChecked = false;
    }

    displayCheck.current = tmpChecked;
  };



  const onSelectionChanged = (sender) => {

  }

  const onSourceChanged = () => {
    if (gridRef) {
      gridRef.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: 'isChecked2', header: " ", width: 30, align: "right", allowDragging: false, isReadOnly: false, allowResizing: false, allowSorting: false, allowMerging: false }
      , { binding: 'divisionCode', header: "Div.", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'groupChannel', header: "Group\nChannel", width: 130, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'billToCode', header: "Bill To Code", width: 150, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'billToName', header: "Bill To Name", width: 210, allowDragging: true, isReadOnly: true }
      , { binding: 'priceMoveDate', header: "Price\nMove date", width: 140, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'modelCode', header: "Model", width: 170, allowDragging: true, isReadOnly: true } // , align: "right", format: "n2"
      , { binding: 'differencePerUnit', header: "Difference\nper unit", width: 120, allowDragging: true, isReadOnly: true, align: "right", editor: (new InputNumber(document.createElement('div'), { format: "n0", min: 0, max: 9999999999999 })) }
      , { binding: 'oldPrice', header: "Old Price", width: 100, allowDragging: true, isReadOnly: true, align: "right" }
      , { binding: 'newPrice', header: "New Price", width: 100, allowDragging: true, isReadOnly: true, align: "right" }
      , { binding: 'expectedQty', header: "Expected Qty", width: 120, allowDragging: true, isReadOnly: true, align: "right" }

    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();
    SearchGridFormat();

    const getPageInfo = async () => {
      let resDivision = await ApiManager.post(retrieveSelCodeUrl, { "class": "045" });

      const allCombo = [{ cd: "", nm: "ALL" }];
      let defaultCode = "ALL";

      entityDivision.current.setList({ data: allCombo.concat(resDivision), initValue: defaultCode });

      //조회된 내역존재 (다건)
      if (resData) {
        resData?.list?.forEach((el, index) => {
          el.isChecked2 = false;
        });
        setGridData(new CollectionView(resData.list, { trackChanges: true, refreshOnEdit: false }));

        entityPageNo.current = 1;
        let pageNoVal = entityPageNo.current;
        let sendParam = {
          comCode: comCode
          , billToCode: reqBillToCode
          , modelCode: reqModelCode || ''
          , divisionCode: ''
          , priceMoveDate: reqPriceProtectionDate
          , pageSize: '1'
          , rowSize: pageInfo.pageRowSize
          , pageNo: pageNoVal.toString()
        }
        searchDataRef.current = sendParam;

        totCntRef.current = Number(resData.totCnt);
        pageCntRef.current = Number(resData.list.length);
      }
    }

    if (open) {
      filterSearch?.current?.clear();
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  const onClickAdd = () => {
    popComponent.current.hide();
    let flexGrid = gridRef.current;
    let selectedItems = flexGrid.rows.filter((row) => row.dataItem.isChecked2);

    closeFn(selectedItems);
  }

  // 조회
  const onSearch = (param, e) => {
    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {
      comCode: comCode
      , billToCode: reqBillToCode
      , modelCode: entityModelCode.current?.getValue()?.toUpperCase() || ''
      , divisionCode: entityDivision.current?.getValue() || ''
      , priceMoveDate: reqPriceProtectionDate
      , pageSize: '1'
      , rowSize: pageInfo.pageRowSize
      , pageNo: pageNoVal.toString()
    }
    searchDataRef.current = sendParam
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    let row_index = gridRef.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveProgramModelList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveProgramModelList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
        setGridDataCount(0);
      } else {

        resultData?.list?.forEach((el, index) => {
          el.isChecked2 = false;
        });


        let resultCnt = 0;
        if (param.searchFlag) {

          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`LG Program Model Search`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Division
              </label>
            </div>
            <div className="bx-inp combo">
              <ComCombo
                ref={entityDivision}
                elementId={"entityDivision"}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Model Code
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityModelCode}
                defaultValue={reqModelCode}
              />
              <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
              <Button variant="contained" onClick={(e) => onClickAdd()}>{"Add"}</Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default LgProgramModelSchPop;