import React, { createContext, useState, useEffect } from 'react';
import { ApiManager } from './ApiUtil';

export const MessageContext = createContext([]);

const MessageProvider = ({ children }) => {

    const [messageList, setMessageList] = useState([]);

    useEffect(() => {
        const loadMessageData = async () => {
            let resultData = await ApiManager.post("/com/message/retrieveMessage", {});
            // 데이터 바인딩
            setMessageList(resultData);
        };
        loadMessageData();
    }, []);


    return (
        <MessageContext.Provider value={{ messageObject: messageList }}>{children}</MessageContext.Provider>
    );
}
export default MessageProvider;
