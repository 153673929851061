import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";

import * as wjInput from '@grapecity/wijmo.react.input';

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";
import { ComNoticfication } from 'common/components/ComNoticfication';
import ComCombo from 'common/components/ComCombo';
import ComTextBox from 'common/components/ComTextBox';
import ComTextBoxNumber from 'common/components/ComTextBoxNumber';
import ComTextBoxFind from 'common/components/ComTextBoxFind';
import { SampleRequestUtils } from '../../Spl/Common/SampleRequestUtils';
import { SampleUtils } from '../../Spl/Common/SampleUtils';
import CityStateZipPop from './CityStateZipPop';

function CreditCardInfoPop({ open, closeFn, randomKey, readType, values, rowIndex, pageInfo }) {
  const comCode = sessionStorage.getItem("comCode").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();

  //url
  const retrieveCommonCodeList = "/spl/common/retrieveCommonCodeList";

  const [disable, setDisable] = useState(readType === 'ready');
  const [billingName, setBillingName] = useState(values?.ccBillingName);
  const [holderName, setHolderName] = useState(values?.ccHolderName);
  const [address1, setAddress1] = useState(values?.ccAddress1);
  const [address2, setAddress2] = useState(values?.ccAddress2);
  const [cardNo, setCardNo] = useState(values?.ccNo);
  const [pinNo, setPinNo] = useState(values?.ccPinNo);
  const [approvalNo, setApprovalNo] = useState(values?.ccApprovalNo);
  const [cvsNo, setCvsNo] = useState(values?.ccCvsNo);
  const [city, setCity] = useState(values?.ccCity);
  const [state, setState] = useState(values?.ccState);
  const [zip, setZip] = useState(values?.ccZip);
  const [maxLengthCvs, setMaxLengthCvs] = useState(4);
  const [remark, setRemark] = useState(values?.ccRemark);

  const entityCardType = useRef(null);
  const entityExpireMm = useRef(null);
  const entityExpireYyyy = useRef(null);
  const entityCardNo = useRef(null);
  const entityCvsNo = useRef(null);
  const entityPinNo = useRef(null);

  const cardTypeList = useRef([]);
  const popComponent = useRef();
  const [randomKey2, setRandomKey] = useState(null);

  // popup
  const defaultZipSearchParam = useRef({
    url: "/com/splComPop/retrieveCityStateZipList"
    , comCode: comCode
    , searchText: ''
    , pageSize: '1'
    , rowSize: pageInfo.pageRowSize
    , pageNo: '1'
  });
  const zipResData = useRef(null);
  const [openModalZip, setOpenModalZip] = useState(false);
  
  //Memory 
  const _isMounted = useRef(true);

  const onClickSave = () => {
    // check validate()
    if (!cardNo) {
      ComNoticfication.ShowErrorUI("error", "Please Input The Credit Card No Info");
      return;
    }

    if (!entityExpireMm?.current?.getValue() || !entityExpireYyyy?.current?.getValue()) {
      ComNoticfication.ShowErrorUI("error", "Please Input The Credit Card Expire Info");
      return;
    }

    if (!cvsNo) {
      ComNoticfication.ShowErrorUI("error", "Please Input The Credit Card CVS No Info");
      return;
    }

    if (!zip || !city) {
      ComNoticfication.ShowErrorUI("error", "Please Input The Credit Card Zip Info");
      return;
    }    

    // 닫으면서 부모로 데이터 전달
    let savedData = {...values};
    savedData.ccTypeCode = entityCardType?.current?.getValue() ?? null;
    savedData.ccHolderName = holderName?.toUpperCase() ?? null;
    savedData.ccNo = cardNo?.toUpperCase() ?? null;
    savedData.ccExpireMm = entityExpireMm?.current?.getValue() ?? null;
    savedData.ccExpireYyyy = entityExpireYyyy?.current?.getValue() ?? null;
    savedData.ccCvsNo = cvsNo?.toUpperCase() ?? null;
    savedData.ccPinNo = pinNo?.toUpperCase() ?? null;
    savedData.ccApprovalNo = approvalNo?.toUpperCase() ?? null;
    savedData.ccBillingName = billingName?.toUpperCase() ?? null;
    savedData.ccAddress1 = address1?.toUpperCase() ?? null;
    savedData.ccAddress2 = address2?.toUpperCase() ?? null;
    savedData.ccCity = city?.toUpperCase() ?? null;
    savedData.ccState = state?.toUpperCase() ?? null;
    savedData.ccZip = zip?.toUpperCase() ?? null;
    savedData.ccRemark = remark?.toUpperCase() ?? null;
    
    popComponent.current.hide();
    closeFn(savedData, rowIndex);
  }

  const onChangeCardType = (elementId, value) => {
    if (value) {
      let card = cardTypeList.current?.find(i => i.code === value);
      setCvsNo('');
      setMaxLengthCvs(Number(card.attr));
    }
  }

  const openZipPopup = (elementId, userCode, resultData) => {
    ComUtils.setComRandomKey(setRandomKey);

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        setZip(resultData.list[0].postalCode);
        setCity(resultData.list[0].cityName);
        setState(resultData.list[0].stateCode);
      } else {
        //여러건이면 팝업으로 전달
        zipResData.current = resultData;
        setOpenModalZip(true);
      }
    } else {
      setOpenModalZip(true);
    }
  };

  const callZipPopup = (elementId, value) => {
    defaultZipSearchParam.current.searchText = value.toUpperCase();
    setZip(value.toUpperCase());
    setCity('');
    setState('');
  };

  const closeZipPopup = async (selectedItems) => {
    if (selectedItems) {
      setZip(selectedItems.postalCode);
      setCity(selectedItems.cityName);
      setState(selectedItems.stateCode);

      defaultZipSearchParam.current.searchText = selectedItems.postalCode;
    }

    zipResData.current = null;
    setOpenModalZip(false);
  }

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();

    const getPageInfo = async () => {
      let sendParam = {
        comCode: comCode,
        userGroup: userGroup,
        type: "CC_TYPE",
      }

      let resultData = await ApiManager.post(retrieveCommonCodeList, sendParam)

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("error", "코드 조회 중 에러가 발생했습니다.");
        return;
      }
      
      cardTypeList.current = resultData;

      entityCardType?.current?.setList({data: resultData, initValue: values?.ccTypeCode, code: "code", name: 'value'});
      entityExpireMm?.current?.setValue(values?.ccExpireMm);
      entityExpireYyyy?.current?.setValue(values?.ccExpireYyyy);
    }

    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{width:"800px"}}
      >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`Credit Card Information`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap fixed-column2 mt-10">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Credit Card Type
              </label>
            </div>
            <div className="bx-inp combo">
              <ComCombo
                ref={entityCardType}
                defaultDisabled={disable}
                callRelationFuntion={disable ? undefined : onChangeCardType}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
              Credit Card Billing Name	
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={billingName}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setBillingName(val?.toUpperCase())}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
              Credit Card Holder Name	
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={holderName}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setHolderName(val?.toUpperCase())}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Address1	
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={address1}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setAddress1(val?.toUpperCase())}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol">*</span>Credit Card No
              </label>
            </div>
            <div className="bx-inp combo">
                <ComTextBox
                  ref={entityCardNo}
                  defaultValue={cardNo}
                  defaultDisabled={disable}
                  defaultMaxLength={16}
                  callRelationFuntion={(id,val) => {
                    val = val.replace(/[^0-9]/gi, "");
                    entityCardNo?.current?.setValue(val);
                    setCardNo(val);
                    }
                  }
                />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Address2
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={address2}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setAddress2(val?.toUpperCase())}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol">*</span>Card Expire Date(MM/YYYY)
              </label>
            </div>
            <div className="bx-inp combo">
              <ComCombo
                ref={entityExpireMm}
                defalultList={SampleRequestUtils.getMonthList()}
                defaultDisabled={disable}
              />
              <ComCombo
                ref={entityExpireYyyy}
                defalultList={SampleRequestUtils.getYearList()}
                defaultDisabled={disable}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                City
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={city}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setCity(val)}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol">*</span> Credit Card CVS No
              </label>
            </div>
            <div className="bx-inp combo">
                <ComTextBox
                  ref={entityCvsNo}
                  defaultValue={cvsNo}
                  defaultDisabled={disable}
                  defaultMaxLength={maxLengthCvs}
                  callRelationFuntion={(id,val) => {
                    val = val.replace(/[^0-9]/gi, "");
                    entityCvsNo?.current?.setValue(val);
                    setCvsNo(val);
                    }
                  }
                />              
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                State
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={state}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setState(val)}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Credit Card PIN No	
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityPinNo}
                defaultValue={pinNo}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => {
                  val = val.replace(/[^0-9]/gi, "");
                  entityPinNo?.current?.setValue(val);
                  setPinNo(val);
                  }
                }
              />              
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
              <span className="requiresimbol">*</span> Zip
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBoxFind
                defaultValue={zip}
                defaultDisabled={disable}
                callOpenPopupFunction={openZipPopup}
                callRelationFuntion={callZipPopup}
                callClearFunction={callZipPopup}
                defaultRaiseClick={false}
                defaultReadOnly={false}
                defaultSearch={defaultZipSearchParam}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Credit Card Approval No	
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={approvalNo}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setApprovalNo(val?.toUpperCase())}
              />
            </div>
          </div>

          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Comment
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                defaultValue={remark}
                defaultDisabled={disable}
                callRelationFuntion={(id,val) => setRemark(val?.toUpperCase())}
              />
            </div>
          </div>
        </div>        
      </div>

      { !disable &&
        <div className="wj-dialog-footer">
          <Button variant="contained" onClick={() => onClickSave()}>
            Save
          </Button>
        </div>
      }

      {openModalZip && <CityStateZipPop open={openModalZip} closeFn={closeZipPopup} randomKey={randomKey2} pageInfo={pageInfo} reqSearchText={zip} resultData={zipResData?.current}/>}
    </wjInput.Popup>
  )
}

export default CreditCardInfoPop;