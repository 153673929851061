import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";
import { FlexGrid } from "@grapecity/wijmo.react.grid";

import Divider from "@mui/material/Divider";

import { ComNoticfication } from "common/components/ComNoticfication";
import { ApiManager } from "common/lib/ApiUtil";
import * as wjInput from "@grapecity/wijmo.react.input";
import ComTextBox from "common/components/ComTextBox";
import { ComUtils } from "common/lib/ComUtils";
import { CollectionView } from "@grapecity/wijmo";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { MessageContext } from "common/lib/MessageProvider";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import ComCheckBox from "common/components/ComCheckBox";
import ComTextArea from "common/components/ComTextArea";
import BillToSchPop from "../BillToSchPop";
import ComRadioBoxNoneCheck from "common/components/ComRadioBoxNoneCheck";

function VendorRegPop({ open, closeFn, reqRandomKey, billToData, pageInfo }) {
  //session정보
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userId = sessionStorage.getItem("userId").toString();
  const userLevel = pageInfo.userLevel;

  const randomKey = useRef(null);

  //url
  const insertVendorInfo = "/clm/oth/vendor/insertVendorInfo";
  const retrieveBillToListUrl = "/com/comPop/retrieveBillToList";

  // 검색
  const entityBillTo = useRef(null);
  const entityBillToCode = useRef(null);
  const entityBillToName = useRef(null);
  const [billToCd, setBillToCd] = useState("");
  const [billToNm, setBillToNm] = useState("");
  const entityVendorName = useRef(null);
  const entityVendorDbaName = useRef(null);
  const entityVendorAddress = useRef(null);
  const entityVendorCity = useRef(null);
  const entityVendorStateProvince = useRef(null);
  const entityVendorPostalCode = useRef(null);
  const entityVendorTaxId = useRef(null);
  const entityVendorDunBradstreet = useRef(null);
  const entityDumyY = useRef(null);
  const entityDumyN = useRef(null);
  const entityRemAddress = useRef(null);
  const entityRemCity = useRef(null);
  const entityRemStateProvince = useRef(null);
  const entityRemPostalCode = useRef(null);
  const entityPayTerm = useRef(null);
  const entityPayMethod = useRef(null);
  const entityReqContactName = useRef(null);
  const entityReqContactDept = useRef(null);
  const entityReqContactPhone = useRef(null);
  const entityReqContactFax = useRef(null);
  const entityBankName = useRef(null);
  const entityBankAddress = useRef(null);
  const entityBankCity = useRef(null);
  const entityBankStateProvince = useRef(null);
  const entityBankPostalCode = useRef(null);
  const entityBankContract = useRef(null);
  const entityBankContractPhone = useRef(null);
  const entityBankNameAcct = useRef(null);
  const entityAccountNo = useRef(null);
  const entityAbaRoutingNo = useRef(null);
  const entityCerPrintName = useRef(null);
  const entityCerTitle = useRef(null);
  const entityCerPhone = useRef(null);
  const entityCerSignature = useRef(null);
  const entityCerDate = useRef(null);
  const entityComment = useRef(null);

  const filterSearch = useRef(null);
  const gridSearch = useRef(null);
  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const WBECBYes = useRef();
  const WBECBNo = useRef();
  const TermItem = useRef();
  const MethodItem = useRef();

  const [isHiddenVDInfo, setHiddenVDInfo] = useState(true);
  const [isHiddenBankInfo, setHiddenBankInfo] = useState(true);
  const [isHiddenTrade, setHiddenTrade] = useState(true);

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const successMessage = ComUtils.getMessage(messageObject, "SMSG_COM_INF_001");
  const msgGsrErr028 = ComUtils.getMessage(messageObject, "GSR_ERR_00028"); //Submit Error. Please Contact System Admin.

  //billToPopup[S]
  const [openModalBillTo, setOpenModalBillTo] = useState(false);

  const defaultBillToSearchParam = useRef({
    url: retrieveBillToListUrl,
    comCode: comCode,
    userType: userType,
    userGroup: userGroup,
    billToCode: "", //changeValue
    billToName: "",
    pageSize: "1",
    rowSize: "100",
    pageNo: "1",
    userLevel: "1" /* userLevel */,
    userId: userId,
  });

  const billToResData = useRef(null);

  //billTo팝업
  const openBillToPopup = async (elementId, userCode) => {
    defaultBillToSearchParam.current.billToCode =
      entityBillToCode.current.getValue();
    let resultData = await ApiManager.post(
      retrieveBillToListUrl,
      defaultBillToSearchParam.current
    );
    console.log(resultData);

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        entityBillToCode.current.setValue(resultData.list[0].billTo);
        entityBillToName.current.setValue(resultData.list[0].billToName);
      } else {
        //여러건이면 팝업으로 전달
        billToResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalBillTo(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalBillTo(true);
    }
  };

  const callBillToPopup = (elementId, value) => {
    defaultBillToSearchParam.current.billToCode = value.toUpperCase();
    entityBillToCode.current.setValue(value.toUpperCase());
  };

  const closeBillToPopup = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      entityBillToCode.current.setValue(selectedItems.billTo);
      entityBillToName.current.setValue(selectedItems.billToName);
      defaultBillToSearchParam.current.billToCode = selectedItems.billTo;
    }
    billToResData.current = null;
    setOpenModalBillTo(false);
  };
  //billToPopup[E]

  const onSave = () => {
    let billToCode = entityBillToCode?.current?.getValue();
    let vendorName = entityVendorName?.current?.getValue();
    let vendorAddress = entityVendorAddress?.current?.getValue();
    let vendorCity = entityVendorCity?.current?.getValue();
    let vendorStateProvince = entityVendorStateProvince?.current?.getValue();
    let vendorPostalCode = entityVendorPostalCode?.current?.getValue();
    let payMethod = entityPayMethod?.current?.getValue();
    let bankName = entityBankName?.current?.getValue();
    let bankAddress = entityBankAddress?.current?.getValue();
    let bankCity = entityBankCity?.current?.getValue();
    let bankStateProvince = entityBankStateProvince?.current?.getValue();
    let bankPostalCode = entityBankPostalCode?.current?.getValue();
    let bankContract = entityBankContract?.current?.getValue();
    let bankContractPhone = entityBankContractPhone?.current?.getValue();
    let bankNameAcct = entityBankNameAcct?.current?.getValue();
    let accountNo = entityAccountNo?.current?.getValue();
    let abaRoutingNo = entityAbaRoutingNo?.current?.getValue();
    let ceritifyPrintName = entityCerPrintName?.current?.getValue();
    let ceritifyTitle = entityCerTitle?.current?.getValue();
    let ceritifyPhone = entityCerPhone?.current?.getValue();

    if (billToCode == null || billToCode == "") {
      ComNoticfication.ShowAlertUI("", "Bill to is required.");
    } else if (vendorName == null || vendorName == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Name] of the [Vendor Information]",
        () => {
          entityVendorName.current.setFocus();
        }
      );
    } else if (vendorAddress == null || vendorAddress == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Address] of the [Vendor Information]",
        () => {
          entityVendorAddress.current.setFocus();
        }
      );
    } else if (vendorCity == null || vendorCity == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [City] of the [Vendor Information]",
        () => {
          entityVendorCity.current.setFocus();
        }
      );
    } else if (vendorStateProvince == null || vendorStateProvince == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [State/Province] of the [Vendor Information]",
        () => {
          entityVendorStateProvince.current.setFocus();
        }
      );
    } else if (vendorPostalCode == null || vendorPostalCode == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Postal Code] of the [Vendor Information]",
        () => {
          entityVendorPostalCode.current.setFocus();
        }
      );
    } else if (payMethod == null || payMethod == "") {
      ComNoticfication.ShowAlertUI(
        "",
        "Enter the [Pay Method] of the [Vendor Information]"
      );
    } else if (bankName == null || bankName == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Name of Bank] of the [Bank Information]",
        () => {
          entityBankName.current.setFocus();
        }
      );
    } else if (bankAddress == null || bankAddress == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Bank Address] of the [Bank Information]",
        () => {
          entityBankAddress.current.setFocus();
        }
      );
    } else if (bankCity == null || bankCity == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [City] of the [Bank Information]",
        () => {
          entityBankCity.current.setFocus();
        }
      );
    } else if (bankStateProvince == null || bankStateProvince == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [State/Province] of the [Bank Information]",
        () => {
          entityBankStateProvince.current.setFocus();
        }
      );
    } else if (bankPostalCode == null || bankPostalCode == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Postal Code] of the [Bank Information]",
        () => {
          entityBankPostalCode.current.setFocus();
        }
      );
    } else if (bankContract == null || bankContract == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Bank Contact] of the [Bank Information]",
        () => {
          entityBankContract.current.setFocus();
        }
      );
    } else if (bankContractPhone == null || bankContractPhone == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Contact Phone #] of the [Bank Information]",
        () => {
          entityBankContractPhone.current.setFocus();
        }
      );
    } else if (bankNameAcct == null || bankNameAcct == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Name on the Acct.] of the [Bank Information]",
        () => {
          entityBankNameAcct.current.setFocus();
        }
      );
    } else if (accountNo == null || accountNo == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [Account No.] of the [Bank Information]",
        () => {
          entityAccountNo.current.setFocus();
        }
      );
    } else if (abaRoutingNo == null || abaRoutingNo == "") {
      ComNoticfication.ShowAlertUICallback(
        "",
        "Enter the [ABA Routing No.] of the [Bank Information]",
        () => {
          entityAbaRoutingNo.current.setFocus();
        }
      );
    } else if (ceritifyPrintName == null || ceritifyPrintName == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Print Name]", () => {
        entityCerPrintName.current.setFocus();
      });
    } else if (ceritifyTitle == null || ceritifyTitle == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Title.]", () => {
        entityCerTitle.current.setFocus();
      });
    } else if (ceritifyPhone == null || ceritifyPhone == "") {
      ComNoticfication.ShowAlertUICallback("", "Enter the [Phone #]", () => {
        entityCerPhone.current.setFocus();
      });
    } else {
      onSaveExecute();
    }
  };

  const onSaveExecute = async () => {
    let sendParam = {};
    sendParam.conCode = comCode;
    sendParam.userId = userId;
    sendParam.txtBillTo = entityBillToCode?.current?.getValue();
    sendParam.vendorName = entityVendorName?.current?.getValue();
    sendParam.vendorDbaName = entityVendorDbaName?.current?.getValue();
    sendParam.vendorAddress = entityVendorAddress?.current?.getValue();
    sendParam.vendorCity = entityVendorCity?.current?.getValue();
    sendParam.vendorStateProvince =
      entityVendorStateProvince?.current?.getValue();
    sendParam.vendorPostalCode = entityVendorPostalCode?.current?.getValue();
    sendParam.vendorTaxId = entityVendorTaxId?.current?.getValue();
    sendParam.vendorDunBradstreet =
      entityVendorDunBradstreet?.current?.getValue();

    sendParam.remittanceAddress = entityRemAddress?.current?.getValue();
    sendParam.remittanceCity = entityRemCity?.current?.getValue();
    sendParam.remittanceStateProvince =
      entityRemStateProvince?.current?.getValue();
    sendParam.remittancePostalCode = entityRemPostalCode?.current?.getValue();

    sendParam.payTerm = entityPayTerm?.current?.getValue();
    sendParam.payMethod = entityPayMethod?.current?.getValue();

    sendParam.requestorContactName = entityReqContactName?.current?.getValue();
    sendParam.requestorContactDept = entityReqContactDept?.current?.getValue();
    sendParam.requestorContactPhone =
      entityReqContactPhone?.current?.getValue();
    sendParam.requestorContactFax = entityReqContactFax?.current?.getValue();

    sendParam.bankName = entityBankName?.current?.getValue();
    sendParam.bankAddress = entityBankAddress?.current?.getValue();
    sendParam.bankCity = entityBankCity?.current?.getValue();
    sendParam.bankStateProvince = entityBankStateProvince?.current?.getValue();
    sendParam.bankPostalCode = entityBankPostalCode?.current?.getValue();
    sendParam.bankContract = entityBankContract?.current?.getValue();
    sendParam.bankContractPhone = entityBankContractPhone?.current?.getValue();
    sendParam.bankNameAcct = entityBankNameAcct?.current?.getValue();
    sendParam.accountNo = entityAccountNo?.current?.getValue();
    sendParam.abaRoutingNo = entityAbaRoutingNo?.current?.getValue();

    sendParam.ceritifyPrintName = entityCerPrintName?.current?.getValue();
    sendParam.ceritifyTitle = entityCerTitle?.current?.getValue();
    sendParam.ceritifyPhone = entityCerPhone?.current?.getValue();
    sendParam.ceritifySignature = entityCerSignature?.current?.getValue();
    sendParam.ceritifyDate = entityCerDate?.current?.getValue();

    sendParam.comCode = comCode;

    let resultData = await ApiManager.post(insertVendorInfo, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleError,
        "An error occurred during processing"
      );
    } else {
      ComNoticfication.ShowAlertUI("", successMessage);
      // setCondBillToCode(sendParam.txtBillTo);
      popComponent.current.hide();
      closeFn(null);
    }
  };

  //Memory
  const _isMounted = useRef(true);

  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {
      entityBillToCode?.current?.setValue(billToData.billToCode);
      entityBillToName?.current?.setValue(billToData.billToName);

      setBillToCd(billToData.billToCode);
      setBillToNm(billToData.billToName);

      entityVendorDbaName?.current?.setValue(null);
      entityVendorAddress?.current?.setValue(null);
      entityVendorCity?.current?.setValue(null);
      entityVendorStateProvince?.current?.setValue(null);
      entityVendorPostalCode?.current?.setValue(null);
      entityVendorTaxId?.current?.setValue(null);
      entityVendorDunBradstreet?.current?.setValue(null);

      entityRemAddress?.current?.setValue(null);
      entityRemCity?.current?.setValue(null);
      entityRemStateProvince?.current?.setValue(null);
      entityRemPostalCode?.current?.setValue(null);

      entityPayTerm?.current?.setValue(null);
      entityPayMethod?.current?.setValue(null);

      entityReqContactName?.current?.setValue(null);
      entityReqContactDept?.current?.setValue(null);
      entityReqContactPhone?.current?.setValue(null);
      entityReqContactFax?.current?.setValue(null);

      entityBankName?.current?.setValue(null);
      entityBankAddress?.current?.setValue(null);
      entityBankCity?.current?.setValue(null);
      entityBankStateProvince?.current?.setValue(null);
      entityBankPostalCode?.current?.setValue(null);
      entityBankContract?.current?.setValue(null);
      entityBankContractPhone?.current?.setValue(null);
      entityBankNameAcct?.current?.setValue(null);
      entityAccountNo?.current?.setValue(null);
      entityAbaRoutingNo?.current?.setValue(null);

      entityCerPrintName?.current?.setValue(null);
      entityCerTitle?.current?.setValue(null);
      entityCerPhone?.current?.setValue(null);

      const [CerDate] = await Promise.all([
        ApiManager.getServerDate("MM/DD/YYYY"),
      ]);
      entityCerSignature?.current?.setValue(userId);
      entityCerDate?.current?.setValue(CerDate);

      randomKey.current = reqRandomKey;
    };
    getPageInfo();
    if (open) {
      filterSearch?.current?.clear();
    }

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [open]);

  const onCancleClick = () => {
    popComponent.current.hide();
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  };

  return (
    <wjInput.Popup
      className="pop-resizable"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{
        height: "700px",
        overflowY: "auto",
        padding: "30px",
      }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"VENDOR REGISTRATION"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="page-wrap">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="bx-btn-group">
            <Button
              variant="contained"
              style={{ fontSize: "1.1rem" }}
              onClick={onSave}
            >
              {"Save"}
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: "5px", fontSize: "1.1rem" }}
              onClick={onCancleClick}
            >
              {"Close"}
            </Button>
          </div>
        </div>
        <div className="page-in-wrap">
          <span className="required-field">(*) is required madatory field</span>
          <div className="search-wrapper">
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Bill To Code
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityBillToCode}
                    elementId={"entityBillToCode"}
                    callRelationFuntion={callBillToPopup}
                    callOpenPopupFunction={openBillToPopup}
                    isSearch
                    defaultSearch={defaultBillToSearchParam}
                    defaultValue={billToCd}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Bill To Name
                  </label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityBillToName}
                    elementId={"entityBillToName"}
                    defaultDisabled={true}
                    defaultValue={billToNm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="title-rg-form">
            LG Electronics Vendor Registration Form
          </div>

          <span className="required-form">B. To be completed by supplier</span>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenVDInfo ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenVDInfo(!isHiddenVDInfo)}
              >
                {isHiddenVDInfo ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Vendor Information</span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenVDInfo ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorName}
                      elementId={"entityVendorName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">DBA Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorDbaName}
                      elementId={"entityVendorDbaName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Address
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorAddress}
                      elementId={"entityVendorAddress"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>City
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorCity}
                      elementId={"entityVendorCity"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>State/Province
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorStateProvince}
                      elementId={"entityVendorStateProvince"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Postal Code
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorPostalCode}
                      elementId={"entityVendorPostalCode"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Tax ID #
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorTaxId}
                      elementId={"entityVendorTaxId"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Dun & Bradstreet #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityVendorDunBradstreet}
                      elementId={"entityVendorDunBradstreet"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Company Website(URL)</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      Certified Minority or Women Owned Company (M/WBE)?
                    </label>
                  </div>
                  <div
                    className="bx-inp combo inp-mix"
                    style={{ height: "60px" }}
                  >
                    <div className="group-checkbox-entry">
                      <div className="item-checkbox">
                        <ComCheckBox
                          ref={entityDumyY}
                          elementId={"entityDumyY"}
                          idCheckbox="entityDumyY"
                          defaultStyle={{ pointerEvents: "none" }}
                        />
                        <label className="text-checkbox">Yes</label>
                      </div>
                      <div className="item-checkbox">
                        <ComCheckBox
                          ref={entityDumyN}
                          elementId={"entityDumyN"}
                          idCheckbox="entityDumyN"
                          defaultStyle={{ pointerEvents: "none" }}
                          // onClick="return false;"
                        />
                        <label className="text-checkbox">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Remittance Address (If different from above adress)
                </div>

                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemAddress}
                      elementId={"entityRemAddress"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">City</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemCity}
                      elementId={"entityRemCity"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">State/Province</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemStateProvince}
                      elementId={"entityRemStateProvince"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityRemPostalCode}
                      elementId={"entityRemPostalCode"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Payment Information
                </div>
                <div
                  className="bx-item column-4column"
                  style={{ height: "60px" }}
                >
                  <div className="div-label">
                    <label className="txt-label">
                      E-mail address for notification of payment / PO
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">df</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Pay Term</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="group-checkbox-entry">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "0", Description: "0" },
                          { Value: "10", Description: "10" },
                          { Value: "15", Description: "15" },
                          { Value: "20", Description: "20" },
                          { Value: "25", Description: "25" },
                          { Value: "30", Description: "30" },
                          { Value: "45", Description: "45" },
                          { Value: "60", Description: "60" },
                          { Value: "75", Description: "75" },
                          { Value: "90", Description: "90" },
                        ]}
                        direction={1}
                        ref={entityPayTerm}
                        elementId={"entityPayTerm"}
                        radioGroupName={"entityPayTerm"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Pay Method</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <div className="pay-method">
                      <div className="please-select">*Please select one.</div>
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "ACH", Description: "ACH(Direct Deposit)" },
                          { Value: "WIRE", Description: "Wire(Overseas only)" },
                          { Value: "CHECK", Description: "Check" },
                        ]}
                        direction={1}
                        ref={entityPayMethod}
                        elementId={"entityPayMethod"}
                        radioGroupName={"entityPayMethod"}
                      />
                    </div>
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Requestor Contact Information
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactName}
                      elementId={"entityReqContactName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Dept</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactDept}
                      elementId={"entityReqContactDept"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactPhone}
                      elementId={"entityReqContactPhone"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label"> Fax #</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityReqContactFax}
                      elementId={"entityReqContactFax"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  Accounts Receivable Primary Contact Information
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Position</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label"> E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
              </div>
            </div>
          </div>
          <span className="required-form">C. To be completed by supplier</span>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenBankInfo ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenBankInfo(!isHiddenBankInfo)}
              >
                {isHiddenBankInfo ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">
                  Bank Information (Bank Account notification letter or voided
                  check is required)
                </span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenBankInfo ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name Of Bank
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankName}
                      elementId={"entityBankName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Bank Address
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankAddress}
                      elementId={"entityBankAddress"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>City
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankCity}
                      elementId={"entityBankCity"}
                    />
                  </div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>State/Province
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankStateProvince}
                      elementId={"entityBankStateProvince"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Postal Code
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankPostalCode}
                      elementId={"entityBankPostalCode"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Bank Contact
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankContract}
                      elementId={"entityBankContract"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Contact Phone #
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankContractPhone}
                      elementId={"entityBankContractPhone"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name On the Acct
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityBankNameAcct}
                      elementId={"entityBankNameAcct"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Account No.
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityAccountNo}
                      elementId={"entityAccountNo"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>ABA Routing No.
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityAbaRoutingNo}
                      elementId={"entityAbaRoutingNo"}
                    />
                  </div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      IBAN No. (Only for Europe)
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      SWIFT code (Only for Non-U.S.)
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenTrade ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setHiddenTrade(!isHiddenTrade)}
              >
                {isHiddenTrade ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Trade Reference Information</span>
              </div>
            </div>

            <div
              className="search-wrapper"
              style={isHiddenTrade ? {} : { display: "none" }}
            >
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Trade Reference #1</label>
                  </div>
                  <div className="bx-inp combo inp-mix">Company name:</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">City/State</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Trade Reference #2</label>
                  </div>
                  <div className="bx-inp combo inp-mix">Company name:</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">City/State</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Trade Reference #3</label>
                  </div>
                  <div className="bx-inp combo inp-mix">Company name:</div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">Address</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">City/State</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>

                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Postal Code</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Name</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Contact Phone #</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">E-mail</label>
                  </div>
                  <div className="bx-inp combo inp-mix"></div>
                </div>
                <div className="bx-item column-4column item-only-bg fz-12">
                  I certify that the information provided above is correct.
                </div>
                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Print Name
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerPrintName}
                      elementId={"entityCerPrintName"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Title
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerTitle}
                      elementId={"entityCerTitle"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Phone #
                    </label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerPhone}
                      elementId={"entityCerPhone"}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Signature</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerSignature}
                      elementId={"entityCerSignature"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item column-2column">
                  <div className="div-label">
                    <label className="txt-label">Date</label>
                  </div>
                  <div className="bx-inp combo inp-mix">
                    <ComTextBox
                      ref={entityCerDate}
                      elementId={"entityCerDate"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div className="bx-inp-control open">
                <span className="txt-title">D. LG Accounting ONLY</span>
              </div>
            </div>

            <div className="search-wrapper">
              <div className="bx-search2">
                <div className="bx-item column-4column">
                  <ComTextArea
                    defaultStyle={{
                      resize: "vertical",
                      minHeight: "40px",
                      background: "#fff",
                    }}
                    elementId={"entityComment"}
                    defaultDisabled={true}
                    defaultValue={"Comments:"}
                  ></ComTextArea>
                </div>
              </div>
              <div className="lg-accounting-vendor-code mt-10">
                Vendor Code:
              </div>
            </div>
          </div>
        </div>
        {openModalBillTo ? (
          <BillToSchPop
            open={openModalBillTo}
            closeFn={closeBillToPopup}
            randomKey={randomKey.current}
            reqBillToCode={entityBillToCode?.current?.getValue()}
            reqBillToName={entityBillToName?.current?.getValue()}
            userLevel={userLevel}
            billToResData={billToResData.current}
            pageInfo={pageInfo}
          />
        ) : null}
      </div>
    </wjInput.Popup>
  );
}

export default VendorRegPop;
