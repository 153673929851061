import React, { useState, useEffect } from "react";
import "assets/styles/components/breadcrumb.scss";
import { useDispatch, useSelector } from "react-redux";
import arrowRightSide from "assets/icons/arrow-right-side.svg";
import starOutline from "assets/icons/star-outline.svg";
import starOutlineActive from "assets/icons/star-outline-active.svg";
import {  viewHome } from "reducer/OpenMenus";

function Breadcrumb(pageInfo) {
  const [props, setProps] = useState();
  const [isFavorite, setFavorite] = useState(false);
  const main = ["Home"];
  const dispatch = useDispatch();

  useEffect(() => {
    if (pageInfo?.items?.menuName) {
      setProps(main.concat(pageInfo.items.parentNamePath.split(">")));
    }
  }, [pageInfo]);

  const redirectHome = () => {
    dispatch(viewHome(pageInfo));
  }

  return (
    <div className="main-breadcrumb">
      {props?.map((item, index) => (
        <div className="breadcrumb" key={index}>
          {item === "Home" ? 
            <span
              style={{
                fontSize: index < props.items?.length - 1 ? "12px" : "13px",
                fontWeight: index < props.items?.length - 1 ? "400" : "700",
                color: index < props.length - 1 ? "#79716B" : "#1C1917",
                cursor: index < props.length - 1 ? "pointer" : "",
                textDecoration: "underline 2px"
              }}
              onClick={redirectHome}
            >
              {item}
            </span>
          :
            <span
              style={{
                fontSize: index < props.items?.length - 1 ? "12px" : "13px",
                fontWeight: index < props.items?.length - 1 ? "400" : "700",
                color: index < props.length - 1 ? "#79716B" : "#1C1917",
                cursor: index < props.length - 1 ? "pointer" : "",
              }}
            >
              {item}
            </span>
          }
          {index < props.length - 1 ? (
            <img
              src={arrowRightSide}
              style={{ width: "16px", height: "16px" }}
            />
          ) : (
            ""
          )}
        </div>
      ))}
      <div className="favorite" onClick={() => setFavorite(!isFavorite)}>
        <img
          src={isFavorite ? starOutlineActive : starOutline}
          style={{
            width: "15px",
            height: "15px",
          }}
        />
      </div>
    </div>
  );
}

export default Breadcrumb;
