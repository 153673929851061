import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
// import "assets/styles/pages/PagesCommon.scss";
// import "assets/styles/pages/VendorPrintPop.scss";
// import "assets/css/print.css";
// import "/styles/VendorPrintPop.css";
import { Button } from "@mui/material";

import ComCheckBox from "common/components/ComCheckBox";
import ComTextArea from "common/components/ComTextArea";
import ComTextBox from "common/components/ComTextBox";
import ComRadioBoxNoneCheck from "common/components/ComRadioBoxNoneCheck";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";
import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";

const VendorPrtPop = React.forwardRef(({
  orderData
  , windowPrintFunction
}
  , ref) => {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const msgClmErr011 = ComUtils.getMessage(messageObject, "CLM_ERR_00011"); // An error occurred during processing

  //session
  const userId = sessionStorage.getItem("userId").toString();
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const retrieveVendorInfo = "/clm/oth/vendor/retrieveVendorInfo";

  const entityBillTo = useRef(null);

  // Requestor Information
  const entityReqDate = useRef(null);
  const entityReqDeptName = useRef(null);
  const entityReqDeptCode = useRef(null);
  const entityReqName = useRef(null);
  const entityReqExt = useRef(null);
  const entityReqEmail = useRef(null);

  const entityPurchaseAmtLess = useRef(null);
  const entityPurchaseAmtAbove = useRef(null);
  const entityPurchasePerLess = useRef(null);
  const entityPurchasePerAbove = useRef(null);
  const entitySubsidiary = useRef(null);
  const entityExpenseType = useRef(null);
  const entityActionType = useRef(null);
  const entityPayFrequency = useRef(null);

  const entityDescribe = useRef(null);

  // Vendor Information
  const entityVendorName = useRef(null);
  const entityVendorDbaName = useRef(null);
  const entityVendorAddress = useRef(null);
  const entityVendorCity = useRef(null);
  const entityVendorStateProvince = useRef(null);
  const entityVendorPostalCode = useRef(null);
  const entityVendorTaxId = useRef(null);
  const entityVendorDunBradstreet = useRef(null);
  const entityDumyY = useRef(null);
  const entityDumyN = useRef(null);
  const entityRemAddress = useRef(null);
  const entityRemCity = useRef(null);
  const entityRemStateProvince = useRef(null);
  const entityRemPostalCode = useRef(null);
  const entityPayTerm = useRef(null);
  const entityPayMethod = useRef(null);
  const entityReqContactName = useRef(null);
  const entityReqContactDept = useRef(null);
  const entityReqContactPhone = useRef(null);
  const entityReqContactFax = useRef(null);

  // Bank Information
  const entityBankName = useRef(null);
  const entityBankAddress = useRef(null);
  const entityBankCity = useRef(null);
  const entityBankStateProvince = useRef(null);
  const entityBankPostalCode = useRef(null);
  const entityBankContract = useRef(null);
  const entityBankContractPhone = useRef(null);
  const entityBankNameAcct = useRef(null);
  const entityAccountNo = useRef(null);
  const entityAbaRoutingNo = useRef(null);

  // Trade Reference Information
  const entityCerPrintName = useRef(null);
  const entityCerTitle = useRef(null);
  const entityCerPhone = useRef(null);
  const entityCerSignature = useRef(null);
  const entityCerDate = useRef(null);
  const entityComment = useRef(null);

  const entityVendorCode = useRef(null);

  const filterSearch = useRef();
  const popComponent = useRef();
  const randomKey = useRef(null);


  const [handlePurchaseAmtLess, setHandlePurchaseAmtLess] = useState(false);
  const [handlePurchaseAmtAbove, setHandlePurchaseAmtAbove] = useState(false);

  //popup
  const printAreaElement = useRef(null);

  React.useImperativeHandle(ref, () => (
    {
      getValue: () => printAreaElement.current
    }
  ));

  //Memory
  const _isMounted = useRef(true);

  // radio Disabled
  const radioChangeDisabled = (elementId, val) => {
    if (elementId === "entityPurchaseAmtLess") {
      if (entityPurchaseAmtLess?.current?.getCheckedValues()) { // LESS
        setHandlePurchaseAmtLess(true);
      } else {
        setHandlePurchaseAmtLess(false);
      }
      entityPurchasePerLess.current.setValue("");

    } else if (elementId === "entityPurchaseAmtAbove") {
      if (entityPurchaseAmtAbove?.current?.getCheckedValues()) { // ABOVE
        setHandlePurchaseAmtAbove(true);
      } else {
        setHandlePurchaseAmtAbove(false);
      }
      entityPurchasePerAbove.current.setValue("");
    }
  }

  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {
      // let paramBillToCode = condBillToCode ?? callBillToCode;
      if (orderData) {
        const [resultData] = await Promise.all([
          ApiManager.post(retrieveVendorInfo, { 
            billToCode: orderData.billToCode, 
            vendorCode: orderData.vendorCode,
          }),
        ]);

        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
        } else {
          entityBillTo?.current?.setValue({ code: null });
          entityReqDate?.current?.setValue(null);
          entityReqDeptName?.current?.setValue(null);
          entityReqDeptCode?.current?.setValue(null);
          entityReqName?.current?.setValue(null);
          entityReqExt?.current?.setValue(null);
          entityReqEmail?.current?.setValue(null);

          entityPurchaseAmtLess?.current?.setValue(null);
          entityPurchasePerLess?.current?.setValue(null);
          entityPurchaseAmtAbove?.current?.setValue(null);
          entityPurchasePerAbove?.current?.setValue(null);
          entitySubsidiary?.current?.setValue(null);
          entityExpenseType?.current?.setValue(null);
          entityActionType?.current?.setValue(null);
          entityPayFrequency?.current?.setValue(null);

          entityVendorName?.current?.setValue(null);
          entityVendorDbaName?.current?.setValue(null);
          entityVendorAddress?.current?.setValue(null);
          entityVendorCity?.current?.setValue(null);
          entityVendorStateProvince?.current?.setValue(null);
          entityVendorPostalCode?.current?.setValue(null);
          entityVendorTaxId?.current?.setValue(null);
          entityVendorDunBradstreet?.current?.setValue(null);
          entityRemAddress?.current?.setValue(null);
          entityRemCity?.current?.setValue(null);
          entityRemStateProvince?.current?.setValue(null);
          entityRemPostalCode?.current?.setValue(null);
          entityPayTerm?.current?.setValue(null);
          entityPayMethod?.current?.setValue(null);
          entityReqContactName?.current?.setValue(null);
          entityReqContactDept?.current?.setValue(null);
          entityReqContactPhone?.current?.setValue(null);
          entityReqContactFax?.current?.setValue(null);
          entityBankName?.current?.setValue(null);
          entityBankAddress?.current?.setValue(null);
          entityBankCity?.current?.setValue(null);
          entityBankStateProvince?.current?.setValue(null);
          entityBankPostalCode?.current?.setValue(null);
          entityBankContract?.current?.setValue(null);
          entityBankContractPhone?.current?.setValue(null);
          entityBankNameAcct?.current?.setValue(null);
          entityAccountNo?.current?.setValue(null);
          entityAbaRoutingNo?.current?.setValue(null);
          entityCerPrintName?.current?.setValue(null);
          entityCerTitle?.current?.setValue(null);
          entityCerPhone?.current?.setValue(null);
          entityCerSignature?.current?.setValue(null);
          entityCerDate?.current?.setValue(null);
          entityVendorCode?.current?.setValue(null);

          entityBillTo?.current?.setValue({ code: resultData?.billToCode, text: resultData?.billToName });
          entityReqDate?.current?.setValue(resultData?.requestorDate);
          entityReqDeptName?.current?.setValue(resultData?.requestorDeptName);
          entityReqDeptCode?.current?.setValue(resultData?.requestorDeptCode);
          entityReqName?.current?.setValue(resultData?.requestorName);
          entityReqExt?.current?.setValue(resultData?.requestorExt);

          if (resultData?.purchaseAmt != null && resultData?.purchaseAmt != '') {
            if (resultData?.purchaseAmt === 'LESS') {
              entityPurchaseAmtLess?.current?.setValue(resultData?.purchaseAmt)
              entityPurchasePerLess?.current?.setValue(resultData?.purchasePer)
            } else {
              entityPurchaseAmtAbove?.current?.setValue(resultData?.purchaseAmt);
              entityPurchasePerAbove?.current?.setValue(resultData?.purchasePer);
            }
          }
          entitySubsidiary?.current?.setValue(resultData?.subsidiary);
          entityExpenseType?.current?.setValue(resultData?.expenseType);
          entityActionType?.current?.setValue(resultData?.actionType);
          entityPayFrequency?.current?.setValue(resultData?.paymentFrequency);

          entityVendorName?.current?.setValue(resultData?.vendorName);
          entityVendorDbaName?.current?.setValue(resultData?.vendorDbaName);
          entityVendorAddress?.current?.setValue(resultData?.vendorAddress);
          entityVendorCity?.current?.setValue(resultData?.vendorCity);
          entityVendorStateProvince?.current?.setValue(resultData?.vendorStateProvince);
          entityVendorPostalCode?.current?.setValue(resultData?.vendorPostalCode);
          entityVendorTaxId?.current?.setValue(resultData?.vendorTaxId);
          entityVendorDunBradstreet?.current?.setValue(resultData?.vendorDunBradstreet);
          entityRemAddress?.current?.setValue(resultData?.remittanceAddress);
          entityRemCity?.current?.setValue(resultData?.remittanceCity);
          entityRemStateProvince?.current?.setValue(resultData?.remittanceStateProvince);
          entityRemPostalCode?.current?.setValue(resultData?.remittancePostalCode);

          entityPayTerm?.current?.setValue(resultData?.payTerm);
          entityPayMethod?.current?.setValue(resultData?.payMethod);

          entityReqContactName?.current?.setValue(resultData?.requestorContactName);
          entityReqContactDept?.current?.setValue(resultData?.requestorContactDept);
          entityReqContactPhone?.current?.setValue(resultData?.requestorContactPhone);
          entityReqContactFax?.current?.setValue(resultData?.requestorContactFax);
          entityBankName?.current?.setValue(resultData?.bankName);
          entityBankAddress?.current?.setValue(resultData?.bankAddress);
          entityBankCity?.current?.setValue(resultData?.bankCity);
          entityBankStateProvince?.current?.setValue(resultData?.bankStateProvince);
          entityBankPostalCode?.current?.setValue(resultData?.bankPostalCode);
          entityBankContract?.current?.setValue(resultData?.bankContract);
          entityBankContractPhone?.current?.setValue(resultData?.bankContractPhone);
          entityBankNameAcct?.current?.setValue(resultData?.bankNameAcct);
          entityAccountNo?.current?.setValue(resultData?.accountNo);
          entityAbaRoutingNo?.current?.setValue(resultData?.abaRoutingNo);
          entityCerPrintName?.current?.setValue(resultData?.ceritifyPrintName);
          entityCerTitle?.current?.setValue(resultData?.ceritifyTitle);
          entityCerPhone?.current?.setValue(resultData?.ceritifyPhone);
          entityCerSignature?.current?.setValue(resultData?.ceritifySignature);
          entityCerDate?.current?.setValue(resultData?.ceritifyDate);
          entityVendorCode?.current?.setValue(resultData?.vendorCode);
        }
        if (windowPrintFunction) {
          setTimeout(() => { windowPrintFunction(); }, 1000);
        }
      }
    }

    getPageInfo();

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [orderData]);

  const [isHiddenVDInfo, setHiddenVDInfo] = useState(true);
  const [isHiddenBankInfo, setHiddenBankInfo] = useState(true);
  const [isHiddenTrade, setHiddenTrade] = useState(true);
  const [isHiddenRequestorInfo, setHiddenRequestorInfo] = useState(true);
  return (
    <div id="printAreaElement" ref={printAreaElement} style={{ display: 'none' }}>
      <div className="print-wrapper">
        <div className="title-rg-form">
          LG Electronics Vendor Registration Form
        </div>
        <span className="required-field ml-5">
          A.To be completed by LG Employee
        </span>
        <div className="section-wrapper">
          <div className="section-content">
            <div
              className="table-wrapper"
              style={{ minWidth: "750px", margin: "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <th scope="col">Requestor</th>
                    <th scope="col">Team Leader </th>
                    <th scope="col">Dept. Leader</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="txt-center">Dept.</th>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                  </tr>
                  <tr>
                    <td className="txt-center required-field">
                      * Hand Written Sign{" >> "}
                    </td>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                  </tr>
                  <tr>
                    <th className="txt-center">GP</th>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                  </tr>
                  <tr>
                    <th className="txt-center">ACCOUNTING</th>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                    <td className="txt-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="filter-title">
            <div
              className={
                isHiddenRequestorInfo ? "bx-inp-control open" : "bx-inp-control"
              }
              onClick={() => setHiddenRequestorInfo(!isHiddenRequestorInfo)}
            >
              {isHiddenRequestorInfo ? (
                <UnfoldMoreRoundedIcon />
              ) : (
                <UnfoldLessRoundedIcon />
              )}
              <span className="txt-title">Requestor Information</span>
            </div>
          </div>

          <div
            className="section-content"
            style={isHiddenRequestorInfo ? {} : { display: "none" }}
          >
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Date</label>
                </div>
                <div className="bx-inp combo inp-mix">
                  <ComTextBox
                    ref={entityReqDate}
                    elementId={"entityReqDate"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item ">
                <div className="div-label">
                  <label className="txt-label">Dept. Name</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqDeptName}
                    elementId={"entityReqDeptName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Dept. Code</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqDeptCode}
                    elementId={"entityReqDeptCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>

              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Requestor's Name</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqName}
                    elementId={"entityReqName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Ext #</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqExt}
                    elementId={"entityReqExt"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Email</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqEmail}
                    elementId={"entityReqEmail"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Purchase Amount</label>
                </div>
                <div className="bx-inp">
                  <div className="group-checkbox">
                    <div className="d-flex-print">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "LESS", Description: "Less than $10K (per" },
                        ]}
                        ref={entityPurchaseAmtLess}
                        elementId={"entityPurchaseAmtLess"}
                        radioGroupName={"entityPurchaseAmtLess"}
                        // callRelationFuntion={radioChangeDisabled}
                        // defaultDisabled={handlePurchaseAmtAbove ? true : false}
                        defaultDisabled={true}
                      />
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "ORDER", Description: "Order" },
                          { Value: "YEAR", Description: "Year" },
                        ]}
                        ref={entityPurchasePerLess}
                        elementId={"entityPurchasePerLess"}
                        radioGroupName={"entityPurchasePerLess"}
                        // callRelationFuntion={radioChangeDisabled}
                        // defaultDisabled={handlePurchaseAmtLess ? false : true}
                        defaultDisabled={true}
                      />
                      <label>&#41; *send this form to lge.usaccounting@lge.com</label>
                    </div>
                    <div className="d-flex-print">
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "ABOVE", Description: "$10K and above (per" },
                        ]}
                        ref={entityPurchaseAmtAbove}
                        elementId={"entityPurchaseAmtAbove"}
                        radioGroupName={"entityPurchaseAmtAbove"}
                        // callRelationFuntion={radioChangeDisabled}
                        // defaultDisabled={handlePurchaseAmtLess ? true : false}
                        defaultDisabled={true}
                      />
                      <ComRadioBoxNoneCheck
                        radioList={[
                          { Value: "ORDER", Description: "Order" },
                          { Value: "YEAR", Description: "Year" },
                        ]}
                        ref={entityPurchasePerAbove}
                        elementId={"entityPurchasePerAbove"}
                        radioGroupName={"entityPurchasePerAbove"}
                        // callRelationFuntion={radioChangeDisabled}
                        // defaultDisabled={handlePurchaseAmtAbove ? false : true}
                        defaultDisabled={true}
                      />
                      <label>&#41; *send this form to lge.gp@lge.com</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Subsidiary</label>
                </div>
                <div className="bx-inp">
                  <div className="d-flex-print">
                    <ComRadioBoxNoneCheck
                      radioList={[
                        { Value: "US", Description: "US" },
                        { Value: "MU", Description: "MU" },
                        { Value: "MR", Description: "MR" },
                        { Value: "ZE", Description: "ZE" },
                        { Value: "AI", Description: "AI" },
                      ]}
                      direction={1}
                      ref={entitySubsidiary}
                      elementId={"entitySubsidiary"}
                      radioGroupName={"entitySubsidiary"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Expense Type</label>
                </div>
                <div className="bx-inp">
                  <div className="d-flex-print">
                    <ComRadioBoxNoneCheck
                      radioList={[
                        { Value: "RE", Description: "Regular Expense" },
                        { Value: "FA", Description: "Fixed Asset (EPS)" },
                        { Value: "PO", Description: "Purchase Order" },
                      ]}
                      direction={1}
                      // callRelationFuntion={handleDivision}
                      ref={entityExpenseType}
                      elementId={"entityExpenseType"}
                      radioGroupName={"entityExpenseType"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Action Type</label>
                </div>
                <div className="bx-inp">
                  <div className="d-flex-print">
                    <ComRadioBoxNoneCheck
                      radioList={[
                        { Value: "NEW", Description: "New" },
                        { Value: "CHANGE", Description: "Change" },
                        { Value: "DEACTIVATION", Description: "Deactivation" },
                        { Value: "REACTIVATION", Description: "Reactivation" },
                      ]}
                      direction={1}
                      // callRelationFuntion={handleDivision}
                      ref={entityActionType}
                      elementId={"entityActionType"}
                      radioGroupName={"entityActionType"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Payment Frequency</label>
                </div>
                <div className="bx-inp">
                  <div className="d-flex-print">
                    <ComRadioBoxNoneCheck
                      radioList={[
                        { Value: "FREQUENTLY", Description: "Frequently" },
                        { Value: "ANNUALLY", Description: "Annually" },
                        { Value: "ONETIME", Description: "One Time" },
                      ]}
                      direction={1}
                      // callRelationFuntion={handleDivision}
                      ref={entityPayFrequency}
                      elementId={"entityPayFrequency"}
                      radioGroupName={"entityPayFrequency"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-item item-only-bg">
                Describe in detail the bussiness perpose for which this vendor
                being hired:
              </div>
              <div className="bx-item">
                <ComTextArea
                  ref={entityDescribe}
                  defaultStyle={{
                    resize: "vertical",
                    minHeight: "40px",
                    background: "#fff",
                  }}
                  elementId={"entityDescribe"}
                  defaultDisabled={true}
                ></ComTextArea>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <span className="required-field">B.To be completed by supplier</span>
        </div>
        <div className="section-wrapper">
          <div className="filter-title">
            <div
              className={
                isHiddenVDInfo ? "bx-inp-control open" : "bx-inp-control"
              }
              onClick={() => setHiddenVDInfo(!isHiddenVDInfo)}
            >
              {isHiddenVDInfo ? (
                <UnfoldMoreRoundedIcon />
              ) : (
                <UnfoldLessRoundedIcon />
              )}
              <span className="txt-title">Vendor Information</span>
            </div>
          </div>

          <div
            className="section-content"
            style={isHiddenVDInfo ? {} : { display: "none" }}
          >
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Name
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorName}
                    elementId={"entityVendorName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">DBA Name</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorDbaName}
                    elementId={"entityVendorDbaName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Address
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorAddress}
                    elementId={"entityVendorAddress"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>City
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorCity}
                    elementId={"entityVendorCity"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>State/Province
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorStateProvince}
                    elementId={"entityVendorStateProvince"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Postal Code
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorPostalCode}
                    elementId={"entityVendorPostalCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Tax ID #
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorTaxId}
                    elementId={"entityVendorTaxId"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Dun & Bradstreet #</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorDunBradstreet}
                    elementId={"entityVendorDunBradstreet"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Company Website(URL)</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Certified Minority or Women Owned Company (M/WBE)?
                  </label>
                </div>
                <div
                  className="bx-inp"
                  style={{ height: "60px" }}
                >
                  <div className="d-flex-print">
                    <ComCheckBox
                      ref={entityDumyY}
                      elementId={"entityDumyY"}
                      idCheckbox="entityDumyY"
                      defaultStyle={{ pointerEvents: 'none' }}
                    />
                    <label className="text-checkbox">
                      Yes
                    </label>
                  </div>
                  <div className="d-flex-print">
                    <ComCheckBox
                      ref={entityDumyN}
                      elementId={"entityDumyN"}
                      idCheckbox="entityDumyN"
                      defaultStyle={{ pointerEvents: 'none' }}
                    />
                    <label className="text-checkbox">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="bx-item item-only-bg">
                Remittance Address (If different from above adress)
              </div>

              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Address</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityRemAddress}
                    elementId={"entityRemAddress"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">City</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityRemCity}
                    elementId={"entityRemCity"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">State/Province</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityRemStateProvince}
                    elementId={"entityRemStateProvince"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Postal Code</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityRemPostalCode}
                    elementId={"entityRemPostalCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item item-only-bg">Payment Information</div>
              <div className="bx-item" style={{ height: "60px" }}>
                <div className="div-label">
                  <label className="txt-label">
                    E-mail address for notification of payment / PO
                  </label>
                </div>
                <div className="bx-inp">df</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Pay Term</label>
                </div>
                <div className="bx-inp">
                  <div className="d-flex-print">
                    <ComRadioBoxNoneCheck
                      radioList={[
                        { Value: "0", Description: "0" },
                        { Value: "10", Description: "10" },
                        { Value: "15", Description: "15" },
                        { Value: "20", Description: "20" },
                        { Value: "25", Description: "25" },
                        { Value: "30", Description: "30" },
                        { Value: "45", Description: "45" },
                        { Value: "60", Description: "60" },
                        { Value: "75", Description: "75" },
                        { Value: "90", Description: "90" },
                      ]}
                      direction={1}
                      ref={entityPayTerm}
                      elementId={"entityPayTerm"}
                      radioGroupName={"entityPayTerm"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Pay Method
                  </label>
                </div>
                <div className="bx-inp">
                  <div className="required-field fwb">* Please select one</div>
                  <div className="d-flex-print">
                    <ComRadioBoxNoneCheck
                      radioList={[
                        { Value: "ACH", Description: "ACH(Direct Deposit" },
                        { Value: "WIRE", Description: "Wire(Overseas only)" },
                        { Value: "CHECK", Description: "Check" },
                      ]}
                      direction={1}
                      ref={entityPayMethod}
                      elementId={"entityPayMethod"}
                      radioGroupName={"entityPayMethod"}
                      defaultDisabled={true}

                    />
                  </div>
                </div>
              </div>
              <div className="bx-item item-only-bg">
                Requestor Contact Information
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Name</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqContactName}
                    elementId={"entityReqContactName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Dept</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqContactDept}
                    elementId={"entityReqContactDept"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Phone #</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqContactPhone}
                    elementId={"entityReqContactPhone"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Fax #</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityReqContactFax}
                    elementId={"entityReqContactFax"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item item-only-bg">
                Accounts Receivable Primary Contact Information
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Name</label>
                </div>
                <div className="bx-inp">
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Position</label>
                </div>
                <div className="bx-inp">
                </div>
              </div>
              <div className="bx-item column-2column">
                <div className="div-label">
                  <label className="txt-label">Phone #</label>
                </div>
                <div className="bx-inp">
                </div>
              </div>
              <div className="bx-item column-2column">
                <div className="div-label">
                  <label className="txt-label"> E-mail</label>
                </div>
                <div className="bx-inp">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <span className="required-field">C.To be completed by supplier</span>
        </div>
        <div className="section-wrapper">
          <div className="filter-title">
            <div
              className={
                isHiddenBankInfo ? "bx-inp-control open" : "bx-inp-control"
              }
              onClick={() => setHiddenBankInfo(!isHiddenBankInfo)}
            >
              {isHiddenBankInfo ? (
                <UnfoldMoreRoundedIcon />
              ) : (
                <UnfoldLessRoundedIcon />
              )}
              <span className="txt-title">
                Bank Information (Bank Account notification letter or voided
                check is required)
              </span>
            </div>
          </div>

          <div
            className="section-content"
            style={isHiddenBankInfo ? {} : { display: "none" }}
          >
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Name of Bank
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityBankName}
                    elementId={"entityBankName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Bank Address
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityBankAddress}
                    elementId={"entityBankAddress"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>City
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityBankCity}
                    elementId={"entityBankCity"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-search2 fixed-column2">
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>State/Province
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityBankStateProvince}
                      elementId={"entityBankStateProvince"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Postal Code
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityBankPostalCode}
                      elementId={"entityBankPostalCode"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="bx-search2 fixed-column1">
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Bank Contact
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityBankContract}
                      elementId={"entityBankContract"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Contact Phone #
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityBankContractPhone}
                      elementId={"entityBankContractPhone"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Name on the Acct.
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityBankNameAcct}
                      elementId={"entityBankNameAcct"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>Account No.
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityAccountNo}
                      elementId={"entityAccountNo"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      <span className="requiresimbol"> *</span>ABA Routing No.
                    </label>
                  </div>
                  <div className="bx-inp">
                    <ComTextBox
                      ref={entityAbaRoutingNo}
                      elementId={"entityAbaRoutingNo"}
                      defaultDisabled={true}
                    />
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      IBAN No. (Only for Europe)
                    </label>
                  </div>
                  <div className="bx-inp">
                  </div>
                </div>
                <div className="bx-item">
                  <div className="div-label">
                    <label className="txt-label">
                      SWIFT code (Only for Non-U.S.)
                    </label>
                  </div>
                  <div className="bx-inp">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="filter-title">
            <div
              className={
                isHiddenTrade ? "bx-inp-control open" : "bx-inp-control"
              }
              onClick={() => setHiddenTrade(!isHiddenTrade)}
            >
              {isHiddenTrade ? (
                <UnfoldMoreRoundedIcon />
              ) : (
                <UnfoldLessRoundedIcon />
              )}
              <span className="txt-title">Trade Reference Information</span>
            </div>
          </div>

          <div
            className="section-content"
            style={isHiddenTrade ? {} : { display: "none" }}
          >
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Trade Reference #1</label>
                </div>
                <div className="bx-inp">Company name:</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Address</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">City/State</label>
                </div>
                <div className="bx-inp"></div>
              </div>

              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Postal Code</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Name</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Phone #</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">E-mail</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Trade Reference #2</label>
                </div>
                <div className="bx-inp">Company name:</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Address</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">City/State</label>
                </div>
                <div className="bx-inp"></div>
              </div>

              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Postal Code</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Name</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Phone #</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">E-mail</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Trade Reference #3</label>
                </div>
                <div className="bx-inp">Company name:</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Address</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">City/State</label>
                </div>
                <div className="bx-inp"></div>
              </div>

              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Postal Code</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Name</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Contact Phone #</label>
                </div>
                <div className="bx-inp"></div>
              </div>
            </div>
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">E-mail</label>
                </div>
                <div className="bx-inp"></div>
              </div>
              <div className="bx-item item-only-bg">
                I certify that the information provided above is correct.
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Print Name
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityCerPrintName}
                    elementId={"entityCerPrintName"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="bx-search2 fixed-column2">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Title
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityCerTitle}
                    elementId={"entityCerTitle"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Phone #
                  </label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityCerPhone}
                    elementId={"entityCerPhone"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Signature</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityCerSignature}
                    elementId={"entityCerSignature"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Date</label>
                </div>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityCerDate}
                    elementId={"entityCerDate"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <div className="filter-title">
            <div className="bx-inp-control open">
              <span className="txt-title">D. LG Accounting ONLY</span>
            </div>
          </div>

          <div className="section-content">
            <div className="bx-search2 fixed-column1">
              <div className="bx-item">
                <ComTextArea
                  defaultStyle={{
                    resize: "vertical",
                    minHeight: "40px",
                    background: "#fff",
                  }}
                  elementId={"entityComment"}
                  defaultDisabled={true}
                  defaultValue={"Comments:"}
                ></ComTextArea>
              </div>
            </div>
            <div className="vendor-code">
              <div className="lg-accounting-vendor-code">
                <label className="txt-label">Vendor Code:</label>
                <div className="bx-inp">
                  <ComTextBox
                    ref={entityVendorCode}
                    elementId={"entityVendorCode"}
                    defaultDisabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default VendorPrtPop;
