import { useContext, useEffect, useRef, useState } from 'react';


import { ApiManager } from 'common/lib/ApiUtil';
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";

import { ComUtils } from "common/lib/ComUtils";
import { ComNoticfication } from 'common/components/ComNoticfication';
import { MessageContext } from "common/lib/MessageProvider";
import Divider from "@mui/material/Divider";
import ComTextArea from "common/components/ComTextArea";

import * as wjInput from "@grapecity/wijmo.react.input";


function SerialRegPop({ open, closeFn, pageInfo, randomKey, reqQty, reqProgramId, reqSerialNo, reqResult, reqMsg, reQstrScreen, reqRow, reqClaimId }) {

  //session정보
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const retrieveNewValidSerial = "/clm/isf/spiff/retrieveNewValidSerial";

  const entitySerialText = useRef(null);

  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);

  const { messageObject } = useContext(MessageContext);

  //Memory
  const _isMounted = useRef(true);


  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => {

    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);



  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null, null);
  };

  const onValidate = (e) => {
    let strText = entitySerialText?.current?.getValue()?.trim();
    strText = strText.replace(/[\{\}\[\]\/?.;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi, "");
    let strText2 = "";
    let arrText2 = strText.split('\n');
    for (let n = 0; n < arrText2.length; n++) {
      strText2 = strText2 + "," + arrText2[n];
    }
    strText2 = strText2.substring(1);
    var arrText = strText2.split(",");

    strText2 = "";
    for (let n = 0; n < arrText.length; n++) {
      if (arrText[n] != "") {
        strText2 = strText2 + "," + arrText[n];
      }
    }

    strText2 = strText2.substring(1);
    arrText = strText2.split(",");

    var qty = reqQty;
    let chkFlag = true;
    if (strText == null || strText == "") {
      if (reQstrScreen === "SpiffCM" || reQstrScreen === "SpiffCM2" || reQstrScreen === "SpiffRSAlaimsExtension" || reQstrScreen === "SpiffUM" || reQstrScreen === "SpiffCNClaimsExtension" || reQstrScreen === "SpiffConfRM") {
        chkFlag = false;
        // Do you want to delete serial No?
        ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "CLM_CNF_00027"), onDeleteExcute);

      }
    }

    if (chkFlag) {
      if (qty != arrText.length) {

        if (reQstrScreen === "SpiffCM" || reQstrScreen === "SpiffCM2" || reQstrScreen === "SpiffRSAlaimsExtension" || reQstrScreen === "SpiffUM" || reQstrScreen === "SpiffCNClaimsExtension") {
          // Serial Number count dose not match claimed qty. Would you like to adjust the claimed qty?
          ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "CLM_CNF_00028"), onNewValidSerialExcute, { "arrText": arrText, "strText2": strText2 });

        } else {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            ComUtils.getMessage(messageObject, "CLM_INF_00009") // Serial Number count dose not match claimed qty.
          );
          return;
        }
      } else {
        onNewValidSerialExcute(true, { "arrText": arrText, "strText2": strText2 });
      }
    }
  }

  const onDeleteExcute = (r) => {
    if (r) {
      popComponent.current.hide();
      setPopuView(false);
      closeFn(null, reqRow);
    }
  }

  const onNewValidSerialExcute = async (r, p) => {
    if (r) {

      let strSerialNo = "";
      let chkFlag = true;
      for (let i = 0; i < p.arrText.length; i++) {
        strSerialNo = p.arrText[i];

        if (isDuplicate(p.arrText, strSerialNo) > 0) {
          // The entered serial nos are duplicated :
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00132", [strSerialNo]));
          chkFlag = false;
          break;
        }

      }

      if (chkFlag) {
        //rsaSpiffReq - payMethod=2
        let resultData = await ApiManager.post(retrieveNewValidSerial, { "strFirstFlag": "N", "strSerialNo": p.strText2, "payMethod": reQstrScreen === "SpiffCM2" ? '2' : '1', "programId": reqProgramId, "claimId": reqClaimId, "entryNo": "", "claimType": reQstrScreen })

        if (resultData?.statusCode) {
          // An error occurred during processing
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00011"));
        } else {

          let msg = "";
          if (resultData?.errorFlag === "Y") {
            for (let inx = 0; inx < resultData?.serialList?.length; inx++) {
              if (resultData.serialList[inx].result === "2") {
                // Duplicate [" + resultData.serialList[inx].serialNo + "]. Previous claimed on " + resultData.serialList[inx].claimId + "\r\n"
                msg = msg + ComUtils.getMessage(messageObject, "CLM_ERR_00133", [resultData.serialList[inx].serialNo, resultData.serialList[inx].claimId]);
              }
            }
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msg);

          } else {
            popComponent.current.hide();
            setPopuView(false);
            closeFn(resultData, reqRow);
          }

        }

      }

    }
  }

  const isDuplicate = (arrObj, strSelVal) => {
    let iResult = 0; // no duplication :0, duplicate :1
    let checkCnt = 0;
    let strVal;
    for (let k = 0; k < arrObj.length; k++) {
      strVal = arrObj[k].toString();
      if (strVal == strSelVal) {
        checkCnt++;
      }

    }

    if (checkCnt > 1) {
      iResult = 1;
    }

    return iResult;

  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null, null);
    });
  }


  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (

    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{ minHeight: "500px", minWidth: "550px" }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{(reQstrScreen === "MIR" ? "MIR" : reQstrScreen === "SpiffCM2" ? "RSA" : "SPIFF") + " Serial Number"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        Enter one serial number per line or separate by comma.

        <div className="bx-table mt-10">
          <div className="bx-inp">
            <ComTextArea
              defaultStyle={{
                resize: "vertical",
                width: "100%",
                minHeight: "490px",
                height: "100%"
              }}

              ref={entitySerialText}
              defaultValue={reqSerialNo}
              elementId={"entitySerialText}"}

            />
          </div>
          <Button variant="contained" onClick={(e) => { onValidate(e) }}>{"Apply"}</Button>
        </div>
      </div>
    </wjInput.Popup >
  );
}

export default SerialRegPop;
