import { ClmWindowStyle } from "../Rsf/ClmWindowStyle";

function IrConfScmDtlList({ pageInfo,
  claimId,
  billToName,
  customerInfo,
  claimList,
  appvlList,
  selApprovalStatus,
  fileList,
  scmTotalDebitAmt,
  scmTotalClaimAmt,
  scmAccumClaimAmt,
  scmAccumClaimOverAmt,
  scmTotalClaimOverQty,
  userId,
  empNo,
  serverUrl
}) {


  return (
    <div style={ClmWindowStyle.scmPrintWrapper}>
      <div style={ClmWindowStyle.scmHeaderButtonWrapper}>
        <button id="approveButton" name="approveButton" style={{ ...ClmWindowStyle.scmHeaderButton, backgroundColor: "white", display: selApprovalStatus === "TO-DO" ? '' : 'none', cursor: "pointer" }}>Approve</button>
        <button id="rejectButton" name="rejectButton" style={{ ...ClmWindowStyle.scmHeaderButton, backgroundColor: "#a50034", display: selApprovalStatus === "TO-DO" ? '' : 'none', cursor: "pointer" }}>Reject</button>
      </div>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmFilterTitle}>

          <div>
            <span style={ClmWindowStyle.scmTxtTitle}>
              Customer Information
            </span>
          </div>

          <div style={{
            ...ClmWindowStyle.scmActionHeader,
            display: customerInfo.claimEntryType != "WEEKLY" ?
              ((customerInfo.postAuditCustomer === "Y" || customerInfo.postAuditClaim === "Y") ? 'flex' : 'none') : 'none'
          }}>
            <div>
              <span style={ClmWindowStyle.scmFieldRequired}>*</span>This is Post Audit Claim
            </div>
            <label>
              <input
                type="radio"
                name="postAuditClaim"
                value="Y"
                checked={customerInfo.postAuditClaim === 'Y'}
              />
              Yes
            </label>

            <label>
              <input
                type="radio"
                name="postAuditClaim"
                value="N"
                checked={customerInfo.postAuditClaim === 'N'}
              />
              No
            </label>

            <div style={ClmWindowStyle.scmCustomDate}>
              <span style={ClmWindowStyle.scmFieldRequired}>*</span>Activity Date
              <div>
                <input type='text' name='activityDateFrom' id="activityDateFrom" value="" />
                ~ &nbsp;
                <input type='text' name='activityDateTo' id="activityDateTo" value="" />
              </div>

            </div>
          </div>
        </div>
        <div style={{ marginTop: "5px" }}>
          <div style={ClmWindowStyle.scmBxSearch2FixedColumn3}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Claim Number </label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {claimId}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Bill To Code </label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.billTo}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Bill To Name</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {billToName}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Promotion No</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.promotionNo}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Promotion ID</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.programId}
              </div>
            </div>

            <div style={{ ...ClmWindowStyle.scmBxItem, display: customerInfo.claimEntryType != "WEEKLY" ? '' : 'none' }}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Debit Memo</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.debitMemo}
              </div>
            </div>
            <div style={{ ...ClmWindowStyle.scmBxItem, display: customerInfo.claimEntryType != "WEEKLY" ? 'none' : '' }}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Claim Period</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                {customerInfo.claimPeriod}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}>Total Debit Amt.</label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                $ {scmTotalDebitAmt}
              </div>
            </div>
          </div>
          <div style={{ ...ClmWindowStyle.scmBxSearch2FixedColumn1, display: customerInfo.claimEntryType != "WEEKLY" ? 'grid' : 'none' }}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Remark </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, paddingRight: "0px" }}>
                <textarea
                  style={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    width: "100%",
                  }}
                  placeholder={"Please input remark ..."}
                  value={customerInfo.comment}
                  id="scmComment"

                />
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> LG Claim Remark </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, paddingRight: "0px" }}>
                <textarea
                  style={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    width: "100%",
                  }}
                  placeholder={"Please input remark ..."}
                  value={customerInfo.lgComment}
                  id="scmLgComment"
                />
              </div>
            </div>
          </div>

          <div style={{ ...ClmWindowStyle.scmBxSearch2FixedColumn1, display: selApprovalStatus === "TO-DO" ? '' : 'none' }}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Approval / Rejection<br />Comment </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, paddingRight: "0px" }}>
                <textarea
                  style={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    width: "100%",
                  }}
                  placeholder={"Please input remark ..."}
                  value={customerInfo.review}
                  id="scmApproveComment"
                />
                <input type="hidden" name="scmUserId" id="scmUserId" value={userId} />
                <input type="hidden" name="scmClaimId" id="scmClaimId" value={claimId} />
                <input type="hidden" name="scmEmpNo" id="scmEmpNo" value={empNo} />
                <input type="hidden" name="serverUrl" id="serverUrl" value={serverUrl} />
                <input type="hidden" name="scmCreateId" id="scmCreateId" value={customerInfo.createId} />
                <input type="hidden" name="scmClaimEntryType" id="scmClaimEntryType" value={customerInfo.claimEntryType} />
              </div>
            </div>
          </div>
          <div style={ClmWindowStyle.scmBxSearch2FixedColumn4}>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Current Claim Amount </label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                $ {scmTotalClaimAmt}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Accum. Claim Amount </label>
              </div>
              <div style={ClmWindowStyle.scmBxInput}>
                $ {scmAccumClaimAmt}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel}> Accum. Claim<br />Over Amount </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, color: 'red', fontWeight: 'bold' }}>
                $ {scmAccumClaimOverAmt}
              </div>
            </div>
            <div style={ClmWindowStyle.scmBxItem}>
              <div style={ClmWindowStyle.scmDivLabel}>
                <label style={ClmWindowStyle.scmTxtLabel} > Accum. Claim<br />Over Qty. </label>
              </div>
              <div style={{ ...ClmWindowStyle.scmBxInput, color: 'red', fontWeight: 'bold' }}>
                {scmTotalClaimOverQty}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmHeaderTable}>
          <span style={ClmWindowStyle.scmSectionTitle}>
            <h3 style={ClmWindowStyle.scmH3Style}>{"IR Claim Detail List"}</h3>
          </span>
        </div>

        <div style={{ marginTop: "5px" }}>
          <div style={{ marginTop: "10px" }}>
            <table style={ClmWindowStyle.scmTableStyle}>
              <thead>
                <tr>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Program Period</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Model</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">LG Unit Reimbursement</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Current Claim<br />Qty.</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Accum. Claim<br />Qty.</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Sell Thru<br />Qty. ({customerInfo.sellOutCreateDate})</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Diff. b/w Claim vs<br />GDMI (QTY)</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Sell Thru<br />Qty.(OLAP)</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Sell Thru<br />Qty.(GDMI)</th>
                </tr>
              </thead>
              <tbody>
                {
                  claimList?.map((fn, index) => (
                    <tr key={index}>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }} >{fn.period}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "left" }} >{fn.model}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} >$ {(fn.reimbursementAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} >{(fn.arConfQty ?? 0).toLocaleString()}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.netScmSellThruClaim ?? 0).toLocaleString()}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}> {(fn.netScmSellThru ?? 0).toLocaleString()}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{((fn.netScmSellThruClaim - fn.netScmSellThru) ?? 0).toLocaleString()}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.netscmsellthru2 ?? 0).toLocaleString()}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{(fn.netscmsellthru3 ?? 0).toLocaleString()}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }} colSpan="3">Total</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + claim.arConfQty, 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + claim.netScmSellThruClaim, 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + claim.netScmSellThru, 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + (claim.netScmSellThruClaim - claim.netScmSellThru), 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + claim.netscmsellthru2, 0).toLocaleString()}</td>
                  <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "right" }}>{claimList.reduce((sum, claim) => sum + claim.netscmsellthru3, 0).toLocaleString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmHeaderTable}>
          <span style={ClmWindowStyle.scmSectionTitle}>
            <h3 style={ClmWindowStyle.scmH3Style}>{"Approval Line"}</h3>
          </span>
        </div>

        <div style={{ marginTop: "5px" }}>
          <div style={{ marginTop: "10px" }}>
            <table style={ClmWindowStyle.scmTableStyle}>
              <thead>
                <tr>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Seq.</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Approval</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">E-Mail</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Status</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Approval Date</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">Comments</th>
                </tr>
              </thead>
              <tbody>
                {
                  appvlList?.map((fn, index) => (
                    <tr key={index}>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{fn.flowSeq}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{fn.personName}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{fn.email}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{fn.status}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{fn.apprDate}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{fn.comments}</td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={ClmWindowStyle.scmSectionWrapper}>
        <div style={ClmWindowStyle.scmHeaderTable}>
          <span style={ClmWindowStyle.scmSectionTitle}>
            <h3 style={ClmWindowStyle.scmH3Style}>{"Attachment"}</h3>
          </span>
        </div>

        <div style={{ marginTop: "5px" }}>
          <div style={{ marginTop: "10px" }}>
            <table style={ClmWindowStyle.scmTableStyle}>
              <thead>
                <tr>

                  <th scope="col" style={{ ...ClmWindowStyle.scmThStyle, width: "100px" }}>No.</th>
                  <th style={ClmWindowStyle.scmThStyle} scope="col">fileName</th>
                </tr>
              </thead>
              <tbody>
                {
                  fileList?.map((fn, index) => (
                    <tr key={index}>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "center" }}>{index + 1}</td>
                      <td style={{ ...ClmWindowStyle.scmTdStyle, textAlign: "left" }}>{fn.originalFileName}</td>

                    </tr>
                  ))
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IrConfScmDtlList;
