import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
//sharepoint_cns 추가
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Button, Divider } from "@mui/material";
import imgExternal from "assets/icons/spiff-user-icon.svg";
import imgSpiff from "assets/icons/spiff-people-icon.svg";
import "assets/styles/pages/SignUpPage.scss";
import Breadcrumb from "common/components/ComBreadcrumb";

function TermsAndConditionsPage({ openSignUpExternal }) {
  const isCheckedAgree = useRef(null);
  const isAgree = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledPolicy, setIsDisabledPolicy] = useState(true);

  const navigate = useNavigate();

  const onCheckedAgreeClick = (e) => {
    isAgree.current = e.target.checked;
    if (isAgree.current) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  const onCheckedAgreePolicyClick = (e) => {
    if (e.target.checked) {
      setIsDisabledPolicy(false);
    } else {
      setIsDisabledPolicy(true);
    }
  };

  const breadCrumbs = [
    {
      text: "Login",
      link: "#",
    },
    {
      text: "Terms and Conditions",
      link: "#",
    },
  ];

  return (
    <div className="container-signup">
      {/* <Breadcrumb items={breadCrumbs} /> */}
      <div className="user-type-card">
        <Button className="card active">
          <img src={imgSpiff} />
          <p>RSA User</p>
        </Button>
        <Button
          className="card default"
          onClick={openSignUpExternal}
        >
          <img src={imgExternal} />
          <p>External User</p>
        </Button>
        {/* <Button
          className="card default btn-external-user-pc"
          onClick={openSignUpExternal}
        >
          <img src={imgExternal} />
          <p>External User</p>
        </Button> */}
        {/* <Button className="card inactive btn-external-user-mb">
          <img src={imgExternal} />
          <p>External User</p>
        </Button> */}
      </div>
      <div className="body-text">
        <div className="boder-text">
          <div className="ct-tx-spiffuser">
            <span className="title-spiff">
              Salespeople Incentive Fund (SPIFF) Membership Agreement
            </span>
            <div>
              <span className="sub-title-spiff">
                By Registering for this website , you agree to abide by the
                terms and conditions of this site and the LG Achievement
                program.
              </span>
              <p>
                1. This promotion is only available to authorized dealer salespeople 
                selling to end-users. Employees of LG Electronics may not
                participate in this program.
              </p>
              <p>
                2. LG Electronics in not responsible for late, lost or
                misdirected mail. LG Electronics reserves the right to reject
                submissions deemed to be in violation of these terms and
                conditions or any incomplete, illegal submissions or mutilated
                or damaged forms.
              </p>
              <p>
                3. Any attempt by an individual to falsify information or to
                defraud LG Electronics regarding any aspect of the program
                guidelines will result in that individual being terminated from
                the program.
              </p>
              <p>4. End user information is for audit purpose only.</p>
              <p>
                5. If you change your name, address or your social security
                number, you must update your profile. Failure to do so may
                result in incorrect information being sent to IRS.
              </p>
            </div>
            <div>
              <span className="sub-title-spiff">Rules and Regulations</span>
              <p>
                1. Qualifying retailers who sell qualifying products to end
                users are eligible.
              </p>
              <p>
                2. If a legible sales receipt is not received with the claim,
                you will be notified and the claim will remain in pending status
                for up to 30 days waiting for the proper back up (receipt).
              </p>
              <p>3. Payment may not be issued for the following reasons.</p>
              <p>- Incomplete claim form.</p>
              <p>- Illegible claim form</p>
              <p>
                - Missing copy of sales receipt(s) for qualifying LG brand
                products(s) sold
              </p>
              <p>- Missing social security number</p>
              <p>- Invalid sale date</p>
              <p>- Invalid model(s) claimed</p>
              <p>- Invoice number on sales receipt does not match claim</p>
              <p>- Claim is submitted 90 days after last sale date</p>
              <p>
                - Participant will be inactivated if there is no activity for 90
                days. Particiapnts will need to re-register after 90 days of
                inactivity.
              </p>
            </div>
            <div className="ct-checkbox-spiffuser">
              <div className="check-condition">
                <input
                  name="comCheckBox"
                  type="checkbox"
                  ref={isCheckedAgree}
                  onClick={onCheckedAgreeClick}
                  class
                  id="conditionCheckbox"
                ></input>
                <label for="conditionCheckbox">
                  I have read and agree to the
                  <span style={{"text-decoration":"none"}}>LG SPIFF Membership Agreement</span>
                </label>
              </div>
            </div>
            <div className="ct-checkbox-spiffuser">
              <div className="check-condition">
                <input
                  name="comCheckBox"
                  type="checkbox"
                  onClick={onCheckedAgreePolicyClick}
                  id="conditionCheckboxPolicy"
                ></input>
                <label for="conditionCheckboxPolicy">
                  {`I have read and agree to the`}
                  <span style={{"text-decoration":"none"}}>LG PRIVACY POLICY.</span>
                </label>
              </div>
            </div>
          </div>
          <div className="btn-footer">
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={() => navigate("/login", {})}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/sign-up-spiff", {})}
              disabled={isDisabled || isDisabledPolicy}
            >
              {"Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TermsAndConditionsPage;
