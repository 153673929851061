import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import CloseIcon from '@mui/icons-material/Close';
import ComTextBox from 'common/components/ComTextBox';
import ComComboMultiAll from 'common/components/ComComboMultiAll';
import ComDate from 'common/components/ComDate';
import Divider from "@mui/material/Divider";

import * as wijmo from "@grapecity/wijmo";
import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';

function LgProgramSchPop({ open, closeFn, randomKey, reqLgPgmNo, reqLgPgmName, reqModelCode, reqBillTo, reqClaimType, reqRequestAmt, reqType, resData, pageInfo }) {
  const comCode = sessionStorage.getItem("comCode").toString();

  //url
  const retrieveProgramList = "/com/comPop/retrieveProgramList";
  const retrieveReasonCodeList = "/com/comCode/retrieveReasonCodeList";
  const retrieveProgramBalanceGerp = "/com/comPop/retrieveProgramBalanceGerp";

  const currentPgmNo = useRef(null);
  const [reasonCodeList, setReasonCodeList] = useState([]);

  const entityProgramNo = useRef(null);
  const entityStartDate = useRef(null);
  const entityEndDate = useRef(null);
  const entityProgramName = useRef(null);
  const entityReasonCode = useRef(null);
  const entityAccrualAmt = useRef(null);
  const entityAdjustAmt = useRef(null);
  const entityPaidAmt = useRef(null);
  const entityInProcessingAmt = useRef(null);
  const entityBalance = useRef(null);

  const filterSearch = useRef(null);

  const gridRef = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const popComponent = useRef();

  const convertUpperCase = (el, value) => {
    if (value && el.current) {
      el.current.setValue(value.toUpperCase());
    }
  };

  const getProgramBalanceGerp = async (lgPgmNo) => {
    currentPgmNo.current = lgPgmNo;

    let resultData = await ApiManager.post(retrieveProgramBalanceGerp, {
      claimType: reqClaimType,
      billTo: reqBillTo,
      lgPgmNo: lgPgmNo
    });

    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "An error occurred during processing");
      return false;
    }

    if (resultData?.length == 0) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please check the condition..");
      return false;
    }

    let res = resultData[0].balanceInfo.split("|");
    let errCode = res[0];
    let errMsg = res[1];

    if (errCode === "E") {
      entityAccrualAmt.current.setValue("");
      entityAdjustAmt.current.setValue("");
      entityPaidAmt.current.setValue("");
      entityInProcessingAmt.current.setValue("");
      entityBalance.current.setValue("");
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, errMsg);

      return false;
    } else if (errCode == "S") {
      var promotionNo = res[2];
      var offerName = res[3];
      var accrualAmt = wijmo.Globalize.formatNumber(parseFloat(res[4]), "n2");
      var adjustAmt = wijmo.Globalize.formatNumber(parseFloat(res[5]), "n2");
      var paidAmt = wijmo.Globalize.formatNumber(parseFloat(res[6]), "n2");
      var processingAmt = wijmo.Globalize.formatNumber(parseFloat(res[10]), "n2");
      var balanceAmt = wijmo.Globalize.formatNumber(parseFloat(res[7]), "n2");

      entityAccrualAmt.current.setValue(accrualAmt);
      entityAdjustAmt.current.setValue(adjustAmt);
      entityPaidAmt.current.setValue(paidAmt);
      entityInProcessingAmt.current.setValue(processingAmt);
      entityBalance.current.setValue(balanceAmt);

      return true;
    } else {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Please check the condition..");

      return false;
    }
  }

  /***** Grid init *************************************************************************************************/
  const initFilter = (f) => { filterSearch.current = f; }

  const initGrid = (sender) => {

    setGridObject(sender);
    gridRef.current = sender;

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, {
      hasShowNumbering: true,
      selectionMode: 6,
      canAreaInfo: false,
      // hasCheckBox: false,
      // showCheckAll: false,
      // hasDisableCheck: false,
    });


    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
        onClickApply();
      }
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });

  }

  const onSelectionChanged = (sender) => {

  }

  const onSourceChanged = () => {
    if (gridObject) {
      gridObject.select(-1, -1);
    }
  }
  /*******Grid Format *********************************************************************************/
  //Memory
  const _isMounted = useRef(true);

  // Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: 'offerCode', header: "Program No.", width: 140, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'offerName', header: "Program Name", width: 220, allowDragging: true, isReadOnly: true }
      , { binding: 'promotionNo', header: "LG Promotion#", width: 180, allowDragging: true, isReadOnly: true }
      , { binding: 'lgPgmDesc', header: "Program Description", width: 320, allowDragging: true, isReadOnly: true }
      , { binding: 'startDateActive', header: "Start Date", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'endDateActive', header: "End Date", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'salesProgramReasonCode', header: "Reason Code", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'locDescription', header: "Reason Name", width: 160, allowDragging: true, isReadOnly: true }
      , { binding: 'discountCategoryCode', header: "Type", width: 80, allowDragging: true, isReadOnly: true, align: "center" }
    ];
    setGridColGroup([...gridFormat]);
  }, []);

  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();
    SearchGridFormat();

    const getPageInfo = async () => {
      let resReason = await ApiManager.post(retrieveReasonCodeList, { class: "040", claimType: reqClaimType });
      entityReasonCode.current.setList({ data: resReason });

      //조회된 내역존재 (다건)
      if (resData) {
        setGridData(new CollectionView(resData.list, { trackChanges: true, refreshOnEdit: false }));

        entityPageNo.current = 1;
        let pageNoVal = entityPageNo.current;
        let sendParam = {
          comCode: comCode
          , paramBillTo: reqBillTo
          , modelCode: reqModelCode
          , claimType: reqClaimType
          , lgPgmNo: reqLgPgmNo
          , lgPgmName: reqLgPgmName
          , reasonCode: []
          , startDate: entityStartDate.current.getOriginalValue() ? wijmo.Globalize.format(entityStartDate.current.getOriginalValue(), "MM/dd/yyyy") : '01/01/2000'
          , endDate: entityEndDate.current.getOriginalValue() ? wijmo.Globalize.format(entityEndDate.current.getOriginalValue(), "MM/dd/yyyy") : '12/31/2099'
          , pageSize: '1'
          , rowSize: pageInfo.pageRowSize
          , pageNo: pageNoVal.toString()
        }
        searchDataRef.current = sendParam;

        totCntRef.current = Number(resData.totCnt);
        pageCntRef.current = Number(resData.list.length);
      }
    }

    if (open) {
      filterSearch?.current?.clear();
    }
    getPageInfo();

    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  const onSelectedList = (elementId, checkedItems) => {
    let itemArr = checkedItems.filter(el => el.cd !== "ALL").map(el => el.nm);

    if (elementId === 'entityReasonCode') {
      setReasonCodeList(itemArr);
    }
  }

  const executeApply = () => {
    let selectedItem = gridRef.current?.collectionView?.currentItem;
    let accrualAmt = entityAccrualAmt.current?.getValue() || 0;
    let paidAmt = entityPaidAmt.current?.getValue() || 0;
    let processingAmt = entityInProcessingAmt.current?.getValue() || 0;
    let balanceAmt = entityBalance.current?.getValue() || 0;

    selectedItem.pgmBalance = balanceAmt ? Number(balanceAmt?.replaceAll(',', '')) : 0;

    if (reqRequestAmt > balanceAmt) {

      let lAccrualAmt = Number(accrualAmt)?.toLocaleString();
      let lPaidAmt = Number(paidAmt)?.toLocaleString();
      let lProcessingAmt = Number(processingAmt)?.toLocaleString();
      let lBalanceAmt = Number(balanceAmt)?.toLocaleString();

      ComNoticfication.ShowAlertUI("Info", "Request Amount should not be greater than Balance.\n\nAccrual Amt:" + lAccrualAmt + ", Paid Amt:" + lPaidAmt + ", In-Processing Amt:" + lProcessingAmt + ", Balance Amt:" + lBalanceAmt);
    }

    popComponent.current.hide();
    closeFn(selectedItem);
  }

  const onClickApply = async () => {
    let selectedItem = gridRef.current?.collectionView?.currentItem;

    if (!selectedItem) {
      ComNoticfication.ShowErrorUI("Info", "Please select a LG Program#.");
      return;
    }

    if (reqType === "MR" || reqType === "AG") {
      popComponent.current.hide();
      closeFn(selectedItem);
    } else {
      if (currentPgmNo.current != selectedItem.offerCode) {
        let ret = await getProgramBalanceGerp(selectedItem.offerCode);
        if (ret) {
          executeApply();
        }
      } else {
        executeApply();
      }
    }

  }

  const onClickBalance = () => {
    let selectedItem = gridRef.current?.collectionView?.currentItem;

    if (!selectedItem) {
      ComNoticfication.ShowErrorUI("Info", "Please select a LG Program#.");
      return;
    }

    getProgramBalanceGerp(selectedItem.offerCode);
  }

  // 조회
  const onSearch = (param, e) => {
    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {
      comCode: comCode
      , paramBillTo: reqBillTo
      , modelCode: reqModelCode
      , claimType: reqClaimType
      , lgPgmNo: entityProgramNo.current.getValue() || ''
      , lgPgmName: entityProgramName.current.getValue() || ''
      , reasonCode: reasonCodeList
      , startDate: entityStartDate.current.getOriginalValue() ? wijmo.Globalize.format(entityStartDate.current.getOriginalValue(), "MM/dd/yyyy") : '01/01/2000'
      , endDate: entityEndDate.current.getOriginalValue() ? wijmo.Globalize.format(entityEndDate.current.getOriginalValue(), "MM/dd/yyyy") : '12/31/2099'
      , pageSize: '1'
      , rowSize: pageInfo.pageRowSize
      , pageNo: pageNoVal.toString()
    }
    searchDataRef.current = sendParam
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {
    let row_index = gridRef.current.selection.row
    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveProgramList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveProgramList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI("Error", "코드 조회 중 에러가 발생했습니다.");
        setGridDataCount(0);
      } else {
        let resultCnt = 0;
        if (param.searchFlag) {

          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;

        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const closePopup = () => {
    popComponent.current.hide();
    closeFn(null);
  }

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn && closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title"> {`LG Program Search`}</h2>
        <Button color="secondary" className="btn-close" tabIndex={-1} onClick={closePopup}><CloseIcon /></Button>
      </div>

      <div className="content-modal">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Program No
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityProgramNo}
                elementId={entityProgramNo}
                callRelationFuntion={(id, value) => convertUpperCase(id, value)}
                defaultValue={reqLgPgmNo}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Start Date
              </label>
            </div>
            <div className="bx-inp combo">
              <ComDate
                ref={entityStartDate}
                elementId={"entityStartDate"}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
                isNullable={true}
                defaultValue={"01/01/2017"}
              />
              ~
              <ComDate
                ref={entityEndDate}
                elementId={"entityEndDate"}
                controlDateFormat={"MM/dd/yyyy"}
                controlDateMask={"##/##/####"}
                controlDateValidFormat={"__/__/____"}
                isNullable={true}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Program Name
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityProgramName}
                elementId={entityProgramName}
                callRelationFuntion={(id, value) => convertUpperCase(id, value)}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Reason Code
              </label>
            </div>
            <div className="bx-inp combo">
              <ComComboMultiAll
                ref={entityReasonCode}
                elementId={"entityReasonCode"}
                isShowAllCheck="Y"
                callRelationFuntion={onSelectedList}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Accrual Amt
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityAccrualAmt}
                elementId={"entityAccrualAmt"}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Adjust Amt
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityAdjustAmt}
                elementId={"entityAdjustAmt"}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Paid Amt
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityPaidAmt}
                elementId={"entityPaidAmt"}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label" style={{ color: "blue" }}>
                In-Processing Amt
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityInProcessingAmt}
                elementId={"entityInProcessingAmt"}
              />
            </div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">
                Balance
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityBalance}
                elementId={"entityBalance"}
              />
              <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
              <Button variant="contained" onClick={(e) => onClickApply()}>{"Apply"}</Button>
              <Button variant="contained" onClick={(e) => onClickBalance()}>{"Balance"}</Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  )
}

export default LgProgramSchPop;