import * as wijmo from "@grapecity/wijmo";
import { InputNumber, InputDate } from "@grapecity/wijmo.input";
import { createElement, format } from "@grapecity/wijmo";
import { BaseClaim } from "./BaseClaim";
import search from "assets/icons/search.svg";

class ClaimADV extends BaseClaim {
  constructor(props) {
    super(props);    
  }

  getGirdFormat = () => {
    let out = [
      { 
        binding: 'isChecked2', 
        header: " ", 
        width: 30, 
        allowDragging: false, 
        isReadOnly: false, 
        allowResizing: false, 
        allowSorting: false ,
        visible: this.pageType !== 'Dtl',
      },
      {
        binding: "promotionNo",
        header: "LG Promotion#",
        width: 200,
        allowDragging: true,
        isRequired: true,
      },
      {
        binding: "orgRequestAmt",
        header: "Org. Request Amt",
        width: 140,
        allowDragging: true,
        isReadOnly: true, 
        align: "right",
        format: "n2",
        aggregate: 'Sum',
        visible: !this.orgRequestHidden,
      },
      {
        binding: "requestAmt",
        header: "Request Amt",
        width: 120,
        allowDragging: true,
        isRequired: true,
        format: "n2",        
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
        align: "right",
        cellTemplate: (ctx, cell) => {
          let value = ctx.value;
          let element = createElement(
            format(`<span style="color: red">${wijmo.Globalize.format(value, 'n2')}</span>`, {} ), cell
          );
        }
      },     
      {
        binding: "rejectCode",
        header: "Rejection Reason",
        width: 180,
        allowDragging: true,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
        dataMap: this.rejectYn === 'Y' ? this.rejectionReasonMap : undefined,
      },
      {
        binding: "rejectDesc",
        header: "Rejection Comment",
        width: 160,
        allowDragging: true,
        isRequired: false,
        visible: (this.pageType === 'Dtl') || (this.pageType === 'Mod' && this.rejectYn === 'Y'),
      },
      {
        binding: "activitySalesClaimDate",
        header: "Activity Date",
        width: 130,
        allowDragging: true,
        isRequired: true,
        align: "center",
        format: 'MM/dd/yyyy',
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          if (value) {
            ctx.value = wijmo.Globalize.format(new Date(value), 'MM/dd/yyyy');
            return ctx.value;
          } else {
            return '';
          }
        },
        editor: new InputDate(document.createElement("div"), {
          format: "MM/dd/yyyy",
          mask: "##/##/####",
          isRequired: false,
          invalidInput: (s, e) => {
            if (s.text === "__/__/____") {
              s.text = "";
              s.value = null;
            }
          },
        })
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 150,
        allowDragging: true,
        isRequired: false,
        cellTemplate: (ctx, cell) => {
          if (this.pageType === 'Dtl')
            return ctx.value;

          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openChargePopup;
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 150,
        allowDragging: true,
        isRequired: false,		
        align: "right",
        format: "n2",
        editor: (new InputNumber(document.createElement('div'), { format: "n2", min: 0 })),
        aggregate: 'Sum',
        align: "right",
        cellTemplate: (ctx, cell) => {
          let value = ctx.value;
          let element = createElement(
            format(`<span style="color: red">${wijmo.Globalize.format(value, 'n2')}</span>`, {} ), cell
          );
        }
      },
      {
        binding: "pgmReasonCode",
        header: "PGM Reason Code",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "lgPgmNo",
        header: "LG Program No",
        width: 150,
        allowDragging: true,
        cellTemplate: (ctx, cell) => {         
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;  
          let callFunc = this.openLgProgramPopup;

          if (this.pageType === 'Dtl')
            return ctx.value;
          if (this.claimAnalystYn !== 'Y') {
            return ctx.value;
          }

          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

          let buttonElement = element.querySelector("img.grid_btn_find");
          buttonElement.onclick = function (e) {
            e.preventDefault();
            callFunc.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "lgPgmName",
        header: "LG Program Name",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
      },
      {
        binding: "pgmBalance",
        header: "PGM Balance",
        width: 150,
        allowDragging: false,
        isReadOnly: true,
        format: "n2",        
        visible: this.pageType !== 'Dtl'
      },
      {
        binding: "orgPgmNo",
        header: this.pageType === 'Dtl' ? "Org. Program No" : "Transfer From Program No",
        width: 200,
        allowDragging: true,
        visible: this.claimAnalystYn === 'Y' || this.pageType === 'Dtl',
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let value = ctx.value;
          let rowIndex = ctx.row.index;
          let dataItem = ctx.row.dataItem;
          let callFunc = this.openOrgProgramPopup;

          if (this.isDisabledorgPgmNo) {
            return ctx.value;
          }
          
          let element = createElement(
            format(
              `<div class="wj-cell-button">
                <span>
                  ${value || ""}
                </span> 
                <img class="grid_btn_find" src='${search}' />
              </div>`
            ,{}),cell);

            
          let buttonElement = element.querySelector("img.grid_btn_find");

          buttonElement.onclick = (e) => {            
            e.preventDefault();
            callFunc.apply(e, [{ rowIndex, dataItem, ctx }, e]);
          };
        },
      },
      {
        binding: "orgBillTo",
        header: this.pageType === 'Dtl' ? "Org. Bill To" : "Transfer From Bill To",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
        isRequired: false,
        visible: this.claimAnalystYn === 'Y' || this.pageType === 'Dtl',
      },
      {
        binding: "approvedAmt",
        header: "Approved.Amt",
        width: 150,
        align: "right",
        format: "n2",
        aggregate: 'Sum',
        allowDragging: true,
        visible: this.pageType === 'Dtl',
      },
      {
        binding: "customerDesc",
        header: "Customer Description",
        width: "*",
        minWidth: 250,
        allowDragging: true,
        isRequired: false,
      },
      {
        binding: "disputeFlag",
        header: "Dispute",
        width: 150,
        allowDragging: true,
        dataMap: this.disputeMap,
        visible: this.pageType !== 'Dtl',
      },
    ];

    return out;
  }

  setFormatItem = (s, e) => {
    if (s.columns[e.col]?.binding === "promotionNo") {
      s.columnFooters.setCellData(0, e.col, "Total", true, false);
    }
    if (e.panel === s.columnFooters) {
      e.cell.style.justifyContent = "end";
    }
  }

  getData = () => {
    return [{
      isChecked2: false,
      promotionNo: "",
      requestAmt: null,
      activitySalesClaimDate: null,
      lgChargebackNo: "",
      lgChargebackAmt: null,
      pgmReasonCode: "",
      lgPgmNo: "",
      lgPgmName: "",
      pgmBalance: "",
      orgPgmNo: "",
      orgBillTo: "",
      customerDesc: "",
      disputeFlag: "",
    }];
  }


  getTemplateFileName = () => {
    return "ADV";
  }

  getTemplateGirdFormat = () => {
    return [
      {
        binding: "promotionNo",
        header: "*LG Promotion#",
        width: 200,
      },
      {
        binding: "requestAmt",
        header: "*Request Amt",
        width: 120,
        align: "right",  
      },
      {
        binding: "activitySalesClaimDate",
        header: "Activity Date",
        width: 150,
        align: "center"
      },
      {
        binding: "customerDesc",
        header: "Customer Description",
        width: "*",
        minWidth: 250,
      },
      {
        binding: "lgChargebackNo",
        header: "LG Chargeback#",
        width: 200,
      },
      {
        binding: "lgChargebackAmt",
        header: "LG Chargeback Amt",
        width: 150,
        align: "right",
      },
      {
        binding: "lgPgmNo",
        header: "LG Program No",
        width: 200,
      },
      {
        binding: "lgPgmName",
        header: "LG Program Name",
        width: 200,
      },      
    ];
  }

  getTemplateData = () => {
    return [{
      promotionNo: "input LG Promotion No",
      requestAmt: "1",
      activitySalesClaimDate: "12/31/2015",
      lgChargebackNo: "input Chargeback No",
      lgChargebackAmt: "1",
      lgPgmNo: "input LG Program No",
      lgPgmName: "input LG Program Name",
      customerDesc: "input Customer Description",
    }];
  }

  convertTemplateToGridData = (el, idx) => {
    let gridFormat = this.getTemplateGirdFormat();

    return {
      rowNum: idx,
      promotionNo: el[this.getHeaderByBinding(gridFormat, "promotionNo")],
      requestAmt: el[this.getHeaderByBinding(gridFormat, "requestAmt")],
      activitySalesClaimDate: el[this.getHeaderByBinding(gridFormat, "activitySalesClaimDate")],
      lgChargebackNo: el[this.getHeaderByBinding(gridFormat, "lgChargebackNo")],
      lgChargebackAmt: el[this.getHeaderByBinding(gridFormat, "lgChargebackAmt")],
      lgPgmNo: el[this.getHeaderByBinding(gridFormat, "lgPgmNo")],
      lgPgmName: el[this.getHeaderByBinding(gridFormat, "lgPgmName")],
      customerDesc: el[this.getHeaderByBinding(gridFormat, "customerDesc")],
    }
  }

  isMergeNumbering() {
    return false;
  }
}
export default ClaimADV;