import { ComUtils } from "common/lib/ComUtils";
import moment from 'moment/moment';
import { useEffect, useRef } from "react";
import { ClmWindowStyle } from "../Rsf/ClmWindowStyle";


function IrSpiffDateOverlapReqDtl({
    claimType,
    claimId,
    customerInfo,
    claimList,
    fileList,
    userId,
    totRejectCount,
    userLevel,
    userType,
    serverUrl
}) {
    /*******Grid Format *********************************************************************************/
    //Memory
    const _isMounted = useRef(true);

    /**********************************  Page Load 설정 **********************************/

    // 데이터 로딩 초기화
    useEffect(() => {

        let abortController = new AbortController();

        // ComponentWillUnmount in Class Component
        return () => {
            _isMounted.current = false;
            abortController.abort();
        };
    }, []);



    return (

        <div style={ClmWindowStyle.printWrapper}>
            <div style={ClmWindowStyle.sectionWrapper}>
                <div style={ClmWindowStyle.filterTitle}>
                    <span style={{ fontSize: "14px", color: "#44403c", fontWeight: "bold" }}>Dealer Information</span>
                </div>
                <div
                    style={{ marginTop: "5px" }}
                >
                    <div
                        style={
                            {
                                border: "solid 1px rgba(58, 53, 65, 0.12)",
                                height: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "14px",
                                fontWeight: "700",
                                margin: "10px 0px",
                                textAlign: "center",
                                background: "#f2f2f2",
                                display: (claimType === "IR" ? 'none' : (customerInfo.status === "MODI" ? '' : (customerInfo.status === "APRV" ? '' : 'none')))
                            }}>

                        {customerInfo.status === "MODI" ? 'This claim is in "Draft" status. To submit this claim, click on Submit button.' : ''}
                        {customerInfo.status === "APRV" ? 'Your claim is approved.' : ''}

                    </div>
                    <div style={ClmWindowStyle.bxSearch2FixedColumn1}>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}> Claim Number </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {claimId}
                            </div>
                        </div>
                    </div>
                    <div style={ClmWindowStyle.bxSearch2FixedColumn3}>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Bill To Code</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.billTo}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Bill To Name</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.billToName}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>
                                    Address Line 1
                                </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.address1}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Address Line 2</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.address2}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>
                                    City
                                </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.city}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>
                                    State
                                </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.state}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>
                                    Zip Code
                                </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.zip}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>
                                    Dealer Contact Tel
                                </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.tel}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Dealer Contact Fax</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.fax}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Dealer Contact Email</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.email}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Accounts Payable Email</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.acctPayEmail}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Chargeback #</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.chargeBackNo}
                            </div>
                        </div>
                    </div>
                    <div style={ClmWindowStyle.bxSearch2FixedColumn1}>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}> Remark </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.remark}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}> LG Only Remark </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {claimType === "IR" ? customerInfo.lgComment : customerInfo.remarkInternal}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}> Review Comment</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.review}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={ClmWindowStyle.sectionWrapper}>
                <div style={ClmWindowStyle.filterTitle}>
                    <span style={ClmWindowStyle.txtTitle}>Rebate Claim Information</span>
                </div>
                <div style={{ marginTop: "5px" }}>
                    <div style={ClmWindowStyle.bxSearch2FixedColumn3}>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Promotion No</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.promotionNo}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Program ID</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.programId}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>
                                    Debit Memo #
                                </label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.debitMemo}
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Total Debit Amt.</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>$ {(customerInfo.debitMemoAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>

                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>Payment Method</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                <label>
                                    <input
                                        type="radio"
                                        name="payMethod"
                                        value="1"
                                        checked={customerInfo.payMethod === '1'}
                                        disabled
                                    />
                                    Credit
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="payMethod"
                                        value="0"
                                        checked={customerInfo.payMethod === '0'}
                                        disabled
                                    />
                                    Check
                                </label>
                            </div>
                        </div>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}>LG Salesperson</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.salesmanName}
                            </div>
                        </div>
                    </div>
                    <div style={ClmWindowStyle.bxSearch2FixedColumn1}>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={ClmWindowStyle.divLabel}>
                                <label style={ClmWindowStyle.txtLabel}> Claim Analyst</label>
                            </div>
                            <div style={ClmWindowStyle.bxInput}>
                                {customerInfo.claimAnalyst}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={ClmWindowStyle.sectionWrapper}>
                <span style={ClmWindowStyle.sectionTitle}>
                    <h3>{"Request List"}</h3>
                    <p style={ClmWindowStyle.txtTotal}>
                        {"Total"}
                        <strong style={ClmWindowStyle.txtNum}>{claimList?.length?.toLocaleString()}</strong>
                    </p>
                </span>
                <div style={{ marginTop: "5px" }}>
                    <div style={{ marginTop: "10px", display: claimType === "IR" ? '' : 'none' }}>
                        <table style={ClmWindowStyle.tableOverlapStyle} id='tbl0' >
                            <thead>
                                <tr>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Program Period</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Model</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Rebate Amount</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">LG Unit<br />Reimbursement</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Claim<br />Quantity</th>
                                    {totRejectCount > 0 && <th style={ClmWindowStyle.thOverlapStyle} scope="col">LG Confirm<br />Quantity</th>}
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Reimbursement<br />Total</th>
                                    {totRejectCount > 0 && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Reject Comment</th>}
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Status</th>
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Net Sell-thru<br />Quantity</th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Net Sell-In Quantity</th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Net Claim Quantity</th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Net Claim <br />Allowed<br />Quantity</th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Sell Out Date Range</th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Sold Date <br />Overlapped</th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Cust. unit<br />Reimburse </th>}
                                    {userLevel === "1" && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Over CM Amt.</th>}

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    claimList?.map((fn, index) => (
                                        <tr key={index}>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign} id={"rowId" + index}>{fn.period}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{fn.model}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {(fn.spiffIrAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {(fn.reimbursementAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{(fn.claimQty ?? 0).toLocaleString()}</td>
                                            {totRejectCount > 0 && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{(fn.arConfQty ?? 0).toLocaleString()}</td>}
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {(fn.reimburAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            {totRejectCount > 0 && <td style={ClmWindowStyle.tdOverlapStyleLeftAlign}>{fn.rejectComment}</td>}
                                            <td style={ClmWindowStyle.tdOverlapStyleLeftAlign}>{fn.statusDesc === "MODIFY" ? "Draft" : fn.statusDesc}</td>
                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{(fn.netScmSellThru ?? 0).toLocaleString()}</td>}
                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{(fn.netSellInQty ?? 0).toLocaleString()}</td>}   {/* Net Sell-In Quantity */}
                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{((fn.netAprvQty ?? 0).toLocaleString() + " APRV")} <br />
                                                {((fn.netPendQty ?? 0).toLocaleString() + " HOLD")}</td>}   {/* Net Sell-In Quantity */}
                                            {userLevel === "1" && <td style={{
                                                border: "1px solid rgba(58, 53, 65, 0.12)",
                                                height: "30px",
                                                fontSize: "11px",
                                                padding: "0px 5px",
                                                textAlign: "right",
                                                color: (fn.claimQty ?? 0) > (fn.allowedQty ?? 0) ? 'red' : ''
                                            }}>{(fn.allowedQty ?? 0).toLocaleString()}</td>}   {/* Net Claim Allowed Quantity */}
                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>
                                                {moment(ComUtils.DateToInteger(fn.dateSoldFrom) + "", "YYYYMMDD").format("MM/DD/YYYY")}~
                                                {moment(ComUtils.DateToInteger(fn.dateSoldTo) + "", "YYYYMMDD").format("MM/DD/YYYY")}
                                            </td>}


                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleLeftAlign}>{fn.dateOverlap}</td>}
                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{fn.custUnitReimburse == '' || fn.custUnitReimburse == null ? '' : (fn.custUnitReimburse ?? 0).toLocaleString()}</td>}
                                            {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{fn.overCmAmt == null || fn.overCmAmt == '' ? '' : ('$ ' + (fn.overCmAmt ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</td>}
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign} colSpan="4">Total</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{claimList.reduce((sum, claim) => sum + claim.claimQty, 0).toLocaleString()}</td>
                                    {totRejectCount > 0 && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{claimList.reduce((sum, claim) => sum + claim.arConfQty, 0).toLocaleString()}</td>}
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {claimList.reduce((sum, claim) => sum + claim.reimburAmount, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign} colSpan={totRejectCount > 0 ? "8" : "7"}></td>
                                    {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{claimList.reduce((sum, claim) => sum + claim.custUnitReimburse, 0).toLocaleString()}</td>}
                                    {userLevel === "1" && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {claimList.reduce((sum, claim) => sum + claim.overCmAmt, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>}

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* SPIFF */}

                    <div style={{ marginTop: "10px", display: claimType === "IR" ? 'none' : '' }}>
                        <table style={ClmWindowStyle.tableOverlapStyle} id='tbl0' >
                            <thead>
                                <tr>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Program Period</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Model</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Claim Qty</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Unit Spiff<br />Amount</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Claim Amount</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Approved Qty</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Approved Amount</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Cust. Invoice #</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Sales Date</th>
                                    {(customerInfo.payMethod === "1" && userType === "E") && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Sell Out Date Range</th>}
                                    {(customerInfo.payMethod === "1" && userType === "E") && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Sold Date <br />Overlapped</th>}
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Delivery Date</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">Back-up Submission Method</th>
                                    {(customerInfo.payMethod === "1" && userType === "E") && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Cust. unit<br />Reimburse</th>}
                                    {(customerInfo.payMethod === "1" && userType === "E") && <th style={ClmWindowStyle.thOverlapStyle} scope="col">Over CM Amt.</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    claimList?.map((fn, index) => (
                                        <tr key={index}>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign} id={"rowId" + index}>{fn.period}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{fn.model}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{(fn.qty ?? 0).toLocaleString()}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {(fn.spiffIrAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {(fn.claimAmount ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{fn.status === "APRV" ? (fn.arConfirmQty ?? 0).toLocaleString() : 0}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {fn.status === "APRV" ? ((fn.arConfirmQty ?? 0) * (fn.spiffIrAmount ?? 0)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{fn.invoiceNo}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{moment(ComUtils.DateToInteger(fn.salesDate) + "", "YYYYMMDD").format("MM/DD/YYYY")}</td>
                                            {(customerInfo.payMethod === "1" && userType === "E") &&
                                                <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>

                                                    {fn.dateSoldFrom && moment(ComUtils.DateToInteger(fn.dateSoldFrom) + "", "YYYYMMDD").format("MM/DD/YYYY")}~
                                                    {fn.dateSoldTo && moment(ComUtils.DateToInteger(fn.dateSoldTo) + "", "YYYYMMDD").format("MM/DD/YYYY")}
                                                </td>
                                            }
                                            {(customerInfo.payMethod === "1" && userType === "E") &&
                                                <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>
                                                    {fn.dateOverlap}
                                                </td>
                                            }
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{fn?.deliveryDate && moment(ComUtils.DateToInteger(fn.deliveryDate) + "", "YYYYMMDD").format("MM/DD/YYYY")}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{fn.backUpMethod}</td>
                                            {(customerInfo.payMethod === "1" && userType === "E") && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{fn.custUnitReimburse == '' || fn.custUnitReimburse == null ? '' : (fn.custUnitReimburse ?? 0).toLocaleString()}</td>}
                                            {(customerInfo.payMethod === "1" && userType === "E") && <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{fn.overCmAmt == null || fn.overCmAmt == '' ? '' : ('$ ' + (fn.overCmAmt ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))}</td>}

                                        </tr>
                                    ))
                                }
                                <tr>

                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign} colSpan="2">Total</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{claimList.reduce((sum, claim) => sum + claim.qty, 0).toLocaleString()}</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}></td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {claimList.reduce((sum, claim) => sum + claim.claimAmount, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>{claimList.reduce((sum, claim) => sum + (claim.arConfirmQty), 0).toLocaleString()}</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>$ {claimList.reduce((sum, claim) => sum + ((claim.arConfirmQty ?? 0) * (claim.spiffIrAmount ?? 0)), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign} colSpan="6"></td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}>
                                        {(customerInfo.payMethod === "1" && userType === "E") && claimList.reduce((sum, claim) => sum + claim.custUnitReimburse, 0).toLocaleString()}
                                    </td>
                                    <td style={ClmWindowStyle.tdOverlapStyleRightAlign}> $
                                        {(customerInfo.payMethod === "1" && userType === "E") && claimList.reduce((sum, claim) => sum + claim.overCmAmt, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div style={ClmWindowStyle.sectionWrapper}>
                <div style={{ marginTop: "5px" }}>
                    <div style={ClmWindowStyle.bxSearch2FixedColumn2}>
                        <div style={ClmWindowStyle.bxItem}>
                            <div style={{ width: "100%", height: "100%" }}>
                                <table style={ClmWindowStyle.tableOverlapStyle}>
                                    <thead>
                                        <tr>
                                            <th style={ClmWindowStyle.thOverlapStyle} scope="col">Asst.</th>
                                            <th style={ClmWindowStyle.thOverlapStyle} scope="col">Mgr.</th>
                                            <th style={ClmWindowStyle.thOverlapStyle} scope="col">Sr.Mgr.</th>
                                            <th style={ClmWindowStyle.thOverlapStyle} scope="col">VP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{customerInfo.status === "APRV" ? customerInfo.claimAnalyst : ''}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}></td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{customerInfo.status === "APRV" ? customerInfo.approver : ''}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={ClmWindowStyle.sectionWrapper}>
                {/* <div className="header-table"> */}
                <span style={ClmWindowStyle.sectionTitle}>
                    <h3>{"Attachment"}</h3>
                </span>
                {/* </div> */}

                <div style={{ marginTop: "5px" }}>
                    <div style={{ marginTop: "10px" }}>
                        <table style={ClmWindowStyle.tableOverlapStyle}>
                            <thead>
                                <tr>
                                    <th style={{ ...ClmWindowStyle.thOverlapStyle, width: "100px" }} scope="col">No.</th>
                                    <th style={ClmWindowStyle.thOverlapStyle} scope="col">fileName</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fileList?.map((fn, index) => (
                                        <tr key={index}>
                                            <td style={ClmWindowStyle.tdOverlapStyleCenterAlign}>{index + 1}</td>
                                            <td style={ClmWindowStyle.tdOverlapStyleLeftAlign}>
                                                <p
                                                    className="fileDown"
                                                    data-id={`${fn.fileId}`}
                                                    data-seq={`${fn.fileSeq}`}
                                                    style={{ cursor: "pointer", width: "fit-content" }}
                                                >
                                                    {fn.originalFileName}
                                                </p>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input type="hidden" name="serverUrl" id="serverUrl" value={serverUrl} />
            <input type="hidden" name="claimType" id="claimType" value={claimType} />
            {/* <div className="section-wrapper">
        Debit memo and sales report must be included with each IR claim. To attach debit memo and sales report files<br />
        electronically, please submit files above. If you cannot upload those documents as attachment files, please mail-in <br />
        those documents to the following address. If you have any questions or concerns, please email us at <br />
        <a href="mailto:ir.claim@lge.com" style={{ color: "blue" }}>ir.claim@lge.com</a>
      </div>
      <br />

      <div className="section-wrapper">
        <td align="center" class="text_comment2">ATTN: Account Receivable / IR&SPIFF Team <br />
          LG Electronics  U.S.A., Inc.<br />
          &nbsp;111 Sylvan Ave.<br />
          &nbsp;North Building<br />
          &nbsp;Englewood Cliffs, NJ 07632
        </td>
        <td width="10%" align="right"></td>
      </div> */}
        </div >
    );
}

export default IrSpiffDateOverlapReqDtl;
