import React, { useState, useEffect, useContext, useRef, useCallback, pageInfo } from 'react';

import { Button } from '@mui/material';
import 'assets/styles/pages/ModalCommon.scss';

import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';
import ComTextBox from 'common/components/ComTextBox';

import * as wjInput from '@grapecity/wijmo.react.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from 'common/lib/ComUtils';
import { ComNoticfication } from 'common/components/ComNoticfication';
import { MessageContext } from "common/lib/MessageProvider";


function ModelSchPop({ open, closeFn, randomKey, reqModelCode, reqModelDesc, reqInvoiceNo, reqClaimAnalystYn, modelResData, mainGubunCd, pageInfo }) {

  // 메세지 추가
  const { messageObject } = useContext(MessageContext);
  const msgClmErr011 = ComUtils.getMessage(messageObject, "CLM_ERR_00011"); // An error occurred during processing

  //session정보
  const comCode = sessionStorage.getItem("comCode").toString();

  //url 쿼리분기조건
  const retrieveModelList = mainGubunCd ? "/com/comPop/retrieveGsrModelList" : "/com/comPop/retrieveModelList";

  // 검색
  const entityModelCode = useRef(null);


  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);
  const filterSearch = useRef(null);

  const [gridObject, setGridObject] = useState(null);
  const [gridDataCount, setGridDataCount] = useState('0');

  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const initFilter = (f) => {
    filterSearch.current = f;
  };

  const initGrid = (sender) => {
    setGridObject(sender);

    // sender.rowHeaders.columns.splice(0, 1);
    ComUtils.gridInit(sender, { hasShowNumbering: true, selectionMode: 6, canAreaInfo: false, hasCheckBox: false, hasDisableCheck: true, hasReadOnlyCombo: true, readOnlyComboList: ["langCode", "effectiveStartDate"] })


    sender.addEventListener(sender.hostElement, "dblclick", (e) => {
      let ht = sender.hitTest(e);

      if (ht._row !== -1 && ht._p.cellType === 1 && (sender.selectedItems) && Array.isArray(sender.selectedItems) && sender.selectedItems.length > 0) {
        popComponent.current.hide();
        closeFn(sender.selectedItems[0]);
      }
    });

    sender.scrollPositionChanged.addHandler((s, e) => {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        if (pageCntRef.current != totCntRef.current) {
          let view = s.collectionView;
          if (view) {
            let index = view._pgView.length; // keep position in case the view is sorted
            entityPageNo.current += 1;
            let pageNoVal = entityPageNo.current;
            searchDataRef.current.pageNo = pageNoVal.toString();
            onSearchExecute(searchDataRef, { searchFlag: false, view: view })
            view.refresh();
            view.currentPosition = index;
          }
        }
      }
    });
  };
  // Grid Format
  const SearchGridFormat = useCallback(() => {

    let gridFormat = [
      { binding: 'modelCode', header: "Model Code", width: 200, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: 'modelDesc', header: "Description", width: 400, allowDragging: true, isReadOnly: true, align: "left" }
      , { binding: 'textModel', header: "textModel", width: 350, allowDragging: true, isReadOnly: true, align: "left", visible: false }
      , { binding: 'productLevel3Code', header: "productLevel3Code", width: 150, allowDragging: false, isReadOnly: true, visible: false }
      , { binding: 'modelGrossWeight', header: "modelGrossWeight", width: 150, allowDragging: false, isReadOnly: true, visible: false }
    ];
    setGridColGroup([...gridFormat]);
  }, []);
  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {

    let abortController = new AbortController();

    const getPageInfo = async () => {

      //조회된 내역존재 (다건)
      if (modelResData) {
        setGridData(new CollectionView(modelResData.list, { trackChanges: true, refreshOnEdit: false }));

        entityPageNo.current = 1;
        let pageNoVal = entityPageNo.current;
        let sendParam = {
          comCode: comCode
          , modelCode: reqModelCode
          , modelDesc: reqModelDesc
          , invoiceNo: reqInvoiceNo
          , claimAnalystYn: reqClaimAnalystYn
          , pageSize: '1'
          , rowSize: pageInfo.pageRowSize
          , pageNo: pageNoVal.toString()
        }

        searchDataRef.current = sendParam;

        totCntRef.current = Number(modelResData.totCnt);
        pageCntRef.current = Number(modelResData.list.length);
        setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
      }

      SearchGridFormat();
    }

    if (open) {
      filterSearch?.current?.clear();

    }
    getPageInfo();
    return () => { _isMounted.current = false; abortController.abort(); }

  }, [open]);

  // 조회
  const onSearch = (param, e) => {

    pageCntRef.current = 0;
    totCntRef.current = 0;
    entityPageNo.current = 1;
    let pageNoVal = entityPageNo.current;

    let sendParam = {
      pageSize: '1'
      , rowSize: pageInfo.pageRowSize
      , pageNo: pageNoVal.toString()
      , comCode: comCode
      , modelCode: entityModelCode.current.getValue()
      , modelDesc: reqModelDesc
      , invoiceNo: reqInvoiceNo
      , claimAnalystYn: reqClaimAnalystYn
    }
    searchDataRef.current = sendParam
    onSearchExecute(sendParam, param);
  }

  const onSearchExecute = async (sendParam, param) => {

    filterSearch.current.clear();

    try {
      let resultData = null;
      if (param.searchFlag) {
        //조회버튼 클릭
        resultData = await ApiManager.post(retrieveModelList, sendParam);
      } else {
        //스크롤페이징
        resultData = await ApiManager.post(retrieveModelList, searchDataRef.current);
      }

      if (resultData?.statusCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr011);
        setGridDataCount('0');
      } else {
        let resultCnt = 0;
        if (param.searchFlag) {

          setGridData(
            new CollectionView(resultData.list, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          resultCnt = resultData.list.length;
          // //단건시 팝업 close
          // if (resultCnt == 1) {
          //   popComponent.current.hide();
          //   closeFn(resultData.list[0]);
          // }
        } else {
          let collectionView = new CollectionView(resultData.list, {
            trackChanges: true,
            refreshOnEdit: false,
          })

          let totResultData = [...param.view._pgView, ...collectionView._pgView];
          setGridData(totResultData);
          resultCnt = totResultData.length;
        }
        totCntRef.current = Number(resultData.totCnt);
        pageCntRef.current = Number(resultCnt);

        if (totCntRef.current == pageCntRef.current) {
          setGridDataCount(totCntRef.current.toLocaleString());
        } else {
          setGridDataCount(pageCntRef.current.toLocaleString() + ' / ' + totCntRef.current.toLocaleString());
        }
      }
    }
    catch (ex) {
      console.log(ex.message)
    }
  }

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn(null);
  };

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true, (sender) => {
      closeFn(null);
    });
  }

  let top = 0;
  const shown = (p) => {
    top = p.hostElement.style.top;
  }
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  }

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)), window.scrollX);
    bnd.top = Math.max(Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height), window.scrollY);
  }

  const positionChanged = (s, e) => {
    //console.log(e);
    top = s.hostElement.style.top
  }

  const [customWidths, setCustomWidths] = useState([{ size: 200, minSize: 100, resize: "dynamic" }, { minSize: 100, resize: "dynamic" }]);
  const handlePanelUpdate = (widths) => { setCustomWidths(widths) };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"Model Search"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal ">
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item column-4column">
            <div className="div-label">
              <label className="txt-label">
                <span className="requiresimbol">*</span>
                Model Code / Desc
              </label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox ref={entityModelCode} elementId={"entityModelCode"} defaultValue={reqModelCode} />
              <Button variant="contained" onClick={(e) => { onSearch({ searchFlag: true, view: null }, e) }}>{"Search"}</Button>
            </div>
          </div>
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ width: "602px", minheight: "200px", height: "400px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ModelSchPop;
