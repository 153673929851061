//import React, { useState, useMemo, useEffect} from 'react';
import React, { useState, useEffect } from "react";
//import { RecoilRoot, atom, selector, useRecoilState, useSetRecoilState, useRecoilValue, useRecoilValueLoadable} from 'recoil';

import Footer from "./Footer";
import Header from "./Header";
import "assets/styles/layout/LayoutContainer.scss";
import "assets/styles_mobile/components/layout/pages/ResponsivePageCommon.scss";

import ContentLayer from "./ContentLayer";
import ContentHeader from "./ContentHeader";
import LandingHeader from "./LandingHeader";
import MessageProvider from "common/lib/MessageProvider";
import DashboardHeader from "./DashboardHeader";
import { useSelector } from "react-redux";
import TagPage from "./TagPage";

export default function LayoutContainer() {
  const [menuFixed, setMenuFixed] = useState(true);
  const currentMenu = useSelector((state) => state.openMenus);
  const currentPageItem = currentMenu.find((item) => item.Active) || null;
  const handleToggle = () => {
    setMenuFixed(!menuFixed);
  };

  // set scrollX value
  const [position, setPosition] = useState(0);
  const onScroll = () => {
    setPosition(window.scrollX);
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <MessageProvider>
      <div
        className={
          menuFixed ? "layout-container" : "layout-container menu-over"
        }
      >
        <Header
          handleToggle={handleToggle}
          menuFixed={menuFixed}
          position={position}
        />
        <div className="contents-wrap">
          {/* <DashboardHeader /> */}
          <LandingHeader
            handleToggle={handleToggle}
            menuFixed={menuFixed}
            position={position}
          />

          <TagPage />
          <div
            className="tab-container"
            style={{
              paddingTop: currentPageItem?.menuId !== "0" ? "79px" : "79px",
            }}
          >
            <div className="in-wrap">
              <div className="panel-wrap">
                <ContentLayer />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </MessageProvider>
  );
}
